import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import {
  white,
  primary,
  gutters,
  borderRadius,
  transitionBase,
  mqMax,
} from "client-lib";
import { Slide, SlideContainer, Header } from "src/features/layout";

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(/img/wood-bg.jpg);
  background-size: cover;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 46, 77, 0.6);
  }
`;

const Stepper = styled.div`
  margin-top: 100px;
  display: grid;
  column-gap: ${gutters.md}px;
  grid-template-columns: 1fr 1fr 1fr;
  ${mqMax.phone} {
    display: block;
    margin-top: 50px;
  }
`;

const Step = styled(Link)`
  height: 154px;
  box-sizing: border-box;
  background-color: ${white};
  border: 1px solid ${white};
  border-radius: ${borderRadius.lg}px;
  padding: ${gutters.xl}px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color ${transitionBase};
  &:hover {
    background-color: rgba(24, 24, 24, 0);
    color: ${white};
    transition: color ${transitionBase}, background-color ${transitionBase};
    > span {
      border-color: ${white};
      color: ${white};
      transition: color ${transitionBase}, border-color ${transitionBase};
    }
  }
  ${mqMax.phone} {
    margin-bottom: ${gutters.md}px;
  }
`;

const StepNumber = styled.span`
  width: 44px;
  height: 44px;
  border-radius: 22px;
  border: 1px solid ${primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${primary};
`;

const StepMessage = styled.div``;

const Message = styled.div`
  font-weight: 400;
  line-height: 1.4;
  color: ${white};
  max-width: 50%;
  ${mqMax.phone} {
    max-width: none;
    text-align: center;
    font-size: 110%;
  }
`;

export const HowSlide = memo(function SlideIntro() {
  return (
    <Slide>
      <Background />
      <SlideContainer>
        <Header color={white}>
          Как это
          <br />
          работает
        </Header>
        <Message>
          На Метафабрике можно произвести собственную мебель с помощью
          онлайн-конструктора. Он позволяет настроить тип материала, габариты,
          фурнитуру и множество других параметров. Завершив работу над макетом,
          вы оплачиваете заказ, и мы производим ваше изделие — в&nbsp;розницу
          или сразу оптовую партию.
        </Message>
        <Stepper>
          <Step to="/templates">
            <StepNumber>1</StepNumber>
            <StepMessage>Выбираете шаблон</StepMessage>
          </Step>
          <Step to="/templates">
            <StepNumber>2</StepNumber>
            <StepMessage>Настраиваете под себя</StepMessage>
          </Step>
          <Step to="/templates">
            <StepNumber>3</StepNumber>
            <StepMessage>Отправляете на производство</StepMessage>
          </Step>
        </Stepper>
      </SlideContainer>
    </Slide>
  );
});
