import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { primary, white, grey40, transitionFast } from "client-lib/styles";

const CARET_SIZE = 14;
const BAR_LINE_WIDTH = 2;

const Container = styled.div`
  position: relative;
  height: ${CARET_SIZE}px;
  width: 100%;
  user-select: none;
`;

const Bar = styled.div<{ progress: number }>(
  ({ progress }) => css`
    position: relative;
    margin-top: ${(CARET_SIZE - BAR_LINE_WIDTH) / 2}px;
    height: ${BAR_LINE_WIDTH}px;
    background-color: ${grey40};
    &:after {
      content: "";
      display: block;
      height: ${BAR_LINE_WIDTH}px;
      background-color: ${primary};
      width: ${progress}%;
    }
    div {
      left: ${progress}%;
    }
  `
);

const Caret = styled.div<{ isActive: boolean }>(({ isActive }) => [
  css`
    position: absolute;
    width: ${CARET_SIZE}px;
    height: ${CARET_SIZE}px;
    margin-left: -${CARET_SIZE / 2}px;
    top: -${(CARET_SIZE - BAR_LINE_WIDTH) / 2}px;

    border: 4px solid ${primary};
    border-radius: ${CARET_SIZE / 2}px;
    box-sizing: border-box;
    background-color: ${white};
    transition: transform ${transitionFast}, border-width ${transitionFast};
  `,
  isActive &&
    css`
      border-width: 2px;
      transform: scale(1.5, 1.5);
    `,
]);

interface Props {
  readOnly?: boolean;
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
}

export function Slider({ readOnly = false, value, min, max, onChange }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dragStartPosition, setDragStartPosition] = useState<number | null>(
    null
  );
  const [dragStartValue, setDragStartValue] = useState<number | null>(null);

  const normalizedValue = Math.min(max, Math.max(min, value));

  const relativeValue = useMemo<number>(() => {
    if (normalizedValue < min) {
      return 0;
    }
    if (normalizedValue > max) {
      return max;
    }
    if (min === max) {
      return 0;
    }

    return (normalizedValue - min) / (max - min);
  }, [normalizedValue, min, max]);

  const handleContainerMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (readOnly) {
        return;
      }
      if (!containerRef.current) {
        return;
      }
      const bounds = containerRef.current!.getBoundingClientRect();
      const relativeClickValue = (event.clientX - bounds.left) / bounds.width;
      const newValue = Math.round(min + (max - min) * relativeClickValue);
      onChange(Math.max(min, Math.min(newValue, max)));
    },
    [min, max, onChange, readOnly]
  );

  const handleTouchStart = useCallback(
    (event: React.TouchEvent) => {
      if (readOnly) {
        return;
      }
      const touch = event.touches.item(0);
      setDragStartPosition(touch.pageX);
      setDragStartValue(normalizedValue);
    },
    [normalizedValue, readOnly]
  );
  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (readOnly) {
        return;
      }
      setDragStartPosition(event.pageX);
      setDragStartValue(normalizedValue);
    },
    [normalizedValue, readOnly]
  );

  const handleTouchMove = useCallback(
    (event: React.TouchEvent) => {
      if (
        dragStartPosition === null ||
        dragStartValue === null ||
        !containerRef.current
      ) {
        return;
      }
      const touch = event.touches.item(0);
      const deltaRelational =
        (touch.pageX - dragStartPosition) / containerRef.current.clientWidth;
      const newValue = Math.round(
        dragStartValue + deltaRelational * (max - min)
      );
      onChange(Math.max(min, Math.min(newValue, max)));
    },
    [dragStartPosition, dragStartValue, min, max, onChange]
  );

  const handleTouchEnd = useCallback(() => {
    setDragStartPosition(null);
  }, []);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (
        dragStartPosition === null ||
        dragStartValue === null ||
        !containerRef.current
      ) {
        return;
      }
      const deltaRelational =
        (event.pageX - dragStartPosition) / containerRef.current.clientWidth;
      const newValue = Math.round(
        dragStartValue + deltaRelational * (max - min)
      );
      onChange(Math.max(min, Math.min(newValue, max)));
    },
    [dragStartPosition, dragStartValue, min, max, onChange]
  );

  useEffect(() => {
    const onMouseUp = () => setDragStartPosition(null);
    window.addEventListener("mouseup", onMouseUp);
    return () => window.removeEventListener("mouseup", onMouseUp);
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, [handleMouseMove]);

  return (
    <Container onMouseDown={handleContainerMouseDown} ref={containerRef}>
      <Bar progress={relativeValue * 100}>
        <Caret
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          isActive={dragStartPosition !== null}
        />
      </Bar>
    </Container>
  );
}
