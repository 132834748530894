import React, { useContext, useEffect, useMemo, useState } from "react";
import { TemplateEnvironment, TemplatePreset, Decomposition } from "shared";
import { TemplateFull } from "src/features/template-editor/types";
import { assembleTemplate, useTemplateVariables } from "./service";

type TemplateEditorValue<Config = any, Assets = any, Variables = any> = {
  templatePreset: TemplatePreset;
  templateVariables: Variables;
  templateFull: TemplateFull<Config, Assets, Variables>;
  readOnly: boolean;
  initialConfigValues: Config;
  configValues: Config;
  setConfigValues: (config: Config) => void;
  decomposition: Decomposition;
};

const TemplateEditorContext = React.createContext<TemplateEditorValue>({
  templatePreset: {} as TemplatePreset,
  templateVariables: {},
  templateFull: {} as TemplateFull<any, any, any>,
  readOnly: false,
  initialConfigValues: {} as any,
  configValues: {} as any,
  setConfigValues: () => {},
  decomposition: {} as Decomposition,
});

export function useTemplateEditor<
  Config,
  Assets,
  Variables
>(): TemplateEditorValue<Config, Assets, Variables> {
  return useContext<TemplateEditorValue<Config, Assets, Variables>>(
    TemplateEditorContext
  );
}

interface Props<Config> {
  templatePreset: TemplatePreset;
  templateEnvironment: TemplateEnvironment;
  readOnly?: boolean;
  rawInitialConfig?: string | null;
  onChange?: (config: Config) => void;
  children: React.ReactNode;
}

export function TemplateEditorProvider<Config, Assets, Variables>({
  templatePreset,
  templateEnvironment,
  readOnly = false,
  rawInitialConfig,
  onChange,
  children,
}: Props<Config>) {
  const templateFull = useMemo(
    () => assembleTemplate(templateEnvironment),
    [templateEnvironment]
  );

  const templateVariables = useTemplateVariables(templateFull, templatePreset);

  const initialConfigValues = useMemo<Config>(() => {
    const templateInitialValues = templateFull.model.configInitialValues(
      templateFull.assets
    );
    try {
      const presetInitialValues = templateFull.model.configSchema.parse(
        JSON.parse(rawInitialConfig || "")
      );
      return {
        ...templateInitialValues,
        ...presetInitialValues,
      };
    } catch (err) {}

    return templateInitialValues;
  }, [templateFull, rawInitialConfig]);

  const [configValues, setConfigValues] = useState<Config>(initialConfigValues);

  useEffect(
    function reinitialize() {
      setConfigValues(initialConfigValues);
    },
    [initialConfigValues]
  );

  useEffect(
    function reportChangeToParent() {
      onChange?.(configValues);
    },
    [onChange, configValues]
  );

  const decomposition = useMemo(() => {
    return templateFull.getDecomposition(
      configValues,
      templateFull.assets,
      templateVariables
    );
  }, [configValues, templateFull, templateVariables]);

  const value = useMemo<TemplateEditorValue<Config, Assets, Variables>>(
    () => ({
      templatePreset,
      templateVariables,
      templateFull,
      readOnly,
      initialConfigValues,
      configValues,
      onChange,
      setConfigValues,
      decomposition,
    }),
    [
      templatePreset,
      templateVariables,
      templateFull,
      readOnly,
      initialConfigValues,
      configValues,
      onChange,
      decomposition,
    ]
  );

  return (
    <TemplateEditorContext.Provider value={value}>
      {children}
    </TemplateEditorContext.Provider>
  );
}
