import React, { ReactNode, FunctionComponent, Ref, useEffect } from "react";
import styled, { StyledComponent } from "@emotion/styled";
import { css } from "@emotion/react";
import { useField, useFormikContext, FormikHandlers } from "formik";
import { Label } from "../label";

const Root = styled.div(({ horizontal }: { horizontal: boolean }) => [
  horizontal
    ? css`
        display: flex;
        align-items: center;
        label {
          margin-right: 0.5em;
        }
      `
    : null,
]);

const InputBlock = styled.div`
  display: flex;
  align-items: center;
`;

export interface InputProps<RefType = HTMLInputElement> {
  error?: string | null;
  disabled?: boolean;
  seamless?: boolean;
  type?: string;
  step?: string;
  children?: ReactNode;
  onChange: FormikHandlers["handleChange"];
  ref?: Ref<RefType>;
  controlProps?: any;
}

interface Options<Val> {
  horizontal?: boolean;
  treatEmptyAsNull?: boolean;
  translateInputValue?: (rawInputValue: any) => Val | string;
  formatValue?: (value: Val) => string;
}

export function withField<Val = any, RefType = any>(
  InputComponent:
    | FunctionComponent<InputProps<RefType>>
    | StyledComponent<any, InputProps<RefType>, any>,
  {
    horizontal = false,
    treatEmptyAsNull = true,
    translateInputValue,
    formatValue,
  }: Options<Val> = {}
) {
  return function ({
    label,
    LabelComponent,
    touched: forceTouched,
    error: forceError,
    hideError = true,
    name,
    disabled,
    seamless,
    type,
    step,
    children,
    side,
    onChange,
    inputRef,
    controlProps,
    horizontal: horizontalProp,
    className,
  }: {
    label?: string;
    LabelComponent?: typeof React.Component;
    touched?: boolean;
    error?: boolean;
    hideError?: boolean;
    name: string;
    disabled?: boolean;
    seamless?: boolean;
    type?: string;
    step?: string;
    children?: ReactNode;
    side?: ReactNode;
    onChange?: (value: Val) => void;
    inputRef?: Ref<RefType>;
    controlProps?: any;
    horizontal?: boolean;
    className?: string;
  }) {
    const [{ ...field }, meta, { setValue }] = useField<Val>(name);
    const { setFieldTouched } = useFormikContext();
    useEffect(
      function forceTouchedIfPropChanges() {
        if (forceTouched !== undefined) {
          setFieldTouched(name, forceTouched);
        }
      },
      [forceTouched, setFieldTouched]
    );

    const isError = Boolean(
      forceError ||
        (meta.error !== undefined && meta.touched ? meta.error : null)
    );

    const renderedLabel = label ? (
      <Label isError={isError}>
        {label}
        {!hideError && isError && meta.error}
      </Label>
    ) : LabelComponent ? (
      <LabelComponent isError={isError} />
    ) : null;

    return (
      <Root horizontal={horizontalProp || horizontal} className={className}>
        {renderedLabel}
        <InputBlock>
          <InputComponent
            {...controlProps}
            {...field}
            value={formatValue ? formatValue(field.value) : field.value}
            onChange={(e: React.ChangeEvent<any>) => {
              const rawInputValue = e.target.value;
              const translatedValue = translateInputValue
                ? translateInputValue(rawInputValue)
                : rawInputValue === "" && treatEmptyAsNull
                ? null
                : rawInputValue;

              setValue(translatedValue);
              if (onChange) {
                onChange(translatedValue);
              }
            }}
            type={type}
            step={step}
            error={isError}
            disabled={disabled}
            seamless={seamless}
            ref={inputRef}
          >
            {children}
          </InputComponent>
          {side}
        </InputBlock>
      </Root>
    );
  };
}
