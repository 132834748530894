import React, { useMemo } from "react";
import { MaterialExpanded, shelfLoft1Utils, getDimensionsMm } from "shared";
import { Box, Measurement } from "../../objects";
import { FrameItem } from "./frame-item";
import { useShelf } from "./shelf-context";
import { useSceneOptions } from "../../scene-context";

export function Package({
  frameMaterial,
  frameCoverageMaterial,
}: {
  frameMaterial: MaterialExpanded;
  frameCoverageMaterial: MaterialExpanded | null;
}) {
  const options = useSceneOptions();
  const { config, assets, variables } = useShelf();

  const positions = useMemo(
    () => shelfLoft1Utils.distributeShelvePositions(config, assets, variables),
    [config, assets, variables]
  );

  const framePipe = getDimensionsMm(frameMaterial);
  const { lengthMm, heightMm, widthMm } = useMemo(() => {
    const dimensions = shelfLoft1Utils.detectPackageDimensions(
      config,
      assets,
      variables
    );

    if (!dimensions) {
      return {
        lengthMm: 0,
        heightMm: 0,
        widthMm: 0,
      };
    }
    return {
      lengthMm: dimensions[0],
      heightMm: dimensions[1],
      widthMm: dimensions[2],
    };
  }, [config, assets, variables]);

  return (
    <React.Fragment>
      {/* <Measurements config={config} assets={assets} /> */}
      <group position={[0, -heightMm / 2, 0]}>
        {positions.map((position, index) => {
          const item = config.items[position.itemIndex];
          const coverageMaterial =
            assets.shelfCoverage.find((m) => m.id === item.coverageId) || null;

          const surfaceProps = coverageMaterial
            ? {
                xSurface: coverageMaterial.texture3,
                ySurface: coverageMaterial.texture1,
                zSurface: coverageMaterial.texture2,
              }
            : {
                xSurface: assets.shelfMaterial.texture3,
                ySurface: assets.shelfMaterial.texture1,
                zSurface: assets.shelfMaterial.texture2,
              };
          return (
            <Box
              key={index}
              xSize={position.length}
              ySize={item.thickness}
              zSize={item.width}
              {...surfaceProps}
              offset={[
                position.dx - position.layerLength / 2 + position.length / 2,
                position.dy +
                  (framePipe.d1 ?? 0) * (config.frameItemsCount + 0.5),
                0,
              ]}
            />
          );
        })}
        {new Array(config.frameItemsCount).fill(null).map((_, index) => (
          <FrameItem
            key={index}
            material={frameMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, 0, Math.PI / 2]}
            position={[
              config.frameHeight / 2,
              (framePipe.d1 ?? 0) * (index + 0.5),
              0,
            ]}
          />
        ))}
        {options.showMeasurements && (
          <React.Fragment>
            <Measurement
              key="Length"
              p1={[-lengthMm / 2, 0, widthMm / 2]}
              p2={[lengthMm / 2, 0, widthMm / 2]}
              normal={[0, -1, 0]}
              textInside
            />
            <Measurement
              key="thickness"
              p1={[lengthMm / 2, 0, 0]}
              p2={[lengthMm / 2, heightMm, 0]}
              normal={[1, 0, 0]}
              textRotation={[0, 0, Math.PI / 2]}
              textInside
            />
            <Measurement
              key="width"
              p1={[-lengthMm / 2, 0, widthMm / 2]}
              p2={[-lengthMm / 2, 0, -widthMm / 2]}
              normal={[-1, 0, 0]}
              textRotation={[-Math.PI / 2, 0, Math.PI / 2]}
            />
          </React.Fragment>
        )}
      </group>
    </React.Fragment>
  );
}
