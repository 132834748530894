import React, { useContext, useState, useEffect, useRef } from "react";

export type DeliveryValue = {
  total: number;
  ozon: number;
  tk: number;
  region: string | null;
  deliveryLocationId: string | null;
  setTotal: (v: number) => void;
  setOzon: (v: number) => void;
  setTk: (v: number) => void;
  setRegion: (v: string | null) => void;
  setDeliveryLocationId: (v: string | null) => void;
};

export const deliveryValueDefault = {
  total: 0,
  ozon: 0,
  tk: 0,
  region: null,
  deliveryLocationId: null,
  setTotal: () => {},
  setOzon: () => {},
  setTk: () => {},
  setRegion: () => {},
  setDeliveryLocationId: () => {},
};

const DeliveryContext =
  React.createContext<DeliveryValue>(deliveryValueDefault);

export function useDelivery(): DeliveryValue {
  return useContext(DeliveryContext);
}

export interface DeliveryProviderProps {
  tk: number;
  ozon: number;
  region: string | null;
  deliveryLocationId: string | null;
  children: React.ReactNode;
}

export function DeliveryProvider({
  tk: tkInitial,
  ozon: ozonInitial,
  region: regionInitial,
  deliveryLocationId: deliveryLocationIdInitial,
  children,
}: DeliveryProviderProps) {
  const amountsRef = useRef<{ total: number; ozon: number; tk: number }>({
    total: tkInitial + ozonInitial,
    ozon: ozonInitial,
    tk: tkInitial,
  });
  const [total, setTotal] = useState<number>(amountsRef.current.total);
  const [ozon, setOzon] = useState<number>(amountsRef.current.ozon);
  const [tk, setTk] = useState<number>(amountsRef.current.tk);
  const [region, setRegion] = useState<string | null>(regionInitial);
  const [deliveryLocationId, setDeliveryLocationId] = useState<string | null>(
    deliveryLocationIdInitial
  );

  useEffect(
    function handleTotalChange() {
      amountsRef.current.total = total;
      let delta =
        amountsRef.current.total -
        (amountsRef.current.tk + amountsRef.current.ozon);
      const keys: ("tk" | "ozon")[] =
        amountsRef.current.tk > amountsRef.current.ozon
          ? ["tk", "ozon"]
          : ["ozon", "tk"];
      for (const key of keys) {
        if (delta === 0) {
          break;
        }
        if (amountsRef.current[key] + delta >= 0) {
          amountsRef.current[key] += delta;
          delta = 0;
        } else {
          delta += amountsRef.current[key];
          amountsRef.current[key] = 0;
        }
      }
      setOzon(amountsRef.current.ozon);
      setTk(amountsRef.current.tk);
    },
    [total]
  );

  useEffect(
    function handleOzonChange() {
      amountsRef.current.ozon = Math.min(amountsRef.current.total, ozon);
      amountsRef.current.tk =
        amountsRef.current.total - amountsRef.current.ozon;
      setOzon(amountsRef.current.ozon);
      setTk(amountsRef.current.tk);
    },
    [ozon]
  );

  useEffect(
    function handleTkChange() {
      amountsRef.current.tk = Math.min(amountsRef.current.total, tk);
      amountsRef.current.ozon =
        amountsRef.current.total - amountsRef.current.tk;
      setOzon(amountsRef.current.ozon);
      setTk(amountsRef.current.tk);
    },
    [tk]
  );

  return (
    <DeliveryContext.Provider
      value={{
        total,
        ozon,
        tk,
        region,
        deliveryLocationId,
        setTotal,
        setOzon,
        setTk,
        setRegion,
        setDeliveryLocationId,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  );
}
