import { breakpointsMin, breakpointsMax, gutters } from "./variables";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const CONTAINER_WIDTH = 1140;
export const BROWSER_SCROLLBAR_WIDTH = 20;

export const mqMin = {
  phone: `@media (min-width: ${breakpointsMin.phone + 1}px)`,
  tablet: `@media (min-width: ${breakpointsMin.tablet + 1}px)`,
  desktop: `@media (min-width: ${breakpointsMin.desktop + 1}px)`,
  container: `@media (min-width: ${
    CONTAINER_WIDTH + BROWSER_SCROLLBAR_WIDTH + 1
  }px)`,
};

export const mqMax = {
  phone: `@media (max-width: ${breakpointsMax.phone}px)`,
  tablet: `@media (max-width: ${breakpointsMax.tablet}px)`,
  desktop: `@media (max-width: ${breakpointsMax.desktop}px)`,
  container: `@media (max-width: ${
    CONTAINER_WIDTH + BROWSER_SCROLLBAR_WIDTH
  }px)`,
};

export const stdContainer = css`
  max-width: ${CONTAINER_WIDTH}px;
  margin: 0 auto;
`;

export const StdContainer = styled.div<{ responsivePadding?: boolean }>([
  stdContainer,
  ({ responsivePadding }) =>
    responsivePadding
      ? css`
          @media (max-width: ${CONTAINER_WIDTH - gutters.md}px) {
            padding-left: ${gutters.md}px;
            padding-right: ${gutters.md}px;
          } ;
        `
      : null,
]);

function cellWidth(value: number): string {
  return `${Math.floor((value / 12) * 1000 * 100) / 1000}%`;
}

export const Row = styled.div``;

export const Cell = styled.div<{
  base: number;
  tablet?: number;
  phone?: number;
}>(({ base, tablet, phone }) => [
  css`
    box-sizing: border-box;
    width: ${cellWidth(base)};
    display: inline-block;
    vertical-align: top;
  `,
  tablet !== undefined &&
    css`
      ${mqMax.tablet} {
        width: ${cellWidth(tablet)};
      }
    `,
  phone !== undefined &&
    css`
      ${mqMax.phone} {
        width: ${cellWidth(phone)};
      }
    `,
]);
