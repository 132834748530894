import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  gutters,
  black,
  grey50,
  errorRed,
  primary,
  borderRadius,
  typographyBody2,
} from "../../styles";
import { StepData } from "./types";

const borderColor = grey50;

const INDICATOR_SIZE = 18;
const INDICATOR_CORE_SIZE = 10;

const Container = styled.div<{
  isFirst: boolean;
  isLast: boolean;
  isActive: boolean;
  isChecked: boolean;
  isError: boolean;
  color: string;
}>(({ isFirst, isLast, isActive, isError, color }) => [
  css`
    position: relative;
    text-decoration: none;
    color: ${isActive ? (isError ? errorRed : color) : black};
  `,
  !isFirst &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: ${INDICATOR_SIZE / 2}px;
        height: 1px;
        left: -${INDICATOR_SIZE / 2}px;
        width: 50%;
        background-color: ${borderColor};
      }
    `,
  !isLast &&
    css`
      &:after {
        content: "";
        position: absolute;
        top: ${INDICATOR_SIZE / 2}px;
        height: 1px;
        right: -${INDICATOR_SIZE / 2}px;
        ${isFirst ? `left: ${INDICATOR_SIZE / 2}px;` : "width: 50%;"}
        background-color: ${borderColor};
      }
    `,
]);

const Wrapper = styled.div<{
  isFirst: boolean;
}>(({ isFirst }) => [
  css`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  `,
  isFirst &&
    css`
      position: relative;
      left: -50%;
    `,
]);

const Indicator = styled.div<{
  isActive: boolean;
  isError: boolean;
  color: string;
}>(
  ({ isActive, isError, color }) => css`
    display: flex;
    width: ${INDICATOR_SIZE}px;
    height: ${INDICATOR_SIZE}px;
    align-items: center;
    justify-content: center;
    border: 2px solid ${isError ? errorRed : isActive ? color : borderColor};
    box-sizing: border-box;
    border-radius: ${borderRadius.md}px;
  `
);

const IndicatorCore = styled.div<{
  isActive: boolean;
  isError: boolean;
  color: string;
}>(
  ({ isActive, color, isError }) => css`
    width: ${INDICATOR_CORE_SIZE}px;
    height: ${INDICATOR_CORE_SIZE}px;
    background-color: ${isError ? errorRed : isActive ? color : borderColor};
    border-radius: ${borderRadius.sm}px;
  `
);

const Title = styled.div([
  typographyBody2,
  css`
    margin-top: ${gutters.xs}px;
  `,
]);

interface Props {
  step: StepData;
  isActive: boolean;
  isFirst: boolean;
  isLast: boolean;
  onActivate: (activeStepId: string) => void;
  color?: string;
}

export function StepItem({
  step,
  isActive,
  isFirst,
  isLast,
  onActivate,
  color = primary,
}: Props) {
  return (
    <Container
      key={step.id}
      isActive={isActive}
      isChecked={step.isChecked}
      isError={step.isError}
      isFirst={isFirst}
      isLast={isLast}
      color={color}
      onClick={() => onActivate(step.id)}
    >
      <Wrapper isFirst={isFirst}>
        <Indicator isActive={isActive} isError={step.isError} color={color}>
          {step.isChecked && (
            <IndicatorCore
              isActive={isActive}
              isError={step.isError}
              color={color}
            />
          )}
        </Indicator>
        <Title>{step.title}</Title>
      </Wrapper>
    </Container>
  );
}
