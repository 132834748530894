import { css } from "@emotion/react";
import { typographyBody2 } from "./typography";
import { borderGrey, white } from "./colors";
import { borderRadius, gutters, INPUT_HEIGHT, INPUT_WIDTH } from "./variables";

export const inputStyles = [
  typographyBody2,
  css`
    outline: none;
    box-sizing: border-box;
    background: none;
    width: ${INPUT_WIDTH}px;
    height: ${INPUT_HEIGHT}px;
    line-height: 100%;
    border: none;
    outline: 1px solid ${borderGrey};
    padding: 0 ${gutters.md}px;
    border-radius: ${borderRadius.sm}px;
    background-color: ${white};
  `,
];
