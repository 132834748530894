import React from "react";
import {
  OrderEnvironment,
  Table,
  TBody,
  TH,
  THead,
  TR,
} from "client-lib/fragments";
import { OrderLineItemRow } from "./order-line-item-row";
import { DeliveryRow } from "./delivery-row";
import { DeliveryEstimate } from "./delivery-estimate";
import { SummaryRow } from "./summary-row";

interface Props {
  orderEnvironment: OrderEnvironment;
  isEditable?: boolean;
  skipDeliveryEstimate?: boolean;
}

export function OrderBlock({
  orderEnvironment,
  isEditable = false,
  skipDeliveryEstimate,
}: Props) {
  const { orderLineItems, order } = orderEnvironment;
  return (
    <React.Fragment>
      <Table fixed autoWidth>
        <THead>
          <TR>
            <TH width="0">#</TH>
            <TH>Товар</TH>
            <TH width="80px" align="center">
              Количество
            </TH>
            <TH width="80px" align="center">
              Цена
            </TH>
            <TH width="80px" align="right">
              Стоимость
            </TH>
            {isEditable && <TH width="2%" align="center" />}
          </TR>
        </THead>
        <TBody>
          {orderLineItems.map((orderLineItem, index) => {
            return (
              <OrderLineItemRow
                key={orderLineItem.id}
                isEditable={isEditable}
                number={index + 1}
                orderLineItemEnvironment={orderLineItem}
              />
            );
          })}
          <DeliveryRow order={order} number={orderLineItems.length + 1} />
          <SummaryRow orderEnvironment={orderEnvironment} />
        </TBody>
      </Table>
      {!skipDeliveryEstimate && <DeliveryEstimate order={order} />}
    </React.Fragment>
  );
}
