import React from "react";
import { TemplatePreset, TemplateEnvironment } from "shared";
import { TemplateEditorProvider } from "src/features/template-editor/template-editor-context";
import { TemplateEditorCore } from "src/features/template-editor/template-editor-core";

interface Props<Config> {
  readOnly?: boolean;
  templateEnvironment: TemplateEnvironment;
  rawInitialConfig?: string | null;
  onChange?: (config: Config) => void;
  templatePreset: TemplatePreset;
}
export function TemplateEditor<Config>({
  readOnly = false,
  templateEnvironment,
  onChange,
  rawInitialConfig,
  templatePreset,
}: Props<Config>) {
  return (
    <TemplateEditorProvider
      templatePreset={templatePreset}
      templateEnvironment={templateEnvironment}
      readOnly={readOnly}
      rawInitialConfig={rawInitialConfig}
      onChange={onChange}
    >
      <TemplateEditorCore />
    </TemplateEditorProvider>
  );
}
