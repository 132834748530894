import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  ReactNode,
  MouseEvent,
} from "react";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
import {
  primary,
  primaryMuted,
  secondary,
  secondaryMuted,
  white,
  black,
  grey50,
  lighten,
  transitionBase,
  primaryFamily,
} from "../../styles";

const ANIMATION_DURATION = 500;

export type ButtonSize = "XS" | "S" | "M" | "L" | "XL";

type Dimension = {
  fontSize: string;
  lineHeight: string;
  height: number;
  circleSize: number;
  padding: number;
  borderRadius: number;
};

const DIMENSIONS: { [k in ButtonSize]: Dimension } = {
  XS: {
    fontSize: "12px",
    lineHeight: "16px",
    circleSize: 36,
    padding: 12,
    height: 28,
    borderRadius: 4,
  },
  S: {
    fontSize: "14px",
    lineHeight: "18px",
    circleSize: 40,
    padding: 16,
    height: 36,
    borderRadius: 4,
  },
  M: {
    fontSize: "15px",
    lineHeight: "20px",
    circleSize: 50,
    padding: 20,
    height: 40,
    borderRadius: 4,
  },
  L: {
    fontSize: "18px",
    lineHeight: "20px",
    circleSize: 50,
    padding: 30,
    height: 50,
    borderRadius: 6,
  },
  XL: {
    fontSize: "22px",
    lineHeight: "24px",
    circleSize: 60,
    padding: 40,
    height: 60,
    borderRadius: 6,
  },
};

type ColorSchema = {
  backgroundColor: string;
  backgroundHoverColor: string;
  borderColor?: string;
  ripple: string;
  color: string;
  opacity?: number;
};

export type ColorSchemaKey =
  | "primary"
  | "secondary"
  | "white"
  | "transparent"
  | "seamless";
const COLOR_SCHEMAS: {
  [k in "enabled" | "disabled"]: {
    [k in ColorSchemaKey]: ColorSchema;
  };
} = {
  enabled: {
    primary: {
      backgroundColor: primary,
      backgroundHoverColor: lighten(primary, 0.9),
      color: white,
      ripple: white,
    },
    secondary: {
      backgroundColor: secondary,
      backgroundHoverColor: lighten(secondary, 0.9),
      color: white,
      ripple: white,
    },
    white: {
      backgroundColor: white,
      backgroundHoverColor: lighten(white, 0.95),
      color: black,
      ripple: lighten(white, 0.5),
      borderColor: grey50,
    },
    transparent: {
      backgroundColor: "transparent",
      backgroundHoverColor: "transparent",
      color: "inherit",
      borderColor: "inherit",
      ripple: lighten(white, 0.5),
    },
    seamless: {
      backgroundColor: "transparent",
      backgroundHoverColor: "transparent",
      color: "inherit",
      borderColor: "transparent",
      ripple: lighten(white, 0.5),
    },
  },
  disabled: {
    primary: {
      backgroundColor: primaryMuted,
      backgroundHoverColor: primaryMuted,
      color: white,
      ripple: white,
    },
    secondary: {
      backgroundColor: secondaryMuted,
      backgroundHoverColor: secondaryMuted,
      color: white,
      ripple: white,
    },
    white: {
      backgroundColor: lighten(white, 0.95),
      backgroundHoverColor: lighten(white, 0.95),
      color: black,
      ripple: lighten(white, 0.5),
      borderColor: grey50,
    },
    transparent: {
      backgroundColor: "transparent",
      backgroundHoverColor: "transparent",
      color: "inherit",
      borderColor: "inherit",
      opacity: 0.5,
      ripple: lighten(white, 0.5),
    },
    seamless: {
      backgroundColor: "transparent",
      backgroundHoverColor: "transparent",
      color: "inherit",
      borderColor: "transparent",
      opacity: 0.5,
      ripple: lighten(white, 0.5),
    },
  },
};

const rippleAnimation = keyframes`
  0% {
    opacity: 0.3;
    transform: scale(1);
  }

  50% {
    transform: scale(8);
  }

  100% {
    opacity: 0;
  }
`;

const RootStyles = ({
  padding,
  height,
  fontSize,
  lineHeight,
  backgroundColor,
  backgroundHoverColor,
  borderColor,
  color,
  opacity,
  borderRadius,
}: {
  padding: number;
  height: number;
  fontSize: string;
  lineHeight: string;
  backgroundColor: string;
  backgroundHoverColor: string;
  borderColor?: string;
  color: string;
  opacity?: number;
  borderRadius: number;
}) => css`
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;

  ${opacity !== undefined ? `opacity: ${opacity};` : ""}

  height: ${height}px;
  padding: 0 ${padding}px;
  background-color: ${backgroundColor};
  color: ${color};

  font-family: ${primaryFamily};
  line-height: ${lineHeight};
  font-size: ${fontSize};

  border-width: 0.5px;
  border-style: solid;
  border-color: ${borderColor || "transparent"};

  border-radius: ${borderRadius}px;
  text-decoration: none;

  transition: background-color ${transitionBase};
  &:hover {
    background-color: ${backgroundHoverColor};
  }
`;

const RootTags = {
  button: styled.button<Dimension & ColorSchema>(RootStyles),
  div: styled.div<Dimension & ColorSchema>(RootStyles),
};

type RippleData = {
  rippleId: number;
  x: number;
  y: number;
  circleSize: number;
  color: string;
};

const Ripple = styled.div<RippleData>(
  ({ x, y, circleSize, color }) => css`
    position: absolute;
    top: ${y}px;
    left: ${x}px;
    height: ${circleSize}px;
    width: ${circleSize}px;
    background-color: ${color};
    border-radius: 50%;
    opacity: 0.5;
    animation-name: ${rippleAnimation};
    animation-duration: ${ANIMATION_DURATION * 2}ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    pointer-events: none;
  `
);

export interface ButtonProps {
  size?: ButtonSize;
  color?: ColorSchemaKey;
  children?: ReactNode;
  tag?: "button" | "div";
  onClick?: () => any;
  type?: "button" | "submit";
  disabled?: boolean;
  className?: string;
}

export function Button({
  size = "M",
  color = "primary",
  tag = "button",
  children,
  onClick,
  type = "button",
  disabled,
  className,
}: ButtonProps) {
  const [ripples, setRipples] = useState<RippleData[]>([]);
  const [rippleId, setRippleId] = useState<number>(1);

  const ripplesRef = useRef(ripples);
  ripplesRef.current = ripples;

  const dimension = DIMENSIONS[size];
  const { circleSize } = dimension;
  const colorSchema = COLOR_SCHEMAS[disabled ? "disabled" : "enabled"][color];

  const rippleTimer = useRef<any>();

  useEffect(() => {
    return () => {
      if (rippleTimer.current) {
        clearTimeout(rippleTimer.current);
      }
    };
  }, []);

  const popRipple = useCallback(() => {
    setRipples(ripplesRef.current.slice(1));
  }, [setRipples, ripplesRef]);

  const handleClick = useCallback(
    (evt: MouseEvent) => {
      if (disabled) {
        return;
      }
      const box = (evt.target as HTMLElement).getBoundingClientRect();

      setRippleId(rippleId + 1);
      setRipples([
        ...ripples,
        {
          circleSize,
          rippleId,
          x: evt.clientX - box.left - circleSize / 2,
          y: evt.clientY - box.top - circleSize / 2,
          color: colorSchema.ripple,
        },
      ]);

      rippleTimer.current = setTimeout(popRipple, ANIMATION_DURATION);
      if (onClick) {
        onClick();
      }
    },
    [
      disabled,
      ripples,
      setRipples,
      rippleId,
      setRippleId,
      circleSize,
      popRipple,
      onClick,
      colorSchema.ripple,
    ]
  );
  const Root = RootTags[tag];
  return (
    <Root
      onClick={handleClick}
      {...dimension}
      {...colorSchema}
      type={type}
      className={className}
    >
      {children}
      {!disabled &&
        ripples.map((ripple: RippleData) => (
          <Ripple key={ripple.rippleId} {...ripple} />
        ))}
    </Root>
  );
}
