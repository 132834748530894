import React from "react";
import { MaterialExpanded } from "shared";
import { Box } from "../../objects";
import { Config } from "./types";

export function SupportItem({
  config,
  material,
  supportMaterial,
  coverage,
  index,
}: {
  config: Config;
  material: MaterialExpanded;
  supportMaterial: MaterialExpanded;
  coverage: MaterialExpanded | null;
  index: number;
}) {
  if (config.supportAmount === 0) {
    return null;
  }
  const surface = coverage?.texture1 || supportMaterial.texture1;
  if (!surface) {
    return null;
  }
  if (!supportMaterial.mmDimension1 || !supportMaterial.mmDimension2) {
    return null;
  }
  if (!material.mmDimension1 || !material.mmDimension2) {
    return null;
  }

  const framePipeWidth = material.mmDimension1;

  const supportPipeWidth = supportMaterial.mmDimension1;
  const supportPipeHeight = supportMaterial.mmDimension2;

  return (
    <Box
      xSize={config.supportLength}
      ySize={supportPipeHeight}
      zSize={supportPipeWidth}
      defaultSurface={surface}
      offset={[
        (config.supportLength + framePipeWidth) / 2,
        (config.frameHeight - supportPipeHeight) / 2,
        config.supportAmount === 1
          ? 0
          : ((index === 0 ? 1 : -1) * (config.supportGap + supportPipeWidth)) /
            2,
      ]}
    />
  );
}
