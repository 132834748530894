import React, { memo } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { keyframes } from "@emotion/react";
import { white, grey90, trueBlack, withAlpha } from "../styles";
import { Spinner as SpinnerVK } from "@vkontakte/vkui";

type SpinnerProps = React.ComponentProps<typeof SpinnerVK>;

type SpinnerSize = "S" | "M" | "L";
type ColorVariant = "light" | "black";

const sizes: Record<SpinnerSize, number> = {
  S: 12,
  M: 16,
  L: 20,
};

const colors: Record<ColorVariant, { bg: string; fg: string }> = {
  light: {
    bg: white,
    fg: grey90,
  },
  black: {
    bg: trueBlack,
    fg: white,
  },
};

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: inline-block;
`;

export const Spinner = memo(function Spinner(props: SpinnerProps) {
  return (
    <Container>
      <SpinnerVK {...props} />
    </Container>
  );
});

export const SpinnerOld = styled.div([
  css`
    animation: ${rotateAnimation} 0.75s infinite linear;
  `,
  ({
    size = "M",
    variant = "light",
    background,
  }: {
    size?: SpinnerSize;
    variant?: ColorVariant;
    background?: string;
  }) => `
    display: inline-block;
    height: ${sizes[size]}px;
    width: ${sizes[size]}px;
    opacity: 0.95;
    border-radius: 50%;
    background: ${colors[variant].fg};
    background: linear-gradient(to right, ${colors[variant].fg} 0%, ${withAlpha(
    colors[variant].fg,
    0.25
  )} 15%, ${withAlpha(colors[variant].fg, 0)} 50%);
    position: relative;
    transform: translateZ(0);
    &:before {
      width: 50%;
      height: 50%;
      background: ${colors[variant].fg};
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }
    &:after {
      content: "";
      background: ${background || colors[variant].bg};
      width: 90%;
      height: 90%;
      border-radius: 50%;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  `,
]);

export const SpinnerInline = styled(Spinner)`
  display: inline-block;
  vertical-align: baseline;
  margin: 0 0.5em;
`;

export const SpinnerInlineCompensator = styled.div<{ size?: SpinnerSize }>(
  ({ size = "M" }) => `
    display: inline-block;
    vertical-align: baseline;
    height: ${sizes[size]}px;
    width: ${sizes[size]}px;
    margin: 0 0.5em;
  `
);
