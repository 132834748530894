import React from "react";
import { Spinner } from "client-lib";
import { Layout } from "src/features/layout";
import { useEnvironment } from "src/features/environment";
import { Contacts } from "./contacts";

export function ContactsPage() {
  const { environment } = useEnvironment();

  return (
    <Layout>
      <h1>Контакты</h1>
      {environment ? (
        <Contacts>
          <p>
            С нами можно связаться по телефону{" "}
            <b>{environment.companyRequisites.phone}</b>, или заполнив
            контактную форму:
          </p>
        </Contacts>
      ) : (
        <Spinner />
      )}
    </Layout>
  );
}
