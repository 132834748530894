import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";

export const pricingConfigSchema = z.object({
  ...standardAttributes,
  marginPercent: z.number().nonnegative(),
  settingUpHourKopecks: z.number().int().nonnegative(),
});
checkSchema(pricingConfigSchema);
export type PricingConfig = z.infer<typeof pricingConfigSchema>;

export const pricingConfigInputSchema = pricingConfigSchema.pick({
  marginPercent: true,
  settingUpHourKopecks: true,
});
export type PricingConfigInput = z.infer<typeof pricingConfigInputSchema>;
