import React from "react";
import { ShelfConfigItem } from "shared";
import {
  Box,
  Measurement,
  SERIF,
  MEASUREMENT_DEFAULT_OFFSET,
} from "../../objects";
import { useShelf } from "./shelf-context";
import { getSplitItems } from "./utils";
import { useSceneOptions } from "../../scene-context";

interface Props {
  item: ShelfConfigItem;
  bottomY: number;
}

export function ShelfItem({ item, bottomY }: Props) {
  const options = useSceneOptions();
  const { config, assets } = useShelf();
  const coverageMaterial =
    assets.shelfCoverage.find((m) => m.id === item.coverageId) || null;

  const surfaceProps = coverageMaterial
    ? {
        xSurface: coverageMaterial.texture3,
        ySurface: coverageMaterial.texture1,
        zSurface: coverageMaterial.texture2,
      }
    : {
        xSurface: assets.shelfMaterial.texture3,
        ySurface: assets.shelfMaterial.texture1,
        zSurface: assets.shelfMaterial.texture2,
      };

  const splitItems = getSplitItems(config, assets, item.length);
  return (
    <React.Fragment>
      {splitItems.map((splitItem, index) => (
        <Box
          key={index}
          xSize={splitItem.length}
          ySize={item.thickness}
          zSize={item.width}
          {...surfaceProps}
          offset={[splitItem.offset, bottomY + item.thickness / 2, 0]}
        />
      ))}

      {options.showMeasurements && (
        <React.Fragment>
          <Measurement
            key="thickness"
            p1={[
              config.frameGap / 2,
              bottomY - item.thickness,
              -item.width / 2,
            ]}
            p2={[config.frameGap / 2, bottomY, -item.width / 2]}
            normal={[0, 0, -1]}
            outer
            outerSerifLength={5}
            outerEmptySerifLength={0}
            textInside
            offset={
              (config.width - item.width) / 2 + MEASUREMENT_DEFAULT_OFFSET
            }
            textRotation={[0, Math.PI / 2, Math.PI / 2]}
          />
          <Measurement
            key="width"
            p1={[item.length / 2, bottomY, -item.width / 2]}
            p2={[item.length / 2, bottomY, item.width / 2]}
            normal={[1, 0, 0]}
            textInside
            offset={
              (config.length - item.length) / 2 + MEASUREMENT_DEFAULT_OFFSET * 2
            }
            textRotation={[-Math.PI / 2, 0, Math.PI / 2]}
          />
          <Measurement
            key="length"
            p1={[
              -item.length / 2,
              bottomY + item.thickness + 1,
              item.width / 2 - 2 * SERIF,
            ]}
            p2={[
              item.length / 2,
              bottomY + item.thickness + 1,
              item.width / 2 - 2 * SERIF,
            ]}
            normal={[0, 0, 1]}
            textInside
            offset={SERIF}
            textRotation={[-Math.PI / 2, 0, 0]}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
