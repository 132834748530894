import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Formik } from "formik";
import {
  Spinner,
  Switcher,
  SwitcherItem,
  CogIcon,
  white,
  grey20,
  grey50,
  gutters,
  mqMax,
  mqMin,
  toFormikSchema,
  APP_BAR_HEIGHT,
} from "client-lib";
import { useTemplateEditor } from "src/features/template-editor/template-editor-context";
import { JIVOSITE_BAR_HEIGHT } from "src/features/layout";
import { Scene, sceneCanvasHeightStyles } from "./scene";
import { ConfigForm } from "./config-form";
import { usePricing, PricingSummary, PricingSnippet } from "./pricing";
import { RenderMode } from "./types";
import { SettingsModal } from "./settings-modal";

const SCENE_OPTIONS = {
  showMeasurements: true,
};

const ScreenHeightScene = styled(Scene)`
  height: calc(100vh - ${APP_BAR_HEIGHT}px);
`;

const Container = styled.div([
  css`
    position: relative;
    overflow: hidden;
    position: relative;
  `,
  sceneCanvasHeightStyles,
]);

const SceneContainer = styled.div`
  position: relative;
`;

export const SIDEBAR_WIDTH = 275;

const Sidebar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${white};
  width: ${SIDEBAR_WIDTH}px;
  height: 100%;
  z-index: 1;
  ${mqMax.tablet} {
    display: none;
  }
`;

const SidebarContainer = styled.div`
  position: relative;
  height: 100%;
  padding: ${gutters.xs}px ${gutters.lg}px;
  box-sizing: border-box;
  width: ${SIDEBAR_WIDTH}px;
  overflow-y: auto;
`;

const RightSidebar = styled(Sidebar)`
  right: 0;
  left: auto;
  ${mqMax.tablet} {
    display: none;
  }
`;
const RightSidebarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: ${JIVOSITE_BAR_HEIGHT}px;
`;

const MODE_WIDTH = 100;
const ModePicker = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: ${-MODE_WIDTH}px;
  label {
    border-color: ${grey50};
    background-color: ${grey20};
  }
`;

const MobileSettings = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  ${mqMin.tablet} {
    display: none;
  }
`;

const MobilePricing = styled.div`
  position: absolute;
  bottom: ${gutters.sm}px;
  left: 0px;
  width: 100%;
  ${mqMin.tablet} {
    display: none;
  }
`;

const RENDER_MODE_SWITCHER_ITEMS: SwitcherItem<RenderMode>[] = [
  { id: "model", text: "Модель" },
  { id: "package", text: "Упаковка" },
];

export function TemplateEditorCore<Config, Assets, Variables>() {
  const {
    templatePreset,
    templateVariables,
    templateFull,
    initialConfigValues,
    configValues,
    setConfigValues,
    decomposition,
  } = useTemplateEditor<Config, Assets, Variables>();
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [renderMode, setRenderMode] = useState<RenderMode>("model");

  const validationSchema = toFormikSchema(templateFull.model.configSchema);

  const handleCloseSettings = useCallback(() => {
    setIsSettingsOpen(false);
  }, []);

  const { pricing, ...pricingMeta } = usePricing(
    templatePreset.id,
    configValues
  );

  return (
    <Container>
      <Sidebar>
        <SidebarContainer>
          <Formik
            initialValues={initialConfigValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={setConfigValues}
          >
            <ConfigForm />
          </Formik>
        </SidebarContainer>
      </Sidebar>
      <SceneContainer>
        <ScreenHeightScene options={SCENE_OPTIONS}>
          <templateFull.component
            config={configValues}
            assets={templateFull.assets}
            variables={templateVariables}
            mode={renderMode}
          />
        </ScreenHeightScene>
        <ModePicker>
          <Switcher
            items={RENDER_MODE_SWITCHER_ITEMS}
            activeId={renderMode}
            onChange={setRenderMode}
          />
        </ModePicker>
        <MobileSettings
          onClick={() => {
            setIsSettingsOpen(true);
          }}
        >
          <CogIcon />
        </MobileSettings>
        <MobilePricing>
          {pricing ? (
            <PricingSnippet pricing={pricing} {...pricingMeta} />
          ) : (
            <Spinner />
          )}
        </MobilePricing>
      </SceneContainer>
      <RightSidebar>
        <RightSidebarContainer>
          <PricingSummary
            pricing={pricing}
            productPackage={decomposition.package}
            {...pricingMeta}
          />
        </RightSidebarContainer>
      </RightSidebar>
      {isSettingsOpen && (
        <SettingsModal
          initialValues={initialConfigValues}
          onChange={setConfigValues}
          onCloseRequest={handleCloseSettings}
        />
      )}
    </Container>
  );
}
