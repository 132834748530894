import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";

export const categorySchema = z.object({
  ...standardAttributes,
  title: z.string(),
  parentId: z.string().nullable(),
  ordering: z.number().int(),
});
checkSchema(categorySchema);
export type Category = z.infer<typeof categorySchema>;

export const categoryInputSchema = z.object({
  id: standardAttributes.id,
  title: z.string(),
  parentId: z.string().nullable(),
  ordering: z.number().int(),
});
export type CategoryInput = z.infer<typeof categoryInputSchema>;
