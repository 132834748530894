import gql from "graphql-tag";
import { TemplatePresetEnvironment } from "shared";
import { TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT } from "client-lib/fragments";

export const MainPageQuery = gql`
  query MainPage {
    TemplatePresetEnvironments(landing: true) {
      ...TemplatePresetEnvironmentFullFragment
    }
  }
  ${TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface MainPageVars {}

export type MainPageData = {
  TemplatePresetEnvironments: TemplatePresetEnvironment[];
};
