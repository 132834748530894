import gql from "graphql-tag";
import { pricingSchema, Pricing } from "shared";
import { getFieldsFromSchema } from "client-lib/services";

export const GetPricing = gql`
  query GetPricing($templatePresetId: String!, $configValuesSerialized: String!) {
    GetPricing(templatePresetId: $templatePresetId, configValuesSerialized: $configValuesSerialized) {
      ${getFieldsFromSchema(pricingSchema)}
    }
  }
`;

export type GetPricingVars = {
  configValuesSerialized: string;
  templatePresetId: string;
};

export type GetPricingData = {
  GetPricing: Pricing;
};
