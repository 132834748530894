import React from "react";
import { Spinner } from "client-lib/ui";
import {
  TemplateEnvironment,
  TemplatePreset,
  TemplateShotActual,
} from "shared";
import { Layout, Container, Bar, Left, Center, Right } from "./components";
import { TemplateProvider } from "../template-context";
import { TemplateLayoutContent } from "./template-layout-content";
import { ProductionProvider } from "../../order/production-context";

interface Props {
  templateEnvironment?: TemplateEnvironment;
  templatePreset?: TemplatePreset | null;
  templateShotActual?: TemplateShotActual | null;
  templateShotLatestVersionNumber?: number | null;
}

export function TemplateLayout({
  templatePreset,
  templateEnvironment,
  templateShotActual,
  templateShotLatestVersionNumber,
}: Props) {
  if (!templateEnvironment || !templatePreset) {
    return (
      <Layout>
        <Bar>
          <Left />
          <Center />
          <Right />
        </Bar>
        <Container>
          <Spinner />
        </Container>
      </Layout>
    );
  }

  return (
    <TemplateProvider
      templatePreset={templatePreset}
      templateEnvironment={templateEnvironment}
      templateShotActual={templateShotActual || null}
      templateShotLatestVersionNumber={templateShotLatestVersionNumber || null}
    >
      <ProductionProvider>
        <TemplateLayoutContent />
      </ProductionProvider>
    </TemplateProvider>
  );
}
