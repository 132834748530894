import React from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Spinner, grey20 } from "client-lib/ui";
import { Layout } from "src/features/layout";
import {
  TemplatePresetEnvironments,
  TemplatePresetEnvironmentsData,
} from "./queries";
import { PresetCard } from "./preset-card";

const List = styled.div`
  position: relative;
`;

export function TemplatePresetsPage() {
  const { data } = useQuery<TemplatePresetEnvironmentsData>(
    TemplatePresetEnvironments
  );
  return (
    <Layout bg={grey20}>
      <h1>Шаблоны</h1>
      <p>
        По каждому из этих шаблонов можно создать в конструкторе товар за
        несколько минут
      </p>
      {data ? (
        <List>
          {data.TemplatePresetEnvironments.map((templatePresetEnv) => (
            <PresetCard
              key={templatePresetEnv.id}
              templatePresetEnvironment={templatePresetEnv}
            />
          ))}
        </List>
      ) : (
        <Spinner />
      )}
    </Layout>
  );
}
