import { z } from "shared";
import { toFormikSchema } from "client-lib";

export const MAX_AMOUNT = 100;

export const amountSchema = z.object({
  amount: z.number().int().positive().max(MAX_AMOUNT),
});
export type Amount = z.infer<typeof amountSchema>;

export const formikAmountSchema = toFormikSchema(amountSchema);
