import React, { useEffect, useMemo } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Button } from "client-lib/ui";
import { Layout } from "src/features/layout";
import { AuthControl, useAuth } from "src/features/auth";

export function DashboardPage() {
  const { user, logout, setToken } = useAuth();
  const { search } = useLocation();
  const history = useHistory();
  const urlToken = useMemo(
    () => new URLSearchParams(search).get("token"),
    [search]
  );

  useEffect(
    function acceptTokenAndRefresh() {
      if (urlToken) {
        setToken(urlToken);
        history.replace("/account");
      }
    },
    [urlToken, setToken, history]
  );

  return (
    <Layout>
      <h1>Личный кабинет</h1>
      <AuthControl />
      {user && !user.isGuest && (
        <p>
          Вы авторизованы как {user.nameFirst} {user.nameLast}
        </p>
      )}
      <div>
        <Link to="/account/models">Мои модели</Link>
        <br />
        <Link to="/account/orders">Мои заказы</Link>
        <br />
        <Link to="/account/cart">Корзина</Link>
        <br />
      </div>
      <br />
      {user && !user.isGuest && <Button onClick={logout}>Выйти</Button>}
    </Layout>
  );
}
