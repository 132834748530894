import gql from "graphql-tag";

export const SignUp = gql`
  mutation SignUp($email: String!, $password: String!) {
    SignUp(email: $email, password: $password)
  }
`;

export type SignUpVars = {
  email: string;
  password: string;
};

export type SignUpData = {
  SignUp: string;
};
