import { PricingValue } from "./pricing-item";
import { PricingLimitValues } from "../pricing-limit";

export function pickBestPricingValue(
  pricingValues: PricingValue[],
  amount: number
): PricingValue | null {
  let bestValue: PricingValue | null = null;
  for (const candidateValue of pricingValues) {
    const limit = PricingLimitValues[candidateValue.limit];
    if (limit > amount && amount !== 0) {
      continue;
    }
    if (!bestValue || candidateValue.costKopecks < bestValue.costKopecks) {
      bestValue = candidateValue;
    }
  }
  return bestValue;
}
