import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { grey10, grey20, gutters, Button, mqMax } from "client-lib";
import { Slide, SlideContainer, Header } from "src/features/layout";

const Body = styled.div`
  display: flex;
  align-items: center;
  min-height: 570px;
  ${mqMax.phone} {
    min-height: auto;
  }
`;

const Side = styled.div`
  width: 50%;
  position: relative;
  a {
    display: inline-block;
    margin-top: ${gutters.xxl}px;
  }
  ${mqMax.phone} {
    width: auto;
    text-align: center;
    margin-bottom: ${gutters.xxl}px;
  }
`;

const Right = styled(Side)`
  text-align: right;
  ${mqMax.phone} {
    display: none;
  }
`;

const Item = styled.div`
  background-color: ${grey20};
  padding: ${gutters.xl}px;
  margin-bottom: ${gutters.md}px;
  margin-left: -${gutters.xl}px;
  text-align: left;
`;

export const HonestlySlide = memo(function HonestlySlide() {
  return (
    <Slide style={{ backgroundColor: grey10 }}>
      <SlideContainer>
        <Header>Честно</Header>
        <Body>
          <Side>
            <Item>
              Мы не скрываем, из чего складывается цена изделия —
              вы&nbsp;знаете, за что платите, вплоть до каждого самореза
            </Item>
            <Item>
              Вы знаете цену доставки в ваш регион еще на этапе моделирования.
            </Item>
            <Item>
              Выгодные условия для селлеров: больше единиц в заказе — меньше
              цена за изготовление и доставку!
            </Item>
            <Link to="/templates">
              <Button size="XL">Перейти в&nbsp;конструктор</Button>
            </Link>
          </Side>
          <Right>
            <img src="/img/grey-stool.jpg" />
          </Right>
        </Body>
      </SlideContainer>
    </Slide>
  );
});
