import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  errorRed as errorColor,
  white,
  gutters,
  inputStyles,
  typographyBody1,
} from "../../styles";
import { withField } from "./formik-field-wrapper";

export const selectStyle = css`
  border-right: ${gutters.sm}px solid transparent;
  background-color: ${white};
`;

export const StyledSelect = styled.select(
  ({ error, seamless }: { error?: string | boolean; seamless?: boolean }) => [
    ...inputStyles,
    typographyBody1,
    selectStyle,
    error
      ? css`
          outline-color: ${errorColor};
        `
      : null,
    seamless
      ? css`
          outline-color: transparent;
          padding-left: 0;
          padding-right: 0;
        `
      : null,
  ]
);

export const SelectField = withField(StyledSelect);
export const SelectFieldNumeric = withField(StyledSelect, {
  translateInputValue: Number,
});
