import gql from "graphql-tag";
import { SupportMessageInput, SupportMessage } from "shared";
import { SUPPORT_MESSAGE_FULL_FRAGMENT } from "client-lib";

export const CreateSupportMessage = gql`
  mutation CreateSupportMessage($input: SupportMessageInput!) {
    CreateSupportMessage(input: $input) {
      ...SupportMessageFullFragment
    }
  }
  ${SUPPORT_MESSAGE_FULL_FRAGMENT}
`;

export type CreateSupportMessageVars = {
  input: SupportMessageInput;
};

export type CreateSupportMessageData = {
  CreateSupportMessage: SupportMessage;
};
