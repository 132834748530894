import React from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ORDER_STATUS_TITLES, OrderStatus, regionsRFByCode } from "shared";
import {
  Breadcrumbs,
  Spinner,
  FormatDate,
  Table,
  TR,
  TD,
  gutters,
  Price,
  grey80,
  typographyBody2,
  FormatDateTime,
} from "client-lib/ui";
import { Layout } from "src/features/layout";
import { useRouteMatch } from "react-router-dom";
import { RefreshPriceButton } from "src/features/order/refresh-price-button";
import { AuthControl } from "src/features/auth";
import {
  OrderEnvironmentQuery,
  OrderEnvironmentData,
  OrderEnvironmentVars,
} from "./queries";
import { OrderBlock } from "./order-block";
import { ActionBlock } from "./action-block";
import { useOrderUpdateDeliveryCosts } from "./service";

const StatusBlock = styled.div`
  font-weight: 600;
  margin-bottom: ${gutters.md}px;
`;

const DeliveryEstimateComment = styled.div([
  typographyBody2,
  css`
    margin-top: ${gutters.xs}px;
    color: ${grey80};
  `,
]);

const DeliveryTable = styled(Table)`
  td {
    vertical-align: top;
  }
`;

export function OrderPage() {
  const { params } = useRouteMatch<{ orderId: string }>();

  const { refetch, data } = useQuery<
    OrderEnvironmentData,
    OrderEnvironmentVars
  >(OrderEnvironmentQuery, {
    variables: {
      orderId: params.orderId,
    },
  });

  useOrderUpdateDeliveryCosts(data, refetch);

  if (!data) {
    return (
      <Layout>
        <Breadcrumbs
          items={[
            { title: "Кабинет", url: "/account" },
            { title: "Заказы", url: "/account/orders" },
            { title: "Заказ" },
          ]}
        />
        <Spinner />
      </Layout>
    );
  }

  const { order, deliveryLocation, invoice, priceActualTill } =
    data.OrderEnvironment;
  const now = new Date();

  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Кабинет", url: "/account" },
          { title: "Заказы", url: "/account/orders" },
          { title: `Заказ #${order.teamNumber}` },
        ]}
      />
      <h1>
        Заказ #{order.teamNumber} от <FormatDate date={order.fixedAt} />
      </h1>
      <AuthControl />
      <StatusBlock>
        Статус заказа: {ORDER_STATUS_TITLES[order.status]}
      </StatusBlock>
      {priceActualTill &&
      order.status === OrderStatus.AWAITING_PAYMENT &&
      priceActualTill > now ? (
        <p>
          Цены действительны до <FormatDateTime date={priceActualTill} />
        </p>
      ) : (
        <p>
          Срок актуальности указанных цен истек, нужно{" "}
          <RefreshPriceButton type="link" orderId={order.id}>
            пересчитать цены
          </RefreshPriceButton>
          .
        </p>
      )}
      <OrderBlock
        orderEnvironment={data.OrderEnvironment}
        skipDeliveryEstimate
      />
      <h2>Доставка</h2>
      <DeliveryTable seamless autoWidth dense>
        <TR>
          <TD>Получатель</TD>
          <TD>{order.deliveryRecipient}</TD>
        </TR>
        <TR>
          <TD>Телефон</TD>
          <TD>{order.deliveryRecipientPhone}</TD>
        </TR>
        <TR>
          <TD>E-mail</TD>
          <TD>{order.deliveryRecipientEmail}</TD>
        </TR>
        <TR>
          <TD>Регион:</TD>
          <TD>
            {regionsRFByCode[order.deliveryRegion || ""] ||
              order.deliveryRegion}
          </TD>
        </TR>
        <TR>
          <TD>Терминал ТК:</TD>
          <TD>{deliveryLocation?.title}</TD>
        </TR>
        <TR>
          <TD>Стоимость доставки до терминала в вашем регионе:</TD>
          <TD>
            {order.deliveryLocationEstimateKopecks && (
              <React.Fragment>
                <Price kopecks={order.deliveryLocationEstimateKopecks} />
                <DeliveryEstimateComment>
                  Это стоимость доставки до терминала в вашем регионе.
                  <br />
                  Она не включена в счет, оплачивается непосредственно
                  транспортной компании при получении.
                  <br />
                  Указана ориентировочная стоимость.
                </DeliveryEstimateComment>
              </React.Fragment>
            )}
          </TD>
        </TR>
      </DeliveryTable>
      <ActionBlock
        order={order}
        invoice={invoice}
        priceActualTill={priceActualTill}
      />
    </Layout>
  );
}
