import React from "react";
import { Order } from "shared";
import { Spinner } from "@vkontakte/vkui";
import { TD, Price } from "client-lib";
import { FixedHeightRow, BoldTD } from "./components";

interface Props {
  order: Order;
  number: number;
}

export function DeliveryRow({ order, number }: Props) {
  return (
    <FixedHeightRow>
      <TD>{number}</TD>
      <TD colSpan={2}>
        Доставка до терминала транспортной компании «Кит» в г.Кирове
      </TD>
      <TD />
      <BoldTD align="right">
        {order.deliveryCostKopecks === null || order.deliveryCostResetAt ? (
          <Spinner />
        ) : (
          <Price kopecks={order.deliveryCostKopecks} />
        )}
      </BoldTD>
    </FixedHeightRow>
  );
}
