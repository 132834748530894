import { padEnd } from "lodash";

function splitGroups(s: string) {
  const remain = s.length % 3;
  const patched =
    remain > 0 ? `${new Array(3 - remain).fill(" ").join("")}${s}` : s;

  const groups = new Array(Math.floor(patched.length / 3)).fill("");
  return groups
    .map((_g, index) => {
      return patched.slice(index * 3, (index + 1) * 3);
    })
    .join("\u00a0")
    .trim();
}

interface Options {
  split?: boolean;
  hideKopecks?: true | false | "auto";
  separator?: string;
}

export function formatPrice(
  kopecks: number,
  { hideKopecks = false, split = true, separator = "," }: Options = {}
): string {
  const kopecksRemain = kopecks % 100;
  const kopecksPart =
    (kopecksRemain && hideKopecks !== true) || hideKopecks === false
      ? `${separator}${padEnd(String(kopecksRemain), 2, "0")}`
      : "";
  const rubles = String(Math.floor(kopecks / 100));
  const rublesPart = split ? splitGroups(rubles) : rubles;
  return `${rublesPart}${kopecksPart}`;
}
