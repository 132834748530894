import React, { useCallback, useMemo } from "react";
import { TemplateConfigControlGroup, isTemplateConfigArrayGroup } from "shared";
import { Form, useFormikContext } from "formik";
import { AutoSubmitter } from "client-lib/ui";
import { useTemplateEditor } from "src/features/template-editor/template-editor-context";
import { ConfigSimpleGroup } from "./config-simple-group";
import { ConfigArrayGroup } from "./config-array-group";
import { ConfigProvider } from "./config-context";

export function ConfigForm<TemplateConfig, Assets, Variables>() {
  const { templateFull, templateVariables, readOnly } = useTemplateEditor<
    TemplateConfig,
    Assets,
    Variables
  >();
  const { values, setValues } = useFormikContext<TemplateConfig>();
  // @ts-ignore
  const _a: Assets | null = null;
  // @ts-ignore
  const _b: Variables | null = null;
  const handleAttributeChange = useCallback(
    (name: string, value: any) => {
      templateFull.controller.onAttributeChange?.(
        name,
        value,
        values,
        templateFull.assets,
        templateVariables,
        setValues
      );
    },
    [values, setValues]
  );

  const controlGroups = useMemo<TemplateConfigControlGroup[]>(
    () =>
      templateFull.controller.getControlGroups(
        values,
        templateFull.assets,
        templateVariables
      ),
    [values, templateFull.assets]
  );

  return (
    <ConfigProvider readOnly={readOnly}>
      <Form>
        {controlGroups.map(
          (group: TemplateConfigControlGroup, groupIndex: number) =>
            isTemplateConfigArrayGroup(group) ? (
              <ConfigArrayGroup
                key={groupIndex}
                group={group}
                onAttributeChange={handleAttributeChange}
                config={values}
              />
            ) : (
              <ConfigSimpleGroup
                key={groupIndex}
                group={group}
                onAttributeChange={handleAttributeChange}
              />
            )
        )}
        <AutoSubmitter timeout={10} />
      </Form>
    </ConfigProvider>
  );
}
