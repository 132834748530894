import React, {
  useCallback,
  useEffect,
  useState,
  ReactNode,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { createPortal } from "react-dom";
import {
  gutters,
  borderRadius,
  white,
  modalShadow,
  zLayers,
  withAlpha,
  trueBlack,
} from "../styles";
import { RoundSeamlessButton } from "./buttons";
import { CloseIcon } from "../icons";

const CONTAINER_WIDTH = 1140;
const DESKTOP_CONTAINER_MARGIN = 32;

type BackdropType = "black" | "grey" | "transparent";

const Backdrop = styled.div<{
  type: BackdropType;
}>(({ type }) => [
  css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: ${zLayers.BACKDROP};
  `,
  css`
    background-color: ${type === "black"
      ? withAlpha(trueBlack, 0.9)
      : type === "grey"
      ? withAlpha(trueBlack, 0.3)
      : "transparent"};
  `,
]);

const headerPadding = css``;

const Header = styled.div`
  padding: ${gutters.sm}px ${gutters.sm}px ${gutters.sm}px ${gutters.lg}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
`;

const Root = styled.div<{
  maxWidth?: string | number;
}>(({ maxWidth }) => [
  css`
    position: relative;
    background-color: ${white};
    box-shadow: ${modalShadow};
    border-radius: ${borderRadius.md}px;
    max-height: calc(100% - ${DESKTOP_CONTAINER_MARGIN * 2}px);
    max-width: min(
      ${normalizeWidth(maxWidth, `${CONTAINER_WIDTH}px`)},
      calc(100% - ${DESKTOP_CONTAINER_MARGIN * 2}px)
    );
    display: flex;
    flex-direction: column;
    cursor: default;
  `,
]);

function normalizeWidth(
  width: string | number | undefined,
  defaultWidth: string
): string {
  return typeof width === "undefined"
    ? defaultWidth
    : typeof width === "string"
    ? width
    : `${width}px`;
}

const CustomHeader = styled.div(headerPadding);

const Body = styled.div<{
  width?: string | number;
  minWidth?: string | number;
}>(
  ({ width, minWidth }) => css`
    position: relative;
    box-sizing: border-box;
    width: ${normalizeWidth(width, "auto")};
    min-width: ${normalizeWidth(minWidth, "200px")};
    max-width: 100%;
    max-height: 75vh;
    padding: 0 ${gutters.lg}px ${gutters.md}px;
    overflow: auto;
    > p:first-child {
      margin-top: 0;
    }
  `
);

export type ModalProps = {
  title?: string;
  customHeader?: ReactNode;
  backdropType?: BackdropType;
  onCloseRequest: () => void;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
};

export const Modal = ({
  title,
  customHeader,
  backdropType = "grey",
  children,
  onCloseRequest,
  width,
  minWidth,
  maxWidth,
}: ModalProps & { children: ReactNode }) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(
    function createModalRoot() {
      const node: HTMLDivElement | null = document.querySelector("#modal-root");
      if (node) {
        setContainer(node);
      } else {
        const newModalRoot = document.createElement("div");
        newModalRoot.id = "modal-root";
        document.body.appendChild(newModalRoot);
        setContainer(newModalRoot);
      }
    },
    [setContainer]
  );

  const header = useMemo<ReactNode>(() => {
    if (customHeader) {
      return <CustomHeader>{customHeader}</CustomHeader>;
    } else if (title) {
      return (
        <Header>
          {title}
          <RoundSeamlessButton onClick={onCloseRequest}>
            <CloseIcon size={24} />
          </RoundSeamlessButton>
        </Header>
      );
    } else {
      return null;
    }
  }, [title, customHeader, onCloseRequest]);

  const handleBackdropClick = useCallback(
    (event: React.SyntheticEvent) => {
      if (event.isPropagationStopped()) {
        return;
      }
      onCloseRequest();
    },
    [onCloseRequest]
  );

  const handleRootClick = useCallback((event: React.SyntheticEvent) => {
    event.stopPropagation();
  }, []);

  if (!container) {
    return null;
  }

  return createPortal(
    <Backdrop type={backdropType} onClick={handleBackdropClick}>
      <Root onClick={handleRootClick} maxWidth={maxWidth}>
        {header}
        <Body width={width} minWidth={minWidth}>
          {children}
        </Body>
      </Root>
    </Backdrop>,
    container
  );
};
