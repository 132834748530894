import React, { memo } from "react";
import styled from "@emotion/styled";
import { DecompositionPackage } from "shared";
import {
  white,
  logo,
  gutters,
  Spinner,
  Button,
  Price,
  AmountInput,
  mqMax,
} from "client-lib";
import { ScreenSlide } from "src/features/layout";
import {
  useDeliveryEstimate,
  DeliveryLocationSelector,
} from "src/features/delivery";
import { useProduction } from "src/features/order";

const Container = styled(ScreenSlide)`
  position: relative;
  ${mqMax.phone} {
    min-height: auto;
  }
`;

const StyledDeliveryLocationSelector = styled(DeliveryLocationSelector)`
  display: block;
  margin-bottom: ${gutters.xl}px;
  section {
    width: 100%;
    margin-bottom: ${gutters.md}px;
  }
  section select {
    width: 100%;
    background: transparent;
    outline-color: ${white};
    color: ${white};
  }
`;

const DeliveryButton = styled(Button)`
  width: 160px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${white};
  }
`;

const ServiceName = styled.div`
  color: ${white};
  margin-top: ${gutters.lg}px;
`;

const ServiceValue = styled.div`
  margin-top: ${gutters.xs}px;
  color: ${logo};
`;

const AmountField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${gutters.md}px;
  margin-right: -${gutters.lg}px;
  margin-left: ${gutters.lg}px;
`;

const AmountLabel = styled.div``;

const StyledAmountInput = styled(AmountInput)`
  color: ${white};
  input {
    outline-color: ${white};
    color: ${white};
    background-color: transparent;
  }
  svg {
    fill: ${white};
  }
`;

interface Props {
  decompositionPackage: DecompositionPackage | null;
}

export const DeliveryCalculator = memo(function DeliveryCalculator({
  decompositionPackage,
}: Props) {
  const {
    amount,
    setAmount,
    deliveryLocationId,
    setDeliveryLocationId,
    deliveryRegion,
    setDeliveryRegion,
  } = useProduction();

  const {
    tkTerminalKopecks,
    tkLocationKopecks,
    isLoading,
    isTerminalCostActual,
    isLocationCostActual,
    refresh,
  } = useDeliveryEstimate(decompositionPackage, deliveryLocationId);

  return (
    <Container>
      <AmountField>
        <AmountLabel>Количество</AmountLabel>
        <StyledAmountInput
          limits={{ min: 1, max: 100 }}
          value={amount}
          onChange={setAmount}
        />
      </AmountField>
      <StyledDeliveryLocationSelector
        region={deliveryRegion}
        onRegionChange={setDeliveryRegion}
        deliveryLocationId={deliveryLocationId}
        onDeliveryLocationChange={setDeliveryLocationId}
      />
      {deliveryLocationId &&
        (!isTerminalCostActual || !isLocationCostActual || isLoading) && (
          <DeliveryButton onClick={refresh} size="L">
            {isLoading ? <Spinner size="regular" /> : "Рассчитать"}
          </DeliveryButton>
        )}
      {isTerminalCostActual &&
        isLocationCostActual &&
        tkTerminalKopecks !== null &&
        tkLocationKopecks !== null && (
          <React.Fragment>
            <ServiceName>Стоимость доставки до терминала</ServiceName>
            <ServiceValue>
              <Price
                kopecks={Math.floor(tkTerminalKopecks / amount)}
                showRubles
              />{" "}
              / шт
            </ServiceValue>
            <ServiceName>Стоимость доставки до вашего региона</ServiceName>
            <ServiceValue>
              <Price
                kopecks={Math.floor(tkLocationKopecks / amount)}
                showRubles
              />{" "}
              / шт
            </ServiceValue>
          </React.Fragment>
        )}
    </Container>
  );
});
