import { useEffect, useState, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { Pricing } from "shared";
import { GetPricing, GetPricingData, GetPricingVars } from "../queries";

const PRICING_DEBOUNCE_TIMEOUT = 500;
type TimeoutResult = ReturnType<typeof setTimeout>;

interface PricingResult {
  isStale: boolean;
  isLoading: boolean;
  pricing: Pricing | null;
}

export function usePricing<ConfigValues>(
  templatePresetId: string,
  configValues: ConfigValues
): PricingResult {
  const configValuesRef = useRef<ConfigValues>(configValues);
  const timeoutRef = useRef<TimeoutResult | null>(null);
  const latestFetchedAtRef = useRef<Date>(new Date());
  const latestChangedAtRef = useRef<Date>(new Date());
  const [isActual, setIsActual] = useState<boolean>(true);
  const [data, setData] = useState<GetPricingData | null>(null);
  const [fetch, { loading }] = useLazyQuery<GetPricingData, GetPricingVars>(
    GetPricing,
    {
      onCompleted(d: GetPricingData) {
        setData(d);
        if (latestFetchedAtRef.current >= latestChangedAtRef.current) {
          setIsActual(true);
        }
      },
    }
  );

  useEffect(
    function onUpdateValues() {
      configValuesRef.current = configValues;
      setIsActual(false);
      latestChangedAtRef.current = new Date();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        latestFetchedAtRef.current = new Date();
        timeoutRef.current = null;
        fetch({
          variables: {
            configValuesSerialized: JSON.stringify(configValuesRef.current),
            templatePresetId,
          },
        });
      }, PRICING_DEBOUNCE_TIMEOUT);
    },
    [configValues, fetch, templatePresetId]
  );

  return {
    isStale: Boolean(data && !isActual),
    isLoading: loading,
    pricing: data?.GetPricing ?? null,
  };
}
