import { z } from "zod";
import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import { TextField, Button, gutters, toFormikSchema } from "client-lib/ui";

const Container = styled.div`
  input {
    width: 250px !important;
    margin-bottom: ${gutters.md}px;
  }
`;

const signInFormSchema = z.object({
  email: z.string().email("Некорректный формат email"),
  password: z.string().min(4, "Пароль должен содержать не меньше 6 символов"),
});

export type SignInFormData = z.infer<typeof signInFormSchema>;
const formikSchema = toFormikSchema(signInFormSchema);

export function SignInForm({
  onSubmit,
  pending,
}: {
  onSubmit: (data: SignInFormData) => void;
  pending?: boolean;
}) {
  const handleSubmit = useCallback(
    (values: SignInFormData) => {
      if (pending) {
        return;
      }
      onSubmit(values);
    },
    [onSubmit]
  );

  return (
    <Container>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={formikSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            <TextField label="Ваш email: " name="email" disabled={pending} />
            <TextField
              label="Пароль: "
              name="password"
              type="password"
              disabled={pending}
            />
            <Button disabled={!isValid} type="submit">
              Войти
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
