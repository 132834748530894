import React, { memo } from "react";
import styled from "@emotion/styled";
import { grey10, gutters, mqMax } from "client-lib";
import {
  ScreenSlide,
  SlideContainer,
  Header,
  Typo160,
} from "src/features/layout";

const Illustration = styled.img`
  position: absolute;
  bottom: 32px;
  left: 0;
  max-height: calc(87% - 125px);
  max-width: 50vw;
  // height: 653px;
  height: auto;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${mqMax.phone} {
    display: block;
    margin-bottom: 260px;
  }
`;

const Message = styled(Typo160)`
  width: 50%;
  text-align: right;
  margin-bottom: ${gutters.xxl}px;
  margin-right: ${gutters.xl}px;
  ${mqMax.phone} {
    width: auto;
    text-align: center;
    font-size: 120%;
  }
`;

const Content = styled(SlideContainer)`
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mqMax.phone} {
    display: block;
    h2 {
      font-size: 40px;
    }
  }
`;

export const TechMaterialsSlide = memo(function WoodSlide() {
  return (
    <ScreenSlide style={{ backgroundColor: grey10, overflowX: "hidden" }}>
      <Content>
        <Header>Технологии и&nbsp;материалы</Header>
        <MessageWrapper>
          <Message>
            <p>
              Мы тщательно продумали нашу мебель до&nbsp;мелочей — от марки
              крепежа и толщины стального профиля до покрытия столешницы.
            </p>
            <p>
              Мы&nbsp;можем подробно рассказать о&nbsp;каждом компоненте,
              который используем.
            </p>
          </Message>
        </MessageWrapper>
      </Content>
      <Illustration src="/img/shelf-part.jpg" />
    </ScreenSlide>
  );
});
