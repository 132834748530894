import { Assets, Config } from "./types";

type LegsMode =
  | "parallel"
  | "parallel-rotated"
  | "sequence"
  | "sequence-rotated"
  | "layer"
  | "layer-rotated";

function detectLegsPackageMode(config: Config): LegsMode {
  const width = Math.max(config.frameTopWidth, config.frameBottomWidth);
  if (width * 2 <= config.countertopWidth) {
    return "parallel";
  }
  if (config.frameHeight * 2 <= config.countertopWidth) {
    return "parallel-rotated";
  }

  if (
    config.frameHeight * 2 <= config.countertopLength &&
    width < config.countertopWidth
  ) {
    return "sequence";
  }

  if (
    width * 2 <= config.countertopLength &&
    config.frameHeight < config.countertopWidth
  ) {
    return "sequence-rotated";
  }

  if (width > config.countertopLength && width <= config.countertopWidth) {
    return "layer";
  }
  if (width <= config.countertopLength && width > config.countertopWidth) {
    return "layer-rotated";
  }

  if (
    config.frameHeight <= config.countertopLength ||
    config.frameHeight > config.countertopWidth
  ) {
    return "layer";
  }

  return "layer-rotated";
}

function detectPackageDimensions(
  config: Config,
  assets: Assets
): [number, number, number] | null {
  const frameMaterial =
    assets.frameMaterials.find((m) => m.id === config.frameMaterialId) || null;

  const mode = detectLegsPackageMode(config);
  if (
    !frameMaterial ||
    !frameMaterial.mmDimension1 ||
    !frameMaterial.mmDimension2
  ) {
    return null;
  }
  const pipeWidth = frameMaterial.mmDimension1;

  switch (mode) {
    case "parallel":
    case "parallel-rotated":
    case "sequence":
    case "sequence-rotated":
      return [
        config.countertopLength,
        config.countertopThickness + pipeWidth,
        config.countertopWidth,
      ];
    case "layer":
      return [
        Math.max(config.countertopLength, config.frameHeight),
        config.countertopThickness + 2 * pipeWidth,
        Math.max(
          config.countertopWidth,
          config.frameTopWidth,
          config.frameBottomWidth
        ),
      ];
    case "layer-rotated":
      return [
        Math.max(
          config.countertopLength,
          config.frameTopWidth,
          config.frameBottomWidth
        ),
        config.countertopThickness + 2 * pipeWidth,
        Math.max(config.countertopWidth, config.frameHeight),
      ];
  }

  return null;
}

export const tableLoft1Utils = {
  detectLegsPackageMode,
  detectPackageDimensions,
};
