import { z } from "zod";
import {
  checkSchema,
  standardAttributes,
  emailOrPhoneSchema,
} from "../services";

export const supportMessageSchema = z.object({
  ...standardAttributes,
  userId: z.string().uuid().nullable(),
  senderName: z.string().min(2).max(200),
  senderEmailOrPhone: emailOrPhoneSchema,
  message: z.string().min(5).max(4096),
});
checkSchema(supportMessageSchema);
export type SupportMessage = z.infer<typeof supportMessageSchema>;

export const supportMessageInputSchema = supportMessageSchema.pick({
  id: true,
  senderName: true,
  senderEmailOrPhone: true,
  message: true,
});
export type SupportMessageInput = z.infer<typeof supportMessageInputSchema>;
