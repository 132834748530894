import { z } from "zod";
import { materialExpandedSchema } from "../../schemas";

import {
  MAX_THICKNESS,
  MIN_THICKNESS,
  MIN_ITEM_STEP,
  MAX_ITEM_STEP,
  MIN_ITEMS_AMOUNT,
  MAX_ITEMS_AMOUNT,
  MIN_INNER_WIDTH,
  MAX_INNER_WIDTH,
  MIN_WIDTH,
  MAX_WIDTH,
  MIN_HEIGHT,
  MAX_HEIGHT,
  MIN_DEPTH,
  MAX_DEPTH,
} from "./constants";

export const MIN_ITEM_LENGTH = 200;

export const shelfConfigItemSchema = z.object({
  coverageId: z.string().nullable(),
  step: z.number().int().min(MIN_ITEM_STEP).max(MAX_ITEM_STEP),
  thickness: z.number().int().min(MIN_THICKNESS).max(MAX_THICKNESS),

  width: z.number().int().min(MIN_WIDTH).max(MAX_WIDTH),
  height: z.number().int().min(MIN_HEIGHT).max(MAX_HEIGHT),
  innerWidth: z.number().int().min(MIN_INNER_WIDTH).max(MAX_INNER_WIDTH),
});
export type ShelfConfigItem = z.infer<typeof shelfConfigItemSchema>;

export const shelfConfigSchema = z.object({
  sidesCount: z.union([z.literal(3), z.literal(4), z.literal(6)]),
  width: z.number().int().min(MIN_WIDTH).max(MAX_WIDTH),
  height: z.number().int().min(MIN_HEIGHT).max(MAX_HEIGHT),
  depth: z.number().int().min(MIN_DEPTH).max(MAX_DEPTH),

  isEquilateral: z.boolean(),

  items: z
    .array(shelfConfigItemSchema)
    .min(MIN_ITEMS_AMOUNT)
    .max(MAX_ITEMS_AMOUNT),

  packageCardboardId: z.string(),
  packageBubbleWrapId: z.string(),
});
export type Config = z.infer<typeof shelfConfigSchema>;

export const shelfAssetsSchema = z.object({
  shelfMaterial: materialExpandedSchema,
  shelfCoverage: z.array(materialExpandedSchema),
  dowel: materialExpandedSchema,
  fastener: materialExpandedSchema,
  hanger: materialExpandedSchema,
  packageCardboard: z.array(materialExpandedSchema),
  packageBubbleWrap: z.array(materialExpandedSchema),
});
export type Assets = z.infer<typeof shelfAssetsSchema>;

export const shelfVariablesSchema = z.object({});
export type Variables = z.infer<typeof shelfVariablesSchema>;
