import { z } from "zod";
import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import { TextField, Button, gutters, toFormikSchema } from "client-lib/ui";

const Container = styled.div`
  input {
    width: 250px !important;
    margin-bottom: ${gutters.md}px;
  }
`;

const signUpFormSchema = z
  .object({
    email: z.string().email("Некорректный формат email"),
    password: z.string().min(8, "Пароль должен содержать не меньше 8 символов"),
    confirmation: z.string(),
  })
  .refine((data) => data.password === data.confirmation, {
    message: "Введенные пароли не совпадают",
    path: ["confirmation"],
  });

export type SignUpFormData = z.infer<typeof signUpFormSchema>;
const formikSchema = toFormikSchema(signUpFormSchema);

export function SignUpForm({
  onSubmit,
  pending,
}: {
  onSubmit: (data: SignUpFormData) => void;
  pending?: boolean;
}) {
  const handleSubmit = useCallback(
    (values: SignUpFormData) => {
      if (pending) {
        return;
      }
      onSubmit(values);
    },
    [onSubmit]
  );

  return (
    <Container>
      <Formik
        initialValues={{ email: "", password: "", confirmation: "" }}
        validationSchema={formikSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            <TextField label="Ваш email: " name="email" disabled={pending} />
            <TextField
              label="Пароль (минимум 8 знаков): "
              name="password"
              type="password"
              disabled={pending}
            />
            <TextField
              label="Повторите пароль: "
              name="confirmation"
              type="password"
              disabled={pending}
            />
            <Button disabled={!isValid} type="submit">
              Зарегистрироваться
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
