import React, { MutableRefObject, useRef } from "react";
import { Global } from "@emotion/react";
import { globalStyles, EnvProvider } from "client-lib";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import {
  SignInPage,
  SignUpPage,
  AuthProvider,
  AuthRef,
} from "src/features/auth";
import { DashboardPage } from "src/features/account";
import {
  CartPage,
  CartOrderPage,
  OrdersPage,
  OrderPage,
} from "src/features/order";
import { MainPage } from "src/features/main";
import {
  TemplatePresetPage,
  TemplatePresetsPage,
  TemplateShotPage,
  TemplateShotsPage,
} from "src/features/templates";
import { ContactsPage } from "src/features/contacts";
import { ApolloProvider } from "src/apollo";
import { EnvironmentProvider } from "src/features/environment";
import { RequisitesPage, PrivacyPolicyPage } from "src/features/legal";
import { ToasterProvider, Toaster } from "src/features/toaster";
import { LoftTableLanding } from "src/features/landings";
import { Theme } from "@radix-ui/themes";

export function App() {
  const authRef: MutableRefObject<AuthRef | null> = useRef<AuthRef>(null);
  return (
    <Theme>
      <EnvProvider>
        <BrowserRouter>
          <ToasterProvider>
            <Toaster />
            <ApolloProvider authRef={authRef}>
              <AuthProvider ref={authRef}>
                <EnvironmentProvider>
                  <Global styles={globalStyles} />
                  <Switch>
                    <Route path="/signin" exact component={SignInPage} />
                    <Route path="/signup" exact component={SignUpPage} />
                    <Route path="/" exact component={MainPage} />
                    <Route
                      path="/loft-table"
                      exact
                      component={LoftTableLanding}
                    />
                    <Route
                      path="/templates"
                      exact
                      component={TemplatePresetsPage}
                    />
                    <Route
                      path="/account/models"
                      exact
                      component={TemplateShotsPage}
                    />
                    <Route
                      path="/account/models/:templateShotId"
                      exact
                      component={TemplateShotPage}
                    />
                    <Route
                      path="/account/models/:templateShotId/:versionNumber"
                      exact
                      component={TemplateShotPage}
                    />
                    <Route
                      path="/templates/:templatePresetId"
                      exact
                      component={TemplatePresetPage}
                    />

                    <Route path="/account" exact component={DashboardPage} />
                    <Route path="/account/cart" exact component={CartPage} />
                    <Route
                      path="/account/cart/order"
                      exact
                      component={CartOrderPage}
                    />
                    <Route
                      path="/account/orders"
                      exact
                      component={OrdersPage}
                    />
                    <Route
                      path="/account/orders/:orderId"
                      exact
                      component={OrderPage}
                    />
                    <Route path="/contacts" exact component={ContactsPage} />
                    <Route
                      path="/requisites"
                      exact
                      component={RequisitesPage}
                    />
                    <Route
                      path="/privacy-policy"
                      exact
                      component={PrivacyPolicyPage}
                    />
                  </Switch>
                </EnvironmentProvider>
              </AuthProvider>
            </ApolloProvider>
          </ToasterProvider>
        </BrowserRouter>
      </EnvProvider>
    </Theme>
  );
}
