import { Assets, Config, Variables } from "./types";
import { getDimensionsMm } from "../utils";

export type SplitItem = {
  length: number;
  offset: number;
};

export function getSplitItems(
  config: Config,
  assets: Assets,
  itemLength: number
): SplitItem[] {
  const count =
    config.areShelvesSplit && config.frameItemsCount > 2
      ? config.frameItemsCount - 1
      : 1;

  const frameMaterial =
    assets.frameMaterials.find((m) => m.id === config.frameMaterialId) || null;
  const framePipe = getDimensionsMm(frameMaterial);

  if (count === 1 || !framePipe.d1) {
    return [
      {
        length: itemLength,
        offset: 0,
      },
    ];
  }

  const basicLength = (config.frameGap + framePipe.d1) / count;
  const edgeDelta = (itemLength - basicLength * count) / 2;
  const edgeLength = basicLength + edgeDelta;

  const result: SplitItem[] = [];
  let offset = -itemLength / 2;

  for (let i = 0; i < count; i++) {
    const isEdge = i === 0 || i === count - 1;
    offset += isEdge ? edgeLength / 2 : basicLength / 2;
    result.push({
      offset,
      length: isEdge ? edgeLength : basicLength,
    });
    offset += isEdge ? edgeLength / 2 : basicLength / 2;
  }

  return result;
}

interface SplitItemPosition {
  itemIndex: number;
  length: number;
  dx: number;
  dy: number;
  layerLength: number;
}

function distributeShelvePositions(
  config: Config,
  assets: Assets,
  _variables: Variables
): SplitItemPosition[] {
  const positions: SplitItemPosition[] = [];

  for (let i = 0; i < config.items.length; i++) {
    const splitItems = getSplitItems(config, assets, config.items[i].length);
    for (const splitItem of splitItems) {
      positions.push({
        itemIndex: i,
        length: splitItem.length,
        dx: 0,
        dy: 0,
        layerLength: 0,
      });
    }
  }

  positions.sort((p1: SplitItemPosition, p2: SplitItemPosition) => {
    const item1 = config.items[p1.itemIndex];
    const item2 = config.items[p2.itemIndex];

    return Math.sign(item1.thickness - item2.thickness);
  });

  const lengthLimit = config.frameHeight;
  let dx = 0;
  let dy = 0;
  let layerThickness = 0;
  for (const position of positions) {
    const item = config.items[position.itemIndex];
    if (layerThickness === 0) {
      layerThickness = item.thickness;
    }

    position.dx = dx;
    position.dy = dy;

    dx += position.length;

    if (dx > lengthLimit) {
      dy += layerThickness;
      layerThickness = 0;
      dx = 0;
      if (position.length <= lengthLimit) {
        position.dy = dy;
        position.dx = 0;
        dx += position.length;
        layerThickness = item.thickness;
      }
    }
  }

  let layerLength = 0;
  let layerStart = 0;
  dy = positions[0]?.dy || 0;
  for (let i = 0; i < positions.length; i++) {
    const position = positions[i];
    if (position.dy > dy) {
      for (let j = layerStart; j < i; j++) {
        positions[j].layerLength = layerLength;
      }
      layerStart = i;
      dy = position.dy;
      layerLength = 0;
    }
    layerLength += position.length;
  }
  // last layer
  for (let j = layerStart; j < positions.length; j++) {
    positions[j].layerLength = layerLength;
  }

  return positions;
}

function detectPackageDimensions(
  config: Config,
  assets: Assets,
  variables: Variables
): [number, number, number] | null {
  const frameMaterial =
    assets.frameMaterials.find((m) => m.id === config.frameMaterialId) || null;

  const pipeWidth = frameMaterial?.mmDimension1
    ? frameMaterial?.mmDimension1
    : 0;

  const positions = distributeShelvePositions(config, assets, variables);
  let maxLength = config.height;
  let maxThickness = 0;
  for (const position of positions) {
    const item = config.items[position.itemIndex];
    if (position.layerLength > maxLength) {
      maxLength = position.layerLength;
    }
    if (position.dy + item.thickness > maxThickness) {
      maxThickness = position.dy + item.thickness;
    }
  }

  return [
    maxLength,
    pipeWidth * config.frameItemsCount + maxThickness,
    config.frameWidth,
  ];
}

export const shelfLoft1Utils = {
  getSplitItems,
  distributeShelvePositions,
  detectPackageDimensions,
};
