import React from "react";
import styled from "@emotion/styled";
import { Spinner } from "@vkontakte/vkui";
import { Button, Price, white } from "client-lib/ui";

const Container = styled.div`
  position: relative;
  flex: 0 0 62px;
  text-align: right;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: ${white};
  opacity: 0.5;
  align-items: center;
  justify-content: center;
`;

type Props = {
  isLoading: boolean;
  isActual: boolean;
  amount: number;
  value: number | null;
  refresh: VoidFunction;
  children?: JSX.Element | null | false;
  className?: string;
};

export function DeliveryCost({
  isLoading,
  isActual,
  amount,
  value,
  refresh,
  children,
  className,
}: Props) {
  if (isLoading) {
    return (
      <Container className={className}>
        <SpinnerWrapper>
          <Spinner size="small" />
        </SpinnerWrapper>
      </Container>
    );
  }
  if (!isActual) {
    return (
      <Container className={className}>
        <Button size="XS" onClick={refresh}>
          Рассчитать
        </Button>
      </Container>
    );
  }
  if (value === null) {
    return <Container className={className} />;
  }
  return (
    children || (
      <Container className={className}>
        <Price kopecks={Math.round(value / amount)} />
      </Container>
    )
  );
}
