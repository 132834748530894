import { getDimensionsMm } from "shared";
import { Config, Assets } from "./types";

export type SplitItem = {
  length: number;
  offset: number;
};

export function getSplitItems(
  config: Config,
  assets: Assets,
  itemLength: number
): SplitItem[] {
  const count =
    config.areShelvesSplit && config.frameItemsCount > 2
      ? config.frameItemsCount - 1
      : 1;

  const frameMaterial =
    assets.frameMaterials.find((m) => m.id === config.frameMaterialId) || null;
  const framePipe = getDimensionsMm(frameMaterial);

  if (count === 1 || !framePipe.d1) {
    return [
      {
        length: itemLength,
        offset: 0,
      },
    ];
  }

  const basicLength = (config.frameGap + framePipe.d1) / count;
  const edgeDelta = (itemLength - basicLength * count) / 2;
  const edgeLength = basicLength + edgeDelta;

  const result: SplitItem[] = [];
  let offset = -itemLength / 2;

  for (let i = 0; i < count; i++) {
    const isEdge = i === 0 || i === count - 1;
    offset += isEdge ? edgeLength / 2 : basicLength / 2;
    result.push({
      offset,
      length: isEdge ? edgeLength : basicLength,
    });
    offset += isEdge ? edgeLength / 2 : basicLength / 2;
  }

  return result;
}
