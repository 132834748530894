import React, { useCallback } from "react";
import { Button, InnerLink } from "client-lib";
import { useMutation } from "@apollo/client";
import {
  RecalculateOrderPricingData,
  RecalculateOrderPricing,
  RecalculateOrderPricingVars,
} from "./queries";

interface Props {
  orderId: string;
  children?: React.ReactNode;
  type?: "button" | "link";
}

export function RefreshPriceButton({
  orderId,
  children,
  type = "button",
}: Props) {
  const [recalculate, { loading }] = useMutation<
    RecalculateOrderPricingData,
    RecalculateOrderPricingVars
  >(RecalculateOrderPricing);

  const handleRecalculate = useCallback(() => {
    recalculate({
      variables: {
        orderId,
      },
    });
  }, [recalculate, orderId]);

  return type === "button" ? (
    <Button size="S" disabled={loading} onClick={handleRecalculate}>
      {children || "Пересчитать"}
    </Button>
  ) : (
    <InnerLink onClick={handleRecalculate}>
      {children || "Пересчитать"}
    </InnerLink>
  );
}
