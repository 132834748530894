import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";

export enum TeamUserRole {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  VIEWER = "VIEWER",
}
export const teamUserRoleSchema = z.nativeEnum(TeamUserRole);

export const teamUserSchema = z.object({
  ...standardAttributes,
  teamId: z.string(),
  userId: z.string(),
  role: teamUserRoleSchema,
  ordering: z.number().int(),
});
checkSchema(teamUserSchema);
export type TeamUser = typeof teamUserSchema["_type"];
