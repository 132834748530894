import React from "react";
import { black } from "../styles";
import { IconBase, IconProps } from "./base";

export function DiameterIcon({ color = black, ...props }: IconProps) {
  return (
    <IconBase {...props}>
      <ellipse
        cx="12"
        cy="12"
        rx="8.76"
        ry="7.96"
        stroke={color}
        strokeWidth="1.38"
        fill={"transparent"}
        id="0"
      />
      <path
        d="m 5.99,23.4 l 10.44,-22.59 l 1.59,0 l -10.44,22.59 z"
        fill={color}
        fillRule="evenodd"
        id="/"
      />
    </IconBase>
  );
}
