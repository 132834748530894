import React, { Dispatch, memo, SetStateAction, useMemo } from "react";
import styled from "@emotion/styled";
import { TemplateConfigControlType, MaterialExpanded } from "shared";
import { Scene } from "src/features/template-editor/scene";
import { useEnv, toFormikSchema } from "client-lib";
import { SceneOptions, TemplateFull } from "src/features/template-editor/types";
import { Control } from "src/features/template-editor/controls";
import { Formik } from "formik";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const SceneContainer = styled.div`
  position: relative;
  flex: 1 1 100%;
`;

const SceneOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Controls = styled.div``;

interface Props {
  configValues: any;
  variables: any;
  templateFull: TemplateFull<any, any, any>;
  setConfigValues: Dispatch<SetStateAction<any>>;
  handleAttributeChange: (name: string, value: any) => void;
}

export const ColorExample = memo(function ColorExample({
  configValues,
  variables,
  templateFull,
  setConfigValues,
  handleAttributeChange,
}: Props) {
  const { isMobileAgent } = useEnv();

  const validationSchema = useMemo(
    () => toFormikSchema(templateFull.model.configSchema),
    [templateFull.model.configSchema]
  );

  const controlDef = useMemo(
    () => ({
      type: TemplateConfigControlType.IMG_PICKER,
      name: "countertopCoverageId",
      nullable: true,
      items: templateFull.assets.countertopCoverage.map(
        (m: MaterialExpanded) => ({
          title: m.pickerTitle || m.title,
          value: m.id,
          img: m.texture1?.path,
        })
      ),
    }),
    [templateFull.assets]
  );

  const sceneOptions = useMemo<SceneOptions>(
    () => ({
      showMeasurements: false,
      disableZoom: !isMobileAgent,
      autoRotate: true,
      camera: {
        position: [0, 800, 900],
      },
    }),
    [isMobileAgent]
  );

  return (
    <Container>
      <Formik
        initialValues={configValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={setConfigValues}
      >
        <Controls>
          <Control def={controlDef} onAttributeChange={handleAttributeChange} />
        </Controls>
      </Formik>
      <SceneContainer>
        <Scene options={sceneOptions}>
          <templateFull.component
            config={configValues}
            assets={templateFull.assets}
            variables={variables}
            mode="model"
          />
        </Scene>
        {isMobileAgent && <SceneOverlay />}
      </SceneContainer>
    </Container>
  );
});
