import React, { useCallback } from "react";
import { useField } from "formik";
import { Slider } from "./slider";

interface Props {
  readOnly: boolean;
  name: string;
  min: number;
  max: number;
  onChange?: (value: number) => void;
}

export function SliderField({ readOnly, name, min, max, onChange }: Props) {
  const [{ value }, , { setValue }] = useField<number>(name);
  const handleChange = useCallback(
    (v: number) => {
      setValue(v);
      onChange?.(v);
    },
    [onChange]
  );
  return (
    <Slider
      readOnly={readOnly}
      min={min}
      max={max}
      value={value}
      onChange={handleChange}
    />
  );
}
