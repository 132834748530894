import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { TemplateConfigControl, TemplateConfigControlType } from "shared";
import {
  IntField,
  SelectField,
  SelectFieldNumeric,
  CheckboxField,
  gutters,
} from "client-lib/ui";
import { ImgPicker } from "./img-picker";
import { SliderControl } from "./slider";
import { useConfig } from "../config-form";

const selectFieldStyles = css`
  margin-bottom: ${gutters.md}px;
  select {
    width: 100%;
  }
`;

const StyledSelectField = styled(SelectField)(selectFieldStyles);
const StyledSelectFieldNumeric = styled(SelectFieldNumeric)(selectFieldStyles);
const StyledCheckboxField = styled(CheckboxField)`
  margin-bottom: ${gutters.md}px;
`;

interface Props {
  def: TemplateConfigControl;
  onAttributeChange: (name: string, value: any) => void;
}

export function Control({ def, onAttributeChange }: Props) {
  const { readOnly } = useConfig();
  if (def.hidden) {
    return null;
  }
  switch (def.type) {
    case TemplateConfigControlType.INT_SLIDER:
      return (
        <SliderControl
          readOnly={readOnly}
          label={def.label}
          name={def.name}
          min={def.min || 0}
          max={def.max || 0}
          options={def.options}
          onChange={(value) => onAttributeChange(def.name, value)}
        />
      );
    case TemplateConfigControlType.INT:
      return (
        <React.Fragment>
          <IntField
            disabled={readOnly}
            name={def.name}
            label={def.label}
            onChange={(value) => onAttributeChange(def.name, value)}
          />
        </React.Fragment>
      );
    case TemplateConfigControlType.IMG_PICKER:
      return (
        <React.Fragment>
          <ImgPicker
            readOnly={readOnly}
            name={def.name}
            label={def.label}
            items={def.items || []}
            nullable={def.nullable}
            onChange={(value) => onAttributeChange(def.name, value)}
          />
        </React.Fragment>
      );
    case TemplateConfigControlType.CHECKBOX:
      return (
        <React.Fragment>
          <StyledCheckboxField
            disabled={readOnly}
            name={def.name}
            label={def.label}
            onChange={(value) => onAttributeChange(def.name, value)}
          />
        </React.Fragment>
      );
    case TemplateConfigControlType.SELECTOR:
    case TemplateConfigControlType.SELECTOR_NUMERIC:
      const SelectControl =
        def.type === TemplateConfigControlType.SELECTOR
          ? StyledSelectField
          : StyledSelectFieldNumeric;

      return (
        <React.Fragment>
          <SelectControl
            disabled={readOnly}
            name={def.name}
            label={def.label}
            onChange={(value) => onAttributeChange(def.name, value)}
          >
            {def.nullable && <option value="">Не выбрано</option>}
            {(def.items || []).map((item) => (
              <option key={item.value} value={item.value}>
                {item.title}
              </option>
            ))}
          </SelectControl>
        </React.Fragment>
      );
  }
  return null;
}
