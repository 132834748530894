import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ICON_SIZE = 24;

export type IconProps = {
  size?: number;
  color?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
};

export const SvgBase = styled.svg<IconProps>(
  ({ color, fill, size = ICON_SIZE }) => css`
    width: ${size}px;
    height: ${size}px;
    fill: ${fill || "transparent"};
    stroke: ${color || "transparent"};
  `
);

export function IconBase({
  children,
  ...props
}: IconProps & { children: ReactNode }) {
  return (
    <SvgBase viewBox={`0 0 ${ICON_SIZE} ${ICON_SIZE}`} {...props}>
      {children}
    </SvgBase>
  );
}

export const Rotate90CounterClockWise = styled.g`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export const Rotate90ClockWise = styled.g`
  transform: rotate(90deg);
  transform-origin: 50% 50%;
`;
