import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { primary, white } from "../../styles";

interface Dimensions {
  size: number;
  fontSize: number;
  borderRadius: number;
}

const Container = styled.div<{ dimensions: Dimensions }>(
  ({ dimensions }) => css`
    display: flex;
    width: ${dimensions.size}px;
    height: ${dimensions.size}px;
    border-radius: ${dimensions.borderRadius}px;
    align-items: center;
    justify-content: center;
    background-color: ${primary};
    font-size: ${dimensions.fontSize}px;
    font-weight: 900;
    color: ${white};
    cursor: pointer;
  `
);

type Size = "sm" | "md" | "lg";

const dimensionsTable: Record<Size, Dimensions> = {
  sm: {
    size: 24,
    fontSize: 14,
    borderRadius: 4,
  },
  md: {
    size: 32,
    fontSize: 18,
    borderRadius: 6,
  },
  lg: {
    size: 40,
    fontSize: 28,
    borderRadius: 8,
  },
};

interface Props {
  size?: Size;
}

export function AdminLogoIcon({ size }: Props) {
  const dimensions = dimensionsTable[size || "md"];
  return <Container dimensions={dimensions}>M</Container>;
}
