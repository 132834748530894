import React from "react";
import { grey90, white } from "../styles";
import { IconBase, IconProps, ICON_SIZE } from "./base";

const STEP = 2;
const HEIGHT = 14;
const WIDTH = 11;
const PADDING_X = (ICON_SIZE - WIDTH - STEP * 2) / 2;
const PADDING_Y = (ICON_SIZE - HEIGHT - STEP * 2) / 2;

export function MultiPageIcon({
  fill = white,
  color = grey90,
  ...props
}: IconProps) {
  return (
    <IconBase {...props}>
      <g transform={"translate(0.5,0.5)"}>
        <rect
          x={PADDING_X}
          y={PADDING_Y}
          width={WIDTH}
          height={HEIGHT}
          fill={fill}
          stroke={color}
          strokeWidth={0.5}
          rx={0.5}
        />
        <rect
          x={PADDING_X + STEP}
          y={PADDING_Y + STEP}
          width={WIDTH}
          height={HEIGHT}
          fill={fill}
          stroke={color}
          strokeWidth={0.5}
          rx={0.5}
        />
        <rect
          x={PADDING_X + STEP * 2}
          y={PADDING_Y + STEP * 2}
          width={WIDTH}
          height={HEIGHT}
          fill={fill}
          strokeWidth={0.5}
          stroke={color}
          rx={0.5}
        />
      </g>
    </IconBase>
  );
}
