import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";
import { techProcessCodeSchema } from "./tech-process";
import { pricingLimitSchema } from "./pricing-limit";

export const costKopecksSchema = z.number().int();

export const pricingCostSchema = z.object({
  ...standardAttributes,
  materialId: z.string().uuid().nullable(),
  techProcessCode: techProcessCodeSchema.nullable(),
  limit: pricingLimitSchema,
  costKopecks: costKopecksSchema,
});
checkSchema(pricingCostSchema);
export type PricingCost = z.infer<typeof pricingCostSchema>;

export const pricingCostInputSchema = pricingCostSchema
  .pick({
    materialId: true,
    techProcessCode: true,
    limit: true,
  })
  .extend({
    costKopecks: costKopecksSchema.nullable(),
  });
export type PricingCostInput = z.infer<typeof pricingCostInputSchema>;
