import {
  Decomposition,
  DecompositionSection,
  TechProcessCode,
  UnitType,
} from "../../schemas";
import { Assets, Config, Variables } from "./types";
import { hypotenuseLength } from "../service";
import { shelfLoft1Utils } from "./utils";
import {
  getUnusedWoodenPanelLength,
  getUnusedWoodenPanelThickness,
  getUnusedWoodenPanelWidth,
  getBoxArea,
  createMaterialItem,
  createTechProcessItem,
} from "../utils";
import { TechProcessPool } from "../../tech-processes";
import { SideRibType } from "../util-types";

const DRILLING_RADIUS = 5 / 2;
const DRILLING_AREA = Math.PI * DRILLING_RADIUS ** 2;

function addRibToSection({
  section,
  prefix,
  widthMm,
  heightMm,
  type,
  materialId,
  assets,
  frameItemsCount,
  frameCoverageId,
}: {
  section: DecompositionSection;
  prefix: string;
  widthMm: number;
  heightMm: number;
  type: SideRibType;
  materialId: string | null;
  assets: Assets;
  frameItemsCount: number;
  frameCoverageId: string | null;
}) {
  const ribAmount =
    type === SideRibType.BOTH ? 2 : type === SideRibType.NONE ? 0 : 1;
  if (ribAmount === 0) {
    return;
  }

  const material = assets.frameMaterials.find((m) => m.id === materialId);
  if (!material) {
    throw new Error("Can't build decomposition: empty frameMaterial for rib");
  }
  if (
    !material.mmDimension1 ||
    !material.mmDimension2 ||
    !material.mmDimension3
  ) {
    throw new Error(
      "Can't build decomposition: empty frameMaterial dimensions"
    );
  }

  const ribLength = (widthMm * widthMm + heightMm * heightMm) ** 0.5;
  const crossPerimeterOrtho =
    (material.mmDimension1 + material.mmDimension2) * 2;
  const crossPerimeter = (crossPerimeterOrtho * ribLength) / widthMm;
  const ribArea = ribLength * crossPerimeterOrtho;

  section.items.push(
    createMaterialItem(material, ribLength, UnitType.MILLIMETER, {
      title: `${prefix}${material.title}`,
      amount: ribAmount * frameItemsCount,
    })
  );

  const pipeSawingArea = crossPerimeter * material.mmDimension3;
  section.items.push(
    createTechProcessItem(
      TechProcessCode.STEEL_SAWING,
      pipeSawingArea,
      UnitType.MILLIMETER_2,
      {
        title: `${prefix}распил стального профиля диагональный`,
        amount: 2 * ribAmount * frameItemsCount,
      }
    )
  );

  section.items.push(
    createTechProcessItem(
      TechProcessCode.STEEL_WELDING,
      crossPerimeter,
      UnitType.MILLIMETER,
      {
        title: `${prefix}сварка стального профиля`,
        amount: 2 * ribAmount * frameItemsCount,
      }
    )
  );

  // Grinding width is 1cm
  const pipeGrindingArea = crossPerimeter * 10;
  section.items.push(
    createTechProcessItem(
      TechProcessCode.STEEL_GRINDING,
      pipeGrindingArea,
      UnitType.MILLIMETER_2,
      {
        title: `${prefix}шлифовка сварочного шва`,
        amount: 2 * ribAmount * frameItemsCount,
      }
    )
  );

  if (ribAmount === 2) {
    section.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_SAWING,
        crossPerimeterOrtho * material.mmDimension3,
        UnitType.MILLIMETER_2,
        {
          title: `${prefix}крестовина – распил стального профиля`,
          amount: 2 * frameItemsCount,
        }
      )
    );
    section.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_WELDING,
        crossPerimeterOrtho,
        UnitType.MILLIMETER,
        {
          title: `${prefix}крестовина – сварка стального профиля`,
          amount: 2 * frameItemsCount,
        }
      )
    );
    section.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_GRINDING,
        // Grinding width is 1cm
        crossPerimeterOrtho * 10,
        UnitType.MILLIMETER_2,
        {
          title: `${prefix}крестовина – шлифовка сварочного шва`,
          amount: 2 * frameItemsCount,
        }
      )
    );
  }

  if (frameCoverageId) {
    const techProcess = TechProcessPool[TechProcessCode.STEEL_POWDER_COATING];
    section.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_POWDER_COATING,
        ribArea,
        UnitType.MILLIMETER_2,
        {
          title: `${prefix}${techProcess.title}`,
          amount: ribAmount * frameItemsCount,
        }
      )
    );
  }
}

export function getDecomposition(
  config: Config,
  assets: Assets,
  variables: Variables
): Decomposition {
  const sections: DecompositionSection[] = [];

  const frameSection: DecompositionSection = {
    title: "Каркас – рама",
    items: [],
  };
  sections.push(frameSection);

  const frameMaterial = assets.frameMaterials.find(
    (m) => m.id === config.frameMaterialId
  );
  if (!frameMaterial) {
    throw new Error("Can't build decomposition: empty frameMaterial");
  }
  if (
    !frameMaterial.mmDimension1 ||
    !frameMaterial.mmDimension2 ||
    !frameMaterial.mmDimension3
  ) {
    throw new Error(
      "Can't build decomposition: empty frameMaterial dimensions"
    );
  }

  const partPerimeter = config.frameHeight * 2 + config.frameWidth * 2;

  const partAreaMm = getBoxArea([
    partPerimeter,
    frameMaterial.mmDimension1,
    frameMaterial.mmDimension2,
  ]);

  {
    frameSection.items.push(
      createMaterialItem(frameMaterial, partPerimeter, UnitType.MILLIMETER, {
        amount: config.frameItemsCount,
      })
    );

    const partDimension2Diagonal = hypotenuseLength(
      frameMaterial.mmDimension2,
      frameMaterial.mmDimension2
    );
    const pipeSawingPerimeter =
      (frameMaterial.mmDimension1 + partDimension2Diagonal) * 2;
    const pipeSawingArea = pipeSawingPerimeter * frameMaterial.mmDimension3;

    frameSection.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_SAWING,
        pipeSawingArea,
        UnitType.MILLIMETER_2,
        {
          title: `Распил стального профиля диагональный`,
          amount: 8 * config.frameItemsCount,
        }
      )
    );

    frameSection.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_WELDING,
        pipeSawingPerimeter,
        UnitType.MILLIMETER,
        {
          title: `Сварка стального профиля`,
          amount: 4 * config.frameItemsCount,
        }
      )
    );

    // Grinding width is 1cm
    const pipeGrindingArea = pipeSawingPerimeter * 10;
    frameSection.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_GRINDING,
        pipeGrindingArea,
        UnitType.MILLIMETER_2,
        {
          title: `Шлифовка сварочного шва`,
          amount: 4 * config.frameItemsCount,
        }
      )
    );

    if (!variables.isRackLike) {
      const profileDrillingVolume = DRILLING_AREA * frameMaterial.mmDimension3;
      frameSection.items.push(
        createTechProcessItem(
          TechProcessCode.STEEL_DRILLING,
          profileDrillingVolume,
          UnitType.MILLIMETER_3,
          {
            title: `Сверление стального профиля (под крепление)`,
            amount: config.frameItemsCount,
          }
        )
      );

      const DRILLING_RADIUS_2 = 10 / 2;
      const DRILLING_AREA_2 = Math.PI * DRILLING_RADIUS_2 ** 2;
      const profileDrillingVolume2 =
        DRILLING_AREA_2 * frameMaterial.mmDimension3;
      frameSection.items.push(
        createTechProcessItem(
          TechProcessCode.STEEL_DRILLING,
          profileDrillingVolume2,
          UnitType.MILLIMETER_3,
          {
            title: `Сверление стального профиля (под шляпку крепления)`,
            amount: 2,
          }
        )
      );
    }

    if (config.frameCoverageId) {
      frameSection.items.push(
        createTechProcessItem(
          TechProcessCode.STEEL_POWDER_COATING,
          partAreaMm,
          UnitType.MILLIMETER_2,
          {
            amount: config.frameItemsCount,
          }
        )
      );
    }
  }

  const frameInnerWidth = config.frameWidth - 2 * config.frameMaterialHeight;
  {
    const section: DecompositionSection = {
      title: `Ребра жесткости`,
      items: [],
    };
    for (let i = 0; i < config.items.length; i++) {
      const item = config.items[i];
      addRibToSection({
        section,
        prefix: `Полка #${i + 1}: `,
        widthMm: frameInnerWidth,
        heightMm: item.marginTop,
        type: item.sideRibType,
        materialId: item.sideRibMaterialId,
        assets,
        frameItemsCount: config.frameItemsCount,
        frameCoverageId: config.frameCoverageId,
      });
    }

    if (config.frameBottomSideRibMaterialId) {
      addRibToSection({
        section,
        prefix: `Gод нижней полкой: `,
        widthMm: frameInnerWidth,
        heightMm: config.frameBottomMargin,
        type: config.frameBottomSideRibType,
        materialId: config.frameBottomSideRibMaterialId,
        assets,
        frameItemsCount: config.frameItemsCount,
        frameCoverageId: config.frameCoverageId,
      });
    }

    if (section.items.length > 0) {
      sections.push(section);
    }
  }

  for (let i = 0; i < config.items.length; i++) {
    const item = config.items[i];
    const section: DecompositionSection = {
      title: `Полка #${i + 1}`,
      items: [],
    };
    sections.push(section);

    const unusedWoodenPanelThickness = getUnusedWoodenPanelThickness(
      item.thickness
    );
    const blankThickness = item.thickness + (unusedWoodenPanelThickness || 0);
    const volume = blankThickness * item.width * item.length;

    section.items.push(
      createMaterialItem(assets.shelfMaterial, volume, UnitType.MILLIMETER_3, {
        title: `${assets.shelfMaterial.title} ${item.length}x${item.width}x${blankThickness}мм`,
      })
    );

    if (unusedWoodenPanelThickness) {
      const unusedVolume =
        unusedWoodenPanelThickness * item.width * item.length;

      section.items.push(
        createTechProcessItem(
          TechProcessCode.WOOD_PLANING,
          unusedVolume,
          UnitType.MILLIMETER_3,
          {
            title: `Строжка мебельного щита ${unusedWoodenPanelThickness}мм`,
          }
        )
      );
    }

    const unusedWoodenPanelWidth = getUnusedWoodenPanelWidth(item.width);
    if (unusedWoodenPanelWidth) {
      const unusedVolume =
        blankThickness * unusedWoodenPanelWidth * item.length;

      section.items.push(
        createMaterialItem(
          assets.shelfMaterial,
          unusedVolume,
          UnitType.MILLIMETER_3,
          {
            title: `Отходы материала по ширине: ${assets.shelfMaterial.title} ${item.length}x${unusedWoodenPanelWidth}x${blankThickness}мм`,
          }
        )
      );
    }
    const unusedWoodenPanelLength = getUnusedWoodenPanelLength(item.length);
    if (unusedWoodenPanelLength) {
      const unusedVolume =
        blankThickness * item.width * unusedWoodenPanelLength;
      section.items.push(
        createMaterialItem(
          assets.shelfMaterial,
          unusedVolume,
          UnitType.MILLIMETER_3,
          {
            title: `Отходы материала по длине: ${assets.shelfMaterial.title} ${unusedWoodenPanelLength}x${item.width}x${blankThickness}мм`,
          }
        )
      );
    }

    if (item.width !== 600 && item.width !== 400) {
      section.items.push(
        createTechProcessItem(
          TechProcessCode.WOOD_SAWING_SIDE,
          item.length * item.thickness,
          UnitType.MILLIMETER_2,
          {
            title: `Опиливание края, сечение ${item.length}x${item.thickness}мм`,
          }
        )
      );
    }

    if (item.length !== 3000) {
      section.items.push(
        createTechProcessItem(
          TechProcessCode.WOOD_SAWING_END,
          item.width * item.thickness,
          UnitType.MILLIMETER_2,
          {
            title: `Опиливание торца, сечение ${item.width}x${item.thickness}мм`,
          }
        )
      );
    }

    const splits =
      config.areShelvesSplit && config.frameItemsCount > 2
        ? config.frameItemsCount - 2
        : 0;
    if (splits > 0) {
      section.items.push(
        createTechProcessItem(
          TechProcessCode.WOOD_SAWING_END,
          item.width * item.thickness,
          UnitType.MILLIMETER_2,
          {
            amount: splits,
            title: `Опиливание торца при разбивке полки, сечение ${item.width}x${item.thickness}мм`,
          }
        )
      );
    }

    const area =
      (2 + splits) * item.thickness * item.width +
      2 * item.thickness * item.length +
      2 * item.width * item.length;

    section.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_GRINDING,
        item.width * item.length,
        UnitType.MILLIMETER_2,
        {
          amount: 2,
          title: `Шлифовка лицевой поверхности`,
        }
      )
    );

    section.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_GRINDING,
        item.thickness * item.length,
        UnitType.MILLIMETER_2,
        {
          amount: 2,
          title: `Шлифовка боковой поверхности`,
        }
      )
    );

    section.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_GRINDING,
        item.thickness * item.width,
        UnitType.MILLIMETER_2,
        {
          amount: 2 + splits * 2,
          title: `Шлифовка торцевой поверхности`,
        }
      )
    );

    const coverage =
      item.coverageId &&
      assets.shelfCoverage.find((c) => c.id === item.coverageId);
    if (coverage) {
      section.items.push(
        createTechProcessItem(
          TechProcessCode.WOOD_OILING,
          item.width * item.length,
          UnitType.MILLIMETER_2,
          {
            amount: 2,
            title: `Покрытие маслом лицевой поверхности`,
          }
        )
      );

      section.items.push(
        createTechProcessItem(
          TechProcessCode.WOOD_OILING,
          item.thickness * item.length,
          UnitType.MILLIMETER_2,
          {
            amount: 2,
            title: `Покрытие маслом боковой поверхности`,
          }
        )
      );

      section.items.push(
        createTechProcessItem(
          TechProcessCode.WOOD_OILING,
          item.thickness * item.width,
          UnitType.MILLIMETER_2,
          {
            amount: 2 + splits * 2,
            title: `Покрытие маслом  торцевой поверхности`,
          }
        )
      );

      section.items.push(
        createMaterialItem(coverage, area, UnitType.MILLIMETER_2)
      );
    }

    const supportMaterial = assets.frameMaterials.find(
      (m) => m.id === item.supportMaterialId
    );
    if (!supportMaterial) {
      throw new Error("Can't build decomposition: empty frameMaterial");
    }
    if (
      !supportMaterial.mmDimension1 ||
      !supportMaterial.mmDimension2 ||
      !supportMaterial.mmDimension3
    ) {
      throw new Error(
        "Can't build decomposition: empty frameMaterial dimensions"
      );
    }

    section.items.push(
      createMaterialItem(
        supportMaterial,
        frameInnerWidth,
        UnitType.MILLIMETER,
        {
          title: `${supportMaterial.title} (перекладина)`,
          amount: 2,
        }
      )
    );

    const pipeSawingPerimeter =
      (supportMaterial.mmDimension1 + supportMaterial.mmDimension2) * 2;
    const pipeSawingArea = pipeSawingPerimeter * supportMaterial.mmDimension3;

    section.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_SAWING,
        pipeSawingArea,
        UnitType.MILLIMETER_2,
        {
          title: `Распил стального профиля поперечный`,
          amount: 2,
        }
      )
    );
    section.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_WELDING,
        pipeSawingPerimeter,
        UnitType.MILLIMETER,
        {
          title: `Сварка стального профиля`,
          amount: 2,
        }
      )
    );
    const pipeGrindingArea = pipeSawingPerimeter * 10;
    section.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_GRINDING,
        pipeGrindingArea,
        UnitType.MILLIMETER_2,
        {
          title: `Шлифовка сварочного шва`,
          amount: 2,
        }
      )
    );

    const profileDrillingVolume = DRILLING_AREA * supportMaterial.mmDimension3;
    section.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_DRILLING,
        profileDrillingVolume,
        UnitType.MILLIMETER_3,
        {
          amount: 4,
        }
      )
    );
  }

  const miscSection: DecompositionSection = {
    title: "Упаковка, крепеж и фурнитура",
    items: [],
  };
  sections.push(miscSection);

  const fastener = assets.fasteners.find((m) => m.id === config.fastenerId);
  if (fastener) {
    miscSection.items.push(
      createMaterialItem(fastener, 1, UnitType.PIECE, {
        amount: config.items.length * 4,
      })
    );
  }

  const dimensions = shelfLoft1Utils.detectPackageDimensions(
    config,
    assets,
    variables
  );
  if (!dimensions) {
    throw new Error("Can't determine package dimensions");
  }

  let totalPackingAreaMm = 0;
  const cardboard = assets.packageCardboard.find(
    (m) => m.id === config.packageCardboardId
  );
  if (cardboard) {
    const cardboardAreaMm = getBoxArea(dimensions) * 2;

    miscSection.items.push(
      createMaterialItem(cardboard, cardboardAreaMm, UnitType.MILLIMETER_2, {
        title: `Внешщняя упаковка (${cardboard.title})`,
      })
    );

    totalPackingAreaMm += cardboardAreaMm;
  }
  const bubbleWrap = assets.packageBubbleWrap.find(
    (m) => m.id === config.packageBubbleWrapId
  );
  if (bubbleWrap) {
    const shelfLayers = 2;
    for (let i = 0; i < config.items.length; i++) {
      const item = config.items[i];
      const shelfAreaMm = getBoxArea([item.length, item.width, item.thickness]);
      miscSection.items.push(
        createMaterialItem(
          bubbleWrap,
          shelfAreaMm * shelfLayers,
          UnitType.MILLIMETER_2,
          {
            title: `${bubbleWrap.title} (полка #${i + 1})`,
          }
        )
      );
      totalPackingAreaMm += shelfAreaMm * shelfLayers;
    }

    const layers = 3;
    miscSection.items.push(
      createMaterialItem(
        bubbleWrap,
        partAreaMm * layers,
        UnitType.MILLIMETER_2,
        {
          title: `${bubbleWrap.title} (каркас)`,
          amount: 2,
        }
      )
    );

    totalPackingAreaMm += partAreaMm * layers;
  }

  miscSection.items.push(
    createTechProcessItem(
      TechProcessCode.PACKING,
      totalPackingAreaMm,
      UnitType.MILLIMETER_2,
      {
        title: `Работы по упаковке`,
      }
    )
  );

  miscSection.items.push(
    createTechProcessItem(
      TechProcessCode.TESTING_ASSEMBLY,
      0.1 * config.items.length,
      UnitType.HOUR
    )
  );

  let massKg = 0;
  for (const section of sections) {
    for (const item of section.items) {
      if (item.massKg) {
        massKg += item.massKg;
      }
    }
  }

  return {
    sections,
    package: {
      massKg,
      lengthMm: dimensions[0],
      heightMm: dimensions[1],
      widthMm: dimensions[2],
    },
  };
}
