import React, {useState, useEffect} from "react";
import styled from "@emotion/styled";
import {
  gutters,
  CloseIcon,
  errorRed,
  secondary,
  mute,
  transitionSlow,
  zLayers
} from "client-lib";
import {useToaster} from "./toaster-context";
import {ToastType, Toast} from "./types";

const WIDTH = 200;
const MAX_HEIGHT = 100;
const OFFSET_LEFT = 20;
const OFFSET_BOTTOM = 20;

const Container = styled.div`
  position: fixed;
  width: ${WIDTH}px;
  bottom: 0;
  height: ${OFFSET_BOTTOM + MAX_HEIGHT}px;
  left: ${OFFSET_LEFT}px;
  width: ${WIDTH}px;
  z-index: ${zLayers.TOASTER};
  pointer-events: none;
`;

const CLOSE_SIZE = 24;
const CLOSE_RIGHT_PADDING = gutters.sm;

const ToastBlock = styled.div<{ hidden: boolean; type?: ToastType; }>`
  position: absolute;
  transition: ${transitionSlow} opacity, ${transitionSlow} bottom;
  left: 0;
  bottom: ${({ hidden }) => hidden ? -(MAX_HEIGHT) : OFFSET_BOTTOM}px;
  opacity: ${({ hidden }) => hidden ? 1 : 1};
 
  display: flex;
  align-items: center;
  
  box-sizing: border-box;
  padding: ${gutters.sm}px ${24 + gutters.sm + gutters.sm}px ${gutters.sm}px ${gutters.lg}px ;
  max-height: ${MAX_HEIGHT}px;
  
  background-color: ${
    ({ type }) => type === "error"
      ? mute(errorRed, 0.25)
      : mute(secondary, 0.25)
  };
  border: ${
    ({ type }) => type === "error"
      ? `1px solid ${errorRed}`
      : `1px solid ${secondary}`
  };
  pointer-events: all;
`;

const Message = styled.div``;

const Close = styled.div`
  position: absolute;
  width: ${CLOSE_SIZE}px;
  height: ${CLOSE_SIZE}px;
  right: ${CLOSE_RIGHT_PADDING}px;
  top: ${gutters.sm}px;
  cursor: pointer;
`;

export function Toaster() {
  const { toast, clear, onMouseEnter, onMouseLeave } = useToaster();

  // the toast which content stays intact
  // even when toast is cleared (needed for the animation period)
  const [stableToast, setStableToast] = useState<Toast | null>(null);

  useEffect(() => {
    if (toast) {
      setStableToast(toast);
    }
  }, [toast]);

  return (
    <Container>
      <ToastBlock hidden={!toast} type={stableToast?.type} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Message>
          {stableToast?.message}
        </Message>
        <Close onClick={clear}>
          <CloseIcon />
        </Close>
      </ToastBlock>
    </Container>
  );
}
