import gql from "graphql-tag";
import { TEMPLATE_SHOT_ACTUAL_FULL_FRAGMENT } from "client-lib";
import { TemplateShotActual } from "shared";

export const UpdateTemplateShotTitle = gql`
  mutation UpdateTemplateShotTitle($templateShotId: String!, $title: String!) {
    UpdateTemplateShotTitle(templateShotId: $templateShotId, title: $title) {
      ...TemplateShotActualFullFragment
    }
  }
  ${TEMPLATE_SHOT_ACTUAL_FULL_FRAGMENT}
`;

export interface UpdateTemplateShotTitleVars {
  templateShotId: string;
  title: string;
}

export type UpdateTemplateShotTitleData = {
  UpdateTemplateShotTitle: TemplateShotActual;
};
