import React, { memo } from "react";
import { Box2, Measurement } from "../../../objects";
import { useShelf } from "../shelf-context";
import { useSceneOptions } from "../../../scene-context";
import { ShelfItemProps } from "./types";

export const ShelfItem6 = memo(function ShelfItem6({
  item,
  edgeX,
  edgeY,
  surface,
}: ShelfItemProps) {
  const options = useSceneOptions();
  const { config } = useShelf();

  const dw = (item.width - item.innerWidth) / 2;
  const dw2 =
    (item.thickness * item.innerWidth) / (item.height - 2 * item.thickness);
  const H = (dw ** 2 + (item.height / 2) ** 2) ** 0.5;
  const dw3 = (item.thickness * H) / (item.height / 2);

  return (
    <React.Fragment>
      <Box2
        xSize={item.innerWidth}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          lbf: [-dw2, 0, 0],
          lbb: [-dw2, 0, 0],
          rbf: [dw2, 0, 0],
          rbb: [dw2, 0, 0],
        }}
        {...surface}
        position={[edgeX + item.width / 2, edgeY + item.thickness / 2, 0]}
      />
      <Box2
        xSize={item.innerWidth}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          ltf: [-dw2, 0, 0],
          ltb: [-dw2, 0, 0],
          rtf: [dw2, 0, 0],
          rtb: [dw2, 0, 0],
        }}
        {...surface}
        position={[
          edgeX + item.width / 2,
          edgeY - item.thickness / 2 + item.height,
          0,
        ]}
      />
      <Box2
        xSize={dw}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          ltf: [dw3, item.height / 2 - item.thickness, 0],
          ltb: [dw3, item.height / 2 - item.thickness, 0],
          lbf: [0, item.height / 2, 0],
          lbb: [0, item.height / 2, 0],
          rbf: [-dw2, 0, 0],
          rbb: [-dw2, 0, 0],
        }}
        {...surface}
        position={[edgeX + dw / 2, edgeY + item.thickness / 2, 0]}
      />
      <Box2
        xSize={dw}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          lbf: [dw3, -item.height / 2 + item.thickness, 0],
          lbb: [dw3, -item.height / 2 + item.thickness, 0],
          ltf: [0, -item.height / 2, 0],
          ltb: [0, -item.height / 2, 0],
          rtf: [-dw2, 0, 0],
          rtb: [-dw2, 0, 0],
        }}
        {...surface}
        position={[edgeX + dw / 2, edgeY - item.thickness / 2 + item.height, 0]}
      />

      <Box2
        xSize={dw}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          ltf: [dw3, item.height / 2 - item.thickness, 0],
          ltb: [dw3, item.height / 2 - item.thickness, 0],
          lbf: [0, item.height / 2, 0],
          lbb: [0, item.height / 2, 0],
          rbf: [-dw2, 0, 0],
          rbb: [-dw2, 0, 0],
        }}
        {...surface}
        position={[
          edgeX + item.innerWidth + (3 * dw) / 2,
          edgeY + item.thickness / 2,
          0,
        ]}
        rotation={[0, Math.PI, 0]}
      />
      <Box2
        xSize={dw}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          lbf: [dw3, -item.height / 2 + item.thickness, 0],
          lbb: [dw3, -item.height / 2 + item.thickness, 0],
          ltf: [0, -item.height / 2, 0],
          ltb: [0, -item.height / 2, 0],
          rtf: [-dw2, 0, 0],
          rtb: [-dw2, 0, 0],
        }}
        {...surface}
        position={[
          edgeX + item.innerWidth + (3 * dw) / 2,
          edgeY - item.thickness / 2 + item.height,
          0,
        ]}
        rotation={[0, Math.PI, 0]}
      />

      {options.showMeasurements && false && (
        <React.Fragment>
          <Measurement
            key="thickness"
            p1={[0, 0, 0]}
            p2={[0, edgeY, 0]}
            normal={[0, 0, -1]}
            outer
            outerSerifLength={5}
            outerEmptySerifLength={0}
            textInside
            textRotation={[0, Math.PI / 2, Math.PI / 2]}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
});
