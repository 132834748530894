import React, { memo, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { StyledSelect, black, white, grey70, gutters } from "client-lib";
import { useTemplate } from "src/features/templates/template-context";

const Container = styled.div`
  display: flex;
  align-items: center;
  select {
    width: 45px;
    height: 24px;
    line-height: 24px;
    background-color: ${black};
    color: ${white};
    outline-color: ${grey70};
    border-right-width: ${gutters.xs}px;
    padding-right: ${gutters.xs}px;
  }
`;

export const TemplateShotVersion = memo(function TemplateShotVersion() {
  const history = useHistory();
  const { templateShotActual, templateShotLatestVersionNumber } = useTemplate();

  const versionNumbers = useMemo<number[]>(() => {
    if (!templateShotActual) {
      return [];
    }
    let maxNumber = templateShotActual.versionNumber;
    if (
      templateShotLatestVersionNumber &&
      templateShotLatestVersionNumber > maxNumber
    ) {
      maxNumber = templateShotLatestVersionNumber;
    }
    return Array(maxNumber)
      .fill(null)
      .map((_, index: number) => index + 1);
  }, [templateShotLatestVersionNumber, templateShotActual]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const number = Number(event.target.value);
      if (number && templateShotActual) {
        history.push(`/account/models/${templateShotActual.id}/${number}`);
      }
    },
    [history, templateShotActual]
  );

  if (!templateShotActual || versionNumbers.length < 2) {
    return null;
  }

  return (
    <Container>
      <StyledSelect
        value={templateShotActual.versionNumber}
        title="Версия"
        onChange={handleChange}
      >
        {versionNumbers.map((versionNumber: number) => (
          <option key={versionNumber} value={versionNumber}>
            {versionNumber}
          </option>
        ))}
      </StyledSelect>
    </Container>
  );
});
