import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  TemplateShotPageData,
  TemplateShotPageQuery,
  TemplateShotPageVars,
} from "../queries";
import { TemplateLayout } from "../template-layout";

export function TemplateShotPage() {
  const {
    params: { templateShotId, versionNumber },
  } = useRouteMatch<{ templateShotId: string; versionNumber?: string }>();

  const { data } = useQuery<TemplateShotPageData, TemplateShotPageVars>(
    TemplateShotPageQuery,
    {
      variables: {
        templateShotId,
        versionNumber: parseInt(versionNumber || "") || null
      },
    }
  );

  return (
    <TemplateLayout
      templatePreset={
        data?.TemplateShotEnvironment.templatePresetEnvironment?.templatePreset
      }
      templateEnvironment={
        data?.TemplateShotEnvironment.templatePresetEnvironment
          ?.templateEnvironment
      }
      templateShotActual={data?.TemplateShotEnvironment.templateShotActual}
      templateShotLatestVersionNumber={data?.TemplateShotEnvironment.latestVersionNumber}
    />
  );
}
