// Based on https://github.com/colinhacks/zod/issues/86#issuecomment-675824680
import { z } from "shared";

class ValidationError extends Error {
  public name = "ValidationError";
  inner: { path: string; message: string }[] = [];
}

function createValidationError(e: z.ZodError) {
  const error = new ValidationError(e.message);
  error.inner = e.errors.map((issue: z.ZodIssue) => ({
    message: issue.message,
    path: issue.path.join("."),
  }));
  return error;
}

// Wrap your zod schema before passing it to Formik's `validationSchema` prop
export function toFormikSchema(schema: z.ZodSchema<any>) {
  return {
    async validate(obj: any) {
      try {
        await Promise.resolve(schema.parse(obj));
      } catch (err) {
        throw createValidationError(err as z.ZodError);
      }
    },
  };
}
