import React from "react";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

interface Props {
  date: Date | null;
}

export function FormatDate({ date }: Props) {
  return date ? (
    <strong>{format(date, "d MMMM yyyy", { locale: ru })}</strong>
  ) : null;
}

export function FormatDateTime({ date }: Props) {
  return date ? (
    <strong>{format(date, "d MMMM yyyy H:mm", { locale: ru })}</strong>
  ) : null;
}
