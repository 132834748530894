import { z } from "zod";
import { tokenUserSchema } from "./user";
import { tokenAuthChannelSchema } from "./auth-channel";

export const tokenSchema = z.object({
  user: tokenUserSchema,
  authChannel: tokenAuthChannelSchema,
  tokenSource: z.string(),
});
export type Token = z.infer<typeof tokenSchema>;

export const tokenSchemaWithMeta = tokenSchema.extend({
  iat: z.number().int().positive(),
  exp: z.number().int().positive(),
});

export type TokenWithMeta = z.infer<typeof tokenSchemaWithMeta>;
