import styled from "@emotion/styled";
import { gutters, ITEM_HEIGHT } from "client-lib/src/styles/variables";
import { secondaryFont } from "client-lib/src/styles/typography";

export const SectionHeader = styled.div`
  height: ${ITEM_HEIGHT}px;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-top: ${gutters.xl}px;
`;

export const Header = styled.h1`
  line-height: 100%;
  margin-top: ${gutters.sm}px;
  margin-bottom: ${gutters.xxs}px;
`;

export const SubHeader = styled.div`
  font-weight: 300;
  ${secondaryFont};
`;
