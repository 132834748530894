import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import {
  gutters,
  black,
  grey10,
  grey30,
  grey70,
  borderRadius,
} from "../styles";
import { Button } from "./buttons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: ${gutters.xl}px ${gutters.xxl}px;
  border-radius: ${borderRadius.lg}px;
  margin: ${gutters.lg}px;
  align-items: center;
  justify-content: space-between;
  line-height: 120%;
  background-color: ${grey10};
`;

const Content = styled.div`
  margin-bottom: ${gutters.md}px;
  color: ${grey70};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    background-color: ${grey30};
    color: ${black};
  }
`;

interface Props {
  children: ReactNode;
  to: string;
  buttonText: string;
}

export function EmptyTeaser({ children, to, buttonText }: Props) {
  return (
    <Container>
      <Box>
        <Content>{children}</Content>
        <StyledLink to={to}>
          <Button size={"S"} color={"white"}>
            {buttonText}
          </Button>
        </StyledLink>
      </Box>
    </Container>
  );
}
