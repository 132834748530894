import React from "react";
import { TemplateShotInput } from "shared";
import { Modal } from "client-lib/ui";
import { TemplateShotForm } from "./template-shot-form";

interface Props {
  onCloseRequest: () => void;
  onSubmit: (input: TemplateShotInput) => void;
  initialValues: TemplateShotInput;
  titleSuggestion: string;
}

export function TemplateShotModal({
  onCloseRequest,
  onSubmit,
  initialValues,
  titleSuggestion,
}: Props) {
  return (
    <Modal onCloseRequest={onCloseRequest} title="Сохранить модель" width={400}>
      <TemplateShotForm
        initialValues={initialValues}
        titleSuggestion={titleSuggestion}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}
