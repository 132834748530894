import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Canvas } from "react-three-fiber";
import { grey15, APP_BAR_HEIGHT } from "client-lib/styles";
import { SceneOptions } from "./types";
import { SceneProvider } from "./scene-context";
import { OrbitControlsWrapper } from "./orbit-controls-wrapper";

export const sceneCanvasHeightStyles = css`
  height: calc(100vh - ${APP_BAR_HEIGHT}px);
`;

const CanvasContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  div {
    position: absolute !important;
    width: 100%;
    height: 100%;
  }
`;

interface Props {
  options: SceneOptions;
  children: JSX.Element;
  className?: string;
}

const LIGHT_COLOR = "#888";

export function Scene({ options, children, className }: Props) {
  return (
    <SceneProvider options={options}>
      <CanvasContainer
        className={className}
        style={{ backgroundColor: options.backgroundColor || grey15 }}
      >
        <Canvas
          {...options.canvas}
          camera={{
            position: options?.camera?.position || [0, 900, 1200],
            far: options?.camera?.far || 50000,
          }}
        >
          <ambientLight intensity={1} />
          <pointLight
            position={[0, 100, 1300]}
            color={LIGHT_COLOR}
            intensity={3}
          />
          <pointLight
            position={[0, 100, -1300]}
            color={LIGHT_COLOR}
            intensity={3}
          />
          <pointLight
            position={[3300, 100, 0]}
            color={LIGHT_COLOR}
            intensity={4}
          />
          <pointLight
            position={[-3300, 100, 0]}
            color={LIGHT_COLOR}
            intensity={4}
          />
          <pointLight
            position={[0, 3000, 0]}
            color={LIGHT_COLOR}
            intensity={4}
          />
          <group>{children}</group>

          <OrbitControlsWrapper />
        </Canvas>
      </CanvasContainer>
    </SceneProvider>
  );
}
