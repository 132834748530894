import gql from "graphql-tag";
import { Order, OrderCustomerStrict } from "shared";
import { ORDER_FULL_FRAGMENT } from "client-lib";

export const CartToOrder = gql`
  mutation CartToOrder($orderId: String!, $orderCustomer: OrderCustomerInput!) {
    CartToOrder(orderId: $orderId, orderCustomer: $orderCustomer) {
      ...OrderFullFragment
    }
  }
  ${ORDER_FULL_FRAGMENT}
`;

export interface CartToOrderVars {
  orderId: string;
  orderCustomer: OrderCustomerStrict;
}

export type CartToOrderData = {
  CartToOrder: Order;
};
