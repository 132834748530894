import React, { useCallback, useMemo } from "react";
import { PricingLimit, PricingLimitValues } from "shared";
import styled from "@emotion/styled";
import { AmountInput, gutters } from "client-lib/ui";
import { LimitSelector } from "../limit-selector";

const Container = styled.div`
  margin-bottom: ${gutters.md}px;
`;

const InputBlock = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${gutters.sm}px;
  input {
    height: 28px;
  }
`;

const SwitcherBlock = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
`;

interface Props {
  label: string;
  value: number;
  onChange: (v: number) => void;
}

export function AmountSelector({ label, value, onChange }: Props) {
  const handleLimitChange = useCallback((v: PricingLimit) => {
    onChange(PricingLimitValues[v]);
  }, []);

  const detectedLimit = useMemo<PricingLimit>(() => {
    const reversedKeys = Object.values(PricingLimit).reverse();
    for (const key of reversedKeys) {
      if (value >= PricingLimitValues[key]) {
        return key;
      }
    }
    return PricingLimit.BASIC;
  }, [value]);

  return (
    <Container>
      <InputBlock>
        {label}
        <AmountInput
          limits={{ min: 1, max: 100 }}
          value={value}
          onChange={onChange}
        />
      </InputBlock>
      <SwitcherBlock>
        Тариф:
        <LimitSelector
          activeLimit={detectedLimit}
          onChange={handleLimitChange}
        />
      </SwitcherBlock>
    </Container>
  );
}
