import React from "react";
import { black } from "../styles";
import { IconBase, IconProps } from "./base";

export function CloseIcon({ color = black, ...props }: IconProps) {
  return (
    <IconBase {...props}>
      <path d="M7 17L17 7M7 7L17 17" stroke={color} />
    </IconBase>
  );
}
