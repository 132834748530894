import styled from "@emotion/styled";
import { hoverGrey } from "../../styles";

export const RoundSeamlessButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: ${hoverGrey};
  }
`;
