import React, { memo } from "react";
import styled from "@emotion/styled";
import { white, gutters, mqMax } from "client-lib";
import { Slide, SlideContainer, Header, Typo140 } from "src/features/layout";

// bg color is average of image
const WoodSlideRoot = styled(Slide)`
  background-color: #1c1611;
  background-image: url(/img/wood-bg-dark.jpg);
  background-size: cover;
  color: ${white};
`;

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  ${mqMax.phone} {
    display: block;
  }
`;

const Side = styled.div`
  width: 50%;
  position: relative;
  ${mqMax.phone} {
    width: auto;
  }
`;

const Comment = styled(Typo140)`
  margin-bottom: ${gutters.xxl}px;
  margin-right: ${gutters.xxl}px;
  p:first-child {
    margin-top: 0;
  }
`;

export const WoodSlide = memo(function WoodSlide() {
  return (
    <WoodSlideRoot>
      <SlideContainer>
        <Header color={white}>Дерево</Header>
        <Body>
          <Side>
            <Comment>
              В основе наших изделий – натуральная древесина, полученная в
              хвойных лесах северных регионов России. Экологичный, надежный и
              долговечный материал.
            </Comment>
            <Comment>
              Исходный материал проходит этап камерной сушки на современном
              профессиональном оборудовании, а затем под давлением склеивается в
              мебельные щиты – прочные панели нужного размера.
            </Comment>
          </Side>
          <Side>
            <Comment>
              <p>
                Мебельные щиты сохраняют всю эстетику природного материала и
                обеспечивают постоянную геометрию, необходимую для качественной
                мебели.
              </p>
              <p>
                В отличие от дешевых ЛДСП-панелей, мебельные щиты на порядок
                более долговечны&nbsp;–&nbsp;такая мебель прослужит вам десятки
                лет, сохраняя великолепный внешний&nbsp;вид.
              </p>
            </Comment>
          </Side>
        </Body>
      </SlideContainer>
    </WoodSlideRoot>
  );
});
