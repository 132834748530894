import { TemplateCode } from "shared";
import { TableLoft1 } from "./table-loft-1";
import { ShelfLoft1 } from "./shelf-loft-1";
import { ShelfMatreshka } from "./shelf-matreshka";

export const templateRenderPool = {
  [TemplateCode.TABLE_LOFT_1]: TableLoft1,
  [TemplateCode.SHELF_LOFT_1]: ShelfLoft1,
  [TemplateCode.SHELF_MATRESHKA]: ShelfMatreshka,
};
