import { cloneDeep } from "lodash";
import { Config, Assets, Variables } from "./types";
import {
  TemplateConfigControlType,
  TemplateConfigControlGroup,
  TemplateController,
} from "../types";
import {
  MAX_THICKNESS,
  MIN_THICKNESS,
  MAX_ITEMS_AMOUNT,
  MIN_ITEMS_AMOUNT,
  MIN_DEPTH,
  MAX_DEPTH,
  MIN_WIDTH,
  MAX_WIDTH,
  MIN_HEIGHT,
  MAX_HEIGHT,
  MIN_INNER_WIDTH,
  MAX_INNER_WIDTH,
  MIN_ITEM_STEP,
  MAX_ITEM_STEP,
} from "./constants";
import { MaterialExpanded } from "../../schemas";
import { ShelfConfigItem } from "./types";

interface ItemFieldTrack {
  index: number;
  field: ItemField;
}

function parseItemFieldTrack(fieldPath: string): ItemFieldTrack | null {
  const parseResult = /^items\[([0-9]+)]\.([a-z0-9]+)$/i.exec(fieldPath);
  if (!parseResult) {
    return null;
  }
  return {
    index: Number(parseResult[1]),
    field: parseResult[2] as ItemField,
  };
}

type ItemField = keyof ShelfConfigItem;
type ScaleField = keyof Pick<
  ShelfConfigItem,
  "step" | "thickness" | "height" | "width" | "innerWidth"
>;
const widthPriority: ScaleField[] = [
  "step",
  "width",
  "innerWidth",
  "thickness",
];
const heightPriority: ScaleField[] = ["step", "height", "thickness"];
const intFields: ScaleField[] = [
  "step",
  "height",
  "thickness",
  "width",
  "innerWidth",
];

type FieldScales = Record<ScaleField, number>;

function isIntZero(v: number) {
  return Math.abs(v) < 1;
}

function roundItemValues(item: ShelfConfigItem) {
  for (const field of intFields) {
    (item as any)[field] = Math.round(item[field] as number);
  }
}

// how width, innerWidth and height depends on step
function getScales(values: Config, index: number): FieldScales {
  switch (values.sidesCount) {
    case 4:
      return {
        height: 1,
        width: 1,
        innerWidth: 1,
        thickness: 2,
        step: 1,
      };
    case 3:
      const item = values.items[index];
      const side = ((item.width / 2) ** 2 + item.height ** 2) ** 0.5;
      // const H = (item.width ** 2 + item.height ** 2) ** 0.5;
      // const dh = (item.thickness * H) / (item.width / 2);
      //
      // const iw =
      //   (item.width * (item.height - item.thickness - dh)) / item.height;
      //
      // const dw = (item.width - item.innerWidth) / 2;

      return {
        height: item.width / side,
        width: item.height / side,
        innerWidth: item.height / side,
        thickness: 1 + side / (item.width / 2),
        step: 1,
      };
    default:
      throw new Error("not implemented");
  }
}

function calculateInnerItemDefect(
  innerItem: ShelfConfigItem,
  outerItem: ShelfConfigItem,
  field: ItemField,
  scales: FieldScales
): number {
  switch (field) {
    case "step": {
      const widthDefect =
        (outerItem.width - innerItem.width) / scales.width -
        (2 * outerItem.thickness + innerItem.step);
      const innerWidthDefect =
        (outerItem.innerWidth - innerItem.innerWidth) / scales.innerWidth -
        (2 * outerItem.thickness + innerItem.step);
      const heightDefect =
        (outerItem.height - innerItem.height) / scales.height -
        (2 * outerItem.thickness + innerItem.step);

      const defects = [widthDefect, innerWidthDefect, heightDefect];
      let extremeIndex: number | null = null;
      for (let i = 0; i < defects.length; i++) {
        if (
          extremeIndex === null ||
          Math.abs(defects[i]) > Math.abs(defects[extremeIndex])
        ) {
          extremeIndex = i;
        }
      }
      return defects[extremeIndex!];
    }
    case "thickness":
      return 0;
    case "width":
      return (
        outerItem.width -
        innerItem.width -
        (2 * outerItem.thickness + innerItem.step) * scales.width
      );
    case "height":
      return (
        outerItem.height -
        innerItem.height -
        (2 * outerItem.thickness + innerItem.step) * scales.height
      );
    case "innerWidth":
      return (
        outerItem.innerWidth -
        innerItem.innerWidth -
        (2 * outerItem.thickness + innerItem.step) * scales.innerWidth
      );
  }

  return 0;
}

function calculateOuterItemDefect(
  innerItem: ShelfConfigItem,
  outerItem: ShelfConfigItem,
  field: ItemField,
  scales: FieldScales
): number {
  switch (field) {
    case "step":
      return 0;
    case "thickness":
      const stepDefect = Math.min(
        -(outerItem.width - innerItem.width) / scales.width +
          (2 * outerItem.thickness + innerItem.step),
        -(outerItem.height - innerItem.height) / scales.height +
          (2 * outerItem.thickness + innerItem.step),
        -(outerItem.innerWidth - innerItem.innerWidth) / scales.innerWidth +
          (2 * outerItem.thickness + innerItem.step)
      );
      return stepDefect / 2;
    case "width":
      return (
        -(outerItem.width - innerItem.width) +
        (2 * outerItem.thickness + innerItem.step) * scales.width
      );
    case "height":
      return (
        -(outerItem.height - innerItem.height) +
        (2 * outerItem.thickness + innerItem.step) * scales.height
      );
    case "innerWidth":
      return (
        -(outerItem.innerWidth - innerItem.innerWidth) +
        (2 * outerItem.thickness + innerItem.step) * scales.innerWidth
      );
  }

  return 0;
}

function getAllowedFix(
  item: ShelfConfigItem,
  field: ScaleField,
  defect: number
) {
  const desiredValue = Number(item[field] || 0) + defect;

  let min: number | null = null;
  let max: number | null = null;
  switch (field) {
    case "thickness":
      min = MIN_THICKNESS;
      max = MAX_THICKNESS;
      break;
    case "step":
      min = MIN_ITEM_STEP;
      max = MAX_ITEM_STEP;
      break;
    case "width":
      min = MIN_WIDTH;
      max = MAX_WIDTH;
      break;
    case "innerWidth":
      min = MIN_INNER_WIDTH;
      max = MAX_INNER_WIDTH;
      break;
    case "height":
      min = MIN_HEIGHT;
      max = MAX_HEIGHT;
      break;
  }

  if (min === null || max === null) {
    return defect;
  }

  if (desiredValue > max) {
    return defect - (desiredValue - max);
  }
  if (desiredValue < min) {
    return defect - (desiredValue - min);
  }

  return defect;
}

function adjustItemDimensions(
  values: Config,
  index: number,
  basicField: ItemField | null
) {
  adjustItemInternals(values, index, basicField);
  const scales = getScales(values, index);
  for (let i = index + 1; i < values.items.length; i++) {
    const prevItem = values.items[i - 1];
    const item: ShelfConfigItem = values.items[i];

    let defect: number = 0;
    for (const field of widthPriority) {
      defect = calculateInnerItemDefect(item, prevItem, field, scales);
      if (isIntZero(defect)) {
        continue;
      }
      const allowedFix = getAllowedFix(item, field, defect);
      (item as any)[field] = (item[field] as number) + allowedFix;
      defect -= allowedFix;
      roundItemValues(item);
    }
    adjustItemInternals(values, i, basicField);

    for (const field of heightPriority) {
      defect = calculateInnerItemDefect(item, prevItem, field, scales);
      if (isIntZero(defect)) {
        continue;
      }
      const allowedFix = getAllowedFix(item, field, defect);
      (item as any)[field] = (item[field] as number) + allowedFix;
      defect -= allowedFix;
      roundItemValues(item);
    }
  }

  for (let i = values.items.length - 2; i >= 0; i--) {
    const nextItem = values.items[i + 1];
    const item: ShelfConfigItem = values.items[i];

    let defect: number = 0;
    for (const field of widthPriority) {
      defect = calculateOuterItemDefect(nextItem, item, field, scales);
      if (isIntZero(defect)) {
        continue;
      }
      const allowedFix = getAllowedFix(item, field, defect);
      (item as any)[field] = (item[field] as number) + allowedFix;
      defect -= allowedFix;
      roundItemValues(item);
    }
    adjustItemInternals(values, i, basicField);
    for (const field of heightPriority) {
      defect = calculateOuterItemDefect(nextItem, item, field, scales);
      if (isIntZero(defect)) {
        continue;
      }
      const allowedFix = getAllowedFix(item, field, defect);
      (item as any)[field] = (item[field] as number) + allowedFix;
      defect -= allowedFix;
      roundItemValues(item);
    }
  }
}

function adjustItemInternals(
  values: Config,
  index: number,
  basicField: ItemField | null
) {
  if (values.sidesCount === 6) {
    return;
  }

  const scales = getScales(values, index);
  const item = values.items[index];
  const widthFields: ScaleField[] = ["innerWidth", "width", "thickness"];
  for (const field of widthFields) {
    if (field === basicField) {
      continue;
    }
    const defectAbs =
      -item.width * scales.width +
      item.innerWidth * scales.innerWidth +
      item.thickness * scales.thickness;
    if (isIntZero(defectAbs)) {
      break;
    }
    const defectSgn = field === "width" ? 1 : -1;
    let defect = (defectAbs * defectSgn) / scales[field];
    const allowedFix = getAllowedFix(item, field, defect);
    (item as any)[field] = (item[field] as number) + allowedFix;
    defect -= allowedFix;
    if (isIntZero(defect)) {
      break;
    }
  }
  roundItemValues(item);
}

export const controller: TemplateController<Config, Assets, Variables> = {
  getControlGroups: (
    _configValues: Config,
    assets: Assets,
    _variables: Variables
  ) => [
    {
      title: "Общие",
      controls: [
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "depth",
          label: "Глубина, мм",
          min: MIN_DEPTH,
          max: MAX_DEPTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "width",
          label: "Ширина, мм",
          min: MIN_WIDTH,
          max: MAX_WIDTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "height",
          label: "Высота, мм",
          min: MIN_HEIGHT,
          max: MAX_HEIGHT,
        },
        // {
        //   type: TemplateConfigControlType.SELECTOR_NUMERIC,
        //   name: "sidesCount",
        //   label: "Количество сторон",
        //   items: [3, 4, 6].map((count: number) => ({
        //     title: `${count} сторон${count !== 6 ? "ы" : ""}`,
        //     value: String(count),
        //   })),
        // },
        // {
        //   type: TemplateConfigControlType.CHECKBOX,
        //   name: "isEquilateral",
        //   label: "Равносторонний",
        // },
      ],
    },
    {
      title: "Полки",
      arrayConfig: {
        amountControlTitle: "Количество",
        itemTitle: (index: number) => `Полка #${index + 1}`,
        addTitle: "Добавить полку",
        name: "items",
        min: MIN_ITEMS_AMOUNT,
        max: MAX_ITEMS_AMOUNT,
      },
      controls: (index: number, _amount: number) => [
        {
          type: TemplateConfigControlType.IMG_PICKER,
          name: "coverageId",
          label: "Покрытие",
          nullable: true,
          items: assets.shelfCoverage.map((m: MaterialExpanded) => ({
            title: m.pickerTitle || m.title,
            value: m.id,
            img: m.texture1?.path,
          })),
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "step",
          label: "Отступ от внешней полки, мм",
          hidden: index === 0,
          min: MIN_ITEM_STEP,
          max: MAX_ITEM_STEP,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "thickness",
          label: "Толщина, мм",
          min: MIN_THICKNESS,
          max: MAX_THICKNESS,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "width",
          label: "Ширина, мм",
          min: MIN_WIDTH,
          max: MAX_WIDTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "height",
          label: "Высота, мм",
          min: MIN_HEIGHT,
          max: MAX_HEIGHT,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "innerWidth",
          label: "Внутренняя ширина, мм",
          min: MIN_INNER_WIDTH,
          max: MAX_INNER_WIDTH,
        },
      ],
    } as TemplateConfigControlGroup,
  ],
  onAttributeChange: (
    name: string,
    v: any,
    values: Config,
    _assets: Assets,
    _variables: Variables,
    setValues: (v: Config) => void
  ) => {
    const track = parseItemFieldTrack(name);

    const clonedValues = cloneDeep(values);
    if (track) {
      (clonedValues.items[track.index] as any)[track.field] = v;
      switch (track.field) {
        case "thickness":
        case "step":
        case "width":
        case "innerWidth":
        case "height":
          adjustItemDimensions(clonedValues, track.index, track.field);
          break;
      }
      setValues(clonedValues);
    } else {
      (clonedValues as any)[name] = v;
      switch (name) {
        case "items": {
          adjustItemDimensions(
            clonedValues,
            clonedValues.items.length - 1,
            null
          );
        }
      }
      setValues(clonedValues);
    }
  },
};
