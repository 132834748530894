import { z } from "zod";
import { pricingSectionSchema } from "./pricing-section";
import { decompositionSchema } from "../decomposition";

export const pricingSchema = decompositionSchema
  .omit({
    sections: true,
  })
  .extend({
    calculatedAt: z.date(),
    sections: z.array(pricingSectionSchema),
  });
export type Pricing = z.infer<typeof pricingSchema>;
