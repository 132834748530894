import React, { memo, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  white,
  grey10,
  grey20,
  grey40,
  grey50,
  primary,
  gutters,
  borderRadius,
  transitionBase,
  Button,
  useEnv,
  mqMax,
} from "client-lib";
import { Slide, SlideContainer, Header, Typo160 } from "src/features/layout";
import { TemplatePreset, TemplateEnvironment } from "shared";
import {
  assembleTemplate,
  useTemplateVariables,
} from "src/features/template-editor/service";
import { SizeExample } from "./size-example";
import { ColorExample } from "./color-example";
import { FittingsExample } from "./fittings-example";

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  button {
    margin-top: ${gutters.md}px;
  }
`;

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  ${mqMax.phone} {
    display: block;
  }
`;

const Content = styled.div`
  width: 50%;
  ${mqMax.phone} {
    width: auto;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  height: 400px;
  background-color: ${grey10};
  border-radius: ${borderRadius.lg}px;
  margin-right: ${gutters.sm}px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${gutters.xl}px;
`;

const Switcher = styled.div`
  width: 50%;
`;

const Item = styled.div<{ isActive: boolean }>(({ isActive }) => [
  css`
    position: relative;
    padding: ${gutters.xl}px;
    padding-right: ${gutters.xl + 24 + gutters.xl}px;
    margin-bottom: ${gutters.md}px;
    cursor: pointer;
    border: 1px solid ${grey50};
    border-radius: ${borderRadius.lg}px;
    background-color: transparent;
    transition: color ${transitionBase}, border-color ${transitionBase},
      background-color ${transitionBase};
    &:hover {
      background-color: ${isActive ? primary : white};
      border-color: ${isActive ? primary : grey40};
    }
  `,
  isActive &&
    css`
      background-color: ${primary};
      color: ${white};
      border-color: ${primary};
    `,
]);

const Message = styled(Typo160)`
  ${mqMax.phone} {
    text-align: center;
    margin-bottom: ${gutters.xl}px;
    font-size: 130%;
  }
`;

const message = (
  <Message>
    Выбирайте любые параметры,
    <br />
    которые вам подойдут
  </Message>
);

type Mode = "start" | "sizing" | "color" | "fittings";

interface Props {
  templateEnvironment: TemplateEnvironment;
  templatePreset: TemplatePreset;
}

export const FeaturesSlide = memo(function FeaturesSlide({
  templateEnvironment,
  templatePreset,
}: Props) {
  const { isMobileAgent } = useEnv();
  // const [mode, setMode] = useState<Mode>(isMobileAgent ? "sizing" : "start");
  const [mode, setMode] = useState<Mode>("sizing");

  const templateFull = useMemo(
    () => assembleTemplate(templateEnvironment),
    [templateEnvironment]
  );

  const initialConfigValues = useMemo(() => {
    if (templatePreset.templateConfig) {
      return JSON.parse(templatePreset.templateConfig);
    }
    return templateFull.model.configInitialValues(templateFull.assets);
  }, [templatePreset, templateFull]);

  const templateVariables = useTemplateVariables(templateFull, templatePreset);

  const [configValues, setConfigValues] = useState(initialConfigValues);

  const handleAttributeChange = useCallback(
    (name: string, value: any) => {
      setConfigValues((current: any) => ({
        ...current,
        [name]: value,
      }));
      templateFull.controller.onAttributeChange?.(
        name,
        value,
        configValues,
        templateFull.assets,
        templateVariables,
        setConfigValues
      );
    },
    [templateFull, templateVariables, setConfigValues, configValues]
  );

  const modeBlock = useMemo<JSX.Element | string | null>(() => {
    switch (mode) {
      case "start":
        return message;
      case "sizing":
        return (
          <ContentWrapper>
            <SizeExample
              configValues={configValues}
              setConfigValues={setConfigValues}
              variables={templateVariables}
              templateFull={templateFull}
              handleAttributeChange={handleAttributeChange}
            />
          </ContentWrapper>
        );
      case "color":
        return (
          <ContentWrapper>
            <ColorExample
              configValues={configValues}
              setConfigValues={setConfigValues}
              variables={templateVariables}
              templateFull={templateFull}
              handleAttributeChange={handleAttributeChange}
            />
          </ContentWrapper>
        );
      case "fittings":
        return (
          <ContentWrapper>
            <FittingsExample />
          </ContentWrapper>
        );
    }
    return null;
  }, [
    mode,
    configValues,
    templateVariables,
    templateFull,
    handleAttributeChange,
  ]);

  return (
    <Slide style={{ backgroundColor: grey20 }}>
      <SlideContainer>
        {isMobileAgent ? (
          <React.Fragment>
            <Header>Удобно</Header>
            {message}
          </React.Fragment>
        ) : (
          <Controls>
            <Header>Удобно</Header>

            <Link to="/templates">
              <Button size="XL">Попробовать</Button>
            </Link>
          </Controls>
        )}
        <Body>
          <Content>{modeBlock}</Content>
          {!isMobileAgent && (
            <Switcher>
              <Item
                onClick={() => setMode("sizing")}
                isActive={mode === "sizing"}
              >
                Настройте любые размеры с точностью до миллиметра, чтобы
                идеально соответствовать вашему интерьеру
              </Item>
              <Item
                onClick={() => setMode("color")}
                isActive={mode === "color"}
              >
                Выберите подходящий вариант покрытия деревянных элементов
              </Item>
              <Item
                onClick={() => setMode("fittings")}
                isActive={mode === "fittings"}
              >
                Укажите подходящую фурнитуру и крепеж
              </Item>
            </Switcher>
          )}
        </Body>
      </SlideContainer>
    </Slide>
  );
});
