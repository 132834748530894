import React, {
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import { isEqual } from "lodash";
import {
  TemplateEnvironment,
  TemplatePreset,
  TemplateShotActual,
} from "shared";

type TemplateValue<Config = any> = {
  templateEnvironment: TemplateEnvironment;
  templatePreset: TemplatePreset;
  templateShotActual: TemplateShotActual | null;
  templateShotLatestVersionNumber: number | null;
  initialConfigSerialized: string | null;
  templateConfig: Config;
  setTemplateConfig: (config: Config) => void;
  isModified: boolean;
  onSave: VoidFunction;
};

const TemplateContext = React.createContext<TemplateValue>({
  templateEnvironment: {} as TemplateEnvironment,
  templatePreset: {} as TemplatePreset,
  templateShotActual: null,
  templateShotLatestVersionNumber: null,
  initialConfigSerialized: null,
  templateConfig: {},
  setTemplateConfig: () => {},
  isModified: false,
  onSave: () => {},
});

export function useTemplate<Config>(): TemplateValue<Config> {
  return useContext<TemplateValue<Config>>(TemplateContext);
}

interface Props {
  templateEnvironment: TemplateEnvironment;
  templatePreset: TemplatePreset;
  templateShotActual: TemplateShotActual | null;
  templateShotLatestVersionNumber: number | null;
  children: JSX.Element;
}

export function TemplateProvider<Config>({
  templatePreset,
  templateEnvironment,
  templateShotActual,
  templateShotLatestVersionNumber,
  children,
}: Props) {
  const initialConfigSerialized: string | null =
    templateShotActual?.templateConfig || templatePreset.templateConfig || null;

  const parsedInitialConfig = useMemo<Config | null>(() => {
    if (initialConfigSerialized === null) {
      return null;
    }
    try {
      return JSON.parse(initialConfigSerialized) as Config;
    } catch (err) {
      return null;
    }
  }, [initialConfigSerialized]);

  const [savedConfig, setSavedConfig] = useState<Config | null>(
    parsedInitialConfig
  );
  const [templateConfig, setTemplateConfig] = useState<Config | null>(
    parsedInitialConfig
  );

  useEffect(
    function () {
      setSavedConfig(parsedInitialConfig);
    },
    [parsedInitialConfig]
  );

  const isModified = useMemo<boolean>(
    () => !isEqual(templateConfig, savedConfig),
    [templateConfig, savedConfig]
  );

  const handleSave = useCallback(async () => {
    setSavedConfig(templateConfig);
  }, [templateConfig]);

  const value = useMemo(
    () => ({
      templatePreset,
      templateEnvironment,
      templateShotActual,
      templateShotLatestVersionNumber,
      isModified,
      initialConfigSerialized,
      templateConfig,
      setTemplateConfig,
      onSave: handleSave,
    }),
    [
      templatePreset,
      templateEnvironment,
      templateShotActual,
      templateShotLatestVersionNumber,
      isModified,
      initialConfigSerialized,
      templateConfig,
      handleSave,
    ]
  );

  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  );
}
