import React from "react";
import { Pricing } from "shared";
import { Modal } from "client-lib/ui";
import { PricingFull } from "./pricing-full";

interface Props {
  pricing: Pricing;
  onCloseRequest: () => void;
}

export function PricingModal({ pricing, onCloseRequest }: Props) {
  return (
    <Modal
      title="Подробная смета на производство"
      onCloseRequest={onCloseRequest}
    >
      <PricingFull pricing={pricing} />
    </Modal>
  );
}
