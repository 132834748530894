import React from "react";
import { black } from "../styles";
import { IconBase, IconProps } from "./base";

export function HamburgerIcon({ color = black, ...props }: IconProps) {
  return (
    <IconBase {...props}>
      <rect
        x="4"
        y="5.5"
        width="16"
        height="2"
        rx="1"
        ry="1"
        stroke="none"
        fill={color}
      />
      <rect
        x="4"
        y="11"
        width="16"
        height="2"
        rx="1"
        ry="1"
        stroke="none"
        fill={color}
      />
      <rect
        x="4"
        y="16.5"
        width="16"
        height="2"
        rx="1"
        ry="1"
        stroke="none"
        fill={color}
      />
    </IconBase>
  );
}
