import React from "react";
import { Spinner } from "client-lib";
import { Layout } from "src/features/layout";
import { useEnvironment } from "src/features/environment";
import { Requisites } from "./requisites";

export function RequisitesPage() {
  const { environment } = useEnvironment();

  return (
    <Layout>
      <h1>Реквизиты</h1>
      {environment ? <Requisites /> : <Spinner />}
    </Layout>
  );
}
