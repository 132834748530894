import React from "react";
const WIDTH = 258;
const HEIGHT = 34;

const RATIO = WIDTH / HEIGHT;

interface Props {
  width?: number;
  height?: number;
}

function sanitizeProps(props: Props) {
  if (props.width) {
    return {
      width: props.width,
      height: props.width / RATIO,
    };
  }

  if (props.height) {
    return {
      width: props.height * RATIO,
      height: props.height,
    };
  }

  return {
    width: WIDTH,
    height: HEIGHT,
  };
}

export function LogoIcon(props: Props) {
  const { width, height } = sanitizeProps(props);
  return (
    <svg viewBox={`0 0 ${WIDTH} ${HEIGHT}`} style={{ width, height }}>
      <path
        d="m 25,28.4867 h -2.0985 l -2.1642,-15.3786 c -0.1313,-0.9184 -0.2077,-1.6505 -0.2296,-2.1973 l -1.2132,2.0003 -6.2304,10.0665 h -0.4918 l -6.263,-10.1322 -1.1476,-1.8689 c -0.0219,0.2622 -0.1201,0.9729 -0.2952,2.1316 l -2.1312,15.3786 h -2.1316 l 3.1809,-22.4944 h 0.6557 l 6.9844,11.3455 c 0.5463,0.8746 1.0055,1.6617 1.3772,2.3612 0.306,-0.5906 0.7652,-1.3883 1.3771,-2.3938 l 7.017,-11.3129 h 0.6232 z m 20.8867,-1.9672 v 1.9672 H 32.2788 v -22.2974 h 13.3126 v 1.9672 h -11.1484 v 7.9355 h 9.8044 v 1.9346 h -9.8044 v 8.4929 z m 20.1327,-18.363 h -6.8531 v 20.3302 h -2.1642 v -20.3302 h -6.8536 v -1.9672 h 15.8709 z m 22.1329,20.2645 v 0.0657 h -2.263 l -2.3607,-5.1155 h -12.1982 l -2.3607,5.1155 h -2.2625 v -0.0657 l 10.3619,-22.3956 h 0.7214 z m -10.7228,-18.5921 c -0.2622,0.6777 -0.6776,1.6724 -1.2458,2.9839 l -3.9349,8.5912 h 10.3945 l -3.9675,-8.5912 c -0.5687,-1.3115 -0.9841,-2.3062 -1.2463,-2.9839 z"
        fill={"#03fbfe"}
        fillRule="evenodd"
        id="meta"
      />
      <path
        d="m 140.7154,28.421 v 0.0657 h -2.2629 l -2.3608,-5.1155 h -12.1981 l -2.3608,5.1155 h -2.2624 v -0.0657 l 10.3618,-22.3956 h 0.7214 z m -10.7227,-18.5921 c -0.2622,0.6777 -0.6777,1.6724 -1.2458,2.9839 l -3.9349,8.5912 h 10.3944 l -3.9675,-8.5912 c -0.5686,-1.3115 -0.984,-2.3062 -1.2462,-2.9839 z"
        fill={"#fff"}
        fillRule="evenodd"
        id="a"
      />
      <path
        d="m 146.5183,6.1893 h 13.5753 v 1.9672 h -11.4111 v 7.2467 h 6.1643 c 1.3776,0 2.6178,0.284 3.722,0.8527 1.1038,0.5682 1.9617,1.3497 2.5741,2.3444 0.6119,0.9948 0.9179,2.1042 0.9179,3.3281 0,1.2244 -0.3115,2.3393 -0.9342,3.3448 -0.6232,1.0055 -1.4815,1.7925 -2.5741,2.3607 -1.093,0.5687 -2.3281,0.8528 -3.7057,0.8528 h -8.3285 z m 8.3285,20.3302 c 1.5304,0 2.7599,-0.4103 3.689,-1.23 0.9291,-0.8197 1.3939,-1.9402 1.3939,-3.3611 0,-1.3991 -0.4648,-2.5028 -1.3939,-3.3118 -0.9291,-0.8089 -2.1586,-1.2132 -3.689,-1.2132 h -6.1643 v 9.1161 z"
        fill={"#fff"}
        fillRule="evenodd"
        id="b"
      />
      <path
        d="m 169.11,6.1893 h 8.2302 c 1.4647,0 2.7273,0.333 3.7872,0.9999 1.0605,0.6669 1.8639,1.5248 2.4101,2.5741 0.5468,1.0492 0.8202,2.1204 0.8202,3.2134 0,1.0931 -0.2627,2.1424 -0.7871,3.1479 -0.5249,1.0054 -1.3282,1.8256 -2.4101,2.4594 -1.0823,0.6339 -2.3994,0.951 -3.9512,0.951 h -5.9351 v 8.9517 h -2.1642 z m 8.0663,11.3781 c 1.093,0 2.0165,-0.2185 2.771,-0.6558 0.754,-0.4373 1.3171,-1.0055 1.6887,-1.705 0.3717,-0.6995 0.5575,-1.454 0.5575,-2.2625 0,-0.8527 -0.2026,-1.6453 -0.6069,-2.3775 -0.4042,-0.7321 -0.978,-1.317 -1.7213,-1.7543 -0.7433,-0.4373 -1.596,-0.6558 -2.5577,-0.6558 h -6.0334 v 9.4109 z"
        fill={"#fff"}
        fillRule="evenodd"
        id="r"
      />
      <path
        d="m 208.8118,6.0254 v 22.4613 h -2.1642 v -15.0838 c 0,-0.9836 0.0326,-1.9784 0.0982,-2.9839 -0.6776,0.8746 -1.4866,1.8363 -2.4264,2.8856 l -13.3457,15.346 h -0.6557 v -22.4613 h 2.1642 v 14.7885 c 0,1.0931 -0.0331,2.1857 -0.0983,3.2787 0.8961,-1.1149 1.7814,-2.153 2.656,-3.1147 l 13.1161,-15.1164 z"
        fill={"#fff"}
        fillRule="evenodd"
        id="i"
      />
      <path
        d="m 232.4792,28.3884 v 0.0983 h -2.7216 l -10.6241,-10.5258 v 10.5258 h -2.1642 v -22.2974 h 2.1642 v 10.5258 l 10.0666,-10.5258 h 2.656 v 0.0983 l -10.5584,10.9845 z"
        fill={"#fff"}
        fillRule="evenodd"
        id="k"
      />
      <path
        d="m 256.9072,28.421 v 0.0657 h -2.2629 l -2.3607,-5.1155 h -12.1982 l -2.3607,5.1155 h -2.2625 v -0.0657 l 10.3618,-22.3956 h 0.7214 z m -10.7227,-18.5921 c -0.2622,0.6777 -0.6776,1.6724 -1.2458,2.9839 l -3.9349,8.5912 h 10.3944 l -3.9674,-8.5912 c -0.5687,-1.3115 -0.9841,-2.3062 -1.2463,-2.9839 z"
        fill={"#fff"}
        fillRule="evenodd"
        id="a"
      />
      {/*<path*/}
      {/*d="m 104.1274,5.1568 c 3.6811,0 7.0231,1.3581 9.4481,3.5521 2.4516,2.2177 3.968,5.2878 3.968,8.681 0,3.3933 -1.5164,6.4633 -3.968,8.6811 -2.425,2.194 -5.767,3.552 -9.4481,3.552 -3.681,0 -7.0226,-1.358 -9.4476,-3.552 -2.4511,-2.2183 -3.9679,-5.2874 -3.9679,-8.6811 0,-3.3937 1.5168,-6.4628 3.9679,-8.681 2.425,-2.194 5.7666,-3.5521 9.4476,-3.5521 z m 8.1311,5.0079 c -2.073,-1.8754 -4.9478,-3.036 -8.1311,-3.036 -3.1832,0 -6.0576,1.1606 -8.1305,3.036 -2.0469,1.8522 -3.3131,4.4071 -3.3131,7.2252 0,2.8181 1.2662,5.373 3.3131,7.2252 2.0729,1.8755 4.9473,3.0361 8.1305,3.0361 3.1833,0 6.0581,-1.1606 8.1311,-3.0361 2.0468,-1.8517 3.3131,-4.4071 3.3131,-7.2252 0,-2.8181 -1.2663,-5.3735 -3.3131,-7.2252 z"*/}
      {/*fill={"#fff"}*/}
      {/*fillRule="evenodd"*/}
      {/*id="O"*/}
      {/*/>*/}
      {/*<path*/}
      {/*d="m 95.35,33 v 0.0656 h 2.2629 c 3.2,-6.9341 11.562,-25.1023 14.6409,-31.8264 h -1.4773 -0.7214 z"*/}
      {/*fill={"#fff"}*/}
      {/*fillRule="evenodd"*/}
      {/*id="/"*/}
      {/*/>*/}
      <ellipse
        cx="104.13"
        cy="17.39"
        rx="12.42"
        ry="11.27"
        stroke="#fff"
        strokeWidth="1.95"
        fill={"transparent"}
        id="0"
      />
      <path
        d="m 95.25,33.15 l 14.8,-32 l 2.25,0 l -14.8,32 z"
        fill="#fff"
        fillRule="evenodd"
        id="/"
      />
    </svg>
  );
}
