import React from "react";
import { useProduction } from "src/features/order";
import { AmountSelector } from "./amount-selector";

interface Props {
  label: string;
}

export function ContextAmountSelector({ label }: Props) {
  const { amount, setAmount } = useProduction();
  return <AmountSelector label={label} value={amount} onChange={setAmount} />;
}
