import { css } from "@emotion/react";
import { black, borderGrey } from "./colors";
import {
  primaryFamily,
  typographyH1,
  typographyH2,
  typographyH3,
  typographyBody1,
} from "./typography";
import "react-datepicker/dist/react-datepicker.css";
import { ReactImageLightboxStyles } from "./react-image-lightbox-styles";
import "@vkontakte/vkui/dist/vkui.css";
import "@radix-ui/themes/styles.css";

export const globalStyles = [
  css`
    html,
    body {
      height: 100%;
      padding: 0;
      margin: 0;
      color: ${black};
      ${typographyBody1}
    }
    * {
      font-family: ${primaryFamily};
    }
    a {
      color: ${black};
    }
    #root {
      height: 100%;
    }
    h1 {
      ${typographyH1}
    }
    h2 {
      ${typographyH2}
    }
    h3 {
      ${typographyH3}
    }
    h4 {
      ${typographyBody1};
      font-weight: 600;
      margin: 0;
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
    td,
    th {
      border-bottom: 1px solid ${borderGrey};
      border-top: 1px solid ${borderGrey};
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: black;
      -webkit-box-shadow: 0 0 0px 1000px rgb(248, 250, 189, 0.75) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        margin-left: 18px;
      }
    }
  `,
  ReactImageLightboxStyles,
];
