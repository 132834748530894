import React, { memo, useMemo } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { gutters } from "client-lib/ui";
import { AnyLegalTerm, isLegalTerm } from "./types";
import { useLegalTerms } from "./legal-terms-context";

interface Props {
  currentIndex: string;
  terms: AnyLegalTerm[];
}

export const LegalTerms = memo(function LegalTerms({
  currentIndex,
  terms,
}: Props) {
  const currentIndexWithSuffix = currentIndex ? `${currentIndex}.` : "";

  return (
    <React.Fragment>
      {terms.map((term, index) => (
        <LegalTermBlock
          key={index}
          term={term}
          currentIndex={`${currentIndexWithSuffix}${index + 1}`}
        />
      ))}
    </React.Fragment>
  );
});

const MIN_BULLET_SIZE = 30;

const Container = styled.div<{ level: number }>(
  ({ level }) => css`
    margin-left: ${level === 1 ? MIN_BULLET_SIZE : 0}px;
    margin-top: ${level === 0 ? gutters.xl : gutters.xs}px;

    > div:first-of-type {
      margin-top: ${level === 0 ? gutters.md : gutters.xs}px;
    }

    white-space: pre-wrap;
  `
);

const Bullet = styled.span`
  font-weight: 600;
  min-width: 2em;
  display: inline-block;
  &:after {
    content: "\u00a0";
  }
`;

interface LegalTermProps {
  currentIndex: string;
  term: AnyLegalTerm;
}

export const LegalTermBlock = memo(function LegalTermBlock({
  currentIndex,
  term,
}: LegalTermProps) {
  const { markers } = useLegalTerms();

  const content = useMemo<string>(() => {
    let result: string = isLegalTerm(term) ? term.content : term;

    if (!isLegalTerm(term) || !term?.keepNewLines) {
      result = result.replace(/[\r\n]/g, "");
    }

    result = result.trim().replace(/[^\S\r\n]+/g, " ");

    for (const marker of markers) {
      result = result.replace(
        new RegExp(`==${marker.originalMarker}==`, "g"),
        marker.actualIndex
      );
    }

    return result;
  }, [term, markers]);

  const level = useMemo(
    () => currentIndex.replace(/[^.]/g, "").length,
    [currentIndex]
  );

  return (
    <Container level={level}>
      <Bullet>{currentIndex}.</Bullet>
      {content}
      {isLegalTerm(term) && !!term.items && (
        <LegalTerms currentIndex={currentIndex} terms={term.items!} />
      )}
    </Container>
  );
});
