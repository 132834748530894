import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Lightbox from "react-image-lightbox";
import {
  gutters,
  borderRadius,
  whiteBlockShadow,
  white,
  grey30,
  grey70,
  getImageUrl,
  useBoolean,
  zLayers,
  typographyBody2,
  Button,
  TemplatePresetEnvironmentForLists,
  MultiPageIcon,
  IconButton,
  mqMax,
} from "client-lib";

const IMAGE_SIZE = 200;

const containerStyles = css`
  display: flex;
  background-color: ${white};
  border-radius: ${borderRadius.lg}px;
  ${whiteBlockShadow};
  overflow: hidden;
  margin-bottom: ${gutters.lg}px;
  text-decoration: none;
  ${mqMax.phone} {
    flex-direction: column;
  }
`;

const ContainerLink = styled(Link)(containerStyles);
const FakeLink = styled.div<{ to: string }>(containerStyles);

const ImageContainer = styled.div`
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${grey30};
  overflow: hidden;
  position: relative;
  ${mqMax.phone} {
    width: 100%;
    background: #999;
  }
`;

const Image = styled.div<{ imageUrl: string }>(
  ({ imageUrl }) => css`
    width: ${IMAGE_SIZE}px;
    height: ${IMAGE_SIZE}px;
    background-image: url(${imageUrl});
    background-size: cover;
  `
);
const EmptyImage = styled.div([
  typographyBody2,
  css`
    color: ${grey70};
  `,
]);

const GalleryButton = styled(IconButton)`
  position: absolute;
  right: ${gutters.sm}px;
  bottom: ${gutters.sm}px;
  transition: transform 0.25s;
  :hover {
    background-color: transparent;
    transform: scale(1.5);
  }
`;

const InfoContainer = styled.div`
  flex: 0 1 100%;
  padding: ${gutters.lg}px ${gutters.xl}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mqMax.phone} {
    justify-content: flex-start;
  }
`;

const Info = styled.div`
  flex: 1 1 100%;
  h1 {
    margin-top: 0;
  }
  ${mqMax.phone} {
    margin-bottom: ${gutters.lg}px;
    h1 {
      margin-bottom: 0px;
    }
  }
`;

const InfoControls = styled.div`
  flex-shrink: 1;
`;

interface Props {
  templatePresetEnvironment: TemplatePresetEnvironmentForLists;
}

export function PresetCard({ templatePresetEnvironment }: Props) {
  const { templatePreset, images } = templatePresetEnvironment;
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [isLightboxOpen, { set: openLightbox, reset: closeLightbox }] =
    useBoolean(false);

  const handleImageClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (images?.length) {
        openLightbox();
      }
    },
    [openLightbox, images]
  );

  // FakeLink prevents navigation when clicked outside of the LightBox
  const Container = isLightboxOpen ? FakeLink : ContainerLink;

  return (
    <Container to={`/templates/${templatePreset.id}`}>
      <ImageContainer>
        {images.length > 0 ? (
          <Image imageUrl={getImageUrl(images[0].image.id)} />
        ) : (
          <EmptyImage>Нет фото</EmptyImage>
        )}
        {images?.length && (
          <GalleryButton onClick={handleImageClick}>
            <MultiPageIcon />
          </GalleryButton>
        )}
      </ImageContainer>
      <InfoContainer>
        <Info>
          <h1>{templatePreset.title}</h1>
          {templatePreset.description}
        </Info>
        <InfoControls>
          <Link to={`/templates/${templatePreset.id}`}>
            <Button>Открыть конструктор</Button>
          </Link>
        </InfoControls>
      </InfoContainer>
      {isLightboxOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: zLayers.MODAL,
            },
          }}
          mainSrc={getImageUrl(images[imageIndex].image.id)}
          nextSrc={
            imageIndex < images.length - 1
              ? getImageUrl(images[imageIndex + 1].image.id)
              : undefined
          }
          prevSrc={
            imageIndex > 0
              ? getImageUrl(images[imageIndex - 1].image.id)
              : undefined
          }
          onMovePrevRequest={() => setImageIndex(imageIndex - 1)}
          onMoveNextRequest={() => setImageIndex(imageIndex + 1)}
          onCloseRequest={closeLightbox}
          imageTitle={images[imageIndex].title}
        />
      )}
    </Container>
  );
}
