import React from "react";
import styled from "@emotion/styled";
import { TemplateShotInput } from "shared";
import { Button, TextField, gutters, grey30, primary } from "client-lib/ui";
import { Form, Formik, FormikProps } from "formik";
import { templateShotInputFormikSchema } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

const Suggestion = styled.div`
  display: inline-block;
  color: ${primary};
  border-bottom: 1px dashed ${primary};
  cursor: pointer;
  line-height: 1;
`;

interface Props {
  initialValues: TemplateShotInput;
  titleSuggestion: string;
  onSubmit: (values: TemplateShotInput) => any;
  disabled?: boolean;
}

export function TemplateShotForm({
  initialValues,
  titleSuggestion,
  onSubmit,
  disabled,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={templateShotInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        isValid,
        dirty,
        values,
        setValues,
      }: FormikProps<TemplateShotInput>) => (
        <Form>
          <p>
            Придумайте подходящее название для этой модели, например{" "}
            <Suggestion
              onClick={() => {
                setValues({ ...values, title: titleSuggestion });
              }}
            >
              {titleSuggestion}
            </Suggestion>
          </p>
          <TextField
            label="Название"
            controlProps={{ size: 50 }}
            disabled={disabled}
            name="title"
          />
          <Controls>
            <Button type="submit" disabled={disabled || !isValid || !dirty}>
              Сохранить
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
