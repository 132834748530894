import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { IntField, gutters } from "client-lib/ui";
import { useField } from "formik";
import { SliderField } from "./slider-field";

const Container = styled.div`
  margin-bottom: ${gutters.sm}px;
`;

const Controls = styled.div`
  display: flex;
  align-items: stretch;
  input {
    width: 100%;
    text-align: right;
    font-size: 14px;
    height: 28px;
  }
`;

const SliderBlock = styled.div<{ wide?: boolean }>`
  flex-basis: ${({ wide }) => (wide ? 100 : 65)}%;
  display: flex;
  align-items: center;
  padding-right: ${gutters.sm}px;
  height: 28px;
`;

const InputBlock = styled.div`
  flex-basis: 35%;
`;

const LabelBlock = styled.div`
  user-select: none;
  display: flex;
  justify-content: space-between;
  line-height: 100%;
  padding-right: ${gutters.sm}px;
`;

const LabelText = styled.div``;

const LabelValue = styled.div``;

interface Options {
  hideInput?: boolean;
  showValue?: boolean;
}

interface Props {
  readOnly?: boolean;
  label?: string;
  name: string;
  min: number;
  max: number;
  className?: string;
  onChange?: (value: number) => void;
  options?: Options;
}

export function SliderControl({
  readOnly = false,
  label,
  name,
  min,
  max,
  className,
  onChange,
  options,
}: Props) {
  const [{ value }] = useField<number>(name);
  const handleChange = useCallback(
    (value: number | string | null) => {
      if (typeof value === "number" && onChange) {
        onChange(value);
      }
    },
    [onChange]
  );
  return (
    <Container className={className}>
      <LabelBlock>
        {label && <LabelText>{label}</LabelText>}
        {options?.showValue && <LabelValue>{value}</LabelValue>}
      </LabelBlock>
      <Controls>
        <SliderBlock wide={options?.hideInput}>
          <SliderField
            readOnly={readOnly}
            name={name}
            min={min}
            max={max}
            onChange={onChange}
          />
        </SliderBlock>
        {!options?.hideInput && (
          <InputBlock>
            <IntField disabled={readOnly} name={name} onChange={handleChange} />
          </InputBlock>
        )}
      </Controls>
    </Container>
  );
}
