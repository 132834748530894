import React, { useCallback } from "react";
import { useAuth } from "src/features/auth";
import { AlertMargined, AlertControls, Button, InnerLink } from "client-lib";

interface Props {
  children?: React.ReactNode;
}

export function AuthControl({ children }: Props) {
  const { user, openAuthModal } = useAuth();

  const handleSignUp = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.isPropagationStopped()) {
        return;
      }
      e.stopPropagation();
      openAuthModal("signup");
    },
    [openAuthModal]
  );

  const handleSignIn = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.isPropagationStopped()) {
        return;
      }
      e.stopPropagation();
      openAuthModal("signin");
    },
    [openAuthModal]
  );

  if (!user) {
    return (
      <AlertMargined onClick={handleSignUp}>
        {children || (
          <p>
            Вы не зарегистрированы. Создавать и редактировать свои модели,
            заказывать их производство, пользоваться всеми функциями личного
            кабинета можно только после регистрации.
          </p>
        )}
        <AlertControls>
          <Button size="S">Зарегистрироваться</Button>
          <InnerLink onClick={handleSignIn}>Войти</InnerLink>
        </AlertControls>
      </AlertMargined>
    );
  }

  if (user.isGuest) {
    return (
      <AlertMargined onClick={handleSignUp}>
        {children || (
          <p>
            Для вас автоматически создан временный личный кабинет – в нем
            хранятся все созданные вами модели.
            <br />
            Чтобы оформлять заказы и надежно защитить все модели, нужно
            зарегистрироваться.
          </p>
        )}
        <AlertControls>
          <Button size="S">Зарегистрироваться</Button>
          <InnerLink onClick={handleSignIn}>Войти</InnerLink>
        </AlertControls>
      </AlertMargined>
    );
  }

  return null;
}
