import { CompanyRequisites } from "shared";
import { LegalTerm } from "../legal-terms";

export function getPrivacyPolicyTerms(
  companyRequisites: CompanyRequisites
): LegalTerm[] {
  return [
    {
      content: "Определение терминов",
      items: [
        `«Администрация» – уполномоченные сотрудники на управление сайтом, которые организуют
          и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав
          персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.`,

        `«Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному
          или определяемому физическому лицу (субъекту персональных данных)`,

        `«Обработка персональных данных» - любое действие (операция) или совокупность действий
        (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными
        данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
        персональных данных.`,

        `«Обработка персональных данных» - любое действие (операция) или совокупность действий
        (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными
        данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
        персональных данных.`,

        `«Конфиденциальность персональных данных» - обязательное для соблюдения Администрацией или иным
        получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта
        персональных данных или наличия иного законного основания.`,

        `«Пользователь сайта (далее Пользователь)» – лицо, имеющее доступ к Сайту, посредством
        сети Интернет и использующее Сайт.`,

        `«Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере
        Пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке
        открыть страницу соответствующего сайта.`,

        `«IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.`,
      ],
    },
    {
      content: "Общие положения",
      items: [
        `Использование Пользователем Сайта означает согласие с настоящей Политикой конфиденциальности
        и условиями обработки персональных данных Пользователя.`,

        `В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить
        использование Сайта.`,

        `Настоящая Политика конфиденциальности применяется только к Сайту. Администрация не контролирует
        и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.`,

        `Администрация сайта не проверяет достоверность персональных данных, предоставляемых
        Пользователем.`,
      ],
    },
    {
      content: "Предмет политики конфиденциальности",
      items: [
        `Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта
        по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет
        по запросу Администрации сайта при регистрации на Сайте.`,

        `Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности,
        предоставляются Пользователем путём заполнения регистрационных форм на Сайте
        и включают в себя ФИО Пользователя, его контактный телефон и e-mail:`,

        `Отключение cookies может повлечь невозможность доступа к частям Сайта, требующим авторизации.`,

        `Сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация
        используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.`,

        `Любая иная персональная информация, не оговоренная выше (история покупок, используемые
        браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев,
        предусмотренных в п.п. ==5.2== и ==5.3==. настоящей Политики конфиденциальности.`,
      ],
    },
    {
      content: "Цели сбора Персональной информации Пользователя",
      items: [
        `Настоящая Политика конфиденциальности устанавливает обязательства Администрации Сайта
        по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет
        по запросу Администрации сайта при регистрации на Сайте.`,

        `Идентификации Пользователя, зарегистрированного на Сайте.`,

        `Предоставления Пользователю доступа к персонализированным ресурсам Сайта.`,

        `Установления с Пользователем обратной связи, включая направление уведомлений, запросов,
        касающихся использования Сайта, оказания услуг, обработка запросов и заявок от Пользователя.`,

        `Определения места нахождения Пользователя для обеспечения безопасности, предотвращения
        мошенничества.`,

        `Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.`,

        `Создания учетной записи для совершения покупок.`,

        `Уведомления Пользователя Сайта о состоянии Заказа.`,

        `Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания
        платежа, определения права на получение кредитной линии Пользователем.`,

        `Предоставления Пользователю эффективной клиентской и технической поддержки при
        возникновении проблем связанных с использованием Сайта.`,

        `Предоставления Пользователю обновлений продукции, специальных
        предложений, информации о ценах, новостной рассылки и иных сведений от имени Оператора или от имени партнеров
        Оператора.`,

        `Осуществления рекламной деятельности с согласия Пользователя.`,

        `Предоставления доступа Пользователю на сайты или сервисы партнеров Операторас целью
        получения продуктов, обновлений и услуг.`,
      ],
    },
    {
      content: "Способы и сроки обработки персональной информации.",
      items: [
        `Обработка персональных данных Пользователя осуществляется без ограничения срока, любым
        законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации
        или без использования таких средств.`,

        {
          marker: "5.2",
          content: `Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные
            данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи,
            в целях повышения качества обслуживания и информирования Пользователя, выполнения заказов Пользователя,
            оформляемых на Сайте, повышения удобства использования Сайта.`,
        },

        {
          marker: "5.3",
          content: `Персональные данные Пользователя могут быть переданы уполномоченным органам
            государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством
            Российской Федерации.`,
        },

        `При утрате или разглашении персональных данных Администрация сайта информирует Пользователя
        об утрате или разглашении персональных данных.`,

        `Администрация сайта принимает необходимые организационные и технические меры для защиты
        персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения,
        блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.`,

        `Администрация сайта совместно с Пользователем принимает все необходимые меры по
        предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных
        данных Пользователя.`,
      ],
    },

    {
      content: "Обязательства сторон",
      items: [
        {
          content: "Пользователь обязан:",
          items: [
            `Предоставить информацию о персональных данных, необходимую для пользования Сайтом.`,

            `Обновить, дополнить предоставленную информацию о персональных данных в случае изменения
        данной информации.`,
          ],
        },
        {
          content: "Администрация сайта обязана:",
          items: [
            `Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей
        Политики конфиденциальности.`,

            `Принимать меры предосторожности для защиты конфиденциальности персональных данных
        Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.`,

            `Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю,
        с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите
        прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или
        неправомерных действий.`,
          ],
        },
      ],
    },

    {
      content: "Ответственность сторон",
      items: [
        `Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки,
        понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с
        законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. ==5.2==, ==5.3==. и ==7.2==. настоящей
        Политики Конфиденциальности.`,

        {
          marker: "7.2",
          content: `В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, 
            если данная конфиденциальная информация:`,
          items: [
            `Стала публичным достоянием до её утраты или разглашения.`,

            `Была получена от третьей стороны до момента её получения Администрацией сайта.`,

            `Была разглашена с согласия Пользователя.`,
          ],
        },
      ],
    },

    {
      content: "Разрешение споров",
      items: [
        `До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта
        и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о
        добровольном урегулировании спора).`,

        `Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно
        уведомляет заявителя претензии о результатах рассмотрения претензии.`,

        `При не достижении соглашения спор будет передан на рассмотрение в судебный орган в
        соответствии с действующим законодательством Российской Федерации.`,

        `К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией
        сайта применяется действующее законодательство Российской Федерации.`,
      ],
    },
    {
      content: "Дополнительные условия",
      items: [
        `Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без
        согласия Пользователя.`,

        `Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если
        иное не предусмотрено новой редакцией Политики конфиденциальности.`,

        `Действующая Политика конфиденциальности размещена на странице по адресу https://metafabrika.ru/privacy-policy`,
      ],
    },
    {
      content: "Сведения об операторе персональных данных",
      items: [
        {
          keepNewLines: true,
          hideNumber: true,
          content: `
${companyRequisites.title}
ИНН ${companyRequisites.inn}
ОГРН ${companyRequisites.ogrn}
КПП ${companyRequisites.kpp}
Юридический адрес: ${companyRequisites.address}
`,
        },
      ],
    },
  ];
}
