import React, { memo, useMemo } from "react";
import styled from "@emotion/styled";
import { Scene } from "src/features/template-editor/scene";
import { white, useEnv } from "client-lib";
import { ScreenSlide, SlideContainer, Header } from "src/features/layout/index";
import { useTemplateEditor } from "src/features/template-editor/template-editor-context";
import { SceneOptions } from "src/features/template-editor/types";
import { Config, Assets, Variables } from "../types";
import { CounterTopForm } from "./countertop-form";

const Slide = styled(ScreenSlide)`
  background-color: ${white};
`;

const Container = styled(SlideContainer)`
  .rt-SelectTrigger {
    .rt-SelectIcon {
      margin-left: 10px;
    }
  }
`;

const SceneContainer = styled.div`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  height: 50vh;
`;

// const SceneOverlay = styled.div`
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
// `;

const parts = new Set<string>(["countertop"]);

export const CounterTopSlide = memo(function CounterTopSlide() {
  const { isMobileAgent } = useEnv();
  const { configValues, templateFull, templateVariables } = useTemplateEditor<
    Config,
    Assets,
    Variables
  >();
  // const { isStale, isLoading, pricing } = usePricing(templatePreset.id, configValues);

  const sceneOptions = useMemo<SceneOptions>(
    () => ({
      showMeasurements: true,
      backgroundColor: white,
      // disableZoom: !isMobileAgent,
      autoRotate: false,
      parts,
      camera: {
        position: [600, 500, 500],
        azimuthalAngle: 0,
        polarAngle: (30 * Math.PI) / 180,
      },
    }),
    [isMobileAgent]
  );

  return (
    <Slide>
      <Container>
        <Header>Столешница</Header>
        <p>
          Столешница лофт-стола обязательно должна быть деревянной. Ни в коем
          случае не заказывайте столешницу из ЛДСП! Пластиковые кромки через
          несколько лет сотрутся, стол приобретет неопрятный вид, его придется
          менять. Настоящий лофт-стол должен прослужить десятки лет без всяких
          нареканий.
        </p>

        <CounterTopForm />
        <SceneContainer>
          <Scene options={sceneOptions}>
            <templateFull.component
              config={configValues}
              assets={templateFull.assets}
              variables={templateVariables}
              mode="model"
            />
          </Scene>
        </SceneContainer>
      </Container>
    </Slide>
  );
});
