import { SectionPrices, SectionPricesAndTotal } from "../types";
import { pickBestPricingValue, Pricing } from "shared";

export function calculateSectionPricesAndTotal(
  pricing: Pricing,
  amount: number = 1
): SectionPricesAndTotal {
  let acc = 0;
  const result: SectionPrices = {};
  for (let i = 0; i < pricing.sections.length; i++) {
    const section = pricing.sections[i];
    result[i] = 0;
    for (const item of section.items) {
      const pricingValue = pickBestPricingValue(item.unitPricingValues, amount);
      if (!pricingValue) {
        throw new Error(
          `Couldn't find appropriate pricing for item ${item.title} of section ${section.title}`
        );
      }
      const itemPrice = Math.round(
        item.unitAmount * pricingValue.costKopecks * item.amount
      );
      result[i] += itemPrice;
      acc += itemPrice;
    }
  }
  return { sectionPrices: result, total: acc };
}

export function calculateTotal(pricing: Pricing, amount: number = 1): number {
  return calculateSectionPricesAndTotal(pricing, amount).total;
}
