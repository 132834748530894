import styled from "@emotion/styled";
import { TD, TR } from "client-lib";

export const FixedHeightRow = styled(TR)`
  height: 53px;
`;

export const BoldTD = styled(TD)`
  font-weight: 600;
  .vkuiSpinner {
    justify-content: flex-end;
  }
`;
