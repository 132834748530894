import React, { RefObject, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { DecompositionPackage } from "shared";
import { gutters } from "client-lib/styles";
import { useProduction } from "src/features/order";
import { useDeliveryEstimate } from "./use-delivery-estimate";
import { DeliveryLocationSelector } from "./delivery-location-selector";
import { DeliveryCost } from "./delivery-cost";

const Container = styled.div`
  margin-top: ${gutters.lg}px;
  h4 {
    margin: ${gutters.lg}px 0 ${gutters.xs}px;
  }
`;

const Comment = styled.div([
  // typographyBody2,
  css`
    margin-bottom: ${gutters.md}px;
  `,
]);

const DeliveryMethod = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${gutters.lg}px;
  user-select: none;
  input {
    height: 28px;
  }
`;
const MethodTitle = styled.div`
  flex: 100% 1 1;
`;

const StyledDeliveryLocationSelector = styled(DeliveryLocationSelector)`
  display: block;
  section {
    width: 100%;
    margin-bottom: ${gutters.sm}px;
  }
  section select {
    width: 100%;
  }
`;

interface Props {
  productPackage: DecompositionPackage;
  children: React.ReactNode;
  contentRef: RefObject<HTMLDivElement>;
}

export function PricingDelivery({
  productPackage,
  children,
  contentRef,
}: Props) {
  const {
    amount,
    deliveryRegion,
    deliveryLocationId,
    setDeliveryRegion,
    setDeliveryLocationId,
  } = useProduction();
  const [initialDeliveryRegion] = useState<string | null>(deliveryRegion);
  const {
    tkTerminalKopecks,
    tkLocationKopecks,
    isLoading,
    isTerminalCostActual,
    isLocationCostActual,
    refresh,
  } = useDeliveryEstimate(productPackage, deliveryLocationId);

  useEffect(
    function () {
      if (!deliveryRegion || initialDeliveryRegion === deliveryRegion) {
        return;
      }
      setTimeout(() => {
        contentRef.current?.scrollTo({
          top: contentRef.current?.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
    },
    [contentRef, deliveryRegion, initialDeliveryRegion]
  );

  return (
    <Container>
      {children}
      <h4>Цена доставки до терминала ТК</h4>
      <Comment>
        Стоимость доставки до терминала ТК, которая будет включена в наш счет.
      </Comment>

      <DeliveryMethod>
        <MethodTitle>За единицу:</MethodTitle>
        <DeliveryCost
          isLoading={isLoading}
          isActual={isTerminalCostActual}
          amount={amount}
          value={tkTerminalKopecks}
          refresh={refresh}
        />
      </DeliveryMethod>
      <h4>Цена доставки до вашего региона</h4>
      <Comment>
        Эту стоимость вы оплачиваете транспортной компании при получении товара.
        Укажите город доставки, и мы рассчитаем ориентировочную стоимость
      </Comment>

      <StyledDeliveryLocationSelector
        region={deliveryRegion}
        onRegionChange={setDeliveryRegion}
        deliveryLocationId={deliveryLocationId}
        onDeliveryLocationChange={setDeliveryLocationId}
      />
      {deliveryLocationId && (
        <DeliveryMethod>
          <MethodTitle>За единицу:</MethodTitle>
          <DeliveryCost
            isLoading={isLoading}
            isActual={isLocationCostActual}
            amount={amount}
            value={tkLocationKopecks}
            refresh={refresh}
          />
        </DeliveryMethod>
      )}
    </Container>
  );
}
