import React, { useMemo } from "react";
import { useEnvironmentStrict } from "src/features/environment";
import { getPrivacyPolicyTerms } from "./privacy-policy-terms";
import { Legal } from "../legal-terms";

export function PrivacyPolicy() {
  const environment = useEnvironmentStrict();

  const terms = useMemo(
    () => getPrivacyPolicyTerms(environment.companyRequisites),
    [environment.companyRequisites]
  );

  return <Legal terms={terms} />;
}
