import { TemplateModel } from "../types";
import {
  shelfConfigSchema,
  shelfAssetsSchema,
  shelfVariablesSchema,
  Config,
  Assets,
  Variables,
} from "./types";
import { MIN_ITEM_STEP, MIN_THICKNESS } from "./constants";

const INITIAL_WIDTH = 400;
const INITIAL_HEIGHT = 400;
const INITIAL_INNER_WIDTH = INITIAL_WIDTH - 2 * MIN_THICKNESS;

export const model: TemplateModel<Config, Assets, Variables> = {
  configSchema: shelfConfigSchema,
  configInitialValues: (assets: Assets) => ({
    sidesCount: 4,
    width: INITIAL_WIDTH,
    height: INITIAL_HEIGHT,
    depth: 150,
    isEquilateral: true,
    items: [
      {
        coverageId: assets.shelfCoverage[0]?.id ?? null,
        step: MIN_ITEM_STEP * 2,
        thickness: MIN_THICKNESS,
        width: INITIAL_WIDTH,
        height: INITIAL_HEIGHT,
        innerWidth: INITIAL_INNER_WIDTH,
      },
      {
        coverageId: assets.shelfCoverage[0]?.id ?? null,
        step: MIN_ITEM_STEP,
        thickness: MIN_THICKNESS,
        width: INITIAL_WIDTH - (2 * MIN_THICKNESS + MIN_ITEM_STEP),
        height: INITIAL_HEIGHT - (2 * MIN_THICKNESS + MIN_ITEM_STEP),
        innerWidth: INITIAL_INNER_WIDTH - (2 * MIN_THICKNESS + MIN_ITEM_STEP),
      },
      {
        coverageId: assets.shelfCoverage[0]?.id ?? null,
        step: MIN_ITEM_STEP,
        thickness: MIN_THICKNESS,
        width: INITIAL_WIDTH - 2 * (2 * MIN_THICKNESS + MIN_ITEM_STEP),
        height: INITIAL_HEIGHT - 2 * (2 * MIN_THICKNESS + MIN_ITEM_STEP),
        innerWidth:
          INITIAL_INNER_WIDTH - 2 * (2 * MIN_THICKNESS + MIN_ITEM_STEP),
      },
      {
        coverageId: assets.shelfCoverage[0]?.id ?? null,
        step: MIN_ITEM_STEP,
        thickness: MIN_THICKNESS,
        width: INITIAL_WIDTH - 3 * (2 * MIN_THICKNESS + MIN_ITEM_STEP),
        height: INITIAL_HEIGHT - 3 * (2 * MIN_THICKNESS + MIN_ITEM_STEP),
        innerWidth:
          INITIAL_INNER_WIDTH - 3 * (2 * MIN_THICKNESS + MIN_ITEM_STEP),
      },
    ],
    packageCardboardId: assets.packageCardboard[0]?.id ?? "",
    packageBubbleWrapId: assets.packageBubbleWrap[0]?.id ?? "",
  }),
  assetsSchema: shelfAssetsSchema,
  variablesSchema: shelfVariablesSchema,
  variablesDefaults: {},
};
