export const MAX_THICKNESS = 40;
export const MIN_THICKNESS = 20;

export const MIN_ITEMS_AMOUNT = 1;
export const MAX_ITEMS_AMOUNT = 10;

export const MIN_ITEM_STEP = 5;
export const MAX_ITEM_STEP = 100;
export const MIN_INNER_WIDTH = 100;
export const MAX_INNER_WIDTH = 800;

export const MIN_WIDTH = 100;
export const MAX_WIDTH = 800;

export const MIN_HEIGHT = 100;
export const MAX_HEIGHT = 800;

export const MIN_DEPTH = 100;
export const MAX_DEPTH = 400;
