import React, { useCallback, useMemo, useState } from "react";
import {
  Pricing,
  PricingLimit,
  pickBestPricingValue,
  PricingLimitValues,
} from "shared";
import styled from "@emotion/styled";
import { InnerLink, Price, gutters, grey50, grey20 } from "client-lib/ui";
import { CartButton } from "src/features/order";
import { LimitSelector } from "./limit-selector";
import { PricingModal } from "./pricing-modal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    border-color: ${grey50};
    background-color: ${grey20};
  }
`;

const Order = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${gutters.xs}px 0 ${gutters.md}px;
`;

const StyledPrice = styled(Price)`
  font-size: 24px;
  margin-right: 0.5em;
`;

interface Props {
  pricing: Pricing;
  isLoading: boolean;
  isStale: boolean;
}

export function PricingSnippet({ pricing }: Props) {
  const [isPricingModalOpen, setIsPricingModalOpen] = useState<boolean>(false);

  const [activeLimit, setActiveLimit] = useState<PricingLimit>(
    PricingLimit.BASIC
  );

  const total = useMemo<number>(() => {
    let acc = 0;
    for (let i = 0; i < pricing.sections.length; i++) {
      const section = pricing.sections[i];
      for (const item of section.items) {
        const pricingValue = pickBestPricingValue(
          item.unitPricingValues,
          PricingLimitValues[activeLimit]
        );
        if (!pricingValue) {
          throw new Error(
            `Couldn't find appropriate pricing for item ${item.title} of section ${section.title}`
          );
        }
        acc += Math.round(
          item.unitAmount * pricingValue.costKopecks * item.amount
        );
      }
    }
    return acc;
  }, [pricing, activeLimit]);

  const handlePricingModalCloseRequest = useCallback(() => {
    setIsPricingModalOpen(false);
  }, []);

  return (
    <Container>
      <LimitSelector activeLimit={activeLimit} onChange={setActiveLimit} />
      <Order>
        <StyledPrice kopecks={total} />
        <CartButton />
      </Order>
      <InnerLink onClick={() => setIsPricingModalOpen(true)}>
        Открыть подробную смету
      </InnerLink>
      {isPricingModalOpen && (
        <PricingModal
          pricing={pricing}
          onCloseRequest={handlePricingModalCloseRequest}
        />
      )}
    </Container>
  );
}
