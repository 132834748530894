import React, { useCallback } from "react";
import { supportMessageInputSchema, SupportMessageInput } from "shared";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import {
  TextField,
  MultilineTextField,
  Button,
  gutters,
  toFormikSchema,
} from "client-lib/ui";

const Container = styled.div`
  max-width: 500px;
  input,
  textarea {
    width: 100% !important;
    margin-bottom: ${gutters.md}px;
  }
  textarea {
    height: 150px;
  }
`;

const formikSchema = toFormikSchema(supportMessageInputSchema);

export function ContactsForm({
  messageId,
  onSubmit,
  pending,
}: {
  messageId: string;
  onSubmit: (input: SupportMessageInput) => void;
  pending?: boolean;
}) {
  const handleSubmit = useCallback(
    (input: SupportMessageInput) => {
      if (pending) {
        return;
      }
      onSubmit(input);
    },
    [onSubmit]
  );

  return (
    <Container>
      <Formik
        initialValues={{
          id: messageId,
          senderName: "",
          senderEmailOrPhone: "",
          message: "",
        }}
        enableReinitialize
        validationSchema={formikSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextField
            label="Как вас зовут"
            name="senderName"
            disabled={pending}
          />
          <TextField
            label="Телефон или e-mail для ответа"
            name="senderEmailOrPhone"
            disabled={pending}
          />
          <MultilineTextField
            label="Сообщение"
            name="message"
            disabled={pending}
          />
          <Button type="submit">Отправить</Button>
        </Form>
      </Formik>
    </Container>
  );
}
