import { MutableRefObject } from "react";
import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import { GraphQLError } from "graphql";
import { History, Location } from "history";
import { constructLoginUrl } from "src/features/auth/service";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { AuthRef } from "src/features/auth";

const DateWrapper = (v: string | null) => (v ? new Date(v) : v);
const dateFieldPolicy = { read: DateWrapper };

export function createClient(
  history: History,
  location: Location,
  authRef: MutableRefObject<AuthRef | null>,
  reportError: (errorText: string) => void
) {
  const client = new ApolloClient({
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        OrderLineItem: {
          fields: {
            createdAt: dateFieldPolicy,
            updatedAt: dateFieldPolicy,
            deletedAt: dateFieldPolicy,
          },
        },
        MaterialExpanded: {
          fields: {
            createdAt: dateFieldPolicy,
            updatedAt: dateFieldPolicy,
            deletedAt: dateFieldPolicy,
          },
        },
        Texture: {
          fields: {
            createdAt: dateFieldPolicy,
            updatedAt: dateFieldPolicy,
            deletedAt: dateFieldPolicy,
          },
        },
        Order: {
          fields: {
            createdAt: dateFieldPolicy,
            updatedAt: dateFieldPolicy,
            deletedAt: dateFieldPolicy,
            fixedAt: dateFieldPolicy,
          },
        },
        OrderEnvironment: {
          fields: {
            priceActualTill: dateFieldPolicy,
          },
        },
      },
    }),
    link: ApolloLink.from([
      setContext((_, { headers }) => {
        const token = localStorage.getItem("auth-token");
        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
          },
        };
      }),
      onError(({ graphQLErrors }: ErrorResponse) => {
        if (graphQLErrors && graphQLErrors.length) {
          if (
            graphQLErrors.some(
              (error: GraphQLError) =>
                error.extensions && error.extensions.code === "UNAUTHENTICATED"
            )
          ) {
            // this will also reset session due to client.onClearStore hook
            client.clearStore();
            history.push(constructLoginUrl(location));
          } else {
            reportError("При обращении к серверу возникла ошибка");
          }
        } else {
          reportError("При обращении к серверу возникла ошибка");
        }
      }),
      new ApolloLink((operation, forward) => {
        return forward(operation).map((response) => {
          const context = operation.getContext();
          const token = context.response.headers.get("token");
          if (token) {
            authRef?.current?.setToken(token);
          }
          return response;
        });
      }),
      createHttpLink({
        uri: process.env.REACT_APP_API_URL,
      }),
    ]),
  });
  return client;
}
