import { z } from "zod";
import { unitTypeSchema } from "./unit";

export enum TechProcessCode {
  WOOD_SAWING_SIDE = "WOOD_SAWING_SIDE",
  WOOD_SAWING_END = "WOOD_SAWING_END",
  WOOD_DRILLING = "WOOD_DRILLING",
  WOOD_DRILLING_BLIND = "WOOD_DRILLING_BLIND",
  WOOD_PLANING = "WOOD_PLANING",
  WOOD_GRINDING = "WOOD_GRINDING",
  WOOD_OILING = "WOOD_OILING",
  STEEL_SAWING = "STEEL_SAWING",
  STEEL_GRINDING = "STEEL_GRINDING",
  STEEL_DRILLING = "STEEL_DRILLING",
  STEEL_WELDING = "STEEL_WELDING",
  STEEL_POWDER_COATING = "STEEL_POWDER_COATING",
  TESTING_ASSEMBLY = "TESTING_ASSEMBLY",
  PACKING = "PACKING",
}
export const techProcessCodeSchema = z.nativeEnum(TechProcessCode);

export const techProcessSchema = z.object({
  code: techProcessCodeSchema,
  title: z.string(),
  basicUnit: unitTypeSchema,
  settingUpSeconds: z.number().int(),
});
export type TechProcess = z.infer<typeof techProcessSchema>;
