import { z } from "zod";
import { materialExpandedSchema } from "../../schemas";
import { sideRibTypeSchema } from "../util-types";

import {
  MAX_LENGTH,
  MIN_LENGTH,
  MAX_WIDTH,
  MIN_WIDTH,
  MAX_RACK_HEIGHT,
  MIN_HEIGHT,
  MAX_THICKNESS,
  MIN_THICKNESS,
  MIN_ITEM_WIDTH,
  MAX_ITEM_WIDTH,
  MIN_ITEM_MARGIN,
  MAX_ITEM_MARGIN,
  MIN_FRAME_GAP,
  MAX_FRAME_GAP,
  MIN_ITEMS_AMOUNT,
  MAX_ITEMS_AMOUNT,
} from "./constants";

export const MIN_ITEM_LENGTH = 200;

export const shelfConfigItemSchema = z.object({
  coverageId: z.string().nullable(),
  supportMaterialId: z.string(),
  length: z.number().int().min(MIN_ITEM_LENGTH).max(MAX_LENGTH),
  width: z.number().int().min(MIN_ITEM_WIDTH).max(MAX_ITEM_WIDTH),
  thickness: z.number().int().min(MIN_THICKNESS).max(MAX_THICKNESS),
  marginTop: z.number().int().min(MIN_ITEM_MARGIN).max(MAX_ITEM_MARGIN),
  sideRibType: sideRibTypeSchema,
  sideRibMaterialId: z.string().nullable(),
});
export type ShelfConfigItem = z.infer<typeof shelfConfigItemSchema>;

export const shelfConfigSchema = z.object({
  length: z.number().int().min(MIN_LENGTH).max(MAX_LENGTH),
  width: z.number().int().min(MIN_WIDTH).max(MAX_WIDTH),
  height: z.number().int().min(MIN_HEIGHT).max(MAX_RACK_HEIGHT),

  items: z
    .array(shelfConfigItemSchema)
    .min(MIN_ITEMS_AMOUNT)
    .max(MAX_ITEMS_AMOUNT),

  frameMaterialId: z.string(),
  frameCoverageId: z.string().nullable(),
  frameHeight: z.number().int().min(MIN_HEIGHT).max(MAX_RACK_HEIGHT),
  frameWidth: z.number().int().min(MIN_WIDTH).max(MAX_WIDTH),
  frameGap: z.number().int().min(MIN_FRAME_GAP).max(MAX_FRAME_GAP),
  frameItemsCount: z.number().int().min(2).max(4),
  frameBottomMargin: z.number().int().min(MIN_ITEM_MARGIN).max(MAX_ITEM_MARGIN),
  frameBottomSideRibType: sideRibTypeSchema,
  frameBottomSideRibMaterialId: z.string().nullable(),
  frameFootingId: z.string().nullable().nullable(),

  frameMaterialWidth: z.number().int(),
  frameMaterialHeight: z.number().int(),

  areShelvesSplit: z.boolean(),

  fastenerId: z.string(),
  packageCardboardId: z.string(),
  packageBubbleWrapId: z.string(),
});
export type Config = z.infer<typeof shelfConfigSchema>;

export const shelfAssetsSchema = z.object({
  shelfMaterial: materialExpandedSchema,
  shelfCoverage: z.array(materialExpandedSchema),
  frameMaterials: z.array(materialExpandedSchema),
  frameCoverage: z.array(materialExpandedSchema),
  frameFooting: z.array(materialExpandedSchema),
  ribMaterials: z.array(materialExpandedSchema),
  fasteners: z.array(materialExpandedSchema),
  packageCardboard: z.array(materialExpandedSchema),
  packageBubbleWrap: z.array(materialExpandedSchema),
});
export type Assets = z.infer<typeof shelfAssetsSchema>;

export const shelfVariablesSchema = z.object({
  isRackLike: z.boolean(),
});
export type Variables = z.infer<typeof shelfVariablesSchema>;
