import { useEffect } from "react";
import { OrderEnvironmentData, OrderEnvironmentVars } from "./queries";

export function useOrderUpdateDeliveryCosts(
  data: OrderEnvironmentData | undefined,
  refetch: (arg: Partial<OrderEnvironmentVars>) => any
) {
  useEffect(
    function () {
      if (!data) {
        return;
      }
      const { order } = data.OrderEnvironment;
      if (order.deliveryCostResetAt || order.deliveryCostKopecks === null) {
        refetch({
          orderId: order.id,
          shouldUpdateDeliveryCosts: true,
        });
      }
    },
    [data, refetch]
  );
}
