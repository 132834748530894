import React from "react";
import { black } from "../styles";
import { IconBase, IconProps } from "./base";

export function MinusIcon({ color = black, ...props }: IconProps) {
  // d="M13 5H12V12H6V13H12V19H13V13H19V12H13V5Z"
  return (
    <IconBase {...props}>
      <path
        d="M16 12v1H7v-1z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </IconBase>
  );
}
