import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  grey15,
  grey40,
  black,
  gutters,
  StdContainer,
  Row,
  Cell,
  typographyBody2,
} from "client-lib";
import { useEnvironment } from "src/features/environment";

export const JIVOSITE_BAR_HEIGHT = 40;

const Container = styled.div`
  background-color: ${black};
  margin-top: ${gutters.xxl}px;
  padding: ${gutters.xl}px 0 ${gutters.md}px;
  a {
    text-decoration: none;
    color: ${grey40};
    font-weight: 100;
  }
`;

const Logo = styled.div`
  font-size: 16px;
  color: ${grey15};
  img {
    display: block;
    height: 16px;
  }
`;

const LogoText = styled.div`
  margin-top: ${gutters.xs}px;
  font-weight: 100;
  color: ${grey15};
`;

const LinksArea = styled(Row)`
  margin: ${gutters.md}px 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const Copyright = styled.div([
  typographyBody2,
  css`
    height: ${JIVOSITE_BAR_HEIGHT}px;
    line-height: ${JIVOSITE_BAR_HEIGHT}px;
    font-weight: 100;
    color: ${grey15};
  `,
]);

const foundYear = 2023;
const currentYear = new Date().getFullYear();
const datesText = `${foundYear}${
  foundYear === currentYear ? "" : ` \u2013 ${currentYear}`
}`;

export function Footer() {
  const { environment } = useEnvironment();

  return (
    <Container>
      <StdContainer responsivePadding>
        <Logo>
          <img src="/img/metafabrika-logo.png" alt="Метафабрика" />
        </Logo>
        <LogoText>Cервис для конструирования и производства мебели</LogoText>
        <LinksArea>
          <Cell base={4} tablet={6} phone={12}>
            <Section>
              <Link to="/templates">Шаблоны товаров</Link>
              <Link to="/account/models">Сохраненные модели</Link>
              <Link to="/contacts">Контакты</Link>
            </Section>
          </Cell>
          <Cell base={4} tablet={6} phone={12}>
            <Section>
              <Link to="/privacy-policy">Политика конфиденциальности</Link>
              <Link to="/agreement">Пользовательское соглашение</Link>
              <Link to="/requisites">Реквизиты организации</Link>
            </Section>
          </Cell>
        </LinksArea>
        {environment && (
          <Copyright>
            &copy; {environment.companyRequisites.title} {datesText}
          </Copyright>
        )}
      </StdContainer>
    </Container>
  );
}
