import { DecompositionItem } from "../schemas/decomposition";
import { Material, MaterialExpanded } from "../schemas/material";
import { TechProcessCode, UnitType } from "../schemas";
import { convertUnit } from "../units";
import { TechProcessPool } from "../tech-processes";

// export const WOODEN_PANEL_THICKNESS = [18, 20, 23, 25, 28, 30, 35, 40];
export const WOODEN_PANEL_THICKNESS = [20, 23, 25, 28, 30, 35, 40];

export function getUnusedWoodenPanelThickness(value: number): number | null {
  let lastFitValue = WOODEN_PANEL_THICKNESS[0];
  for (const stdValue of WOODEN_PANEL_THICKNESS) {
    if (stdValue === value) {
      return null;
    }
    if (stdValue > value) {
      if (lastFitValue >= value) {
        return null;
      }
      return value - lastFitValue;
    }
    lastFitValue = stdValue;
  }
  return null;
}

export function getUnusedWoodenPanelWidth(width: number): number | null {
  const remain = Math.min(600 % width, 400 % width);
  return Math.min(remain % 150, remain % 200);
}

export function getUnusedWoodenPanelLength(length: number): number | null {
  const remain = Math.min(3000 % length, 2500 % length, 2000 % length);
  return Math.min(remain % 400, remain % 500);
}

export function parseCustomFlags(flags: string | null): Set<string> {
  if (!flags) {
    return new Set<string>();
  }
  const flagsParsed = flags.split(",").map((s) => s.trim());
  return new Set<string>(flagsParsed);
}

export function getBoxArea(size: [number, number, number]): number {
  return size[0] * size[1] + size[1] * size[2] + size[2] * size[0];
}

export function createMaterialItem(
  material: Material,
  unitAmount: number,
  unitType: UnitType,
  options: {
    amount?: number;
    title?: string;
  } = {}
): DecompositionItem {
  const convertedUnitAmount = convertUnit(
    unitType,
    material.basicUnit,
    unitAmount
  );
  return {
    title: options.title || material.title,
    materialId: material.id,
    techProcessCode: null,
    unitType: material.basicUnit,
    unitAmount: convertedUnitAmount,
    massKg: material.unitMassKg * convertedUnitAmount,
    amount: options.amount || 1,
  };
}

export function createTechProcessItem(
  techProcessCode: TechProcessCode,
  unitAmount: number,
  unitType: UnitType,
  options: {
    amount?: number;
    title?: string;
  } = {}
): DecompositionItem {
  const techProcess = TechProcessPool[techProcessCode];
  const convertedUnitAmount = convertUnit(
    unitType,
    techProcess.basicUnit,
    unitAmount
  );
  return {
    title: options.title || techProcess.title,
    materialId: null,
    techProcessCode,
    unitType: techProcess.basicUnit,
    unitAmount: convertedUnitAmount,
    massKg: 0,
    amount: options.amount || 1,
  };
}

export type MaterialDimensions = {
  d1: number | null;
  d2: number | null;
};

export function getDimensionsMm(material?: MaterialExpanded | null) {
  return material
    ? {
        d1: material.mmDimension1 ? material.mmDimension1 : null,
        d2: material.mmDimension2 ? material.mmDimension2 : null,
      }
    : { d1: null, d2: null };
}
