import { z } from "zod";
import {
  checkSchema,
  phoneSchema,
  emailSchema,
  standardAttributes,
} from "../services";

export enum OrderStatus {
  CART = "CART",
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  FACTORY_CANCELED = "FACTORY_CANCELED",
  CUSTOMER_CANCELED = "CUSTOMER_CANCELED",
  PRODUCTION = "PRODUCTION",
  DELIVERY = "DELIVERY",
  COMPLETED = "COMPLETED",
}
export const orderStatusSchema = z.nativeEnum(OrderStatus);

export const ORDER_STATUS_TITLES: Record<OrderStatus, string> = {
  [OrderStatus.CART]: "Корзина",
  [OrderStatus.AWAITING_PAYMENT]: "Ожидание платежа",
  [OrderStatus.FACTORY_CANCELED]: "Отменено фабрикой",
  [OrderStatus.CUSTOMER_CANCELED]: "Отменено заказчиком",
  [OrderStatus.PRODUCTION]: "На производстве",
  [OrderStatus.DELIVERY]: "Доставляется",
  [OrderStatus.COMPLETED]: "Завершен",
};

export const orderCustomerStrictSchema = z.object({
  deliveryLocationId: z.string(),
  deliveryRegion: z.string(),
  deliveryRecipient: z.string(),
  deliveryRecipientPhone: phoneSchema,
  deliveryRecipientEmail: emailSchema.nullable(),
});
export type OrderCustomerStrict = z.infer<typeof orderCustomerStrictSchema>;

export const orderCustomerSchemaSource = {
  deliveryLocationId: z.string().nullable(),
  deliveryRegion: z.string().nullable(),
  deliveryRecipient: z.string().nullable(),
  deliveryRecipientPhone: phoneSchema.nullable(),
  deliveryRecipientEmail: emailSchema.nullable(),
};

export const orderCustomerSchema = z.object(orderCustomerSchemaSource);

export const orderSchema = z
  .object({
    ...standardAttributes,
    validationMessage: z.string().nullable(),
    status: orderStatusSchema,
    teamId: z.string().uuid(),

    teamNumber: z.number().int().nullable(),
    fixedAt: z.date().nullable(),
    deliveryCostResetAt: z.date().nullable(),
    deliveryCostKopecks: z.number().int().nonnegative().nullable(),
    deliveryLocationEstimateKopecks: z.number().int().nonnegative().nullable(),
    completionEstimationDays: z.number().int().nullable(),
  })
  .extend(orderCustomerSchemaSource);

checkSchema(orderSchema);
export type Order = typeof orderSchema["_type"];

export const orderDeliveryPatchSchema = orderSchema.pick({
  deliveryLocationId: true,
  deliveryRegion: true,
  deliveryRecipient: true,
  deliveryRecipientPhone: true,
  deliveryRecipientEmail: true,
});
export type OrderDeliveryPatch = z.infer<typeof orderDeliveryPatchSchema>;
