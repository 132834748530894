import React, { memo } from "react";
import styled from "@emotion/styled";
import { grey10, grey20, gutters, borderRadius } from "client-lib";
import { Slide, SlideContainer, Header, Typo140 } from "src/features/layout";

const Body = styled.div``;

const Comment = styled(Typo140)`
  margin-bottom: ${gutters.xxl}px;
  margin-right: ${gutters.xl}px;
  border-radius: ${borderRadius.lg}px;
  background-color: ${grey10};
  padding: ${gutters.xl}px;
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  ul {
    margin-top: ${gutters.lg}px;
    li {
      margin-left: 1em;
      margin-bottom: ${gutters.md}px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const WoodOilingSlide = memo(function WoodOilingSlide() {
  return (
    <Slide style={{ backgroundColor: grey20 }}>
      <SlideContainer>
        <Header>Обработка дерева</Header>
        <Body>
          <Comment>
            Для обработки столешниц и других деревянных элементов мы используем
            масло премиальных марок – Osmo, Rubio Monocoat, Biofa.
          </Comment>
          <Comment>
            <p>
              В отличие от красок, лаков и других пленкообразующих видов
              покрытия – масло проникает вглубь древесины, создавая надежную
              защиту. Оно полностью заполняет все поры – делая их недоступными
              для пыли, грязи и пролитых жидкостей.
            </p>
            <p>
              Кроме того, масло обеспечивает ремонтопригодность поверхности при
              глубоких механических повреждениях. Если повредить защитный слой
              лака или краски, всю поверхность, скорее всего, придется покрывать
              заново – отшелушивания оставшейся части покрытия не избежать. В
              случае с маслом это не проблема – достаточно дополнительно
              пропитать поврежденный участок, полностью восстановив защиту.
            </p>
          </Comment>
          <Comment>
            Различные марки масел могут отличаться по цене в десятки раз. Почему
            мы используем только премиум-сегмент? На это есть три причины:
            <ul>
              <li>
                Многие дешевые масла начинают со временем &laquo;липнуть&raquo;
                – это может оказаться крайне неприятным недостатком, особенно
                для столешницы. Масла, которые используются для изделий
                Метафабрики, такого недостатка лишены.
              </li>
              <li>
                Технология нанесения дешевых марок масел предполагает
                крупнозернистую шлифовку. Это связано с тем, что в мелкие поры
                данные виды масел не впитываются. В результате поверхность
                получается шероховатой. Масла, которые используем мы, требует
                шлифовки самым мелким зерном – поэтому мебель Метафабрики
                потрясающе гладкая на ощупь.
              </li>
              <li>
                У масел премиум марок гораздо сильнее разница между воздействием
                на мягкую и твердую часть древесных волокон. Поэтому они намного
                четче проявляют фактуру дерева, подчеркивая великолепный
                рисунок, созданный самой природой.
              </li>
            </ul>
          </Comment>
        </Body>
      </SlideContainer>
    </Slide>
  );
});
