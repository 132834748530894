import { z } from "shared";
import { Decomposition } from "../schemas/decomposition";

export enum TemplateConfigControlType {
  INT = "INT",
  INT_SLIDER = "INT_SLIDER",
  IMG_PICKER = "IMG_PICKER",
  SELECTOR = "SELECTOR",
  SELECTOR_NUMERIC = "SELECTOR_NUMERIC",
  CHECKBOX = "CHECKBOX",
}

export interface TemplateConfigItem {
  title: string;
  value: string;
  img?: string;
}

export interface TemplateConfigControl {
  name: string;
  label?: string;
  type: TemplateConfigControlType;
  min?: number;
  max?: number;
  nullable?: boolean;
  hidden?: boolean;
  items?: TemplateConfigItem[];
  options?: Record<string, any>;
}

export interface TemplateConfigSimpleGroup {
  title: string;
  controls: TemplateConfigControl[];
}

export interface TemplateConfigArrayGroup {
  title: string;
  arrayConfig: {
    amountControlTitle: string;
    itemTitle: (index: number) => string;
    addTitle: string;
    name: string;
    min: number;
    max: number;
  };
  controls: (index: number, _amount: number) => TemplateConfigControl[];
}
export type TemplateConfigControlGroup =
  | TemplateConfigArrayGroup
  | TemplateConfigSimpleGroup;

export function isTemplateConfigArrayGroup(
  group: any
): group is TemplateConfigArrayGroup {
  return Boolean(group.arrayConfig);
}

export interface TemplateModel<TemplateConfig, Assets, Variables> {
  configSchema: z.Schema<TemplateConfig>;
  configInitialValues: (assets: Assets) => TemplateConfig;
  assetsSchema: z.Schema<Assets>;
  variablesSchema: z.Schema<Variables>;
  variablesDefaults: Variables;
}

export interface TemplateController<TemplateConfig, Assets, Variables> {
  getControlGroups: (
    configValues: TemplateConfig,
    assets: Assets,
    variables: Variables
  ) => TemplateConfigControlGroup[];
  onAttributeChange?: (
    name: string,
    value: any,
    values: TemplateConfig,
    assets: Assets,
    variables: Variables,
    setValues: (v: TemplateConfig) => void
  ) => void;
}

export interface TemplateBack<TemplateConfig, Assets, Variables> {
  model: TemplateModel<TemplateConfig, Assets, Variables>;
  controller: TemplateController<TemplateConfig, Assets, Variables>;
  getDecomposition: (
    config: TemplateConfig,
    assets: Assets,
    variables: Variables
  ) => Decomposition;
}
