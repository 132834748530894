import { z } from "zod";
import { decompositionItemSchema } from "../decomposition/index";
import { pricingLimitSchema } from "../pricing-limit";
import { costKopecksSchema } from "../pricing-cost";

export const pricingValueSchema = z.object({
  limit: pricingLimitSchema,
  costKopecks: costKopecksSchema,
});
export type PricingValue = z.infer<typeof pricingValueSchema>;

export const pricingItemSchema = decompositionItemSchema.extend({
  unitPricingValues: z.array(pricingValueSchema),
});
export type PricingItem = z.infer<typeof pricingItemSchema>;
