import React, { useCallback } from "react";
import { TemplateConfigItem } from "shared";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useField } from "formik";
import {
  gutters,
  primary,
  grey20,
  grey30,
  borderRadius,
  typographyBody2,
} from "client-lib/styles";

const SAMPLE_SIZE = 40;

const Container = styled.div`
  margin-bottom: ${gutters.md}px;
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  margin-right: ${gutters.xs}px;
  margin-bottom: ${gutters.xs}px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ItemSampleWrapper = styled.div<{ isActive: boolean }>(
  ({ isActive }) => css`
    padding: ${gutters.xs}px;
    border-radius: ${borderRadius.md}px;
    border: 1px solid ${isActive ? primary : grey30};
  `
);

const ItemSample = styled.div<{ img?: string }>(
  ({ img }) => css`
    border-radius: ${borderRadius.md}px;
    width: ${SAMPLE_SIZE}px;
    height: ${SAMPLE_SIZE}px;
    background-color: ${grey20};
    ${img ? `background-image: url(${img})` : ""};
  `
);

const ItemLabel = styled.div([typographyBody2]);

interface Props {
  readOnly: boolean;
  label?: string;
  name: string;
  nullable?: boolean;
  items: TemplateConfigItem[];
  onChange?: (value: string | null) => void;
}

export function ImgPicker({
  readOnly,
  label,
  name,
  nullable,
  items,
  onChange,
}: Props) {
  const [{ value }, , { setValue }] = useField<string | null>(name);

  const handleChange = useCallback(
    (newValue: string | null) => {
      if (readOnly) {
        return;
      }
      setValue(newValue);
      onChange?.(newValue);
    },
    [setValue, onChange, readOnly]
  );

  return (
    <Container>
      {label && <label>{label}</label>}
      <Items>
        {nullable && (
          <Item onClick={() => handleChange(null)}>
            <ItemSampleWrapper isActive={value === null}>
              <ItemSample />
            </ItemSampleWrapper>
            <ItemLabel>Нет</ItemLabel>
          </Item>
        )}
        {items.map((item) => (
          <Item key={item.value} onClick={() => handleChange(item.value)}>
            <ItemSampleWrapper isActive={value === item.value}>
              <ItemSample img={item.img} />
            </ItemSampleWrapper>
            <ItemLabel>{item.title}</ItemLabel>
          </Item>
        ))}
      </Items>
    </Container>
  );
}
