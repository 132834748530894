import styled from "@emotion/styled";
import { mqMin, mqMax } from "client-lib";

export const StickyNavigationContainer = styled.div`
  ${mqMin.tablet} {
    position: sticky;
    width: 25vw;
    height: 100vh;
    top: 0;
  }
  ${mqMax.tablet} {
    display: none;
  }
`;

export const WithNavigationGroup = styled.div`
  ${mqMin.tablet} {
    display: flex;
    align-items: top;
  }
`;

export const WithNavigationContent = styled.div`
  ${mqMin.tablet} {
    width: 75vw;
  }
`;
