import {
  Decomposition,
  DecompositionSection,
  TechProcessCode,
  UnitType,
} from "../../schemas";
import { Assets, Config, Variables } from "./types";
import {
  getUnusedWoodenPanelLength,
  getUnusedWoodenPanelThickness,
  getUnusedWoodenPanelWidth,
  getBoxArea,
  createMaterialItem,
  createTechProcessItem,
} from "../utils";

const DOWEL_RADIUS = 8;
const DRILLING_AREA = Math.PI * DOWEL_RADIUS ** 2;
const DRILLING_DEPTH = 60;
const DRILLING_VOLUME = DRILLING_AREA * DRILLING_DEPTH;

function addWoodenPanelToSection({
  section,
  shelfCoverageId,
  widthMm,
  lengthMm,
  thicknessMm,
  assets,
  amount,
}: {
  section: DecompositionSection;
  shelfCoverageId: string | null;
  widthMm: number;
  lengthMm: number;
  thicknessMm: number;
  assets: Assets;
  amount: number;
}) {
  const unusedWoodenPanelThickness = getUnusedWoodenPanelThickness(thicknessMm);
  const blankThickness = thicknessMm + (unusedWoodenPanelThickness || 0);
  const volume = blankThickness * lengthMm * widthMm;

  section.items.push(
    createMaterialItem(assets.shelfMaterial, volume, UnitType.MILLIMETER_3, {
      amount,
      title: `${assets.shelfMaterial.title} ${widthMm}x${lengthMm}x${blankThickness}мм`,
    })
  );

  if (unusedWoodenPanelThickness) {
    const unusedVolume = unusedWoodenPanelThickness * lengthMm * widthMm;

    section.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_PLANING,
        unusedVolume,
        UnitType.MILLIMETER_3,
        {
          amount,
          title: `Строжка мебельного щита ${unusedWoodenPanelThickness}мм`,
        }
      )
    );
  }

  const unusedWoodenPanelWidth = getUnusedWoodenPanelWidth(lengthMm);
  if (unusedWoodenPanelWidth) {
    const unusedVolume = blankThickness * unusedWoodenPanelWidth * widthMm;

    section.items.push(
      createMaterialItem(
        assets.shelfMaterial,
        unusedVolume,
        UnitType.MILLIMETER_3,
        {
          amount,
          title: `Отходы материала по ширине: ${assets.shelfMaterial.title} ${widthMm}x${unusedWoodenPanelWidth}x${blankThickness}мм`,
        }
      )
    );
  }
  const unusedWoodenPanelLength = getUnusedWoodenPanelLength(widthMm);
  if (unusedWoodenPanelLength) {
    const unusedVolume = blankThickness * lengthMm * unusedWoodenPanelLength;
    section.items.push(
      createMaterialItem(
        assets.shelfMaterial,
        unusedVolume,
        UnitType.MILLIMETER_3,
        {
          amount,
          title: `Отходы материала по длине: ${assets.shelfMaterial.title} ${unusedWoodenPanelLength}x${lengthMm}x${blankThickness}мм`,
        }
      )
    );
  }

  if (lengthMm !== 600 && lengthMm !== 400) {
    section.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_SAWING_SIDE,
        widthMm * thicknessMm,
        UnitType.MILLIMETER_2,
        {
          amount,
          title: `Опиливание края, сечение ${widthMm}x${thicknessMm}мм`,
        }
      )
    );
  }

  if (widthMm !== 3000) {
    section.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_SAWING_END,
        lengthMm * thicknessMm,
        UnitType.MILLIMETER_2,
        {
          amount,
          title: `Опиливание торца, сечение ${lengthMm}x${thicknessMm}мм`,
        }
      )
    );
  }

  const area =
    2 * thicknessMm * lengthMm +
    2 * thicknessMm * widthMm +
    2 * lengthMm * widthMm;

  section.items.push(
    createTechProcessItem(
      TechProcessCode.WOOD_GRINDING,
      lengthMm * widthMm,
      UnitType.MILLIMETER_2,
      {
        amount,
        title: `Шлифовка лицевой поверхности`,
      }
    )
  );

  section.items.push(
    createTechProcessItem(
      TechProcessCode.WOOD_GRINDING,
      thicknessMm * widthMm,
      UnitType.MILLIMETER_2,
      {
        amount,
        title: `Шлифовка боковой поверхности`,
      }
    )
  );

  section.items.push(
    createTechProcessItem(
      TechProcessCode.WOOD_GRINDING,
      thicknessMm * lengthMm,
      UnitType.MILLIMETER_2,
      {
        amount,
        title: `Шлифовка торцевой поверхности`,
      }
    )
  );

  const coverage =
    shelfCoverageId &&
    assets.shelfCoverage.find((c) => c.id === shelfCoverageId);
  if (coverage) {
    section.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_OILING,
        lengthMm * widthMm,
        UnitType.MILLIMETER_2,
        {
          amount,
          title: `Покрытие маслом лицевой поверхности`,
        }
      )
    );

    section.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_OILING,
        thicknessMm * widthMm,
        UnitType.MILLIMETER_2,
        {
          amount,
          title: `Покрытие маслом торцевой поверхности`,
        }
      )
    );

    section.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_OILING,
        thicknessMm * lengthMm,
        UnitType.MILLIMETER_2,
        {
          amount,
          title: `Покрытие маслом боковой поверхности`,
        }
      )
    );

    section.items.push(
      createMaterialItem(coverage, area, UnitType.MILLIMETER_2, {
        amount,
      })
    );
  }
}

export function getDecomposition(
  config: Config,
  assets: Assets,
  _variables: Variables
): Decomposition {
  const sections: DecompositionSection[] = [];

  for (let i = 0; i < config.items.length; i++) {
    const item = config.items[i];
    const section: DecompositionSection = {
      title: `Полка #${i + 1}`,
      items: [],
    };
    sections.push(section);

    addWoodenPanelToSection({
      section,
      shelfCoverageId: item.coverageId,
      lengthMm: item.width - 2 * item.thickness,
      widthMm: config.depth,
      thicknessMm: item.thickness,
      assets,
      amount: 2,
    });
    addWoodenPanelToSection({
      section,
      shelfCoverageId: item.coverageId,
      lengthMm: item.height,
      widthMm: config.depth,
      thicknessMm: item.thickness,
      assets,
      amount: 2,
    });

    section.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_DRILLING_BLIND,
        DRILLING_VOLUME,
        UnitType.MILLIMETER_3,
        {
          amount: 12,
        }
      )
    );

    section.items.push(createMaterialItem(assets.dowel, 12, UnitType.PIECE));

    section.items.push(createMaterialItem(assets.hanger, 2, UnitType.PIECE));

    section.items.push(createMaterialItem(assets.fastener, 4, UnitType.PIECE));
  }

  const miscSection: DecompositionSection = {
    title: "Упаковка",
    items: [],
  };
  sections.push(miscSection);

  const dimensions: [number, number, number] = [
    config.items[0].width,
    config.items[0].height,
    config.depth,
  ];

  let totalPackingAreaMm = 0;
  const cardboard = assets.packageCardboard.find(
    (m) => m.id === config.packageCardboardId
  );
  if (cardboard) {
    const cardboardAreaMm = getBoxArea(dimensions) * 2;

    miscSection.items.push(
      createMaterialItem(cardboard, cardboardAreaMm, UnitType.MILLIMETER_2, {
        title: `Внешняя упаковка (${cardboard.title})`,
      })
    );

    totalPackingAreaMm += cardboardAreaMm;
  }
  const bubbleWrap = assets.packageBubbleWrap.find(
    (m) => m.id === config.packageBubbleWrapId
  );
  if (bubbleWrap) {
    const bubbleWrapAreaMm = getBoxArea(dimensions) * 2;
    const layers = 2;
    miscSection.items.push(
      createMaterialItem(
        bubbleWrap,
        bubbleWrapAreaMm * layers,
        UnitType.MILLIMETER_2,
        {
          title: `Внешняя упаковка (${bubbleWrap.title})`,
        }
      )
    );

    totalPackingAreaMm += bubbleWrapAreaMm * layers;
  }

  miscSection.items.push(
    createTechProcessItem(
      TechProcessCode.PACKING,
      totalPackingAreaMm,
      UnitType.MILLIMETER_2,
      {
        title: `Работы по упаковке`,
      }
    )
  );

  miscSection.items.push(
    createTechProcessItem(
      TechProcessCode.TESTING_ASSEMBLY,
      0.1 * config.items.length,
      UnitType.HOUR
    )
  );

  let massKg = 0;
  for (const section of sections) {
    for (const item of section.items) {
      if (item.massKg) {
        massKg += item.massKg;
      }
    }
  }

  return {
    sections,
    package: {
      massKg,
      lengthMm: dimensions[0],
      heightMm: dimensions[1],
      widthMm: dimensions[2],
    },
  };
}
