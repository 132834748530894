import React, { useMemo } from "react";
import { MaterialExpanded, SideRibType, getDimensionsMm } from "shared";
import { Vector3Tuple } from "three";
import { Box } from "../../objects";
import { SupportItem } from "./support-item";
import { SideRib } from "./frame-side-rib";
import { useShelf } from "./shelf-context";

export function FrameItem({
  material,
  coverage,
  position,
  rotation,
}: {
  material: MaterialExpanded;
  coverage: MaterialExpanded | null;
  position?: Vector3Tuple;
  rotation?: Vector3Tuple;
}) {
  const { config, assets, variables } = useShelf();
  const mainPipe = getDimensionsMm(material);
  const surface = coverage?.texture1 || material.texture1;

  const sideRibs = useMemo<JSX.Element[]>(() => {
    const result: JSX.Element[] = [];

    if (!mainPipe.d1 || !mainPipe.d2 || !surface) {
      return result;
    }

    let topY = mainPipe.d2;

    const bottomSideRibMaterial =
      assets.ribMaterials.find(
        (m) => m.id === config.frameBottomSideRibMaterialId
      ) || null;
    const bottomSideRibPipe = getDimensionsMm(bottomSideRibMaterial);

    if (
      bottomSideRibPipe.d1 &&
      bottomSideRibPipe.d2 &&
      variables.isRackLike &&
      config.frameBottomMargin
    ) {
      const firstItem = config.items[0];
      const supportMaterial =
        assets.frameMaterials.find(
          (m) => m.id === firstItem.supportMaterialId
        ) || null;
      const supportPipe = getDimensionsMm(supportMaterial);

      const availableHeight = config.frameBottomMargin - (supportPipe.d2 || 0);

      switch (config.frameBottomSideRibType) {
        case SideRibType.UP:
        case SideRibType.DOWN:
          result.push(
            <SideRib
              key="btm"
              type={config.frameBottomSideRibType}
              y={topY}
              sideRibPipe={bottomSideRibPipe}
              mainPipe={mainPipe}
              surface={surface}
              availableHeight={availableHeight}
              availableWidth={config.frameWidth - 2 * mainPipe.d2}
            />
          );
          break;
        case SideRibType.BOTH:
          result.push(
            <SideRib
              key="btm/down"
              type={SideRibType.DOWN}
              y={topY}
              sideRibPipe={bottomSideRibPipe}
              mainPipe={mainPipe}
              surface={surface}
              availableHeight={availableHeight}
              availableWidth={config.frameWidth - 2 * mainPipe.d2}
            />,
            <SideRib
              key="btm/up"
              type={SideRibType.UP}
              y={topY}
              sideRibPipe={bottomSideRibPipe}
              mainPipe={mainPipe}
              surface={surface}
              availableHeight={availableHeight}
              availableWidth={config.frameWidth - 2 * mainPipe.d2}
            />
          );
          break;
      }
    }

    if (variables.isRackLike) {
      topY += config.frameBottomMargin;
    }

    for (let index = 0; index < config.items.length; index++) {
      const item = config.items[index];

      const gap =
        index >= config.items.length - 1
          ? config.frameHeight - topY - mainPipe.d2
          : item.marginTop;

      const sideRibMaterial =
        assets.ribMaterials.find((m) => m.id === item.sideRibMaterialId) ||
        null;
      const sideRibPipe = getDimensionsMm(sideRibMaterial);

      const supportMaterial =
        assets.frameMaterials.find((m) => m.id === item.supportMaterialId) ||
        null;

      const supportPipe = getDimensionsMm(supportMaterial);
      const availableHeight = gap - item.thickness;
      if (sideRibPipe.d1 && sideRibPipe.d2 && supportPipe.d2 && mainPipe.d2) {
        switch (item.sideRibType) {
          case SideRibType.UP:
          case SideRibType.DOWN:
            result.push(
              <SideRib
                key={index}
                type={item.sideRibType}
                y={topY + item.thickness}
                sideRibPipe={sideRibPipe}
                mainPipe={mainPipe}
                surface={surface}
                availableHeight={availableHeight}
                availableWidth={config.frameWidth - 2 * mainPipe.d2}
              />
            );
            break;
          case SideRibType.BOTH:
            result.push(
              <SideRib
                key={`${index}/up`}
                type={SideRibType.UP}
                y={topY + item.thickness}
                sideRibPipe={sideRibPipe}
                mainPipe={mainPipe}
                surface={surface}
                availableHeight={availableHeight}
                availableWidth={config.frameWidth - 2 * mainPipe.d2}
              />,
              <SideRib
                key={`${index}/down`}
                type={SideRibType.DOWN}
                y={topY + item.thickness}
                sideRibPipe={sideRibPipe}
                mainPipe={mainPipe}
                surface={surface}
                availableHeight={availableHeight}
                availableWidth={config.frameWidth - 2 * mainPipe.d2}
              />
            );
        }
      }

      topY += item.marginTop + item.thickness;
    }

    return result;
  }, [config, assets]);

  const supports = useMemo<JSX.Element[]>(() => {
    const result: JSX.Element[] = [];
    if (!mainPipe.d1 || !mainPipe.d2) {
      return result;
    }
    let topY = mainPipe.d2;
    if (variables.isRackLike) {
      topY += config.frameBottomMargin;
    }

    for (let index = 0; index < config.items.length; index++) {
      const item = config.items[index];

      if (index === 0 && !variables.isRackLike) {
        topY += item.thickness + item.marginTop;
        continue;
      }
      const supportMaterial =
        assets.frameMaterials.find((m) => m.id === item.supportMaterialId) ||
        null;
      if (!supportMaterial) {
        continue;
      }
      const supportPipe = getDimensionsMm(supportMaterial);

      if (!supportPipe.d2) {
        continue;
      }

      result.push(
        <SupportItem
          key={index}
          coverage={coverage}
          supportMaterial={supportMaterial}
          frameMaterial={material}
          topY={topY}
        />
      );
      topY += item.thickness + item.marginTop;
    }
    return result;
  }, [config, assets]);

  if (!mainPipe.d1 || !mainPipe.d2 || !surface) {
    return null;
  }

  const verticalBarHeight = config.frameHeight - mainPipe.d2 * 2;
  return (
    <group position={position} rotation={rotation}>
      {supports}
      {sideRibs}
      <Box
        key="top-bar"
        xSize={mainPipe.d1}
        ySize={mainPipe.d2}
        zSize={config.frameWidth}
        defaultSurface={surface}
        offset={[0, config.frameHeight - mainPipe.d2 / 2, 0]}
      />
      <Box
        key="bottom-bar"
        xSize={mainPipe.d1}
        ySize={mainPipe.d2}
        zSize={config.frameWidth}
        defaultSurface={surface}
        offset={[0, mainPipe.d2 / 2, 0]}
      />
      <Box
        key="close-vertical-bar"
        xSize={mainPipe.d1}
        ySize={verticalBarHeight}
        zSize={mainPipe.d2}
        defaultSurface={surface}
        offset={[
          0,
          verticalBarHeight / 2 + mainPipe.d2,
          (config.frameWidth - mainPipe.d2) / 2,
        ]}
      />
      <Box
        key="far-vertical-bar"
        xSize={mainPipe.d1}
        ySize={verticalBarHeight}
        zSize={mainPipe.d2}
        defaultSurface={surface}
        offset={[
          0,
          verticalBarHeight / 2 + mainPipe.d2,
          -(config.frameWidth - mainPipe.d2) / 2,
        ]}
      />
    </group>
  );
}
