export enum ErrorCode {
  UNAUTHENTICATED = "UNAUTHENTICATED",
  FORBIDDEN = "FORBIDDEN",
  BAD_USER_INPUT = "BAD_USER_INPUT",
  GRAPHQL_VALIDATION_FAILED = "GRAPHQL_VALIDATION_FAILED",

  // project specific errors
  NOT_FOUND = "NOT_FOUND",
  VERIFICATION = "VERIFICATION",
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.message = message;
    this.name = ErrorCode.NOT_FOUND;
  }
}

export class ForbiddenError extends Error {
  constructor(message: string = "Forbidden") {
    super(message);
    this.message = message;
    this.name = ErrorCode.FORBIDDEN;
  }
}
