import React, { useContext, useMemo } from "react";
import { SceneOptions } from "src/features/template-editor/types";

interface SceneValue {
  options: SceneOptions;
}

const SceneContext = React.createContext<SceneValue>({
  options: {
    showMeasurements: false,
  },
});

export function useScene(): SceneValue {
  return useContext<SceneValue>(SceneContext);
}

export function useSceneOptions(): SceneOptions {
  return useScene().options;
}

interface Props {
  options: SceneOptions;
  children: JSX.Element;
}

export function SceneProvider({ options, children }: Props) {
  const value = useMemo<SceneValue>(
    () => ({
      options,
    }),
    [options]
  );

  return (
    <SceneContext.Provider value={value}>{children}</SceneContext.Provider>
  );
}
