import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
} from "react";
import { Toast } from "./types";

type Timeout = ReturnType<typeof setTimeout>;

const TOAST_TIMEOUT = 3000;

type ToasterValue = {
  pushErrorToast: (message: string) => void;
  pushToast: (toast: Toast) => void;
  onMouseEnter: VoidFunction;
  onMouseLeave: VoidFunction;
  clear: () => void;
  toast: Toast | null;
};

const ToasterContext = React.createContext<ToasterValue>({
  pushErrorToast: () => {},
  pushToast: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  clear: () => {},
  toast: null,
});

export function useToaster(): ToasterValue {
  return useContext<ToasterValue>(ToasterContext);
}

interface Props {
  children: React.ReactNode;
}

export function ToasterProvider({ children }: Props) {
  const [toast, setToast] = useState<Toast | null>(null);
  const timeoutRef = useRef<Timeout | null>(null);

  const resetTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const startTimeout = useCallback(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(clear, TOAST_TIMEOUT);
  }, [resetTimeout]);

  const clear = useCallback(() => {
    resetTimeout();
    setToast(null);
  }, [resetTimeout]);

  const pushToast = useCallback(
    (newToast: Toast) => {
      startTimeout();
      setToast(newToast);
    },
    [clear, startTimeout]
  );

  const pushErrorToast = useCallback(
    (message: string) => {
      pushToast({
        type: "error",
        message,
      });
    },
    [pushToast]
  );

  const value = useMemo(
    () => ({
      pushToast,
      pushErrorToast,
      clear,
      toast,
      onMouseEnter: resetTimeout,
      onMouseLeave: startTimeout,
    }),
    [pushToast, pushErrorToast, clear, toast, resetTimeout, startTimeout]
  );

  return (
    <ToasterContext.Provider value={value}>{children}</ToasterContext.Provider>
  );
}
