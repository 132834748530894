import gql from "graphql-tag";
import { getFieldsFromSchema } from "../services";
import {
  templateSchema,
  templatePresetSchema,
  templatePresetImageExpandedSchema,
  templateShotActualSchema,
  textureSchema,
  materialExpandedSchema,
  templateAssetSchema,
  templateAssetExpandedSchema,
  Template,
  TemplatePreset,
  TemplatePresetImageExpanded,
  TemplateEnvironment,
  TemplateShotActual,
} from "shared";

export const TEXTURE_FULL_FRAGMENT = gql`
  fragment TextureFullFragment on Texture {
    ${getFieldsFromSchema(textureSchema)}
  }
`;

export const MATERIAL_EXPANDED_FULL_FRAGMENT = gql`
  fragment MaterialExpandedFullFragment on MaterialExpanded {
    ${getFieldsFromSchema(materialExpandedSchema)}
  }
`;

export const TEMPLATE_ASSET_FULL_FRAGMENT = gql`
  fragment TemplateAssetFullFragment on TemplateAsset {
    ${getFieldsFromSchema(templateAssetSchema)}
  }
`;

export const TEMPLATE_ASSET_EXPANDED_FULL_FRAGMENT = gql`
  fragment TemplateAssetExpandedFullFragment on TemplateAssetExpanded {
    ${getFieldsFromSchema(templateAssetExpandedSchema)}
  }
`;

export const TEMPLATE_FULL_FRAGMENT = gql`
  fragment TemplateFullFragment on Template {
    ${getFieldsFromSchema(templateSchema)}
  }
`;

export const TEMPLATE_ENVIRONMENT_FULL_FRAGMENT = gql`
  fragment TemplateEnvironmentFullFragment on TemplateEnvironment {
    id
    template {
      ...TemplateFullFragment
    }
    assets {
      ...TemplateAssetExpandedFullFragment
    }
  }
  ${TEMPLATE_FULL_FRAGMENT}
  ${TEMPLATE_ASSET_EXPANDED_FULL_FRAGMENT}
`;

export const TEMPLATE_PRESET_FULL_FRAGMENT = gql`
  fragment TemplatePresetFullFragment on TemplatePreset {
    ${getFieldsFromSchema(templatePresetSchema)}
  }
`;

export const TEMPLATE_PRESET_IMAGE_EXPANDED_FULL_FRAGMENT = gql`
  fragment TemplatePresetImageExpandedFullFragment on TemplatePresetImageExpanded {
    ${getFieldsFromSchema(templatePresetImageExpandedSchema)}
  }
`;

export const TEMPLATE_SHOT_ACTUAL_FULL_FRAGMENT = gql`
  fragment TemplateShotActualFullFragment on TemplateShotActual {
    ${getFieldsFromSchema(templateShotActualSchema)}
  }
`;

export const TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT = gql`
  fragment TemplatePresetEnvironmentFullFragment on TemplatePresetEnvironment {
    id
    templatePreset {
      ...TemplatePresetFullFragment
    }
    template {
      ...TemplateFullFragment
    }
    templateEnvironment {
      ...TemplateEnvironmentFullFragment
    }
    images {
      ...TemplatePresetImageExpandedFullFragment
    }
  }
  ${TEMPLATE_ENVIRONMENT_FULL_FRAGMENT}
  ${TEMPLATE_PRESET_FULL_FRAGMENT}
  ${TEMPLATE_PRESET_IMAGE_EXPANDED_FULL_FRAGMENT}
`;

export interface TemplatePresetEnvironment {
  id: string;
  templatePreset: TemplatePreset;
  template: Template;
  templateEnvironment: TemplateEnvironment;
  images: TemplatePresetImageExpanded[];
}

export const TEMPLATE_PRESET_ENVIRONMENT_FOR_LISTS_FRAGMENT = gql`
  fragment TemplatePresetEnvironmentForListsFragment on TemplatePresetEnvironment {
    id
    templatePreset {
      ...TemplatePresetFullFragment
    }
    template {
      ...TemplateFullFragment
    }
    images {
      ...TemplatePresetImageExpandedFullFragment
    }
  }
  ${TEMPLATE_FULL_FRAGMENT}
  ${TEMPLATE_PRESET_FULL_FRAGMENT}
  ${TEMPLATE_PRESET_IMAGE_EXPANDED_FULL_FRAGMENT}
`;

export interface TemplatePresetEnvironmentForLists {
  id: string;
  templatePreset: TemplatePreset;
  template: Template;
  images: TemplatePresetImageExpanded[];
}

export const TEMPLATE_SHOT_ENVIRONMENT_FULL_FRAGMENT = gql`
  fragment TemplateShotEnvironmentFullFragment on TemplateShotEnvironment {
    id
    templateShotActual {
      ...TemplateShotActualFullFragment
    }
    templatePresetEnvironment {
      ...TemplatePresetEnvironmentFullFragment
    }
    latestVersionNumber
  }
  ${TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT}
  ${TEMPLATE_SHOT_ACTUAL_FULL_FRAGMENT}
`;

export interface TemplateShotEnvironment {
  id: string;
  templateShotActual: TemplateShotActual;
  templatePresetEnvironment: TemplatePresetEnvironment;
}
