import React, {
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
  forwardRef,
  ForwardedRef,
  MutableRefObject,
} from "react";
import { pick } from "lodash";
import { decode } from "jsonwebtoken";
import { tokenSchemaWithMeta, TokenUser } from "shared";
import { useBoolean } from "client-lib";
import { AuthModal, AuthModalMode } from "src/features/auth/auth-modal";

const LOCAL_STORAGE_KEY_TOKEN = "auth-token";

type AuthenticatedData = {
  isAuthenticated: true;
  isRegistered: boolean;
  token: string;
  user: TokenUser;
};
type NotAuthenticatedData = {
  isAuthenticated: false;
  isRegistered: boolean;
  token: null;
  user: TokenUser | null; // still can have user here, e.g. after token has expired
};

type AuthValue = {
  setToken: (token: string) => void;
  logout: VoidFunction;
  openAuthModal: (mode: AuthModalMode) => void;
} & (NotAuthenticatedData | AuthenticatedData);

const AuthContext = React.createContext<AuthValue>({
  setToken: () => {
    throw new Error("Attempted to setToken on empty context");
  },
  logout: () => {},
  openAuthModal: () => {},
  isAuthenticated: false,
  isRegistered: false,
  user: null,
  token: null,
});

export function useAuth(): AuthValue {
  return useContext(AuthContext);
}

export function useAuthData(): AuthenticatedData {
  const authValue = useContext(AuthContext);
  if (!authValue.isAuthenticated) {
    throw new Error("Expected authenticated user");
  }
  return pick(
    authValue,
    "isAuthenticated",
    "token",
    "user"
  ) as AuthenticatedData;
}

function extractDataFromToken(token: string | null): {
  user: TokenUser | null;
  authExpiredAt: Date | null;
  isAuthenticated: boolean;
  token: string | null;
} {
  try {
    if (token === null) {
      return {
        user: null,
        authExpiredAt: null,
        isAuthenticated: false,
        token,
      };
    }
    const decoded = decode(token, { json: true });
    const parsed = tokenSchemaWithMeta.parse(decoded);
    return {
      user: parsed.user,
      authExpiredAt: new Date(parsed.exp * 1000),
      isAuthenticated: true,
      token,
    };
  } catch (err) {
    return {
      user: null,
      authExpiredAt: null,
      isAuthenticated: false,
      token: null,
    };
  }
}

export interface AuthRef {
  setToken: (token: string) => void;
}

function isMutableRefObject<T>(ref: any): ref is MutableRefObject<T> {
  return ref && Object.prototype.hasOwnProperty.call(ref, "current");
}

interface Props {
  children: React.ReactNode;
}

export const AuthProvider = forwardRef<AuthRef, Props>(
  ({ children }: Props, ref: ForwardedRef<AuthRef>) => {
    const [authModalMode, setAuthModalMode] = useState<AuthModalMode>("signin");
    const [isAuthOpen, { set: setAuthModalOpen, reset: closeAuthModal }] =
      useBoolean(false);
    const initialValues = extractDataFromToken(
      localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN)
    );
    const [token, setToken] = useState<string | null>(initialValues.token);

    const [user, setUser] = useState<TokenUser | null>(initialValues.user);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
      initialValues.isAuthenticated
    );
    const [authExpiredAt, setAuthExpiredAt] = useState<Date | null>(
      initialValues.authExpiredAt
    );

    useEffect(
      function setExpirationTimeout() {
        const now = new Date();
        const timer: number | null = null;
        if (authExpiredAt && authExpiredAt > now) {
          setIsAuthenticated(true);
          const timeout = authExpiredAt.getTime() - now.getTime();
          if (timeout <= 0x7fffffff) {
            setTimeout(() => {
              setIsAuthenticated(false);
              setToken(null);
            }, timeout);
          }
        }

        return () => {
          if (timer) {
            clearTimeout(timer);
          }
        };
      },
      [authExpiredAt, setIsAuthenticated]
    );

    const handleSetToken = useCallback(
      (
        t: string,
        { saveToLocalStorage = true }: { saveToLocalStorage?: boolean } = {}
      ) => {
        if (saveToLocalStorage) {
          localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, t);
        }

        setToken(t);

        const data = extractDataFromToken(t);
        if (!data.user) {
          throw new Error(`Auth token parsing error: ${t}`);
        }
        setAuthExpiredAt(data.authExpiredAt);
        setUser(data.user);
      },
      [setToken, setAuthExpiredAt, setUser]
    );

    const handleLogout = useCallback(() => {
      localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);

      setToken(null);
      setAuthExpiredAt(null);
      setUser(null);
      setIsAuthenticated(false);
    }, [setToken, setAuthExpiredAt, setUser]);

    const openAuthModal = useCallback(
      (mode: AuthModalMode) => {
        setAuthModalMode(mode);
        setAuthModalOpen();
      },
      [setAuthModalOpen]
    );

    useEffect(
      function onLoad() {
        const t = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
        if (t === null) {
          return;
        }
        try {
          handleSetToken(t, { saveToLocalStorage: false });
        } catch (err) {}
      },
      [handleSetToken]
    );

    const value = useMemo<AuthValue>(() => {
      return {
        isAuthenticated,
        isRegistered: isAuthenticated && user && !user.isGuest,
        user,
        token,
        setToken: handleSetToken,
        logout: handleLogout,
        openAuthModal,
      } as AuthValue;
    }, [
      isAuthenticated,
      user,
      token,
      handleSetToken,
      handleLogout,
      openAuthModal,
    ]);

    if (isMutableRefObject(ref)) {
      const refSetToken = value.setToken;
      // eslint-disable-next-line no-param-reassign
      ref.current = ref.current || { setToken: refSetToken };
      // eslint-disable-next-line no-param-reassign
      ref.current.setToken = refSetToken;
    }

    return (
      <AuthContext.Provider value={value}>
        {children}
        {isAuthOpen && (
          <AuthModal
            onCloseRequest={closeAuthModal}
            initialMode={authModalMode}
          />
        )}
      </AuthContext.Provider>
    );
  }
);
