import React from "react";
import { Pricing } from "shared";
import { Modal, Table, TH, THead, TR } from "client-lib/ui";
import { PricingSection } from "./pricing-section";
import { SectionPrices } from "./types";

interface Props {
  title?: string;
  onCloseRequest: () => void;
  pricing: Pricing;
  sectionPrices: SectionPrices;
  index: number;
}

export function PricingSectionModal({ title, onCloseRequest, ...rest }: Props) {
  return (
    <Modal
      title={title || "Подробная смета на производство"}
      onCloseRequest={onCloseRequest}
    >
      <Table>
        <THead>
          <TR>
            <TH openTop>Наименование</TH>
            <TH openTop>Единица</TH>
            <TH openTop>Цена ед.</TH>
            <TH openTop>Объем</TH>
            <TH openTop>Кол-во</TH>
            <TH openTop>Стоимость</TH>
          </TR>
        </THead>
        <PricingSection {...rest} postline />
      </Table>
    </Modal>
  );
}
