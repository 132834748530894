import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  TemplatePresetPageData,
  TemplatePresetPageQuery,
  TemplatePresetPageVars,
} from "./queries";
import { TemplateLayout } from "./template-layout";

export function TemplatePresetPage() {
  const {
    params: { templatePresetId },
  } = useRouteMatch<{ templatePresetId: string }>();

  const { data } = useQuery<TemplatePresetPageData, TemplatePresetPageVars>(
    TemplatePresetPageQuery,
    {
      variables: {
        templatePresetId,
      },
    }
  );

  return (
    <TemplateLayout
      templatePreset={data?.TemplatePresetEnvironment.templatePreset}
      templateEnvironment={data?.TemplatePresetEnvironment.templateEnvironment}
      templateShotActual={null}
    />
  );
}
