import { z } from "zod";
import { decompositionSectionSchema } from "../decomposition";
import { pricingItemSchema } from "./pricing-item";

export const pricingSectionSchema = decompositionSectionSchema
  .omit({
    items: true,
  })
  .extend({
    items: z.array(pricingItemSchema),
  });
export type PricingSection = z.infer<typeof pricingSectionSchema>;
