import React, { useRef } from "react";
import { DecompositionPackage, Pricing } from "shared";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  borderGrey,
  grey10,
  white,
  gutters,
  typographyCondensed,
  LARGE_ITEM_HEIGHT,
  Spinner,
} from "client-lib/styles";
import { PricingDelivery } from "src/features/delivery";
import { CartButton } from "src/features/order";
import { ContextAmountSelector } from "./context-amount-selector";
import { PricingBrief } from "./pricing-brief";

const ACTION_BAR_HEIGHT = LARGE_ITEM_HEIGHT;
const HEADER_BAR_HEIGHT = LARGE_ITEM_HEIGHT;

const Container = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  border-left: 2px solid ${borderGrey};
`;

const HeaderBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${HEADER_BAR_HEIGHT}px;
  padding: 0 ${gutters.lg}px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${borderGrey};
  box-sizing: border-box;
  background-color: ${white};
  h2 {
    margin: 0;
  }
`;

const Content = styled.div`
  position: absolute;
  top: ${HEADER_BAR_HEIGHT}px;
  left: 0;
  right: 0;
  padding: 0 ${gutters.lg}px;
  bottom: ${ACTION_BAR_HEIGHT}px;
  overflow-y: auto;
  background-color: ${grey10};
  h3 {
    margin-bottom: 0;
    margin-top: 1em;
  }
  h3:first-child {
    margin-top: 0;
  }
`;

const ContentSpace = styled.div`
  height: ${gutters.lg}px;
`;

const Intro = styled.p([
  typographyCondensed,
  css`
    margin: ${gutters.sm}px 0 ${gutters.sm}px;
  `,
]);

const StickyAmount = styled.div`
  background-color: inherit;
  z-index: 1;
  position: sticky;
  top: 0;
  padding: ${gutters.sm}px 0 1px;
  border-bottom: 1px solid ${borderGrey};
`;

const ActionBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${ACTION_BAR_HEIGHT}px;
  padding: 0 ${gutters.lg}px;
  display: flex;
  align-items: center;
  border-top: 1px solid ${borderGrey};
`;

const InfoLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  label {
    position: relative;
  }
  label sup {
    position: absolute;
    top: -3px;
  }
`;

interface Props {
  pricing: Pricing | null;
  productPackage: DecompositionPackage;
  isLoading: boolean;
  isStale: boolean;
}

export function PricingSummary({
  pricing,
  productPackage,
  isLoading,
  isStale,
}: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <Container>
      <HeaderBar>
        <h2>Характеристики и цены</h2>
      </HeaderBar>
      <Content ref={contentRef}>
        <ContentSpace />
        {pricing && (
          <React.Fragment>
            <Intro>Масса, объем и габариты товара в упакованном виде:</Intro>
            <InfoLine>
              <label>Масса, кг</label>
              <div>{Math.round(productPackage.massKg * 1000) / 1000}</div>
            </InfoLine>
            <InfoLine>
              <label>
                Объем, м<sup>3</sup>
              </label>
              <div>
                {Math.round(
                  (productPackage.lengthMm *
                    productPackage.heightMm *
                    productPackage.widthMm) /
                    1000000
                ) / 1000}
              </div>
            </InfoLine>
            <InfoLine>
              <label>Габариты, м</label>
              <div>
                {`${productPackage.lengthMm / 1000} x ${
                  productPackage.widthMm / 1000
                } x ${productPackage.heightMm / 1000}`}
              </div>
            </InfoLine>
          </React.Fragment>
        )}
        <h3>Цены на производство</h3>
        <Intro>
          Тариф на производство зависит от&nbsp;количества заказываемых изделий.
        </Intro>

        <StickyAmount>
          <ContextAmountSelector label="Количество:" />
        </StickyAmount>
        {pricing ? (
          <PricingBrief
            pricing={pricing}
            isLoading={isLoading}
            isStale={isStale}
          />
        ) : (
          <Spinner />
        )}
        <PricingDelivery
          productPackage={productPackage}
          contentRef={contentRef}
        >
          <h3>Цены на доставку</h3>
          <Intro>
            Доставку осуществляет транспортная компания &laquo;Кит&raquo;. Чем
            больше заказ – тем меньше цена доставки за единицу.
          </Intro>
        </PricingDelivery>
        <ContentSpace />
      </Content>
      <ActionBar>
        <CartButton />
      </ActionBar>
    </Container>
  );
}
