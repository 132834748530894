import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "@emotion/styled";
import { Icon12Pencil } from "@vkontakte/icons";
import { ITEM_HEIGHT, gutters } from "../styles/variables";
import { grey60, primary } from "../styles/colors";
import { useBoolean } from "../services/use-boolean";

const Container = styled.div`
  height: ${ITEM_HEIGHT}px;
  input {
    font: inherit;
    line-height: inherit;
    outline: none;
    border: none;
    padding: inherit;
    margin: inherit;
    display: block;
    height: 100%;
  }
  &:hover svg {
    color: ${primary};
  }
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  svg {
    margin-left: ${gutters.xs}px;
    color: ${grey60};
  }
`;

type Timer = ReturnType<typeof setTimeout>;
const DEBOUNCE_INTERVAL = 2000;

interface Props {
  value: string;
  onChange: (v: string) => void;
  onIsEditingChange?: (v: boolean) => void;
  allowEmpty?: boolean;
}

export function AutoInput({
  value: initialValue,
  onChange,
  onIsEditingChange,
  allowEmpty,
}: Props) {
  const timerRef = useRef<Timer | null>(null);
  const [isEditing, { set: edit, reset: resetEdit }] = useBoolean();
  const [value, setValue] = useState<string>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setValue(newValue);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = null;

      if (newValue.trim().length === 0 && !allowEmpty) {
        return;
      }

      timerRef.current = setTimeout(() => {
        onChange(newValue);
      }, DEBOUNCE_INTERVAL);
    },
    [onChange, allowEmpty]
  );

  const finishEditing = useCallback(
    (dontSave: boolean = false) => {
      resetEdit();

      const wontSave = dontSave || (value.trim().length === 0 && !allowEmpty);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        if (!dontSave) {
          onChange(value);
        }
      }
      if (wontSave) {
        setValue(initialValue);
      }
    },
    [onChange, value, resetEdit, setValue, initialValue]
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      switch (e.code) {
        case "Enter":
        case "Escape":
          finishEditing(e.code === "Escape");
          break;
      }
    },
    [finishEditing]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (!isEditing) {
        edit();
      }
    },
    [isEditing, edit]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      finishEditing();
    },
    [finishEditing]
  );

  useEffect(
    function () {
      onIsEditingChange?.(isEditing);
    },
    [isEditing, onIsEditingChange]
  );

  return (
    <Container onClick={handleClick}>
      {isEditing ? (
        <input
          autoFocus
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyPress}
        />
      ) : (
        <Value>
          {value}
          <Icon12Pencil />
        </Value>
      )}
    </Container>
  );
}
