import { mapValues } from "lodash";

export const white = "#ffffff";
export const black = "#08080d";
export const trueBlack = "#000000";

export const grey10 = "#fafafa";
export const grey15 = "#f6f6f6";
export const grey20 = "#f2f2f2";
export const grey30 = "#e5e5e5";
export const grey40 = "#d0d0d0";
export const grey50 = "#b9b9ba";
export const grey60 = "#a3a3a5";
export const grey70 = "#767678";
export const grey80 = "#616164";
export const grey90 = "#343438";
export const grey95 = "#1e1e23";

export const white10 = withAlpha(white, 0.1);
export const black10 = withAlpha(trueBlack, 0.1);

export const mutedGrey = grey50;
export const borderGrey = grey30;
export const disabledGrey = grey50;
export const hoverGrey = grey20;

export const primary = "#006BCF";
export const primaryMuted = mute(primary, 0.5);
export const logo = "#03fbfe";
export const secondary = "#d81ca2"; // https://mycolor.space/?hex=%23D81CA2&sub=1
export const secondaryMuted = mute(secondary, 0.8);

export const errorRed = "#ff2822";
export const errorRedMuted = mute(errorRed, 0.5);

/**
 * Convert hex color code and transparency to rgba
 *
 * @example withAlpha('#FF0000', 0.4); // '#FF000066'
 *
 * @param color {string} Hex color string #RRGGBB format
 * @param alpha {number} Number between 0 and 1 representing transparency
 * @return {string} rgba function formatted string
 */
export function withAlpha(color: string, alpha = 1): string {
  const { r, g, b } = hexToDecimals(color);
  return `rgba(${r},${g},${b},${alpha})`;
}

export function tint(color: string, percentage = 1): string {
  const tintPart = (part: number): number =>
    Math.floor(part + (1 - percentage) * (255 - part));
  const colors = hexToDecimals(color);
  const { r, g, b } = mapValues(colors, tintPart);
  return `rgb(${r},${g},${b})`;
}

export function mute(color: string, factor = 0.8) {
  const hsv = rgbToHsv(hexToDecimals(color));
  const s = hsv.s * factor;
  const { r, g, b } = hsvToRgb({ ...hsv, s: Math.min(1, s) });
  return `rgb(${r},${g},${b})`;
}

export function lighten(color: string, factor = 1) {
  const hsv = rgbToHsv(hexToDecimals(color));
  const v = hsv.v * factor;
  const { r, g, b } = hsvToRgb({ ...hsv, v: Math.min(1, v) });
  return `rgb(${r},${g},${b})`;
}

function colorToDecimal(colorSection: string): number {
  return Number(`0x${colorSection}`);
}

function hexToDecimals(color: string): { r: number; g: number; b: number } {
  const isHexColor = /^#([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})$/;
  const matches = color.toUpperCase().match(isHexColor);
  if (!matches) {
    throw new TypeError(
      "You must pass a full six-digit hex code string: #RRGGBB"
    );
  }

  return {
    b: colorToDecimal(matches[3]),
    g: colorToDecimal(matches[2]),
    r: colorToDecimal(matches[1]),
  };
}

type RGB = {
  r: number;
  g: number;
  b: number;
};
function rgbToHsv(rgb: RGB): HSV {
  const { r, g, b } = rgb;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b),
    d = max - min;

  let h,
    s = max === 0 ? 0 : d / max,
    v = max / 255;

  switch (max) {
    case r:
      h = g - b + d * (g < b ? 6 : 0);
      h /= 6 * d;
      break;
    case g:
      h = b - r + d * 2;
      h /= 6 * d;
      break;
    case b:
      h = r - g + d * 4;
      h /= 6 * d;
      break;
    case min:
    default:
      h = 0;
      break;
  }

  return {
    h: isNaN(h) ? 0 : h,
    s: s,
    v: v,
  };
}

type HSV = {
  h: number;
  s: number;
  v: number;
};
function hsvToRgb(hsv: HSV): RGB {
  const { h, s, v } = hsv;

  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  let r, g, b;
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
    default:
      r = v;
      g = p;
      b = q;
      break;
  }
  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}
