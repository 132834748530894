import React from "react";
import { Vector3Tuple } from "three";
import { MaterialExpanded } from "shared";
import { Box } from "../../objects";
import { Config } from "./types";
import { SupportItem } from "./support-item";

export function Leg({
  config,
  material,
  supportMaterial,
  coverage,
  position,
  rotation,
  scale,
}: {
  config: Config;
  material: MaterialExpanded;
  supportMaterial: MaterialExpanded;
  coverage: MaterialExpanded | null;
  position?: Vector3Tuple;
  rotation?: Vector3Tuple;
  scale?: Vector3Tuple;
}) {
  const surface = coverage?.texture1 || material.texture1;
  if (!surface) {
    return null;
  }

  if (!material.mmDimension1 || !material.mmDimension2) {
    return null;
  }
  const pipeWidth = material.mmDimension1;
  const pipeHeight = material.mmDimension2;

  return (
    <group position={position} rotation={rotation} scale={scale}>
      {new Array(config.supportAmount).fill(true).map((_, index) => (
        <SupportItem
          key={index}
          config={config}
          material={material}
          supportMaterial={supportMaterial}
          coverage={coverage}
          index={index}
        />
      ))}

      <Box
        key="top-bar"
        xSize={pipeWidth}
        ySize={pipeHeight}
        zSize={config.frameTopWidth}
        defaultSurface={surface}
        offset={[0, (config.frameHeight - pipeHeight) / 2, 0]}
      />
      {config.frameHasBottomBar && (
        <Box
          key="bottom-bar"
          xSize={pipeWidth}
          ySize={pipeHeight}
          zSize={config.frameBottomWidth}
          defaultSurface={surface}
          offset={[0, -(config.frameHeight - pipeHeight) / 2, 0]}
        />
      )}
      <Box
        xSize={pipeWidth}
        ySize={config.frameHeight - pipeHeight * 2}
        zSize={pipeHeight}
        defaultSurface={surface}
        offset={[0, 0, (config.frameTopWidth - pipeHeight) / 2]}
        bottomOffset={[
          0,
          -(config.frameTopWidth - config.frameBottomWidth) / 2,
        ]}
      />
      <Box
        xSize={pipeWidth}
        ySize={config.frameHeight - pipeHeight * 2}
        zSize={pipeHeight}
        defaultSurface={surface}
        offset={[0, 0, -(config.frameTopWidth - pipeHeight) / 2]}
        bottomOffset={[0, (config.frameTopWidth - config.frameBottomWidth) / 2]}
      />
    </group>
  );
}
