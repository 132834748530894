import React, { useCallback, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useMutation } from "@apollo/client";
import { Modal, InnerLink, gutters } from "client-lib/ui";
import { SignInForm, SignInFormData } from "./signin/signin-form";
import { SignUpForm, SignUpFormData } from "./signup/signup-form";
import { useAuth } from "./auth-context";
import { SignIn, SignInData, SignInVars } from "./signin/queries";
import { SignUp, SignUpData, SignUpVars } from "./signup/queries";

const Message = styled.div`
  line-height: 1.2;
  margin-bottom: ${gutters.md}px;
`;

export type AuthModalMode = "signin" | "signup";

interface Props {
  initialMode?: AuthModalMode;
  children?: JSX.Element | JSX.Element[] | string;
  onAuthenticate?: () => void;
  onCloseRequest: () => void;
}

export function AuthModal({
  children,
  initialMode,
  onAuthenticate,
  onCloseRequest,
}: Props) {
  const [mode, setMode] = useState<AuthModalMode>(initialMode || "signin");
  const { isRegistered, setToken } = useAuth();

  useEffect(
    function closeWhenRegistered() {
      if (isRegistered) {
        onCloseRequest();
      }
    },
    [isRegistered, onCloseRequest]
  );

  const [signIn, { loading: signInLoading }] = useMutation<
    SignInData,
    SignInVars
  >(SignIn, {
    onError: () => {},
    onCompleted: (result: SignInData) => {
      setToken(result.SignIn);
    },
  });

  const handleSubmitSignIn = useCallback(
    async (values: SignInFormData) => {
      await signIn({
        variables: values,
      });
      onAuthenticate?.();
    },
    [signIn, onAuthenticate]
  );

  const [signUp, { loading: signUpLoading }] = useMutation<
    SignUpData,
    SignUpVars
  >(SignUp, {
    onError: () => {},
    onCompleted: (result: SignUpData) => {
      setToken(result.SignUp);
    },
  });

  const handleSubmitSignUp = useCallback(
    async (values: SignUpFormData) => {
      await signUp({
        variables: values,
      });
      onAuthenticate?.();
    },
    [signIn, onAuthenticate]
  );

  return (
    <Modal
      onCloseRequest={onCloseRequest}
      title={
        mode === "signin" ? "Вход на Метафабрику" : "Регистрация на Метафабрике"
      }
    >
      {mode === "signin" ? (
        <React.Fragment>
          {children || (
            <Message>
              Войдите в свой аккаунт на Метафабрике или
              <br />{" "}
              <InnerLink onClick={() => setMode("signup")}>
                зарегистрируйте новый аккаунт
              </InnerLink>
            </Message>
          )}
          <SignInForm pending={signInLoading} onSubmit={handleSubmitSignIn} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {children || (
            <Message>
              Зарегистрируйте аккаунт на Метафабрике или
              <br />{" "}
              <InnerLink onClick={() => setMode("signin")}>
                войдите в свой аккаунт
              </InnerLink>
            </Message>
          )}
          <SignUpForm pending={signUpLoading} onSubmit={handleSubmitSignUp} />
        </React.Fragment>
      )}
    </Modal>
  );
}
