import { z } from "zod";
const simpleISORegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

export const dateISOSchema = z.string().refine((dateISO: string) => {
  if (!simpleISORegex.test(dateISO)) {
    return false;
  }

  const parts = dateISO.split("-");
  const [y] = parts.map(Number);

  if (y < 2021 || y > 2100) {
    return false;
  }

  const d = new Date(dateISO);
  if (isNaN(d.getTime())) {
    return false;
  }

  return d.toISOString().indexOf(dateISO) === 0;
});

export const minutesSchema = z
  .number()
  .int()
  .nonnegative()
  .max(24 * 60);
