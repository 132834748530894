import { model } from "./model";
import { controller } from "./controller";
import { getDecomposition } from "./get-decomposition";
import { TemplateBack } from "../types";
import { Config, Assets, Variables } from "./types";

export { tableLoft1Utils } from "./utils";
export const tableLoft1Back: TemplateBack<Config, Assets, Variables> = {
  model,
  controller,
  getDecomposition,
};
