import React from "react";
import {
  Pricing,
  PricingLimit,
  pickBestPricingValue,
  getUnit,
  PricingLimitValues,
} from "shared";
import { Price, TBody, TR, TD, TH } from "client-lib/ui";
import { SectionPrices } from "./types";

function formatUnitAmount(v: number): string {
  const p3 = Number(v.toFixed(3));
  const p6 = Number(v.toFixed(6));
  if (p6 !== p3) {
    return v.toFixed(6);
  }
  const p0 = Number(v.toFixed(0));
  return v.toFixed(p0 !== p3 ? 3 : 0);
}

interface Props {
  pricing: Pricing;
  sectionPrices: SectionPrices;
  index: number;
  pricingLimit?: PricingLimit;
  preline?: boolean;
  postline?: boolean;
}

export function PricingSection({
  pricing,
  sectionPrices,
  index,
  pricingLimit = PricingLimit.BASIC,
  preline,
  postline,
}: Props) {
  const section = pricing.sections[index];
  return (
    <TBody>
      {preline && (
        <TR>
          <TH colSpan={3}>{section.title}</TH>
          <TH colSpan={2}>Подитог:</TH>
          <TH align="right">
            <Price kopecks={sectionPrices[index]} />
          </TH>
        </TR>
      )}

      {section.items.map((item, itemIndex) => {
        const pricingValue = pickBestPricingValue(
          item.unitPricingValues,
          PricingLimitValues[pricingLimit]
        )!;
        const itemPrice = Math.round(
          item.unitAmount * pricingValue.costKopecks * item.amount
        );
        return (
          <TR key={itemIndex}>
            <TD>{item.title}</TD>
            <TD align="center">{getUnit(item.unitType).short}</TD>
            <TD align="right">
              <Price kopecks={pricingValue.costKopecks} />
            </TD>
            <TD align="right">{formatUnitAmount(item.unitAmount)}</TD>
            <TD>{item.amount}</TD>
            <TD align="right">
              <Price kopecks={itemPrice} />
            </TD>
          </TR>
        );
      })}
      {postline && (
        <TR>
          <TH colSpan={5}>Итог:</TH>
          <TH align="right">
            <Price kopecks={sectionPrices[index]} />
          </TH>
        </TR>
      )}
    </TBody>
  );
}
