import React, { useCallback, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  HamburgerIcon,
  gutters,
  white,
  black,
  grey95,
  APP_BAR_HEIGHT,
  ITEM_HEIGHT,
  transitionBase,
  mqMin,
  mqMax,
  borderRadius,
} from "client-lib/ui";
import { Cart } from "src/features/order";
import { useAuth } from "src/features/auth";

const Container = styled.div<{ hide?: boolean }>`
  position: fixed;
  width: 100%;
  top: 0;
  height: ${APP_BAR_HEIGHT}px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  z-index: 10;
  background-color: ${black};
  opacity: 1;
  ${mqMin.tablet} {
    padding: 0 ${gutters.xxl}px 0;
    transition: opacity ${transitionBase}, top ${transitionBase};
    will-change: top, opacity;
    ${({ hide }) => hide && `top: -${APP_BAR_HEIGHT}px; opacity: 0;`}
  }
  ${mqMax.tablet} {
    padding-left: ${gutters.md}px;
  }
`;

const Logo = styled(Link)`
  text-decoration: none;
  margin-right: ${gutters.xl}px;
  ${mqMax.tablet} {
    flex: 1 1 100%;
  }
  img {
    height: 18px;
    display: block;
  }
`;

const Menu = styled.div<{ isOpen: boolean }>(
  ({ isOpen }) => css`
    flex-basis: 100%;
    display: flex;
    align-items: center;
    ${mqMax.tablet} {
      display: ${isOpen ? "block" : "none"};
      align-items: flex-start;
      position: absolute;
      right: 0;
      top: ${APP_BAR_HEIGHT}px;
      background-color: ${grey95};
      border-radius: ${borderRadius.md}px;
      border-top-right-radius: 0px;
    }
  `
);

const Hamburger = styled.div<{ isOpen: boolean }>(({ isOpen }) => [
  css`
    width: ${APP_BAR_HEIGHT}px;
    height: ${APP_BAR_HEIGHT}px;
    flex: 1 0 ${APP_BAR_HEIGHT}px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mqMin.tablet} {
      display: none;
    }
  `,
  isOpen &&
    css`
      background-color: ${grey95};
    `,
]);

const MenuItem = styled(Link)<{}>(({}) => [
  css`
    display: block;
    color: ${white};
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;
    opacity: 85%;
    transition: opacity ${transitionBase};
    &:hover {
      opacity: 1;
    }
    padding: 0px ${gutters.md}px 0;
    ${mqMax.tablet} {
      opacity: 1;
      display: flex;
      min-width: 150px;
      align-items: center;
      min-height: ${ITEM_HEIGHT * 1.1}px;
      line-height: 120%;
      padding: 0 ${gutters.xl}px;
    }
  `,
]);
const MenuSeparator = styled.div`
  flex: 1 1 100%;
`;

export function AppBar() {
  const { isAuthenticated } = useAuth();
  const [hide, setHide] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const hamburgerRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef<number>(0);

  const handleScroll = useCallback(function () {
    setHide(window.scrollY > 100 && window.scrollY > scrollPositionRef.current);
    scrollPositionRef.current = window.scrollY;
  }, []);

  const handleDocumentClick = useCallback(function (e: Event) {
    if (!hamburgerRef.current) {
      return;
    }
    if (hamburgerRef.current.contains(e.target as Node)) {
      return;
    }
    setIsMenuOpen(false);
  }, []);

  useEffect(
    function subscribeOnScrolling() {
      document.addEventListener("scroll", handleScroll);
      return () => document.removeEventListener("scroll", handleScroll);
    },
    [handleScroll]
  );

  useEffect(
    function subscribeOnDocumentClick() {
      document.addEventListener("click", handleDocumentClick, true);
      return () => document.removeEventListener("click", handleDocumentClick);
    },
    [handleDocumentClick]
  );

  const handleHamburgerClick = useCallback(() => {
    setIsMenuOpen((v) => !v);
  }, []);

  return (
    <Container hide={hide}>
      <Logo to="/">
        <img src="/img/metafabrika-logo.png" alt="Метафабрика" />
      </Logo>
      <Hamburger
        onClick={handleHamburgerClick}
        ref={hamburgerRef}
        isOpen={isMenuOpen}
      >
        <HamburgerIcon color={white} />
      </Hamburger>
      <Menu isOpen={isMenuOpen}>
        <MenuItem to="/templates">Шаблоны</MenuItem>
        {isAuthenticated && (
          <MenuItem to="/account/models">Мои&nbsp;модели</MenuItem>
        )}
        <MenuItem to="/contacts">Контакты</MenuItem>
        <MenuSeparator />
        {isAuthenticated ? (
          <React.Fragment>
            <MenuItem to="/account">Личный&nbsp;кабинет</MenuItem>
            <Cart />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MenuItem to="/signin">Вход</MenuItem>
            <MenuItem to="/signup">Регистрация</MenuItem>
          </React.Fragment>
        )}
      </Menu>
    </Container>
  );
}
