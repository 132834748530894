import React, { memo } from "react";
import { MaterialExpanded } from "shared";
import { useShelf } from "../shelf-context";
import { ShelfItemProps } from "./types";
import { ShelfItem3, getPackageDeltaXForItem3 } from "./shelf-item3";
import { ShelfItem4 } from "./shelf-item4";
import { ShelfItem6 } from "./shelf-item6";
import { ShelfConfigItem, Config } from "../types";

export function getPackageDeltaX(config: Config, item: ShelfConfigItem) {
  switch (config.sidesCount) {
    case 3:
      return getPackageDeltaXForItem3(item);
    default:
      return item.thickness;
  }
}

type Props = Omit<ShelfItemProps, "surface">;

export const ShelfItem = memo(function ShelfItem(props: Props) {
  const { assets, config } = useShelf();

  const coverageMaterial =
    assets.shelfCoverage.find(
      (m: MaterialExpanded) => m.id === props.item.coverageId
    ) || null;

  const surface = coverageMaterial
    ? {
        xSurface: coverageMaterial.texture3,
        ySurface: coverageMaterial.texture1,
        zSurface: coverageMaterial.texture2,
      }
    : {
        xSurface: assets.shelfMaterial.texture3,
        ySurface: assets.shelfMaterial.texture1,
        zSurface: assets.shelfMaterial.texture2,
      };

  switch (config.sidesCount) {
    case 3:
      return <ShelfItem3 {...props} surface={surface} />;
    case 6:
      return <ShelfItem6 {...props} surface={surface} />;
    default:
      return <ShelfItem4 {...props} surface={surface} />;
  }
});
