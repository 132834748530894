import { TemplateModel } from "../types";
import {
  configSchema,
  assetsSchema,
  variablesSchema,
  Config,
  Assets,
  Variables,
} from "./types";

export const model: TemplateModel<Config, Assets, Variables> = {
  configSchema: configSchema,
  configInitialValues: (assets: Assets) => ({
    length: 1200,
    width: 600,
    height: 800,

    countertopCoverageId: assets.countertopCoverage[0]?.id ?? null,
    countertopLength: 1200,
    countertopWidth: 600,
    countertopThickness: 30,

    frameMaterialId: assets.frameMaterials[0]?.id ?? "",
    frameCoverageId: assets.frameCoverage[0]?.id ?? null,
    frameTopWidth: 590,
    frameBottomWidth: 590,
    frameWidthsAreEqual: true,
    frameHeight: 770,
    frameEdgeOffset: 5,
    frameButtOffset: 5,
    frameFootingId: "",

    frameMaterialWidth: 40,
    frameMaterialHeight: 20,
    frameHasBottomBar: true,

    supportAmount: 0,
    supportMaterialId: assets.frameMaterials[0]?.id ?? "",
    supportLength: 75,
    supportGap: 200,

    fastenerId: assets.fasteners[0]?.id ?? null,
    packageFramePosition: "corners",
    packageCardboardId: assets.packageCardboard[0]?.id ?? "",
    packageBubbleWrapId: assets.packageBubbleWrap[0]?.id ?? "",
  }),
  assetsSchema,
  variablesSchema: variablesSchema,
  variablesDefaults: {
    countertopTitle: "Столешница",
    frameTitle: "Подстолье",
  },
};
