import { z } from "zod";
import { teamSchema } from "./team";
import { teamUserSchema } from "./team-user";
import { phoneSchema } from "../services";

export const companyRequisitesSchema = z.object({
  inn: z.string().regex(/^[0-9]{9}$/),
  ogrn: z.string().regex(/[0-9]{12}/),
  kpp: z.string().regex(/[0-9]{9}/),
  title: z.string(),
  bik: z.string().regex(/[0-9]{8}/),
  corAccount: z.string().regex(/[0-9]{24}/),
  bankAccount: z.string().regex(/[0-9]{24}/),
  bankTitle: z.string(),
  address: z.string(),
  phone: phoneSchema,
  CEO: z.string(),
});
export type CompanyRequisites = z.infer<typeof companyRequisitesSchema>;

export const myEnvironmentSchema = z.object({
  team: teamSchema.nullable(),
  teamUser: teamUserSchema.nullable(),
  cartItemsAmount: z.number().int().nonnegative(),
  cartOrderId: z.string().uuid().nullable(),
  deliveryRegion: z.string().nullable(),
  deliveryLocationId: z.string().uuid().nullable(),
  awaitingOrdersCount: z.number().int(),
});
export type MyEnvironment = z.infer<typeof myEnvironmentSchema>;

export const environmentSchema = z.object({
  myEnvironment: myEnvironmentSchema.nullable(),
  companyRequisites: companyRequisitesSchema,
});
export type Environment = z.infer<typeof environmentSchema>;
