import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { gutters, black, grey10, grey40, ITEM_HEIGHT } from "../styles";

export const Table = styled.table<{
  seamless?: boolean;
  dense?: boolean;
  autoWidth?: boolean;
  fixed?: boolean;
}>(({ seamless, autoWidth, fixed, dense }) => [
  css`
    border-width: ${seamless ? 0 : 1}px;
    border-color: ${grey40};
    ${seamless ? `& td, th { border-width: 0; }` : ""}
    width: ${autoWidth ? "auto" : "100%"};
    table-layout: ${fixed ? "fixed" : "auto"};
  `,
  dense &&
    css`
      td,
      th {
        padding: ${gutters.xs}px ${gutters.sm}px;
      }
    `,
]);
export const THead = styled.thead``;
export const TBody = styled.tbody``;
export const TFoot = styled.tfoot``;
export const TR = styled.tr<{ isHoverable?: boolean }>(({ isHoverable }) => [
  isHoverable &&
    css`
      &:hover td {
        background: ${grey10};
      }
    `,
]);

export const TH = styled.th<{
  align?: "left" | "right" | "center";
  width?: string;
  openTop?: boolean;
  openBottom?: boolean;
}>(
  ({ align = "left", width = "auto", openTop, openBottom }) => css`
    width: ${width};
    min-height: ${ITEM_HEIGHT}px;
    padding: ${gutters.sm}px ${gutters.md}px;
    text-align: ${align};
    border-color: inherit;
    border-top-color: ${openTop ? "transparent" : "inherit"};
    border-bottom-color: ${openBottom ? "transparent" : "inherit"};
    &:first-child {
      padding-left: 0;
    }
  `
);

export const TD = styled.td<{
  align?: "left" | "right" | "center";
  isLink?: boolean;
}>(({ align = "left", isLink }) => [
  css`
    position: relative;
    text-align: ${align};
    border-color: inherit;
    padding: ${gutters.sm}px ${gutters.md}px;
    &:first-child {
      padding-left: 0;
    }
  `,
  isLink
    ? css`
        padding: 0;
        a {
          padding: ${gutters.sm}px ${gutters.md}px;
          display: block;
          height: 100%;
          text-decoration: none;
          color: ${black};
        }
        &:first-child a {
          padding-left: 0;
        }
      `
    : css``,
]);
