import React from "react";
import styled from "@emotion/styled";
import { gutters, white, primary, LARGE_ITEM_HEIGHT } from "../../styles";
import { StepItem } from "./step-item";
import { StepData } from "./types";

const INDICATOR_SIZE = 18;

const Container = styled.div`
  margin: 0 -${gutters.lg}px;
  padding-top: ${(LARGE_ITEM_HEIGHT - INDICATOR_SIZE) / 2}px;
  padding-left: ${gutters.lg}px;
  padding-right: ${gutters.lg}px;
  height: ${LARGE_ITEM_HEIGHT}px;
  box-sizing: border-box;
  background-color: ${white};
  margin-bottom: ${gutters.md}px;
`;

const Navigator = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  max-width: 700px;
  padding-left: 20px;
`;

interface Props {
  steps: StepData[];
  activeStepId: string;
  onActivate: (activeStepId: string) => void;
  color?: string;
}

export function Stepper({
  steps,
  activeStepId,
  onActivate,
  color = primary,
}: Props) {
  return (
    <Container>
      <Navigator>
        {steps.map((step: StepData, index: number) => {
          const isActive = step.id === activeStepId;
          return (
            <StepItem
              key={step.id}
              step={step}
              onActivate={onActivate}
              color={color}
              isActive={isActive}
              isFirst={index === 0}
              isLast={index === steps.length - 1}
            />
          );
        })}
      </Navigator>
    </Container>
  );
}
