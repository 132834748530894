export * from "./types";
import { TemplateCode } from "../schemas";
import { tableLoft1Back, tableLoft1Utils } from "./table-loft-1";
import {
  shelfLoft1Back,
  shelfLoft1Utils,
  ShelfConfigItem,
} from "./shelf-loft-1";
import { shelfMatreshkaBack } from "./shelf-matreshka";
import { TemplateBack } from "./types";
export * from "./util-types";
export * from "./utils";

export * from "./service";
export { tableLoft1Back, shelfLoft1Back, shelfMatreshkaBack };
export type { ShelfConfigItem };

export const templateBackPool: Record<
  TemplateCode,
  TemplateBack<any, any, any>
> = {
  [TemplateCode.TABLE_LOFT_1]: tableLoft1Back,
  [TemplateCode.SHELF_LOFT_1]: shelfLoft1Back,
  [TemplateCode.SHELF_MATRESHKA]: shelfMatreshkaBack,
};

export { tableLoft1Utils, shelfLoft1Utils };
