import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { white, borderGrey, gutters, fontSizeBody2 } from "../styles";

const Container = styled.div`
  background-color: ${white};
  border: 1px solid ${borderGrey};
  margin-top: ${gutters.md}px;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${gutters.sm}px ${gutters.lg}px;
  cursor: pointer;
`;

const Header = styled.div`
  line-height: 1.5;
`;
const Title = styled.div`
  font-weight: 600;
`;
const SubTitle = styled.div`
  font-weight: 300;
  font-size: ${fontSizeBody2}px;
`;
const Right = styled.div``;

const Body = styled.div`
  padding: ${gutters.sm}px ${gutters.lg}px;
`;

interface Props {
  title: string;
  subtitle: string;
  right?: JSX.Element | string;
  children?: JSX.Element | JSX.Element[];
  isExpanded?: boolean;
  onToggle?: (isExpanded: boolean) => void;
}

export function Collapsible({
  title,
  subtitle,
  right,
  children,
  isExpanded: isExpandedProp,
  onToggle,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    isExpandedProp || false
  );

  const handleClick = useCallback(() => {
    setIsExpanded(!isExpanded);
    onToggle?.(!isExpanded);
  }, [isExpanded, onToggle]);

  return (
    <Container>
      <Head onClick={handleClick}>
        <Header>
          <Title>{title}</Title>
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </Header>
        <Right>{right}</Right>
      </Head>
      {isExpanded && <Body>{children}</Body>}
    </Container>
  );
}
