import { TemplateBack, TemplateAssetExpanded } from "shared";

export function assembleAssetValues<Config, Assets, Variables>(
  templateBack: TemplateBack<Config, Assets, Variables>,
  assets: TemplateAssetExpanded[]
): Assets {
  const assetsValues: Record<string, any> = {};

  for (const asset of assets) {
    if (asset.isArray) {
      assetsValues[asset.name] = assetsValues[asset.name]
        ? [...assetsValues[asset.name], asset.materialExpanded!]
        : [asset.materialExpanded!];
    } else {
      assetsValues[asset.name] = asset.materialExpanded!;
    }
  }

  return templateBack.model.assetsSchema.parse(assetsValues);
}

export function hypotenuseLength(cathetus1: number, cathetus2: number) {
  return Math.sqrt(cathetus1 * cathetus1 + cathetus2 * cathetus2);
}
