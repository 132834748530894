import React from "react";
import { formatPrice } from "shared";
import styled from "@emotion/styled";

const Container = styled.span``;

interface Props {
  kopecks: number;
  hideKopecks?: true | false | "auto";
  split?: boolean;
  showRubles?: boolean;
  className?: string;
  onClick?: () => void;
}

export const Price = function ({
  kopecks,
  hideKopecks = false,
  split = true,
  showRubles,
  className,
  onClick,
}: Props) {
  return (
    <Container className={className} onClick={onClick}>
      {formatPrice(kopecks, { hideKopecks, split })}
      {showRubles && " руб."}
    </Container>
  );
};
