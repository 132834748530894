import React, { useCallback, useState } from "react";
import { v4 } from "uuid";
import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { SupportMessageInput } from "shared";
import { Alert, Button } from "client-lib";
import { ContactsForm } from "./contacts-form";
import {
  CreateSupportMessage,
  CreateSupportMessageData,
  CreateSupportMessageVars,
} from "./queries";

const Container = styled.div``;

const PostedMessage = styled(Alert)`
  height: 100px;
  flex-direction: column;
`;

interface Props {
  children?: JSX.Element | JSX.Element[];
}

export function Contacts({ children }: Props) {
  const [isPosted, setIsPosted] = useState<boolean>(false);
  const [messageId, setMessageId] = useState<string>(v4());

  const [post, { loading }] = useMutation<
    CreateSupportMessageData,
    CreateSupportMessageVars
  >(CreateSupportMessage);

  const handleResetPosted = useCallback(() => {
    setMessageId(v4());
    setIsPosted(false);
  }, []);

  const handleSubmit = useCallback(
    async (input: SupportMessageInput) => {
      await post({
        variables: {
          input,
        },
      });
      setIsPosted(true);
    },
    [post, setIsPosted]
  );

  return (
    <Container>
      {children}
      {isPosted ? (
        <PostedMessage>
          <p>Спасибо! Постараемся ответить как можно скорее</p>
          <Button size="S" onClick={handleResetPosted}>
            Отправить еще одно сообщение
          </Button>
        </PostedMessage>
      ) : (
        <ContactsForm
          messageId={messageId}
          onSubmit={handleSubmit}
          pending={loading}
        />
      )}
    </Container>
  );
}
