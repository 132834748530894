import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";
import { textureSchema } from "./texture";
import { unitTypeSchema } from "./unit";

export enum MaterialType {
  STRUCTURE = "STRUCTURE",
  COVERAGE = "COVERAGE",
  FASTENER = "FASTENER",
  FITTINGS = "FITTINGS",
  PACKAGE = "PACKAGE",
}
export const materialTypeSchema = z.nativeEnum(MaterialType);

export const materialSchema = z.object({
  ...standardAttributes,
  type: materialTypeSchema,
  title: z.string(),
  pickerTitle: z.string().nullable(),
  basicUnit: unitTypeSchema,
  unitMassKg: z.number().nonnegative(),
  mmDimension1: z.number().nonnegative().nullable(),
  mmDimension2: z.number().nonnegative().nullable(),
  mmDimension3: z.number().nonnegative().nullable(),
  texture1Id: z.string().uuid().nullable(),
  texture2Id: z.string().uuid().nullable(),
  texture3Id: z.string().uuid().nullable(),
  customFlags: z.string().nullable(),
});
checkSchema(materialSchema);
export type Material = z.infer<typeof materialSchema>;

export const materialExpandedSchema = materialSchema.extend({
  texture1: textureSchema.nullable(),
  texture2: textureSchema.nullable(),
  texture3: textureSchema.nullable(),
});
export type MaterialExpanded = z.infer<typeof materialExpandedSchema>;

export const materialInputSchema = materialSchema.pick({
  id: true,
  type: true,
  title: true,
  pickerTitle: true,
  basicUnit: true,
  unitMassKg: true,
  mmDimension1: true,
  mmDimension2: true,
  mmDimension3: true,
  texture1Id: true,
  texture2Id: true,
  texture3Id: true,
  customFlags: true,
});
export type MaterialInput = z.infer<typeof materialInputSchema>;

export const materialPatchSchema = materialSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
});
export type MaterialPatch = z.infer<typeof materialPatchSchema>;
