import { z } from "shared";

// Gets a list of unique fields. Does not support nested objects or arrays
function getUniqueFieldsFromUnion(zodUnion: z.ZodUnion<any>): string[] {
  const keys = new Set<string>();
  zodUnion.options.forEach((option: z.AnyZodObject) => {
    if (!(option instanceof z.ZodObject)) {
      throw new Error("Non-object unions are not supported");
    }
    Object.entries(option.shape).forEach(([key, value]) => {
      if (value instanceof z.ZodObject || value instanceof z.ZodArray) {
        throw new Error(
          "Nested objects and arrays in a Union are not supported"
        );
      }
      keys.add(key);
    });
  });
  return Array.from(keys);
}

// Gets a list of unique fields. Does not support nested objects or arrays
function getUniqueFieldsFromIntersection(
  zodIntersection: z.ZodIntersection<any, any>
): string[] {
  const keys = new Set<string>();

  const shape = {
    ...zodIntersection._def.left.shape,
    ...zodIntersection._def.right.shape,
  };
  Object.entries(shape).forEach(([key, value]) => {
    if (value instanceof z.ZodObject || value instanceof z.ZodArray) {
      throw new Error(
        "Nested objects and arrays in a Intersction are not supported"
      );
    }
    keys.add(key);
  });
  return Array.from(keys);
}

export function getFieldsFromSchema(
  zodObject: z.AnyZodObject,
  patch: Record<string, string> = {}
) {
  return Object.entries(zodObject.shape).reduce(
    (acc: string, [key, value]): string => {
      if (patch[key] !== undefined) {
        return `${acc}${patch[key]}`;
      }
      const internal: z.ZodFirstPartySchemaTypes =
        value instanceof z.ZodNullable || value instanceof z.ZodOptional
          ? value.unwrap()
          : value;
      if (internal instanceof z.ZodArray) {
        const { element } = internal;
        if (element instanceof z.ZodObject) {
          return `${acc}${key} {\n${getFieldsFromSchema(element)}}\n`;
        }
        if (element instanceof z.ZodUnion) {
          const fields = getUniqueFieldsFromUnion(element);
          return `${acc}${key} {\n${fields.join("\n")}\n}\n`;
        }

        if (element instanceof z.ZodIntersection) {
          const fields = getUniqueFieldsFromIntersection(element);
          return `${acc}${key} {\n${fields.join("\n")}\n}\n`;
        }
      }

      if (internal instanceof z.ZodObject) {
        return `${acc}${key} {\n${getFieldsFromSchema(internal)}}\n`;
      }

      if (internal instanceof z.ZodUnion) {
        const fields = getUniqueFieldsFromUnion(internal);
        return `${acc}${key} {\n${fields.join("\n")}\n}\n`;
      }
      if (internal instanceof z.ZodIntersection) {
        const fields = getUniqueFieldsFromIntersection(internal);
        return `${acc}${key} {\n${fields.join("\n")}\n}\n`;
      }

      return `${acc}${key}\n`;
    },
    ""
  );
}
