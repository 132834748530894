import React from "react";
import { Formik, Form } from "formik";
import { OrderCustomerStrict } from "shared";
import styled from "@emotion/styled";
import { Spinner } from "@vkontakte/vkui";
import { TextField, Button, gutters } from "client-lib";
import { DeliveryLocationForm } from "src/features/delivery";
import { formikCartOrderSchema } from "./types";

const FormContainer = styled(Form)`
  > div {
    margin-bottom: ${gutters.md}px;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  .vkuiSpinner {
    margin-left: ${gutters.md}px;
    width: auto;
  }
`;

interface Props {
  initialValues: OrderCustomerStrict;
  onSubmit: (cartOrder: OrderCustomerStrict) => void;
  isSubmitting: boolean;
}

export function OrderCustomerForm({
  initialValues,
  onSubmit,
  isSubmitting,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formikCartOrderSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <FormContainer>
        <TextField
          name="deliveryRecipient"
          label="Получатель"
          disabled={isSubmitting}
        />
        <TextField
          name="deliveryRecipientPhone"
          label="Телефон получателя"
          disabled={isSubmitting}
        />
        <TextField
          name="deliveryRecipientEmail"
          label="E-mail получателя"
          disabled={isSubmitting}
        />
        <DeliveryLocationForm
          disabled={isSubmitting}
          regionFieldName="deliveryRegion"
          locationFieldName="deliveryLocationId"
        />
        <Controls>
          <Button type="submit" disabled={isSubmitting}>
            Продолжить
          </Button>
          {isSubmitting && <Spinner />}
        </Controls>
      </FormContainer>
    </Formik>
  );
}
