import React, { memo, ReactNode, useContext, useMemo } from "react";
import { RenderMode } from "src/features/template-editor/types";
import { Config, Assets, Variables } from "./types";

type ShelfValue = {
  config: Config;
  assets: Assets;
  variables: Variables;
  mode: RenderMode;
};

const ShelfContext = React.createContext<ShelfValue>({
  config: {} as Config,
  assets: {} as Assets,
  variables: {} as Variables,
  mode: "model",
});

export function useShelf(): ShelfValue {
  return useContext<ShelfValue>(ShelfContext);
}

interface Props {
  config: Config;
  assets: Assets;
  variables: Variables;
  mode: RenderMode;
  children: ReactNode;
}

export const ShelfProvider = memo(function ShelfProvider({
  config,
  assets,
  variables,
  mode,
  children,
}: Props) {
  const value = useMemo<ShelfValue>(
    () => ({
      config,
      assets,
      variables,
      mode,
    }),
    [config, assets, variables, mode]
  );

  return (
    <ShelfContext.Provider value={value}>{children}</ShelfContext.Provider>
  );
});
