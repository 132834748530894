export const fontSizeH1 = 20;
export const fontSizeH2 = 16;
export const fontSizeH3 = 15;
export const fontSizeBody1 = 14;
export const fontSizeBody2 = 13;
export const fontSizeBody3 = 11;

export const transitionTime = 200;
export const transitionEase = "ease-in-out";
export const transitionBase = `${transitionTime}ms ${transitionEase}`;
export const transitionFast = `${transitionTime / 2}ms ${transitionEase}`;
export const transitionSlow = `${transitionTime * 2}ms ${transitionEase}`;

export const borderRadius = {
  sm: 2,
  md: 4,
  lg: 8,
  xl: 12,
  xxl: 16,
};

export const COVER_VH = 80;

export const zLayers = {
  TOASTER: 100,
  BACKDROP: 1000,
  MODAL: 20000,
};

export const INPUT_WIDTH = 170;

export const gutters = {
  xxs: 2,
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
  xxl: 32,
};

export const breakpointsMin = {
  phone: 600,
  tablet: 960,
  desktop: 1140,
};

export const breakpointsMax = {
  phone: breakpointsMin.phone - 1,
  tablet: breakpointsMin.tablet - 1,
  desktop: breakpointsMin.desktop - 1,
};

export const APP_BAR_HEIGHT = 50;

export const IconSizes = {
  XXXS: 8,
  XXS: 12,
  XS: 16,
  S: 20,
  M: 24,
  L: 28,
  XL: 32,
  XXL: 36,
  XXXL: 40,
};

export const HEADER_BAR_HEIGHT = 48;

export const SMALL_ITEM_HEIGHT = 21;
export const ITEM_HEIGHT = 42;
export const LARGE_ITEM_HEIGHT = 60;
export const INPUT_HEIGHT = 36;
