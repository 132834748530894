import React from "react";
import { Formik } from "formik";
import { Modal, toFormikSchema } from "client-lib/ui";
import { useTemplateEditor } from "src/features/template-editor/template-editor-context";
import { ConfigForm } from "./config-form";

interface Props<Config> {
  initialValues: Config;
  onChange: (values: Config) => void;
  onCloseRequest: () => void;
}
export function SettingsModal<ConfigValues>({
  initialValues,
  onChange,
  onCloseRequest,
}: Props<ConfigValues>) {
  const { templateFull } = useTemplateEditor();
  const validationSchema = toFormikSchema(templateFull.model.configSchema);
  return (
    <Modal
      onCloseRequest={onCloseRequest}
      title="Настройки модели"
      width="75vw"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onChange}
      >
        <ConfigForm />
      </Formik>
    </Modal>
  );
}
