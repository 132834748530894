import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { BackIcon, Button, IconButton, white, gutters } from "client-lib/ui";
import { TemplateEditor } from "src/features/template-editor";
import { useAuth } from "src/features/auth";
import { Cart, CartButton } from "src/features/order";
import {
  UpdateTemplatePresetConfig,
  UpdateTemplatePresetConfigData,
  UpdateTemplatePresetConfigVars,
} from "./queries";
import { TemplateShotBlock } from "./template-shot-block";
import {
  Layout,
  Container,
  Bar,
  Left,
  Center,
  Right,
  Back,
} from "./components";
import { useTemplate } from "../template-context";
import { TemplateShotVersion } from "./template-shot-version";

const RightEnd = styled.div`
  display: flex;
  margin-right: ${gutters.md}px;
  align-items: center;
  justify-content: flex-end;
`;

const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 189px;
`;

export function TemplateLayoutContent() {
  const { user } = useAuth();
  const {
    templatePreset,
    templateShotActual,
    templateEnvironment,
    onSave,
    templateConfig,
    initialConfigSerialized,
    setTemplateConfig,
  } = useTemplate();

  const [updatePreset, { loading: isUpdatingPreset }] = useMutation<
    UpdateTemplatePresetConfigData,
    UpdateTemplatePresetConfigVars
  >(UpdateTemplatePresetConfig);

  const handleUpdatePreset = useCallback(async () => {
    await updatePreset({
      variables: {
        templatePresetId: templatePreset.id,
        config: JSON.stringify(templateConfig),
      },
    });
    onSave();
  }, [updatePreset, templatePreset, templateConfig, onSave]);

  return (
    <Layout>
      <Bar>
        <Left>
          <Back to={templateShotActual ? "/account/models" : "/templates"}>
            <IconButton dark>
              <BackIcon color={white} />
            </IconButton>
          </Back>
          <Title>
            {templatePreset?.title}
            {templateShotActual ? ` / ${templateShotActual.title}` : ""}
          </Title>
          <TemplateShotVersion />
        </Left>
        <Center>
          <TemplateShotBlock />
        </Center>
        <Right>
          {user?.isAdmin && (
            <Button
              size="S"
              color="primary"
              onClick={handleUpdatePreset}
              disabled={isUpdatingPreset}
            >
              Сохранить&nbsp;конфигурацию&nbsp;в&nbsp;пресет
            </Button>
          )}
          <CartButton size="S" />
          <RightEnd>
            <Cart />
          </RightEnd>
        </Right>
      </Bar>
      <Container>
        <TemplateEditor
          readOnly={false}
          onChange={setTemplateConfig}
          templateEnvironment={templateEnvironment}
          rawInitialConfig={initialConfigSerialized}
          templatePreset={templatePreset}
        />
      </Container>
    </Layout>
  );
}
