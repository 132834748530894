import React from "react";
import { Spinner } from "client-lib";
import { Layout } from "src/features/layout";
import { useEnvironment } from "src/features/environment";
import { PrivacyPolicy } from "./privacy-policy";

export function PrivacyPolicyPage() {
  const { environment } = useEnvironment();

  return (
    <Layout>
      <h1>Политика конфиденциальности</h1>
      {environment ? <PrivacyPolicy /> : <Spinner />}
    </Layout>
  );
}
