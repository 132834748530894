import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";

export const userSchema = z.object({
  ...standardAttributes,
  nameFirst: z.string(),
  nameLast: z.string(),
  isAdmin: z.boolean(),
  isGuest: z.boolean(),
});
checkSchema(userSchema);
export type User = typeof userSchema["_type"];

export const tokenUserSchema = userSchema.pick({
  id: true,
  nameFirst: true,
  nameLast: true,
  isAdmin: true,
  isGuest: true,
});
export type TokenUser = z.infer<typeof tokenUserSchema>;
