import React, { memo } from "react";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";
import { black, Spinner } from "client-lib";
import { Layout } from "src/features/layout";
import { LoftTableQuery, LoftTableData, LoftTableVars } from "./queries";
import { CoverSlide } from "./cover-slide";
import { IntroSlide } from "./intro-slide";
import { CounterTopSlide } from "./countertop-slide";
import { TemplateEditorProvider } from "src/features/template-editor/template-editor-context";
import { WithNavigationGroup, WithNavigationContent } from "./layout";
import { StageNavigation } from "./stage-navigation";

const PendingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: flex;
  align-items: center;
  justify-content: center;
  background: black;
`;

export const LoftTableLanding = memo(function LoftTableLanding() {
  const { data } = useQuery<LoftTableData, LoftTableVars>(LoftTableQuery);

  if (!data) {
    return (
      <Layout seamless bg={black}>
        <PendingContainer>
          <Spinner size="large" />
        </PendingContainer>
      </Layout>
    );
  }

  return (
    <Layout seamless bg={black}>
      <TemplateEditorProvider
        templatePreset={data.TemplatePresetEnvironment.templatePreset}
        templateEnvironment={data.TemplatePresetEnvironment.templateEnvironment}
      >
        <CoverSlide />
        <IntroSlide />
        <WithNavigationGroup>
          <StageNavigation />
          <WithNavigationContent>
            <CounterTopSlide />
            <IntroSlide />
          </WithNavigationContent>
        </WithNavigationGroup>
        <IntroSlide />
      </TemplateEditorProvider>
    </Layout>
  );
});
