import React, { memo, useMemo } from "react";
import styled from "@emotion/styled";
import { Scene } from "src/features/template-editor/scene";
import { SceneOptions } from "src/features/template-editor/types";
import {
  primary,
  grey30,
  gutters,
  mqMax,
  useEnv,
  DiameterIcon,
  Price,
  Spinner,
} from "client-lib";
import {
  ScreenSlide,
  SlideContainer,
  SLIDE_PADDING_V,
  SLIDE_PADDING_H,
  Header,
} from "src/features/layout";
import { useTemplateEditor } from "src/features/template-editor/template-editor-context";
import {
  usePricing,
  calculateTotal,
} from "src/features/template-editor/pricing";

const BULLET_ICON_SIZE = 16;

const Slide = styled(ScreenSlide)`
  background-color: ${grey30};
`;

const Container = styled(SlideContainer)`
  display: flex;
  align-items: center;
  right: 0;
  justify-content: space-between;

  ${mqMax.desktop} {
    flex-direction: column;
    position: relative;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    padding: ${SLIDE_PADDING_V.tablet}px 0;
  }
`;

const Message = styled.div`
  min-height: 100%;
  ${mqMax.desktop} {
    align-items: center;
    padding: 0 ${SLIDE_PADDING_H.tablet}px;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
`;

const Item = styled.div`
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding-left: ${BULLET_ICON_SIZE + gutters.sm}px;
  margin-bottom: ${gutters.md}px;
  position: relative;
  svg {
    position: absolute;
    left: 0;
    top: 5px;
  }
`;

const SceneContainer = styled.div`
  position: relative;
  width: 50%;
  flex-shrink: 0;
  height: 100vh;
  ${mqMax.desktop} {
    width: 100%;
    height: 65vh;
  }
`;

const HeroPrice = styled(Price)`
  display: block;
  font-weight: 400;
  font-size: 40px;
  margin-top: ${gutters.xl}px;
  + p {
    margin-top: ${gutters.md}px;
  }
`;

const InlinePrice = styled(Price)`
  font-weight: bold;
`;

const SceneOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const IntroSlide = memo(function IntroSlide() {
  const { isMobileAgent } = useEnv();
  const { templatePreset, templateFull, templateVariables, configValues } =
    useTemplateEditor();
  const { isStale, isLoading, pricing } = usePricing(
    templatePreset.id,
    configValues
  );

  const priceTotal = useMemo<number | null>(() => {
    return isStale || isLoading || !pricing ? null : calculateTotal(pricing);
  }, [isStale, isLoading, pricing]);

  const sceneOptions = useMemo<SceneOptions>(
    () => ({
      showMeasurements: true,
      backgroundColor: grey30,
      disableZoom: !isMobileAgent,
      autoRotate: false,
      camera: {
        position: [0, 800, 1500],
      },
    }),
    [isMobileAgent]
  );

  const pillBullet = <DiameterIcon color={primary} size={BULLET_ICON_SIZE} />;

  return (
    <Slide>
      <Container>
        <Message>
          <Header>Произведем по вашим параметрам</Header>
          <p>
            Лофт-стол – стильный, качественный и надежный предмет интерьера.
            Выберите параметры, которые позволят ему максимально вписаться в ваш
            интерьер, а мы возьмем на себя его производство и доставку.
          </p>
          <p>Вот какие параметры вы сможете настроить:</p>
          <List>
            <Item>
              {pillBullet} <b>Столешница</b> – толщина мебельного щита, длина и
              ширина столешницы, тип и цвет покрытия;
            </Item>
            <Item>
              {pillBullet} <b>Подстолье</b> – марка профильной трубы, цвет
              порошкового покрытия, форма рамы, ширина и высота;
            </Item>
            <Item>
              {pillBullet} <b>Фурнитура</b> – тип подпятников, марка крепежа,
              комплектация инструментом для сборки;
            </Item>
          </List>
          {priceTotal !== null ? (
            <HeroPrice kopecks={priceTotal} hideKopecks showRubles />
          ) : (
            <Spinner size={"medium"} />
          )}
          <p>
            На каждом шаге будем подробно информировать вас об изменении цены.
            Цена модели в текущих параметрах составляет{" "}
            {priceTotal !== null ? (
              <InlinePrice kopecks={priceTotal} hideKopecks showRubles />
            ) : (
              <Spinner size={"small"} />
            )}
          </p>
          <p>
            Если вас устраивают все параметры – можем пропустить настройку и{" "}
            <a href={""}>перейти к оформлению заказа</a>.
          </p>
        </Message>

        <SceneContainer>
          <Scene options={sceneOptions}>
            <templateFull.component
              config={configValues}
              assets={templateFull.assets}
              variables={templateVariables}
              mode="model"
            />
          </Scene>
          {isMobileAgent && <SceneOverlay />}
        </SceneContainer>
      </Container>
    </Slide>
  );
});
