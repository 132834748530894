import React, { useContext } from "react";
import { CompiledMarker } from "./types";

type LegalTermsValue = {
  markers: CompiledMarker[];
};

export const LegalTermsContext = React.createContext<LegalTermsValue>({
  markers: [],
});

export function useLegalTerms(): LegalTermsValue {
  return useContext<LegalTermsValue>(LegalTermsContext);
}
