import React, { memo, useMemo } from "react";
import styled from "@emotion/styled";
import { Scene } from "src/features/template-editor/scene";
import { SceneOptions } from "src/features/template-editor/types";
import {
  logo,
  black,
  white,
  gutters,
  APP_BAR_HEIGHT,
  mqMax,
  useEnv,
  DiameterIcon,
} from "client-lib";
import { ScreenSlide, ScreenSlideContainer } from "src/features/layout";
import { useTemplateEditor } from "src/features/template-editor/template-editor-context";

const BULLET_ICON_SIZE = 16;

const Slide = styled(ScreenSlide)`
  min-height: calc(100vh - ${APP_BAR_HEIGHT}px);
  background-color: ${black};
  color: ${white};
`;

const Container = styled(ScreenSlideContainer)`
  display: flex;
  align-items: center;
  right: 0;
  justify-content: space-between;

  ${mqMax.desktop} {
    flex-direction: column-reverse;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;

  ${mqMax.desktop} {
    align-items: center;
  }

  h1 {
    font-weight: 100;
    text-transform: uppercase;
    font-size: 40px;
    margin-bottom: ${gutters.md}px;
    ${mqMax.desktop} {
      text-align: center;
    }
  }
`;

const Pills = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${mqMax.desktop} {
    justify-content: center;
  }
`;
const Pill = styled.div`
  font-weight: 100;
  font-size: 22px;
  margin: 0 ${gutters.lg}px 0 0;
  display: flex;
  align-items: center;
  svg {
    margin-right: ${gutters.xs}px;
  }
`;

const SceneContainer = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  ${mqMax.desktop} {
    width: 100%;
  }
`;

const SceneOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Subheader = styled.div`
  margin-top: ${gutters.sm}px;
  margin-top: 0.125em;
  line-height: 100%;
  font-size: 36px;
  font-weight: 400;
`;

export const CoverSlide = memo(function CoverSlide() {
  const { isMobileAgent } = useEnv();
  const { templateFull, templateVariables, configValues } = useTemplateEditor();

  const sceneOptions = useMemo<SceneOptions>(
    () => ({
      showMeasurements: false,
      backgroundColor: black,
      disableZoom: !isMobileAgent,
      autoRotate: true,
      camera: {
        // position: isMobileAgent ? [0, 900, 2000] : [0, 800, 1200],
        position: [0, 800, 1200],
      },
    }),
    [isMobileAgent]
  );

  const pillBullet = <DiameterIcon color={logo} size={BULLET_ICON_SIZE} />;

  return (
    <Slide>
      <Container>
        <Message>
          <h1>
            Соберите лофт-стол
            <Subheader>собственной конфигурации</Subheader>
          </h1>
          <Pills>
            <Pill>{pillBullet}Размеры</Pill>
            <Pill>{pillBullet}Материал</Pill>
            <Pill>{pillBullet}Покрытие</Pill>
            <Pill>{pillBullet}Фурнитура</Pill>
          </Pills>
        </Message>

        <SceneContainer>
          <Scene options={sceneOptions}>
            <templateFull.component
              config={configValues}
              assets={templateFull.assets}
              variables={templateVariables}
              mode="model"
            />
          </Scene>
          {isMobileAgent && <SceneOverlay />}
        </SceneContainer>
      </Container>
    </Slide>
  );
});
