import React, { useEffect, useRef, memo } from "react";
import { Surface } from "shared";
import {
  DoubleSide,
  MirroredRepeatWrapping,
  BufferGeometry,
  Vector3Tuple,
} from "three";
import { useFrame } from "react-three-fiber";
import { useTexture } from "@react-three/drei";

// rl – right/left (x axis)
// tb - top/bottom (y axis)
// fb - front/back (z axis)
type PointKey = "rtf" | "rtb" | "rbf" | "rbb" | "ltf" | "ltb" | "lbf" | "lbb";

type OffsetMap = Partial<Record<PointKey, Vector3Tuple>>;

const globalDefaultSurface: Surface = {
  path: "/textures/rubio/pine-face.jpg",
  mmWidth: 890,
  mmHeight: 600,
  metalness: 0,
  roughness: 0,
};

function applyOffsetAndPosition(
  offset: OffsetMap | undefined,
  pointKeys: PointKey[],
  items: number[]
) {
  if (items.length !== pointKeys.length * 3) {
    throw new Error("items and pointKeys length should match");
  }
  if (!offset) {
    return items;
  }
  return items.map((value, index) => {
    const pointIndex = Math.floor(index / 3);
    const coordinateIndex = index % 3;

    const offsetDelta = offset?.[pointKeys[pointIndex]]?.[coordinateIndex] || 0;

    return value + offsetDelta;
  });
}

interface BoxProps {
  zSize: number;
  xSize: number;
  ySize: number;
  offset?: OffsetMap;
  position?: Vector3Tuple;
  rotation?: Vector3Tuple;
  defaultSurface?: Surface;
  xSurface?: Surface | null;
  ySurface?: Surface | null;
  zSurface?: Surface | null;
}

export const Box2 = memo(function Box2({
  zSize,
  xSize,
  ySize,
  offset,
  position,
  rotation = [0, 0, 0],
  defaultSurface = globalDefaultSurface,
  ...restProps
}: BoxProps) {
  const needsUpdate = useRef<boolean>(false);
  const zRef = useRef<BufferGeometry>(null);
  const yRef = useRef<BufferGeometry>(null);
  const xRef = useRef<BufferGeometry>(null);

  const xSurface = restProps.xSurface || defaultSurface;
  const ySurface = restProps.ySurface || defaultSurface;
  const zSurface = restProps.zSurface || defaultSurface;

  useEffect(
    function updateAfterPropsChange() {
      if (!zRef.current || !xRef.current || !yRef.current) {
        return;
      }
      needsUpdate.current = true;
    },
    [zSize, xSize, ySize, offset, position, rotation]
  );

  useFrame(() => {
    if (
      !zRef.current ||
      !needsUpdate.current ||
      !xRef.current ||
      !yRef.current
    ) {
      return;
    }

    zRef.current.getAttribute("position").needsUpdate = true;
    zRef.current.getAttribute("normal").needsUpdate = true;
    zRef.current.getAttribute("uv").needsUpdate = true;
    xRef.current.getAttribute("position").needsUpdate = true;
    xRef.current.getAttribute("normal").needsUpdate = true;
    xRef.current.getAttribute("uv").needsUpdate = true;
    yRef.current.getAttribute("position").needsUpdate = true;
    yRef.current.getAttribute("normal").needsUpdate = true;
    yRef.current.getAttribute("uv").needsUpdate = true;

    needsUpdate.current = false;
  });

  // prettier-ignore
  const zPositions = new Float32Array(applyOffsetAndPosition(offset, [
    "lbf",
    "rbf",
    "rtf",

    "lbf",
    "rtf",
    "ltf",

    "rtb",
    "rbb",
    "lbb",

    "ltb",
    "rtb",
    "lbb",
  ], [
    -xSize / 2, -ySize / 2,  zSize / 2,
    xSize / 2, -ySize / 2,  zSize / 2,
    xSize / 2, ySize / 2,  zSize / 2,

    -xSize / 2, -ySize / 2,  zSize / 2,
    xSize / 2, ySize / 2,  zSize / 2,
    -xSize / 2, ySize / 2,  zSize / 2,

    xSize / 2, ySize / 2,  -zSize / 2,
    xSize / 2, -ySize / 2,  -zSize / 2,
    -xSize / 2, -ySize / 2,  -zSize / 2,

    -xSize / 2, ySize / 2,  -zSize / 2,
    xSize / 2, ySize / 2,  -zSize / 2,
    -xSize / 2, -ySize / 2,  -zSize / 2,
  ]));

  // prettier-ignore
  const zNormals = new Float32Array([
    0, 0, 1,
    0, 0, 1,
    0, 0, 1,

    0, 0, 1,
    0, 0, 1,
    0, 0, 1,

    0, 0, -1,
    0, 0, -1,
    0, 0, -1,

    0, 0, -1,
    0, 0, -1,
    0, 0, -1,
  ]);

  // prettier-ignore
  const zUV = new Float32Array([
    0, 0,
    xSize / zSurface.mmWidth, 0,
    xSize / zSurface.mmWidth, ySize / zSurface.mmHeight,

    0, 0,
    xSize / zSurface.mmWidth, ySize / zSurface.mmHeight,
    0, ySize / zSurface.mmHeight,

    xSize / zSurface.mmWidth, ySize / zSurface.mmHeight,
    xSize / zSurface.mmWidth, 0,
    0, 0,

    0, ySize / zSurface.mmHeight,
    xSize / zSurface.mmWidth, ySize / zSurface.mmHeight,
    0, 0,
  ]);

  // prettier-ignore
  const xPositions = new Float32Array(applyOffsetAndPosition(offset, [
    "lbb",
    "lbf",
    "ltf",

    "lbb",
    "ltf",
    "ltb",

    "rtf",
    "rbf",
    "rbb",

    "rtb",
    "rtf",
    "rbb",
  ], [
    -xSize / 2, -ySize / 2,  -zSize / 2,
    -xSize / 2, -ySize / 2,  zSize / 2,
    -xSize / 2, ySize / 2,  zSize / 2,

    -xSize / 2, -ySize / 2,  -zSize / 2,
    -xSize / 2, ySize / 2,  zSize / 2,
    -xSize / 2, ySize / 2,  -zSize / 2,

    xSize / 2, ySize / 2,  zSize / 2,
    xSize / 2, -ySize / 2,  zSize / 2,
    xSize / 2, -ySize / 2,  -zSize / 2,

    xSize / 2, ySize / 2,  -zSize / 2,
    xSize / 2, ySize / 2,  zSize / 2,
    xSize / 2, -ySize / 2,  -zSize / 2,
  ]));

  // prettier-ignore
  const xNormals = new Float32Array([
    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,

    -1, 0, 0,
    -1, 0, 0,
    -1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,

    1, 0, 0,
    1, 0, 0,
    1, 0, 0,
  ]);

  // prettier-ignore
  const xUV = new Float32Array([
    0, 0,
    zSize / xSurface.mmWidth, 0,
    zSize / xSurface.mmWidth, ySize / xSurface.mmHeight,

    0, 0,
    zSize / xSurface.mmWidth, ySize / xSurface.mmHeight,
    0, ySize / xSurface.mmHeight,

    zSize / xSurface.mmWidth, ySize / xSurface.mmHeight,
    zSize / xSurface.mmWidth, 0,
    0, 0,

    0, ySize / xSurface.mmHeight,
    zSize / xSurface.mmWidth, ySize / xSurface.mmHeight,
    0, 0,
  ]);

  // prettier-ignore
  const yPositions = new Float32Array(applyOffsetAndPosition(offset, [
    "ltf",
    "rtf",
    "rtb",

    "ltf",
    "rtb",
    "ltb",

    "rbb",
    "rbf",
    "lbf",

    "lbb",
    "rbb",
    "lbf",
  ], [
    -xSize / 2, ySize / 2, zSize / 2,
    xSize / 2, ySize / 2,  zSize / 2,
    xSize / 2, ySize / 2,  -zSize / 2,

    -xSize / 2, ySize / 2, zSize / 2,
    xSize / 2, ySize / 2,  -zSize / 2,
    -xSize / 2, ySize / 2,  -zSize / 2,

    xSize / 2, -ySize / 2,  -zSize / 2,
    xSize / 2, -ySize / 2,  zSize / 2,
    -xSize / 2, -ySize / 2, zSize / 2,

    -xSize / 2, -ySize / 2,  -zSize / 2,
    xSize / 2, -ySize / 2,  -zSize / 2,
    -xSize / 2, -ySize / 2, zSize / 2,
  ]));

  // prettier-ignore
  const yNormals = new Float32Array([
    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, 1, 0,
    0, 1, 0,
    0, 1, 0,

    0, -1, 0,
    0, -1, 0,
    0, -1, 0,

    0, -1, 0,
    0, -1, 0,
    0, -1, 0,
  ]);

  // prettier-ignore
  const yUV = new Float32Array([
    0, 0,
    xSize / ySurface.mmWidth, 0,
    xSize / ySurface.mmWidth, zSize / ySurface.mmHeight,

    0, 0,
    xSize / ySurface.mmWidth, zSize / ySurface.mmHeight,
    0, zSize / ySurface.mmHeight,

    xSize / ySurface.mmWidth, zSize / ySurface.mmHeight,
    xSize / ySurface.mmWidth, 0,
    0, 0,

    0, zSize / ySurface.mmHeight,
    xSize / ySurface.mmWidth, zSize / ySurface.mmHeight,
    0, 0,
  ]);

  const { zTexture, xTexture, yTexture } = useTexture(
    {
      zTexture: zSurface.path,
      xTexture: xSurface.path,
      yTexture: ySurface.path,
    },
    (textures) => {
      for (const tex of Object.values(textures)) {
        tex.wrapS = MirroredRepeatWrapping;
        tex.wrapT = MirroredRepeatWrapping;
      }
    }
  );

  return (
    <group rotation={rotation} position={position}>
      <mesh>
        <bufferGeometry ref={zRef}>
          <bufferAttribute
            attach="attributes-position"
            array={zPositions}
            count={zPositions.length / 3}
            itemSize={3}
          />
          <bufferAttribute
            attach="attributes-normal"
            array={zNormals}
            count={zNormals.length / 3}
            itemSize={3}
          />
          <bufferAttribute
            attach="attributes-uv"
            array={zUV}
            count={zUV.length / 2}
            itemSize={2}
          />
        </bufferGeometry>
        {zSurface.metalness || zSurface.roughness ? (
          <meshStandardMaterial
            map={zTexture}
            metalness={1 - (zSurface.metalness || 0)}
            roughness={zSurface.roughness || 0}
            side={DoubleSide}
          />
        ) : (
          <meshBasicMaterial map={zTexture} side={DoubleSide} />
        )}
      </mesh>
      <mesh>
        <bufferGeometry ref={xRef}>
          <bufferAttribute
            attach="attributes-position"
            array={xPositions}
            count={xPositions.length / 3}
            itemSize={3}
          />
          <bufferAttribute
            attach="attributes-normal"
            array={xNormals}
            count={xNormals.length / 3}
            itemSize={3}
          />
          <bufferAttribute
            attach="attributes-uv"
            array={xUV}
            count={xUV.length / 2}
            itemSize={2}
          />
        </bufferGeometry>
        {xSurface.metalness || xSurface.roughness ? (
          <meshStandardMaterial
            map={xTexture}
            metalness={1 - (xSurface.metalness || 0)}
            roughness={xSurface.roughness || 0}
            side={DoubleSide}
          />
        ) : (
          <meshBasicMaterial map={xTexture} side={DoubleSide} />
        )}
      </mesh>
      <mesh>
        <bufferGeometry ref={yRef}>
          <bufferAttribute
            attach="attributes-position"
            array={yPositions}
            count={yPositions.length / 3}
            itemSize={3}
          />
          <bufferAttribute
            attach="attributes-normal"
            array={yNormals}
            count={yNormals.length / 3}
            itemSize={3}
          />
          <bufferAttribute
            attach="attributes-uv"
            array={yUV}
            count={yUV.length / 2}
            itemSize={2}
          />
        </bufferGeometry>
        {ySurface.metalness || ySurface.roughness ? (
          <meshStandardMaterial
            map={yTexture}
            metalness={1 - (ySurface.metalness || 0)}
            roughness={ySurface.roughness || 0}
            side={DoubleSide}
          />
        ) : (
          <meshBasicMaterial map={yTexture} side={DoubleSide} />
        )}
      </mesh>
    </group>
  );
});
