import React, { useCallback, useState } from "react";
import { v4 } from "uuid";
import { Button, useBoolean } from "client-lib/ui";
import { TemplateShotModal } from "src/features/templates/template-layout/template-shot-form";
import { useMutation } from "@apollo/client";
import { TemplateShotActual, TemplateShotInput } from "shared";
import { useTemplate } from "src/features/templates/template-context";
import {
  CreateTemplateShot,
  CreateTemplateShotData,
  CreateTemplateShotVars,
} from "../queries";

interface Props {
  onCreate: (shot: TemplateShotActual) => void;
  children?: JSX.Element | string;
  templateConfig: string;
  seamless?: boolean;
  titleSuggestion: string;
}

export function CreateTemplateShotButton({
  children = "Сохранить модель",
  onCreate,
  templateConfig,
  seamless,
  titleSuggestion,
}: Props) {
  const [shotId, setShotId] = useState<string>(v4());
  const [isOpen, { set: open, reset: close }] = useBoolean(false);
  const { templatePreset } = useTemplate();

  const [createShot, { loading }] = useMutation<
    CreateTemplateShotData,
    CreateTemplateShotVars
  >(CreateTemplateShot);

  const handleCreateShot = useCallback(
    async (input: TemplateShotInput) => {
      const { data } = await createShot({
        variables: {
          input,
        },
      });
      close();

      setShotId(v4());
      onCreate(data!.CreateTemplateShot);
    },
    [createShot, onCreate, close]
  );

  if (!templatePreset) {
    return null;
  }

  return (
    <React.Fragment>
      <Button
        size="XS"
        color={seamless ? "seamless" : "primary"}
        onClick={open}
        disabled={loading}
      >
        {children}
      </Button>
      {isOpen && (
        <TemplateShotModal
          onCloseRequest={close}
          initialValues={{
            id: shotId,
            title: "",
            templatePresetId: templatePreset.id,
            templateConfig,
          }}
          titleSuggestion={titleSuggestion}
          onSubmit={handleCreateShot}
        />
      )}
    </React.Fragment>
  );
}
