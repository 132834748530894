import React from "react";
import { omit } from "lodash";
import { withField } from "./formik-field-wrapper";
import { StyledInput, StyledTextarea } from "./styles";

export const TextField = withField(StyledInput);

const MultilineTextFieldRaw = withField(StyledTextarea);

export const MultilineTextField = (
  props: React.ComponentProps<typeof MultilineTextFieldRaw> & {
    rows?: number;
    dontResize?: boolean;
  }
) => {
  return (
    <MultilineTextFieldRaw
      {...{
        ...omit(props, "rows", "dontResize"),
        controlProps: {
          ...props.controlProps,
          rows: props.rows,
          style: {
            ...props.controlProps?.style,
            resize: props.dontResize ? "none" : "both",
          },
        },
      }}
    />
  );
};
