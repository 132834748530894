/**
 * We currently don't have a tool for rotating a pipe and cut OXZ plane
 * We can use skewing with bottom offset option, but skewing changes the original width of a pipe
 * As a temp solution, we can calculate the OXZ contact width and use it for the box being skewed
 * So after skewing it's width gets the original value
 * @param originalBoxWidth Let's say a pipe width
 * @param desiredWidth The width the inclined pipe should take after skewing (including both top/btm contact sizes
 * @param height The height of the pipe (remains constant even after skewing)
 * @returns contact size width so the pipe width remains intact
 */
export function getSkewContactSize(
  originalBoxWidth: number,
  desiredWidth: number,
  height: number
) {
  const w = originalBoxWidth;
  const s = desiredWidth;
  const h = height;
  const w2 = w * w;
  const s2 = s * s;
  const h2 = h * h;

  const a = w2 - h2;
  const b = -2 * s * w2;
  const c = s2 * w2 + h2 * w2;

  const discriminant = b * b - 4 * a * c;

  if (discriminant < 0) {
    // throw new Error("Can't calculate skewContactSize, discriminant is negative");
    return originalBoxWidth;
  }

  const x1 = (-b + discriminant ** 0.5) / (2 * a);
  const x2 = (-b - discriminant ** 0.5) / (2 * a);

  return Math.max(x1, x2, originalBoxWidth);
}
