// export type SimpleLegalTerm = JSX.Element | string;
export type SimpleLegalTerm = string;

export type LegalTerm = {
  content: SimpleLegalTerm;
  marker?: string;
  keepNewLines?: boolean;
  hideNumber?: boolean;
  items?: (SimpleLegalTerm | LegalTerm)[];
};

export type AnyLegalTerm = SimpleLegalTerm | LegalTerm;

export function isLegalTerm(term: any): term is LegalTerm {
  return term.hasOwnProperty("content");
}

export type CompiledMarker = { originalMarker: string; actualIndex: string };
