import { z } from "zod";

export enum PricingLimit {
  BASIC = "BASIC",
  LIMIT1 = "LIMIT1",
  LIMIT2 = "LIMIT2",
  LIMIT3 = "LIMIT3",
  LIMIT4 = "LIMIT4",
}
export const pricingLimitSchema = z.nativeEnum(PricingLimit);

export const PricingLimitValues: Record<PricingLimit, number> = {
  [PricingLimit.BASIC]: 1,
  [PricingLimit.LIMIT1]: 5,
  [PricingLimit.LIMIT2]: 10,
  [PricingLimit.LIMIT3]: 25,
  [PricingLimit.LIMIT4]: 50,
};
