import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";

export const paymentSchema = z.object({
  ...standardAttributes,
  invoiceId: z.string(),
  total: z.number().int(),
});
checkSchema(paymentSchema);
export type Payment = typeof paymentSchema["_type"];
