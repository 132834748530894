import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";

export const invoiceSchema = z.object({
  ...standardAttributes,
  number: z.string(),
  orderId: z.string().uuid(),
  actualTill: z.date(),
});
checkSchema(invoiceSchema);
export type Invoice = typeof invoiceSchema["_type"];
