import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import {
  INPUT_HEIGHT,
  INPUT_WIDTH,
  borderGrey,
  hoverGrey,
  borderRadius,
  gutters,
  white,
  dropdownShadow,
} from "../../styles";
import { CaretIcon, ICON_SIZE } from "../../icons";
import { SpinnerInline } from "../spinner";

const VALUE_MARGIN_RIGHT = ICON_SIZE;
const VALUE_MARGIN_LEFT = gutters.md;

const Container = styled.div`
  position: relative;
  padding: 0 ${VALUE_MARGIN_RIGHT}px 0 ${VALUE_MARGIN_LEFT}px;
  border-radius: ${borderRadius.sm}px;
  outline: 1px solid ${borderGrey};
  background: white;
`;

const CaretContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Value = styled.div`
  height: ${INPUT_HEIGHT}px;
  line-height: ${INPUT_HEIGHT}px;
  width: ${INPUT_WIDTH - VALUE_MARGIN_LEFT - VALUE_MARGIN_RIGHT}px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const List = styled.div`
  position: absolute;
  z-index: 1;
  left: 0px;
  top: ${INPUT_HEIGHT + gutters.xs}px;
  min-width: 100%;
  max-width: 200px;
  max-height: ${10 * INPUT_HEIGHT}px;
  overflow-y: auto;
  background: ${white};
  border-top: 1px solid ${borderGrey};
  ${dropdownShadow};
  border-radius: 0 0 ${borderRadius.sm}px ${borderRadius.sm}px;
  border-radius: ${borderRadius.md}px;
`;

const ListItem = styled.div`
  height: ${INPUT_HEIGHT}px;
  line-height: ${INPUT_HEIGHT}px;
  border-top: 1px solid ${borderGrey};
  box-sizing: border-box;
  &:first-of-type {
    border-top-color: transparent;
  }
  text-overflow: ellipsis;
  padding: 0 ${gutters.md}px;

  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background: ${hoverGrey};
  }
`;

export interface DropdownItem {
  value: string;
  content: string | ReactNode;
}

interface Props {
  value: string | null;
  valueItem?: DropdownItem;
  items?: DropdownItem[];
  getItems?: () => Promise<DropdownItem[]>;
  placeholder?: string;
  onTouch?: () => void;
  onChange?: (value: string) => void;
}

export function Dropdown({
  placeholder,
  valueItem,
  value,
  items: propItems,
  getItems,
  onChange,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [loadedItems, setLoadedItems] = useState<DropdownItem[] | null>(null);
  const items: DropdownItem[] | null = propItems || loadedItems || null;

  const listRef = useRef<HTMLDivElement>(null);
  const handleSelect = useCallback((value: string) => {
    onChange?.(value);
    setIsExpanded(false);
  }, []);

  const activeValueContent = useMemo(() => {
    if (!value) {
      return placeholder || "Выберите значение\u2026";
    }
    if (items) {
      const activeItem = items.find((item) => item.value === value);
      if (activeItem) {
        return activeItem.content;
      }
    }
    if (valueItem && valueItem.value === value) {
      return valueItem.content;
    }
    return <SpinnerInline />;
  }, [value, placeholder, items, valueItem]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Element;
      if (!listRef.current || !target || !isExpanded) {
        return;
      }
      if (
        listRef.current.compareDocumentPosition(target) &
        Node.DOCUMENT_POSITION_CONTAINED_BY
      ) {
        return;
      }
      setIsExpanded(false);
      event.stopPropagation();
    },
    [listRef, isExpanded]
  );

  const handleClickValue = useCallback(async () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded && !items && getItems) {
      setLoadedItems(await getItems());
    }
  }, [isExpanded, items, getItems]);

  useEffect(
    function () {
      window.addEventListener("click", handleClickOutside, true);
      return () => {
        window.removeEventListener("click", handleClickOutside, true);
      };
    },
    [handleClickOutside]
  );

  return (
    <Container>
      <Value onClick={handleClickValue}>
        {activeValueContent}
        <CaretContainer>
          <CaretIcon />
        </CaretContainer>
      </Value>

      {isExpanded && (
        <List ref={listRef}>
          {(items || []).map((item) => (
            <ListItem key={item.value} onClick={() => handleSelect(item.value)}>
              {item.content}
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  );
}
