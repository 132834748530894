import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { useMutation } from "@apollo/client";
import { Spinner } from "@vkontakte/vkui";
import { OrderStatus } from "shared";
import { InnerLink } from "client-lib";
import {
  UpdateOrderStatusData,
  UpdateOrderStatus,
  UpdateOrderStatusVars,
} from "./queries";

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

interface Props {
  orderId: string;
}

export function CancelOrderButton({ orderId }: Props) {
  const [cancel, { loading }] = useMutation<
    UpdateOrderStatusData,
    UpdateOrderStatusVars
  >(UpdateOrderStatus);

  const handleCancel = useCallback(() => {
    if (!window.confirm("Уверены, что хотите отменить заказ?")) {
      return;
    }
    cancel({
      variables: {
        orderId,
        status: OrderStatus.CUSTOMER_CANCELED,
      },
    });
  }, [cancel, orderId]);

  return (
    <Container>
      {loading ? (
        <Spinner />
      ) : (
        <InnerLink onClick={handleCancel}>Отменить заказ</InnerLink>
      )}
    </Container>
  );
}
