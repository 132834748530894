import React, { useCallback, useState, useEffect, memo } from "react";
import { pick } from "lodash";
import styled from "@emotion/styled";
import { Spinner } from "@vkontakte/vkui";
import { Order } from "shared";
import { Price } from "client-lib";
import { DeliveryLocationSelector } from "src/features/delivery";
import { useMutation } from "@apollo/client";
import {
  UpdateOrderDelivery,
  UpdateOrderDeliveryData,
  UpdateOrderDeliveryVars,
} from "./queries";

const Container = styled.div``;

interface Props {
  order: Order;
}

export const DeliveryEstimate = memo(function DeliveryEstimate({
  order,
}: Props) {
  const [locationId, setLocationId] = useState<string | null>(
    order.deliveryLocationId
  );
  const [region, setRegion] = useState<string | null>(order.deliveryRegion);

  const [update] = useMutation<
    UpdateOrderDeliveryData,
    UpdateOrderDeliveryVars
  >(UpdateOrderDelivery);
  useEffect(
    function updateStateVars() {
      setLocationId(order.deliveryLocationId);
      setRegion(order.deliveryRegion);
    },
    [order.deliveryLocationId, order.deliveryRegion]
  );

  const handleLocationChange = useCallback(
    async (deliveryLocationId: string | null) => {
      setLocationId(deliveryLocationId);
      if (!deliveryLocationId) {
        return;
      }
      await update({
        variables: {
          orderId: order.id,
          deliveryPatch: {
            ...pick(
              order,
              "deliveryRecipient",
              "deliveryRecipientPhone",
              "deliveryRecipientEmail"
            ),
            deliveryRegion: region,
            deliveryLocationId,
          },
        },
      });
    },
    [order, region, update]
  );

  const handleRegionChange = useCallback((region: string | null) => {
    setRegion(region);
  }, []);

  return (
    <Container>
      <h3>Ориентировочная стоимость доставки до вашего города</h3>
      <p>
        Не входит в счет, оплачивается транспортной компании «Кит» при
        получении.
      </p>
      <DeliveryLocationSelector
        region={region}
        deliveryLocationId={locationId}
        onDeliveryLocationChange={handleLocationChange}
        onRegionChange={handleRegionChange}
      >
        {order.deliveryLocationId ===
        null ? null : order.deliveryLocationEstimateKopecks === null ||
          order.deliveryCostResetAt ? (
          <Spinner />
        ) : (
          <Price kopecks={order.deliveryLocationEstimateKopecks} />
        )}
      </DeliveryLocationSelector>
    </Container>
  );
});
