import gql from "graphql-tag";
import { Order, OrderLineItemInput } from "shared";
import { ORDER_FULL_FRAGMENT } from "client-lib";

export const AddToCart = gql`
  mutation AddToCart(
    $items: [OrderLineItemInput]!
    $deliveryLocationId: String
  ) {
    AddToCart(items: $items, deliveryLocationId: $deliveryLocationId) {
      ...OrderFullFragment
    }
  }
  ${ORDER_FULL_FRAGMENT}
`;

export interface AddToCartVars {
  items: OrderLineItemInput[];
  deliveryLocationId: string | null;
}

export type AddToCartData = {
  AddToCart: Order;
};
