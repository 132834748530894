import React, {
  Dispatch,
  memo,
  SetStateAction,
  useMemo,
  useState,
  useEffect,
} from "react";
import styled from "@emotion/styled";
import {
  DecompositionPackage,
  TemplateEnvironment,
  TemplatePreset,
} from "shared";
import {
  assembleTemplate,
  useTemplateVariables,
} from "src/features/template-editor/service";
import { Scene } from "src/features/template-editor/scene";
import { RenderMode, SceneOptions } from "src/features/template-editor/types";
import {
  grey70,
  white,
  gutters,
  useEnv,
  borderRadius,
  mqMax,
} from "client-lib";
import { ScreenSlide } from "src/features/layout";
import { PillSelector } from "./pill-selector";

const MOBILE_CONTROLS_HEIGHT = 170;

const Container = styled(ScreenSlide)`
  position: relative;
  height: 100%;
  ${mqMax.phone} {
    min-height: auto;
    background-color: ${grey70};
  }
`;

const SceneWrapper = styled.div`
  position: relative;
  height: 100%;
  ${mqMax.phone} {
    height: calc(100% - ${MOBILE_CONTROLS_HEIGHT}px);
  }
`;

const SceneOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const ModeSelector = styled(PillSelector)`
  position: absolute;
  right: ${gutters.xl}px;
  bottom: 74px;
  ${mqMax.phone} {
    bottom: ${74 + 50}px;
    right: auto;
    left: ${gutters.xl}px;
  }
`;

const Summary = styled.div`
  position: absolute;
  left: ${gutters.xl}px;
  bottom: ${gutters.xl}px;
  background-color: ${white};
  padding: ${gutters.md / 2}px ${gutters.md}px;
  border-radius: ${borderRadius.lg}px;
  ${mqMax.phone} {
    font-size: 15px;
  }
`;

const modeTitles = ["Упаковка", "В сборке"];
const modes: RenderMode[] = ["package", "model"];

interface Props {
  templateEnvironment: TemplateEnvironment;
  templatePreset: TemplatePreset;
  setDecompositionPackage: Dispatch<
    SetStateAction<DecompositionPackage | null>
  >;
}

export const DeliveryScene = memo(function DeliveryScene({
  templateEnvironment,
  templatePreset,
  setDecompositionPackage,
}: Props) {
  const [modeIndex, setModeIndex] = useState<number>(0);
  const { isMobileAgent } = useEnv();

  const templateFull = useMemo(
    () => assembleTemplate(templateEnvironment),
    [templateEnvironment]
  );

  const configValues = useMemo(() => {
    if (templatePreset.templateConfig) {
      return JSON.parse(templatePreset.templateConfig);
    }
    return templateFull.model.configInitialValues(templateFull.assets);
  }, [templatePreset, templateFull]);

  const templateVariables = useTemplateVariables(templateFull, templatePreset);

  const decomposition = useMemo(() => {
    return templateFull.getDecomposition(
      configValues,
      templateFull.assets,
      templateVariables
    );
  }, [configValues, templateFull, templateVariables]);

  useEffect(
    function reportPackageToParent() {
      setDecompositionPackage(decomposition.package);
    },
    [setDecompositionPackage, decomposition.package]
  );

  const summary = useMemo<string>(() => {
    const v =
      Math.round(
        (decomposition.package.lengthMm *
          decomposition.package.heightMm *
          decomposition.package.widthMm) /
          1000000
      ) / 1000;
    return `${decomposition.package.lengthMm} x ${
      decomposition.package.widthMm
    } x ${decomposition.package.heightMm} мм / ${v} м\u00B3 / ${
      Math.round(decomposition.package.massKg * 1000) / 1000
    } кг`;
  }, [decomposition.package]);

  const sceneOptions = useMemo<SceneOptions>(
    () => ({
      showMeasurements: modes[modeIndex] === "package",
      backgroundColor: grey70,
      disableZoom: !isMobileAgent,
      autoRotate: true,
      camera: {
        position: isMobileAgent ? [0, 800, 1250] : [0, 800, 1750],
      },
    }),
    [isMobileAgent, modeIndex]
  );

  return (
    <Container>
      <SceneWrapper>
        <Scene options={sceneOptions}>
          <templateFull.component
            config={configValues}
            assets={templateFull.assets}
            variables={templateVariables}
            mode={modes[modeIndex]}
          />
        </Scene>
      </SceneWrapper>
      {isMobileAgent && <SceneOverlay />}
      <ModeSelector
        titles={modeTitles}
        activeIndex={modeIndex}
        setActiveIndex={setModeIndex}
      />
      <Summary>{summary}</Summary>
    </Container>
  );
});
