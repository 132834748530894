import React from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { Spinner } from "@vkontakte/vkui";
import {
  grey20,
  grey30,
  typographyBody2,
  Button,
  gutters,
} from "client-lib/styles";
import { Layout } from "src/features/layout";
import {
  TemplateShotEnvironments,
  TemplateShotEnvironmentsData,
} from "../queries";
import { ShotCard } from "./shot-card";

const List = styled.div``;

const Placeholder = styled.div([
  typographyBody2,
  css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${grey30};
    padding: ${gutters.xl}px;
    button {
      margin-top: ${gutters.md}px;
    }
  `,
]);

export function TemplateShotsPage() {
  const { data } = useQuery<TemplateShotEnvironmentsData>(
    TemplateShotEnvironments
  );
  return (
    <Layout bg={grey20}>
      <h1>Модели</h1>
      <p>
        Модели – это созданные вами индивидуальные товары на основе наших
        шаблонов.
        <br />
        Чтобы создать новую модель, перейдите в{" "}
        <Link to="/templates">шаблоны</Link>, выберите нужный и откройте
        конструктор.
      </p>
      {data ? (
        <List>
          {data.TemplateShotEnvironments.length === 0 ? (
            <Placeholder>
              Вы пока не создали ни одной модели
              <br />
              <Link to="/templates">
                <Button size="S">К шаблонам</Button>
              </Link>
            </Placeholder>
          ) : (
            data.TemplateShotEnvironments.map((templateShotEnv) => (
              <ShotCard
                key={templateShotEnv.id}
                templateShotEnvironment={templateShotEnv}
              />
            ))
          )}
        </List>
      ) : (
        <Spinner />
      )}
    </Layout>
  );
}
