import { borderGrey, black10 } from "./colors";

export const dropdownShadow = `
  box-shadow: 0 6px 12px ${borderGrey};
`;

export const whiteBlockShadow = `
  box-shadow: 0 0 7px ${borderGrey};
`;

export const bottomShadow = `
  box-shadow: 0 6px 12px ${borderGrey};
`;

export const modalShadow = `
  box-shadow: 0 23px 50px 0 ${black10};
`;
