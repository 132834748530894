import React, { useMemo } from "react";
import { Spinner } from "@vkontakte/vkui";
import { TD, Price, OrderEnvironment } from "client-lib";
import { FixedHeightRow, BoldTD } from "./components";

interface Props {
  orderEnvironment: OrderEnvironment;
}

export function SummaryRow({ orderEnvironment }: Props) {
  const { order, orderLineItems } = orderEnvironment;
  const total = useMemo<number | null>(() => {
    if (order.deliveryCostKopecks === null || order.deliveryCostResetAt) {
      return null;
    }

    let acc = order.deliveryCostKopecks;
    for (const { orderLineItem } of orderLineItems) {
      acc += orderLineItem.price * orderLineItem.amount;
    }
    return acc;
  }, [orderLineItems, order.deliveryCostKopecks, order.deliveryCostResetAt]);

  return (
    <FixedHeightRow>
      <TD />
      <TD colSpan={2} />
      <BoldTD align="right">Итого:</BoldTD>
      <BoldTD align="right">
        {total === null ? <Spinner /> : <Price kopecks={total} />}
      </BoldTD>
    </FixedHeightRow>
  );
}
