import React, { memo } from "react";
import { SideRibType, Surface, MaterialDimensions } from "shared";
import { Box } from "../../objects";
import { getSkewContactSize } from "../../utils";

interface Props {
  type: SideRibType;
  y: number;
  sideRibPipe: MaterialDimensions;
  mainPipe: MaterialDimensions;
  surface: Surface;
  availableHeight: number;
  availableWidth: number;
}

export const SideRib = memo(function SideRib({
  type,
  y,
  sideRibPipe,
  mainPipe,
  surface,
  availableHeight,
  availableWidth,
}: Props) {
  if (
    !mainPipe.d1 ||
    !mainPipe.d2 ||
    !surface ||
    !sideRibPipe.d1 ||
    !sideRibPipe.d2
  ) {
    return null;
  }

  const ySize = availableHeight;
  const zSize = availableWidth;
  const contactSize = getSkewContactSize(sideRibPipe.d1, ySize, zSize);

  switch (type) {
    case SideRibType.UP: {
      const bottomOffsetY = ySize - contactSize;
      return (
        <Box
          xSize={sideRibPipe.d2}
          ySize={zSize}
          zSize={contactSize}
          bottomOffset={[0, -bottomOffsetY]}
          defaultSurface={surface}
          offset={[0, 0, contactSize / 2 + y + bottomOffsetY]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
      );
      break;
    }
    case SideRibType.DOWN: {
      const bottomOffsetY = ySize - contactSize;
      return (
        <Box
          xSize={sideRibPipe.d2}
          ySize={zSize}
          zSize={contactSize}
          bottomOffset={[0, bottomOffsetY]}
          defaultSurface={surface}
          offset={[0, 0, contactSize / 2 + y]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
      );
      break;
    }
    default: {
      return null;
    }
  }
});
