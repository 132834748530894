import React, { useEffect, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { pick } from "lodash";
import { OrderCustomerStrict } from "shared";
import { Breadcrumbs, Spinner } from "client-lib/ui";
import { Layout } from "src/features/layout";
import { useMyEnvironment } from "src/features/environment";
import { useHistory } from "react-router-dom";
import { AuthControl, useAuth } from "src/features/auth";
import {
  OrderEnvironmentQuery,
  OrderEnvironmentData,
  OrderEnvironmentVars,
} from "../../queries";
import { OrderCustomerForm } from "./order-customer-form";
import { CartToOrder, CartToOrderData, CartToOrderVars } from "./queries";
import { CartPageEmpty } from "../cart-page-empty";

export function CartOrderPage() {
  const { user } = useAuth();
  const myEnvironment = useMyEnvironment();
  const history = useHistory();

  const [fetch, { data, refetch }] = useLazyQuery<
    OrderEnvironmentData,
    OrderEnvironmentVars
  >(OrderEnvironmentQuery);

  const [cartToOrder, { loading: cartToOrderPending }] = useMutation<
    CartToOrderData,
    CartToOrderVars
  >(CartToOrder);

  useEffect(
    function queryWhenEnvReady() {
      if (!myEnvironment?.cartOrderId) {
        return;
      }

      fetch({
        variables: {
          orderId: myEnvironment.cartOrderId,
        },
      });
    },
    [myEnvironment, fetch]
  );

  useEffect(
    function updateWhenDeliveryCostReset() {
      if (!data?.OrderEnvironment.order.deliveryCostResetAt) {
        return;
      }
      refetch({
        orderId: data?.OrderEnvironment.order.id,
        shouldUpdateDeliveryCosts: true,
      });
    },
    [data, refetch]
  );

  const handleSubmit = useCallback(
    async (orderCustomer: OrderCustomerStrict) => {
      if (!data) {
        return;
      }
      await cartToOrder({
        variables: {
          orderId: data.OrderEnvironment.order.id,
          orderCustomer,
        },
      });
      history.push(`/account/orders/${data.OrderEnvironment.order.id}`);
    },
    [cartToOrder, data, useHistory]
  );

  if (!user || user.isGuest) {
    return (
      <Layout>
        <Breadcrumbs
          items={[
            { title: "Кабинет", url: "/account" },
            { title: "Корзина", url: "/account/cart" },
            { title: "Оформление заказа" },
          ]}
        />
        <h1>Оформление заказа</h1>
        <AuthControl>
          Оформить заказ можно только после регистрации.
          <br />
          Пожалуйста, зарегистрируйтесь или войдите в свой аккаунт на
          Метафабрике.
        </AuthControl>
      </Layout>
    );
  }

  if (myEnvironment && !myEnvironment.cartOrderId) {
    return (
      <CartPageEmpty
        hasAwaitingOrders={myEnvironment.awaitingOrdersCount > 0}
      />
    );
  }

  if (!data || !myEnvironment) {
    return (
      <Layout>
        <h1>Оформление заказа</h1>
        <Breadcrumbs
          items={[
            { title: "Кабинет", url: "/account" },
            { title: "Корзина", url: "/account/cart" },
            { title: "Оформление заказа" },
          ]}
        />
        <Spinner />
      </Layout>
    );
  }

  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Кабинет", url: "/account" },
          { title: "Корзина", url: "/account/cart" },
          { title: "Оформление заказа" },
        ]}
      />
      <h1>Оформление заказа</h1>
      <OrderCustomerForm
        initialValues={
          pick(
            data.OrderEnvironment.order,
            "deliveryRecipient",
            "deliveryRecipientPhone",
            "deliveryRecipientEmail",
            "deliveryRegion",
            "deliveryLocationId"
          ) as OrderCustomerStrict
        }
        onSubmit={handleSubmit}
        isSubmitting={cartToOrderPending}
      />
    </Layout>
  );
}
