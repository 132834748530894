import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { gutters, mutedGrey } from "../styles";

const Root = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
`;

const itemStyle = css``;

const ItemText = styled.div([itemStyle]);

const ItemLink = styled(Link)([itemStyle]);

const Separator = styled.div`
  display: inline-block;
  padding: 0 ${gutters.sm}px;
  font-size: 12px;
  color: ${mutedGrey};
`;

export type BreadcrumbItem = {
  title: string;
  url?: string;
};

export function Breadcrumbs({ items }: { items: BreadcrumbItem[] }) {
  return (
    <Root>
      {items.map(({ title, url }, index) => (
        <React.Fragment key={index}>
          {url ? (
            <ItemLink to={url!}>{title}</ItemLink>
          ) : (
            <ItemText>{title}</ItemText>
          )}
          {index < items.length - 1 && <Separator>&gt;</Separator>}
        </React.Fragment>
      ))}
    </Root>
  );
}
