import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { HEADER_BAR_HEIGHT, hairlineBottom, gutters } from "client-lib/styles";

export const PageHeader = styled.h2<{ fixed?: boolean }>(({ fixed }) => [
  css`
    line-height: ${HEADER_BAR_HEIGHT}px;
    font-weight: 400;
    margin: 0 ${-gutters.lg}px;
    padding: 0 ${gutters.lg}px;
    background-color: white;
    position: ${fixed ? "fixed" : "static"};
  `,
  fixed
    ? css`
        position: fixed;
        width: 100%;
        z-index: 1;
      `
    : null,
  hairlineBottom,
]);
