import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { black, mqMax, gutters } from "client-lib";

export const SLIDE_PADDING_V = {
  phone: 20,
  tablet: 40,
  desktop: 80,
};

export const SLIDE_PADDING_H = {
  phone: 20,
  tablet: 40,
  desktop: 80,
};

export const Slide = styled.div`
  position: relative;
  font-size: 20px;
  line-height: normal;
  font-weight: 500;
  ${mqMax.phone} {
    font-size: 18px;
  }
`;

export const ScreenSlide = styled(Slide)`
  min-height: 100vh;
`;

export const SlideContainer = styled.div`
  position: relative;
  padding: ${SLIDE_PADDING_V.desktop}px ${SLIDE_PADDING_H.desktop}px;
  ${mqMax.tablet} {
    padding: ${SLIDE_PADDING_V.tablet}px ${SLIDE_PADDING_H.tablet}px;
  }
  ${mqMax.phone} {
    padding: ${SLIDE_PADDING_V.phone}px ${SLIDE_PADDING_H.phone}px;
  }
`;

export const ScreenSlideContainer = styled(SlideContainer)`
  position: absolute;
  padding: 0;
  top: ${SLIDE_PADDING_V.desktop}px;
  bottom: ${SLIDE_PADDING_V.desktop}px;
  left: ${SLIDE_PADDING_H.desktop}px;
  right: ${SLIDE_PADDING_H.desktop}px;

  ${mqMax.tablet} {
    top: ${SLIDE_PADDING_V.tablet}px;
    bottom: ${SLIDE_PADDING_V.tablet}px;
    left: ${SLIDE_PADDING_H.tablet}px;
    right: ${SLIDE_PADDING_H.tablet}px;
  }
  ${mqMax.phone} {
    top: ${SLIDE_PADDING_V.phone}px;
    bottom: ${SLIDE_PADDING_V.phone}px;
    left: ${SLIDE_PADDING_H.phone}px;
    right: ${SLIDE_PADDING_H.phone}px;
  }
`;

export const ButtonArrow = styled.span`
  &:after {
    content: "\u27F6";
    position: relative;
    top: -0.075em;
  }
`;

export const typo70 = css`
  font-size: 70%;
`;
export const typo80 = css`
  font-size: 80%;
`;
export const typo100 = css`
  font-size: 100%;
`;
export const typo140 = css`
  font-size: 140%;
`;
export const typo160 = css`
  font-size: 160%;
`;
export const typo320 = css`
  font-size: 320%;
`;
export const typo450 = css`
  font-size: 450%;
`;

// export const Typo70 = styled.div(typo70);
export const Typo80 = styled.div(typo80);
export const Typo100 = styled.div(typo100);
export const Typo140 = styled.div([
  typo140,
  css`
    ${mqMax.phone} {
      font-size: 22px;
    }
  `,
]);
export const Typo160 = styled.div(typo160);
export const Typo320 = styled.div(typo320);
export const Typo450 = styled.div(typo450);

export const Header = styled.h2<{ color?: string }>(({ color = black }) => [
  typo320,
  css`
    margin-top: 0;
    line-height: normal;
    color: ${color};
    letter-spacing: -1.28px;
    ${mqMax.phone} {
      text-align: center;
      margin-top: ${gutters.xl}px;
      font-size: 48px;
    }
  `,
]);
