import { z } from "zod";

// export type Attribute = {
//   title?: string;
//   subSchemaTitle?: string;
//   isRequired?: boolean;
//   isImmutable?: boolean;
// };

// export interface ZAttribute<T> extends ZodType<T> {
//   attribute: Attribute;
// }

// export type ZAttributeAny = ZAttribute<any>;
//
// export function zAttribute<T>(
//   attribute: Attribute,
//   schema: T
// ): ZAttribute<T> {
//   return {
//     ...schema,
//     attribute,
//   } as unknown as ZAttribute<T>;
// }

export type SortOption<T extends Instance> = Partial<{
  [key in keyof T]: -1 | 1;
}>;

export type ArbitrarySortOption = Record<string, -1 | 1>;

export type Instance = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date | null;
};

export type InstanceBlank<T extends Instance> = Omit<
  T,
  "id" | "createdAt" | "updatedAt" | "deletedAt"
> & {
  id?: string;
};
export type InstanceBlankWithId<T extends Instance> = Omit<
  T,
  "id" | "createdAt" | "updatedAt" | "deletedAt"
> & {
  id: string;
};
export type Patch<T extends Instance> = Partial<
  Omit<T, "createdAt" | "updatedAt" | "id">
>;

export type ShapeByInstance<T extends Instance> = {
  [key in keyof T]: z.ZodTypeAny;
};

export type Schema<T extends Instance> =
  | z.ZodObject<ShapeByInstance<T>>
  //@ts-ignore
  | z.ZodEffects<z.ZodObject<ShapeByInstance<T>>>
  //@ts-ignore
  | z.ZodEffects<z.ZodEffects<z.ZodObject<ShapeByInstance<T>>>>;

// export interface Schema<T extends Instance> {
//   identity: string;
//   tableName?: string;
//   sort?: ArbitrarySortOption;
//   attributes:
//     | z.ZodObject<ShapeByInstance<T>>
//     //@ts-ignore
//     | z.ZodEffects<z.ZodObject<ShapeByInstance<T>>>
//     //@ts-ignore
//     | z.ZodEffects<z.ZodEffects<z.ZodObject<ShapeByInstance<T>>>>;
// }
//
// export type SchemaByInstance<T extends Instance> = Exclude<
//   Schema<T>,
//   "sort"
// > & {
//   sort?: SortOption<T>;
// };
//
export function checkSchema<T extends Instance>(_schema: Schema<T>) {}

export const standardAttributes = {
  id: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
};
export const standardAttributesSchema = z.object(standardAttributes);

export type StandardAttributes = z.infer<typeof standardAttributesSchema>;
