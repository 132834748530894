import React, { memo } from "react";
import styled from "@emotion/styled";
import { grey10, grey20, primary, gutters, mqMax } from "client-lib";
import {
  Slide,
  SlideContainer,
  Header,
  Typo140,
  typo100,
} from "src/features/layout";

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 570px;
  ${mqMax.phone} {
    display: block;
  }
`;

const Side = styled.div`
  width: 50%;
  position: relative;
  a {
    display: inline-block;
    margin-top: ${gutters.xxl}px;
  }
  ${mqMax.phone} {
    width: auto;
  }
`;
const Right = styled(Side)`
  margin-left: ${gutters.md}px;
  margin-top: -${gutters.xl}px;
  ${mqMax.phone} {
    margin-top: 0;
  }
`;

const Comment = styled(Typo140)`
  margin-bottom: ${gutters.xxl}px;
  margin-right: ${gutters.xl}px;
  ${mqMax.phone} {
    font-size: 100%;
  }
`;

const CommentSmall = styled(Comment)(typo100);

const CommentPrimary = styled(Comment)`
  color: ${primary};
  ${mqMax.phone} {
    text-align: center;
  }
`;

const Quote = styled.div`
  background-color: ${grey20};
  padding: ${gutters.xl}px;
  line-height: normal;
  margin-bottom: ${gutters.xl}px;
  p {
    margin: 0 0 ${gutters.lg}px 0;
  }
  ${mqMax.phone} {
    font-size: 100%;
  }
`;

const Author = styled.div`
  color: ${primary};
`;

export const WhoSlide = memo(function WhoSlide() {
  return (
    <Slide style={{ backgroundColor: grey10 }}>
      <SlideContainer>
        <Header>Кто мы?</Header>
        <Body>
          <Side>
            <Comment>
              Основатель и главный технолог Метафабрики Алексей Конышев не
              всегда занимался мебелью — ещё пару лет назад он работал в США над
              технологическими инновациями в стартапе по производству одежды.
              Стартап выкупил H&M — второй по величине fashion-ритейлер в мире.
            </Comment>
            <CommentSmall>
              Всё наше производство расположено в России. Мы не заказываем
              запчасти из Китая или других третьих стран. Поэтому наши цены не
              зависят от колебания курса валюты, а сроки доставки — от текущей
              ситуации на таможне/
            </CommentSmall>
            <CommentSmall>
              Мы постоянно ищем возможность повышать скорость и качество каждого
              процесса, а также работаем над расширением ассортимента. В
              перспективе он будет включать не только мебель.
            </CommentSmall>
            <CommentPrimary>
              Наша миссия — не просто делать качественный продукт, а сделать сам
              отечественный рынок мебели технологичным, прозрачным и
              конкурентоспособным
            </CommentPrimary>
          </Side>
          <Right>
            <Quote>
              <p>
                &laquo;Идея пришла мне в голову случайно, когда друг попросил
                помочь с мебелью для небольшого хостела, в котором он на тот
                момент был совладельцем
              </p>
              <p>
                Я понял, что мои наработки по конструктору одежды, который мы
                делали в Штатах, легко масштабируются именно для этой задачи.
              </p>
              <p>
                Технологию пришлось значительно переделать, чтобы адаптировать
                для мебели — и на уровне ПО, и на уровне процессов. В итоге
                получился удобный конструктор, который даёт возможность клиенту
                спроектировать мебель самостоятельно за пару кликов.&raquo;
              </p>
              <Author>&mdash; Алексей Конышев, основатель компании</Author>
            </Quote>
            <Quote>
              <p>
                &laquo;Так получилось, что за последние два-три года сразу
                несколько моих близких друзей решили начать своё дело на
                платформе Wildberries.
              </p>
              <p>
                Поэтому я не понаслышке знаю о том, какие проблемы могут
                возникнуть у предпринимателей из этой сферы, и со временем мы
                нашли модель производства, которая решает большинство их
                стандартных проблем&raquo;
              </p>
              <Author>&mdash; Артем Зырянов, руководитель производства</Author>
            </Quote>
          </Right>
        </Body>
      </SlideContainer>
    </Slide>
  );
});
