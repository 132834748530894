import React, { memo } from "react";
import { useField } from "formik";
import { DeliveryLocationSelector } from "./delivery-location-selector";

interface Props {
  regionFieldName: string;
  locationFieldName: string;
  className?: string;
  disabled?: boolean;
}

export const DeliveryLocationForm = memo(function DeliveryLocationForm({
  regionFieldName,
  locationFieldName,
  className,
  disabled,
}: Props) {
  const regionField = useField<string | null>(regionFieldName);
  const locationField = useField<string | null>(locationFieldName);

  return (
    <DeliveryLocationSelector
      disabled={disabled}
      className={className}
      region={regionField[0].value}
      regionError={Boolean(regionField[1].error && regionField[1].touched)}
      onRegionChange={regionField[2].setValue}
      deliveryLocationId={locationField[0].value}
      locationError={Boolean(
        locationField[1].error && locationField[1].touched
      )}
      onDeliveryLocationChange={locationField[2].setValue}
    />
  );
});
