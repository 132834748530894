import React, { useMemo } from "react";
import { useLocation, Link } from "react-router-dom";
import { Layout } from "src/features/layout";
import { SignUpBlock } from "./signup-block";

export function SignUpPage() {
  const { search } = useLocation();
  const redirectTo = useMemo<string | null>(() => {
    const redirectValue = new URLSearchParams(search).get("redirect");
    return redirectValue ? decodeURIComponent(redirectValue) : null;
  }, [search]);

  return (
    <Layout>
      <h1>Создать аккаунт</h1>
      <p>
        Просто укажите ваш e-mail, придумайте пароль и аккаунт готов.
        <br />
        Если у вас уже есть аккаунт, можете в него{" "}
        <Link to="/signin">войти</Link>
      </p>
      <SignUpBlock redirectTo={redirectTo || "/"} />
    </Layout>
  );
}
