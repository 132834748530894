import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";
import { templateShotVersionSchema } from "./template-shot-version";
import { templateShotSchema } from "./template-shot";
import { templatePresetSchema } from "./template-preset";

export const orderLineItemSchema = z.object({
  ...standardAttributes,
  orderId: z.string().uuid(),
  title: z.string(),
  templateShotVersionId: z.string().uuid(),
  amount: z.number().int().positive(),
  price: z.number().int().nonnegative(),
  priceCalculatedAt: z.date(),
  priceActualTill: z.date(),
});
checkSchema(orderLineItemSchema);
export type OrderLineItem = typeof orderLineItemSchema["_type"];

export const orderLineItemInputSchema = orderLineItemSchema.pick({
  id: true,
  templateShotVersionId: true,
  amount: true,
});
export type OrderLineItemInput = z.infer<typeof orderLineItemInputSchema>;

export const orderLineItemEnvironmentSchema = z.object({
  id: z.string().uuid(),
  orderLineItem: orderLineItemSchema,
  templateShotVersion: templateShotVersionSchema,
  templateShot: templateShotSchema,
  templatePreset: templatePresetSchema,
});
export type OrderLineItemEnvironment = z.infer<
  typeof orderLineItemEnvironmentSchema
>;
