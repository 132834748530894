import { z } from "zod";
import { unitTypeSchema } from "../unit";
import { techProcessCodeSchema } from "../tech-process";

export const decompositionItemSchema = z.object({
  title: z.string(),
  materialId: z.string().uuid().nullable(),
  techProcessCode: techProcessCodeSchema.nullable(),
  unitType: unitTypeSchema,
  unitAmount: z.number().nonnegative(),
  amount: z.number().nonnegative(),
  massKg: z.number().nonnegative().nullable(),
});
export type DecompositionItem = z.infer<typeof decompositionItemSchema>;
