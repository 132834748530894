import { z,ZodObject } from 'zod'

export function isEffects<T extends z.ZodRawShape>(
  objectOrEffects: any
): objectOrEffects is z.ZodEffects<z.ZodObject<T>> {
  return objectOrEffects.innerType;
}

export function getObjectFromEffects<T extends z.ZodRawShape>(
  objectOrEffects: z.ZodObject<T> | z.ZodEffects<z.ZodObject<T>>
): ZodObject<T> {
  return isEffects(objectOrEffects)
    ? objectOrEffects.innerType()
    : objectOrEffects;
}

export type Literal = boolean | null | number | string;
export type Json = Literal | { [key: string]: Json } | Json[];
export const literalSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.null(),
]);

export const jsonSchema: z.ZodSchema<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
);
