import { Config, Assets, Variables } from "./types";
import {
  TemplateConfigControlType,
  TemplateConfigControlGroup,
  TemplateController,
} from "../types";
import { ShelfConfigItem } from "./types";
import { SideRibType } from "../util-types";
import { SIDE_RIB_TYPE_TITLES } from "../constants";

import {
  MAX_LENGTH,
  MIN_LENGTH,
  MAX_WIDTH,
  MIN_WIDTH,
  MAX_HEIGHT,
  MAX_RACK_HEIGHT,
  MIN_HEIGHT,
  MAX_THICKNESS,
  MIN_THICKNESS,
  MIN_FRAME_GAP,
  MAX_FRAME_GAP,
  MIN_FRAME_WIDTH,
  MAX_FRAME_WIDTH,
  MIN_FRAME_HEIGHT,
  MAX_FRAME_HEIGHT,
  MAX_ITEMS_AMOUNT,
  MIN_ITEMS_AMOUNT,
  MIN_ITEM_WIDTH,
  MAX_ITEM_WIDTH,
  MIN_ITEM_MARGIN,
  MAX_ITEM_MARGIN,
} from "./constants";
import { MaterialExpanded } from "../../schemas";

interface ItemFieldTrack {
  index: number;
  field: keyof ShelfConfigItem;
}

function parseItemFieldTrack(fieldPath: string): ItemFieldTrack | null {
  const parseResult = /^items\[([0-9]+)]\.([a-z0-9]+)$/i.exec(fieldPath);
  if (!parseResult) {
    return null;
  }
  return {
    index: Number(parseResult[1]),
    field: parseResult[2] as keyof ShelfConfigItem,
  };
}

function getClonePatchByTrack(
  values: Config,
  track: ItemFieldTrack,
  value: number,
  patchToApply: Partial<Config>
): Config {
  const clone = {
    ...values,
    ...patchToApply,
  };
  clone.items[track.index][track.field] = value as never;

  return clone;
}

function getMaxLength(values: Config): number {
  let length = 0;
  for (const item of values.items) {
    if (item.length > length) {
      length = item.length;
    }
  }

  return length;
}

function getMaxLengthIndex(values: Config): number {
  let index = 0;
  for (let i = 0; i < values.items.length; i++) {
    const item = values.items[i];
    if (item.length > values.items[index].length) {
      index = i;
    }
  }
  return index;
}

function adjustWidth(values: Config): Partial<Config> | null {
  let patch: Partial<Config> | null = null;
  let maxAllowedItemWidth = values.width - values.frameMaterialHeight * 2;
  for (const item of values.items) {
    if (item.width > maxAllowedItemWidth) {
      if (!patch) {
        patch = {};
      }
      patch!.width = item.width + values.frameMaterialHeight * 2;
      patch!.frameWidth = patch!.width;
      maxAllowedItemWidth = item.width;
    }
  }
  return patch;
}

function getItemsDefinedHeight(values: Config, variables: Variables) {
  let totalHeight = 2 * values.frameMaterialHeight;

  if (variables.isRackLike) {
    totalHeight += values.frameBottomMargin;
  }

  for (let i = 0; i < values.items.length; i++) {
    const item = values.items[i];
    totalHeight += item.thickness;

    // first item doesn't have offset
    if (i < values.items.length - 1) {
      totalHeight += item.marginTop;
    }
  }
  return totalHeight;
}

function adjustHeightByBottomMargin(
  values: Config,
  variables: Variables
): number {
  let totalHeight = getItemsDefinedHeight(values, variables);
  let defect = totalHeight - values.height;

  // if not a rack, bottomMargin doesn't work
  if (!variables.isRackLike) {
    return defect;
  }

  values.frameBottomMargin -= defect;
  values.frameBottomMargin = Math.min(
    MAX_ITEM_MARGIN,
    Math.max(MIN_ITEM_MARGIN, values.frameBottomMargin)
  );
  totalHeight = getItemsDefinedHeight(values, variables);
  return totalHeight - values.height;
}

function adjustHeightByHeight(values: Config, variables: Variables): number {
  let totalHeight = getItemsDefinedHeight(values, variables);
  let defect = totalHeight - values.height;

  values.height += defect;
  values.height = values.frameHeight = Math.min(
    MAX_HEIGHT,
    Math.max(MIN_HEIGHT, values.height)
  );
  totalHeight = getItemsDefinedHeight(values, variables);
  return totalHeight - values.height;
}

function adjustHeightByItems(
  values: Config,
  variables: Variables,
  activeIndex: number | null
): number {
  let totalHeight = getItemsDefinedHeight(values, variables);
  let defect = totalHeight - values.height;

  // trying to fit by reducing margins
  for (let i = values.items.length - 1; i >= 0; i--) {
    const item = values.items[i];
    if (i !== activeIndex && i !== values.items.length - 1) {
      const availableChange = item.marginTop - MIN_ITEM_MARGIN;
      const fix = Math.min(defect, availableChange);
      item.marginTop -= fix;
      defect -= fix;
      if (defect <= 0) {
        break;
      }
    }
  }

  if (defect > 0) {
    // trying to fit by removing items
    for (let i = values.items.length - 1; i > 0; i--) {
      if (i !== activeIndex && i !== values.items.length - 1) {
        values.items.splice(i, 1);
        if (getItemsDefinedHeight(values, variables) < totalHeight) {
          break;
        }
      }
    }
  }

  totalHeight = getItemsDefinedHeight(values, variables);
  return totalHeight - values.height;
}

function adjustItemsHeight(
  values: Config,
  variables: Variables,
  priority: "height" | "frameBottomMargin" | number
) {
  let totalHeight = getItemsDefinedHeight(values, variables);
  let defect = totalHeight - values.height;
  if (defect <= 0) {
    return values;
  }

  switch (priority) {
    case "height":
      defect = adjustHeightByBottomMargin(values, variables);
      if (defect <= 0) {
        return values;
      }
      defect = adjustHeightByItems(values, variables, null);

      return values;
    case "frameBottomMargin":
      defect = adjustHeightByHeight(values, variables);
      if (defect <= 0) {
        return values;
      }
      defect = adjustHeightByItems(values, variables, null);

      return values;
    default:
      defect = adjustHeightByHeight(values, variables);
      if (defect <= 0) {
        return values;
      }
      defect = adjustHeightByBottomMargin(values, variables);
      if (defect <= 0) {
        return values;
      }
      adjustHeightByItems(values, variables, Number(priority));

      return values;
  }
}

export const controller: TemplateController<Config, Assets, Variables> = {
  getControlGroups: (
    configValues: Config,
    assets: Assets,
    variables: Variables
  ) => [
    {
      title: "Общие",
      controls: [
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "length",
          label: "Длина, мм",
          min: MIN_LENGTH,
          max: MAX_LENGTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "width",
          label: "Ширина, мм",
          min: MIN_WIDTH,
          max: MAX_WIDTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "height",
          label: "Высота, мм",
          min: MIN_HEIGHT,
          max: variables.isRackLike ? MAX_RACK_HEIGHT : MAX_HEIGHT,
        },
        {
          type: TemplateConfigControlType.CHECKBOX,
          name: "areShelvesSplit",
          label: "Разбить полки по центральным стойкам",
          hidden: configValues.frameItemsCount <= 2,
        },
      ],
    },
    {
      title: "Каркас",
      controls: [
        {
          type: TemplateConfigControlType.SELECTOR_NUMERIC,
          name: "frameItemsCount",
          label: "Количество стоек",
          items: [2, 3, 4].map((count: number) => ({
            title: `${count} стойки`,
            value: String(count),
          })),
        },
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "frameMaterialId",
          label: "Материал",
          items: assets.frameMaterials.map((m: MaterialExpanded) => ({
            title: m.title,
            value: m.id,
          })),
        },
        {
          type: TemplateConfigControlType.IMG_PICKER,
          name: "frameCoverageId",
          label: "Покрытие",
          nullable: true,
          items: assets.frameCoverage.map((m: MaterialExpanded) => ({
            title: m.pickerTitle || m.title,
            value: m.id,
            img: m.texture1?.path,
          })),
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "frameHeight",
          label: "Высота, мм",
          min: MIN_FRAME_HEIGHT,
          max: variables.isRackLike ? MAX_RACK_HEIGHT : MAX_FRAME_HEIGHT,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "frameWidth",
          label: "Ширина, мм",
          min: MIN_FRAME_WIDTH,
          max: MAX_FRAME_WIDTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "frameGap",
          label: "Расстояние между крайними стойками, мм",
          min: MIN_FRAME_GAP,
          max: MAX_FRAME_GAP,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "frameBottomMargin",
          label: "Отступ снизу, мм",
          hidden: !variables.isRackLike,
          min: MIN_ITEM_MARGIN,
          max: MAX_ITEM_MARGIN,
        },
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "frameBottomSideRibType",
          label: "Боковые ребра жесткости под нижней полкой",
          items: Object.keys(SIDE_RIB_TYPE_TITLES).map((type: string) => ({
            title: SIDE_RIB_TYPE_TITLES[type as SideRibType],
            value: type,
          })),
        },
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "frameBottomSideRibMaterialId",
          label: "Материал боковых ребер",
          hidden: configValues.frameBottomSideRibType === SideRibType.NONE,
          items: assets.ribMaterials.map((m: MaterialExpanded) => ({
            title: m.title,
            value: m.id,
          })),
        },
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "frameFootingId",
          label: "Подкладка/опора",
          nullable: true,
          items: assets.frameFooting.map((m: MaterialExpanded) => ({
            title: m.title,
            value: m.id,
          })),
        },
      ],
    },
    {
      title: "Полки",
      arrayConfig: {
        amountControlTitle: "Количество",
        itemTitle: (index: number) => `Полка #${index + 1}`,
        addTitle: "Добавить полку",
        name: "items",
        min: MIN_ITEMS_AMOUNT,
        max: MAX_ITEMS_AMOUNT,
      },
      controls: (index: number, _amount: number) => [
        {
          type: TemplateConfigControlType.IMG_PICKER,
          name: "coverageId",
          label: "Покрытие",
          nullable: true,
          items: assets.shelfCoverage.map((m: MaterialExpanded) => ({
            title: m.pickerTitle || m.title,
            value: m.id,
            img: m.texture1?.path,
          })),
        },
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "supportMaterialId",
          label: "Материал перекладины",
          hidden: index === 0 && !variables.isRackLike,
          items: assets.frameMaterials.map((m: MaterialExpanded) => ({
            title: m.title,
            value: m.id,
          })),
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "length",
          label: "Длина, мм",
          min: MIN_LENGTH,
          max: MAX_LENGTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "width",
          label: "Ширина, мм",
          min: MIN_ITEM_WIDTH,
          max: MAX_ITEM_WIDTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "thickness",
          label: "Толщина, мм",
          min: MIN_THICKNESS,
          max: MAX_THICKNESS,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "marginTop",
          label: "Высота над полкой",
          hidden: index >= configValues.items.length - 1,
          min: MIN_ITEM_MARGIN,
          max: MAX_ITEM_MARGIN,
        },
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "sideRibType",
          label: "Боковые ребра жесткости",
          items: Object.keys(SIDE_RIB_TYPE_TITLES).map((type: string) => ({
            title: SIDE_RIB_TYPE_TITLES[type as SideRibType],
            value: type,
          })),
        },
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "sideRibMaterialId",
          label: "Материал боковых ребер",
          hidden: configValues.items[index].sideRibType === SideRibType.NONE,
          items: assets.ribMaterials.map((m: MaterialExpanded) => ({
            title: m.title,
            value: m.id,
          })),
        },
      ],
    } as TemplateConfigControlGroup,
  ],
  onAttributeChange: (
    name: string,
    v: any,
    values: Config,
    assets: Assets,
    variables: Variables,
    setValues: (v: Config) => void
  ) => {
    const value = v as number;
    const track = parseItemFieldTrack(name);
    if (track) {
      switch (track.field) {
        case "length": {
          const patch: Partial<Config> = {};
          const minLengthWithExistingGap =
            values.frameGap + 2 * values.frameMaterialWidth;
          if (value < minLengthWithExistingGap) {
            patch.frameGap =
              values.frameGap - (minLengthWithExistingGap - value);
          }
          const nextValues = getClonePatchByTrack(values, track, value, patch);
          setValues({
            ...nextValues,
            length: getMaxLength(nextValues),
          });
          break;
        }
        case "width": {
          const patch = adjustWidth(values) || values;
          if (patch) {
            setValues(getClonePatchByTrack(values, track, value, patch));
          }
          break;
        }
        case "thickness":
        case "marginTop": {
          const fullPatch = getClonePatchByTrack(values, track, value, {});

          adjustItemsHeight(fullPatch, variables, track.index);
          setValues(fullPatch);
          break;
        }
        case "sideRibType": {
          const item = values.items[track.index];
          if (item && !item.sideRibMaterialId) {
            const fullPatch = getClonePatchByTrack(values, track, value, {});
            fullPatch.items[track.index].sideRibMaterialId =
              assets.ribMaterials[0]?.id || null;
            setValues(fullPatch);
          }
          break;
        }
      }
    } else {
      switch (name) {
        case "items": {
          const patch = {
            ...values,
            items: v as ShelfConfigItem[],
          };
          adjustItemsHeight(patch, variables, -1);
          setValues(patch);
          break;
        }

        case "frameBottomMargin": {
          const patch = {
            ...values,
            frameBottomMargin: value,
          };
          adjustItemsHeight(patch, variables, "frameBottomMargin");
          setValues(patch);
          break;
        }
        case "height":
        case "frameHeight": {
          const patch = {
            ...values,
            height: value,
            frameHeight: value,
          };
          adjustItemsHeight(patch, variables, "height");

          setValues(patch);
          break;
        }
        case "length": {
          const patch = {
            ...values,
            length: value,
          };
          const maxLengthIndex = getMaxLengthIndex(values);
          if (values.items[maxLengthIndex].length < value) {
            const delta = value - values.items[maxLengthIndex].length;
            for (const item of patch.items) {
              item.length += delta;
            }
          } else {
            for (const item of patch.items) {
              item.length = Math.min(item.length, value);
            }
            patch.frameGap = Math.min(
              patch.frameGap,
              value - 2 * values.frameMaterialWidth
            );
          }
          setValues(patch);
          break;
        }
        case "frameGap": {
          const patch = {
            ...values,
            frameGap: value,
          };
          const minItemLength = value + 2 * values.frameMaterialWidth;
          for (const item of patch.items) {
            if (item.length < minItemLength) {
              item.length = minItemLength;
            }
          }
          patch.length = getMaxLength(patch);
          setValues(patch);
          break;
        }
        case "width":
        case "frameWidth": {
          const patch: Partial<Config> = {
            width: value,
            frameWidth: value,
          };
          const maxAllowedItemWidth = value - values.frameMaterialWidth * 2;
          for (let i = 0; i < values.items.length; i++) {
            const item = values.items[i];
            if (item.width > maxAllowedItemWidth) {
              if (!patch.items) {
                patch.items = [...values.items];
              }
              patch.items[i].width = maxAllowedItemWidth;
            }
          }
          setValues({ ...values, ...patch });
          break;
        }
        case "frameMaterialId": {
          const frameMaterialId = value as unknown as string;
          const patch: Partial<Config> = { frameMaterialId };
          const frameMaterial =
            assets.frameMaterials.find((m) => m.id === frameMaterialId) || null;

          if (!frameMaterial?.mmDimension1 || !frameMaterial?.mmDimension2) {
            return;
          }
          patch.frameMaterialWidth = frameMaterial?.mmDimension1;
          patch.frameMaterialHeight = frameMaterial?.mmDimension2;

          const maxAllowedItemWidth =
            values.width - patch.frameMaterialHeight * 2;
          for (let i = 0; i < values.items.length; i++) {
            const item = values.items[i];
            if (item.width > maxAllowedItemWidth) {
              if (!patch.items) {
                patch.items = [...values.items];
              }
              patch.items[i].width = maxAllowedItemWidth;
            }
          }
          setValues({
            ...values,
            ...patch,
          });
          break;
        }
      }
    }
  },
};
