import React, {
  memo,
  useState,
  useLayoutEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import {
  white,
  grey10,
  grey20,
  gutters,
  borderRadius,
  Button,
  CaretRight,
  CaretLeft,
  mqMax,
} from "client-lib";
import { Slide, SlideContainer, Header } from "src/features/layout";
import { Gallery } from "./gallery";

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${mqMax.phone} {
    display: block;
  }
`;

const Navigation = styled.div`
  margin-top: ${gutters.xl}px;
  flex: 0 1 1;
  display: flex;
  align-items: center;
  ${mqMax.phone} {
    justify-content: center;
    margin-top: -${gutters.lg}px;
    margin-bottom: ${gutters.xl}px;
  }
`;

const CaretButton = styled(Button)`
  display: bloxk;
  padding: 0;
  height: 36px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${gutters.xs}px;
`;

const SCROLL_GAP = 30;
const Body = styled.div`
  position: relative;
  white-space: nowrap;
  margin-bottom: -${SCROLL_GAP}px;
  &:after {
    content: "";
    position: absolute;
    height: ${SCROLL_GAP}px;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${grey20};
  }
`;

const Scroller = styled.div`
  overflow-x: scroll;
  padding-bottom: ${SCROLL_GAP}px;
`;

const ScrollerLine = styled.div`
  display: flex;
  align-items: stretch;
`;

const REVIEW_MARGIN = gutters.md;
const Review = styled.div`
  white-space: normal;
  vertical-align: top;
  display: inline-block;
  background-color: ${grey10};
  position: relative;
  padding: ${gutters.xl}px ${gutters.xl}px ${gutters.xl}px 150px;
  ${mqMax.phone} {
    padding-left: 95px;
  }
  white-space: wrap;
  box-sizing: border-box;
  margin-right: ${REVIEW_MARGIN}px;
  border-radius: ${borderRadius.md}px;
  &:hover {
    background-color: ${white};
  }
`;

const Text = styled.div`
  p {
    margin: 0 0 ${gutters.xl}px 0;
  }
`;

const Avatar = styled.div`
  position: absolute;
  left: ${gutters.xl}px;
  top: ${gutters.xl}px;
  width: 100px;
  height: 100px;
  ${mqMax.phone} {
    width: 50px;
    height: 50px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const REVIEWS_COUNT = 4;

export const ReviewsSlide = memo(function ReviewsSlide() {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(() => {
    setWidth(Math.min(window.innerWidth - 24, 800));
  }, [setWidth]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();
    return () => window.removeEventListener("resize", updateWidth);
  }, [updateWidth]);

  const goTo = useCallback(
    (slideIndex: number) => {
      scrollerRef.current!.scrollTo({
        left: slideIndex * (width + REVIEW_MARGIN),
        top: 0,
        behavior: "smooth",
      });
    },
    [width]
  );

  const goLeft = useCallback(() => {
    const index = Math.ceil(
      scrollerRef.current!.scrollLeft / (width + REVIEW_MARGIN)
    );
    goTo(index > 0 ? index - 1 : REVIEWS_COUNT - 1);
  }, [width, goTo]);

  const goRight = useCallback(() => {
    const index = Math.ceil(
      scrollerRef.current!.scrollLeft / (width + REVIEW_MARGIN)
    );
    goTo(index < REVIEWS_COUNT - 1 ? index + 1 : 0);
  }, [width, goTo]);

  const reviewStyle = useMemo(
    () => ({
      flex: `${width}px 0 0`,
    }),
    [width]
  );

  return (
    <Slide style={{ backgroundColor: grey20 }}>
      <SlideContainer>
        <Controls>
          <Header>Что у нас получается</Header>
          <Navigation>
            <CaretButton onClick={goLeft}>
              <CaretLeft color={white} size={42} />
            </CaretButton>
            <CaretButton onClick={goRight}>
              <CaretRight color={white} size={42} />
            </CaretButton>
          </Navigation>
        </Controls>
        <Body>
          <Scroller ref={scrollerRef}>
            <ScrollerLine style={{ width: 4 * width }}>
              <Review style={reviewStyle} onClick={() => goTo(0)}>
                <Avatar>
                  <img src="/img/review/olin.jpg" />
                </Avatar>
                <Text>
                  <p>
                    &laquo;Моя самая любимая фича, которую я отмечу из своего
                    опыта работы с Метафабрикой — это даже не онлайн-конструктор
                    и прогрессивная технология ценообразования (хотя и они
                    серьёзно упростили мне жизнь).
                  </p>
                  <p>
                    Самое главное здесь для меня как мебельщика со стажем —
                    автогенерируемая инженерная документация. Издержки в
                    человекочасах на её составление сведены до нуля,
                    человеческий фактор и возможные ошибки в расчётах чертежах —
                    тоже. Везде бы так!&raquo;
                  </p>
                  <p>
                    &mdash; Аркадий Олин,
                    <br />
                    Руководитель мебельной фабрики Vintagist (Киров)
                  </p>
                </Text>
              </Review>
              <Review style={reviewStyle} onClick={() => goTo(1)}>
                <Avatar>
                  <img src="/img/review/sidorkin.jpg" />
                </Avatar>
                <Text>
                  <p>
                    &laquo;Заказывал у ребят партию мебели для одного из своих
                    постоянных клиентов — сети городских кафе. Понравилось, что
                    можно заказать тестовый экземпляр и убедиться в его качестве
                    и соответствии заданным параметрам перед заказом большой
                    партии.
                  </p>
                  <p>
                    Ещё понравился сам онлайн-конструктор, в нём ты на каждом
                    этапе видишь, за что платишь, плюс у тебя есть время семь
                    раз отмерить, прежде чем один раз отрезать, и ты тратишь это
                    время на реальные расчёты, а не бесконечные переговоры и
                    споры с подрядчиком&raquo;
                    <br />
                    <br />
                  </p>
                  <p>
                    &mdash; Валентин Сидоркин,
                    <br />
                    селлер Wildberries (Москва)
                  </p>
                </Text>
              </Review>
              <Review style={reviewStyle} onClick={() => goTo(2)}>
                <Avatar>
                  <img src="/img/review/porhovskih.jpg" />
                </Avatar>
                <Text>
                  <p>
                    &laquo;С уходом из страны IKEA, Jyck и других крупных
                    мебельных ритейлеров многие ищут достойные альтернативы по
                    цене и качеству. Ассортимент Метафабрики на данный момент не
                    покрывает все эти потребности, но я была приятно удивлена
                    сочетанием цены, качества дерева, деталей и сборки, а ещё —
                    возможности менять всё самостоятельно.
                  </p>
                  <p>
                    Уже заказала у ребят кучу разной мебели для клиентов, а для
                    себя смогла продумать здесь классное решение для собственной
                    лоджии. В будущем обязательно продолжу пользоваться
                    сервисом&raquo;
                  </p>
                  <p>
                    &mdash; Анастасия Порховских,
                    <br />
                    дизайнер интерьеров (Санкт-Петербург)
                  </p>
                </Text>
              </Review>
              <Review style={reviewStyle} onClick={() => goTo(3)}>
                <Avatar>
                  <img src="/img/review/uglev.jpg" />
                </Avatar>
                <Text>
                  <p>
                    &laquo;Оценил, что цена на доставку мебели транспортной
                    компанией сразу видна на сайте. Как человек, у которого за
                    последние пять лет было четыре переезда, могу сказать, что
                    это сильно экономит время, потраченное на общение с ТК.
                  </p>
                  <p>
                    Ещё порадовало натуральное дерево, теперь мою столешницу
                    приятно даже просто трогать руками. Онлайн-конструктор даёт
                    возможность продумать детали самому, поэтому получил
                    удовольствие от процесса и почувствовал себя гениальным
                    дизайнером мебели, хотя вообще-то работаю в IT&raquo;
                  </p>
                  <p>
                    &mdash; Андрей Углев,
                    <br />
                    Руководитель отдела мобильной разработки Ozon (Москва)
                  </p>
                </Text>
              </Review>
            </ScrollerLine>
          </Scroller>
        </Body>
        <Gallery />
      </SlideContainer>
    </Slide>
  );
});
