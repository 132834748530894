import React, { memo, useState, useMemo } from "react";
import styled from "@emotion/styled";
import { DecompositionPackage, TemplatePresetEnvironment } from "shared";
import { white, APP_BAR_HEIGHT, gutters, mqMax } from "client-lib";
import { ScreenSlide, Header, SlideContainer } from "src/features/layout";
import { ProductionProvider } from "src/features/order";
import { DeliveryScene } from "./delivery-scene";
import { PillSelector } from "./pill-selector";
import { DeliveryCalculator } from "./delivery-calculator";

const Container = styled(ScreenSlide)`
  height: calc(100vh - ${APP_BAR_HEIGHT}px);
  display: flex;
  ${mqMax.phone} {
    flex-direction: column-reverse;
    min-height: auto;
    height: auto;
  }
`;

const DeliveryPart = styled.div`
  box-sizing: border-box;
  width: 50%;
  color: ${white};
  ${mqMax.phone} {
    width: auto;
  }
`;

const StyledSlideContainer = styled(SlideContainer)`
  ${mqMax.phone} {
    min-height: auto;
  }
`;

const ScenePart = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 50%;
  ${mqMax.phone} {
    height: calc(70vh - ${APP_BAR_HEIGHT}px);
    width: auto;
  }
`;

const ModelSelector = styled(PillSelector)`
  position: absolute;
  left: ${gutters.xl}px;
  bottom: 74px;
`;

interface Props {
  templatePresetEnvironments: TemplatePresetEnvironment[];
}

export const DeliverySlide = memo(function DeliverySlide({
  templatePresetEnvironments,
}: Props) {
  const [presetIndex, setPresetIndex] = useState<number>(0);
  const [decompositionPackage, setDecompositionPackage] =
    useState<DecompositionPackage | null>(null);

  const presetTitles = useMemo(
    () => templatePresetEnvironments.map((e) => e.templatePreset.title),
    [templatePresetEnvironments]
  );

  return (
    <ProductionProvider initialAmount={5}>
      <Container>
        <DeliveryPart>
          <StyledSlideContainer>
            <Header color={white}>Мгновенный расчет доставки</Header>
            <DeliveryCalculator decompositionPackage={decompositionPackage} />
          </StyledSlideContainer>
        </DeliveryPart>
        <ScenePart>
          <DeliveryScene
            templateEnvironment={
              templatePresetEnvironments[presetIndex].templateEnvironment
            }
            templatePreset={
              templatePresetEnvironments[presetIndex].templatePreset
            }
            setDecompositionPackage={setDecompositionPackage}
          />
          <ModelSelector
            titles={presetTitles}
            activeIndex={presetIndex}
            setActiveIndex={setPresetIndex}
          />
        </ScenePart>
      </Container>
    </ProductionProvider>
  );
});
