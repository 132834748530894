import gql from "graphql-tag";
import { TemplatePreset, TemplateShotInput, TemplateShotActual } from "shared";
import {
  TEMPLATE_PRESET_FULL_FRAGMENT,
  TEMPLATE_SHOT_ACTUAL_FULL_FRAGMENT,
} from "client-lib";

export const UpdateTemplatePresetConfig = gql`
  mutation UpdateTemplatePresetConfig(
    $templatePresetId: String!
    $config: String!
  ) {
    UpdateTemplatePresetConfig(
      templatePresetId: $templatePresetId
      config: $config
    ) {
      ...TemplatePresetFullFragment
    }
  }
  ${TEMPLATE_PRESET_FULL_FRAGMENT}
`;

export interface UpdateTemplatePresetConfigVars {
  templatePresetId: string;
  config: string;
}

export type UpdateTemplatePresetConfigData = {
  UpdateTemplatePresetConfig: TemplatePreset;
};

export const CreateTemplateShot = gql`
  mutation CreateTemplateShot($input: TemplateShotInput!) {
    CreateTemplateShot(input: $input) {
      ...TemplateShotActualFullFragment
    }
  }
  ${TEMPLATE_SHOT_ACTUAL_FULL_FRAGMENT}
`;

export interface CreateTemplateShotVars {
  input: TemplateShotInput;
}

export type CreateTemplateShotData = {
  CreateTemplateShot: TemplateShotActual;
};

export const UpdateTemplateShotConfig = gql`
  mutation UpdateTemplateShotConfig(
    $templateShotId: String!
    $templateConfig: String!
  ) {
    UpdateTemplateShotConfig(
      templateShotId: $templateShotId
      templateConfig: $templateConfig
    ) {
      ...TemplateShotActualFullFragment
    }
  }
  ${TEMPLATE_SHOT_ACTUAL_FULL_FRAGMENT}
`;

export interface UpdateTemplateShotConfigVars {
  templateShotId: string;
  templateConfig: string;
}

export type UpdateTemplateShotConfigData = {
  UpdateTemplateShotConfig: TemplateShotActual;
};
