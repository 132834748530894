import { TechProcess, TechProcessCode } from "../schemas/tech-process";
import { UnitType } from "../schemas";

export const TechProcessPool: Record<TechProcessCode, TechProcess> = {
  [TechProcessCode.WOOD_SAWING_SIDE]: {
    code: TechProcessCode.WOOD_SAWING_SIDE,
    title: "Распил по древесине продольный",
    basicUnit: UnitType.CENTIMETER_2,
    settingUpSeconds: 120,
  },
  [TechProcessCode.WOOD_SAWING_END]: {
    code: TechProcessCode.WOOD_SAWING_END,
    title: "Распил по древесине поперечный",
    basicUnit: UnitType.CENTIMETER_2,
    settingUpSeconds: 60,
  },
  [TechProcessCode.WOOD_DRILLING]: {
    code: TechProcessCode.WOOD_DRILLING,
    title: "Сверление по древесине сквозное",
    basicUnit: UnitType.CENTIMETER_3,
    settingUpSeconds: 120,
  },
  [TechProcessCode.WOOD_DRILLING_BLIND]: {
    code: TechProcessCode.WOOD_DRILLING_BLIND,
    title: "Сверление по древесине несквозное",
    basicUnit: UnitType.CENTIMETER_3,
    settingUpSeconds: 180,
  },
  [TechProcessCode.WOOD_PLANING]: {
    code: TechProcessCode.WOOD_PLANING,
    title: "Строжка древесины",
    basicUnit: UnitType.CENTIMETER_3,
    settingUpSeconds: 0,
  },
  [TechProcessCode.WOOD_GRINDING]: {
    code: TechProcessCode.WOOD_GRINDING,
    title: "Шлифовка древесины",
    basicUnit: UnitType.METER_2,
    settingUpSeconds: 0,
  },
  [TechProcessCode.WOOD_OILING]: {
    code: TechProcessCode.WOOD_OILING,
    title: "Пропитка древесины маслом",
    basicUnit: UnitType.METER_2,
    settingUpSeconds: 180,
  },

  [TechProcessCode.STEEL_SAWING]: {
    code: TechProcessCode.STEEL_SAWING,
    title: "Распил по стали",
    basicUnit: UnitType.CENTIMETER_2,
    settingUpSeconds: 300,
  },
  [TechProcessCode.STEEL_GRINDING]: {
    code: TechProcessCode.STEEL_GRINDING,
    title: "Шлифовка по стали",
    basicUnit: UnitType.METER_2,
    settingUpSeconds: 60,
  },
  [TechProcessCode.STEEL_DRILLING]: {
    code: TechProcessCode.STEEL_DRILLING,
    title: "Сверление по стали сквозное",
    basicUnit: UnitType.CENTIMETER_3,
    settingUpSeconds: 120,
  },
  [TechProcessCode.STEEL_WELDING]: {
    code: TechProcessCode.STEEL_WELDING,
    title: "Сварка по стали",
    basicUnit: UnitType.METER,
    settingUpSeconds: 300,
  },
  [TechProcessCode.STEEL_POWDER_COATING]: {
    code: TechProcessCode.STEEL_POWDER_COATING,
    title: "Порошковая покраска",
    basicUnit: UnitType.METER_2,
    settingUpSeconds: 0,
  },

  [TechProcessCode.TESTING_ASSEMBLY]: {
    code: TechProcessCode.TESTING_ASSEMBLY,
    title: "Тестовая сборка",
    basicUnit: UnitType.HOUR,
    settingUpSeconds: 0,
  },
  [TechProcessCode.PACKING]: {
    code: TechProcessCode.PACKING,
    title: "Работы по упаковке",
    basicUnit: UnitType.METER_2,
    settingUpSeconds: 300,
  },
};
