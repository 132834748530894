import React from "react";
import { Table, TR, TD } from "client-lib";
import { useEnvironmentStrict } from "src/features/environment";

export function Requisites() {
  const environment = useEnvironmentStrict();

  return (
    <Table seamless dense autoWidth>
      <TR>
        <TD>Название организации:</TD>
        <TD>{environment.companyRequisites.title}</TD>
      </TR>
      <TR>
        <TD>ИНН:</TD>
        <TD>{environment.companyRequisites.inn}</TD>
      </TR>
      <TR>
        <TD>ОГРН:</TD>
        <TD>{environment.companyRequisites.ogrn}</TD>
      </TR>
      <TR>
        <TD>КПП:</TD>
        <TD>{environment.companyRequisites.kpp}</TD>
      </TR>
      <TR>
        <TD>Банк:</TD>
        <TD>{environment.companyRequisites.bankTitle}</TD>
      </TR>
      <TR>
        <TD>БИК банка:</TD>
        <TD>{environment.companyRequisites.bik}</TD>
      </TR>
      <TR>
        <TD>Расчетный счет:</TD>
        <TD>{environment.companyRequisites.bankAccount}</TD>
      </TR>
      <TR>
        <TD>Юридический адрес:</TD>
        <TD>{environment.companyRequisites.address}</TD>
      </TR>
      <TR>
        <TD>Телефон:</TD>
        <TD>{environment.companyRequisites.phone}</TD>
      </TR>
      <TR>
        <TD>Директор:</TD>
        <TD>{environment.companyRequisites.CEO}</TD>
      </TR>
    </Table>
  );
}
