import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Breadcrumbs, Spinner, Button, FormatDateTime } from "client-lib/ui";
import { Layout } from "src/features/layout";
import { useMyEnvironment } from "src/features/environment";
import { AuthControl } from "src/features/auth";
import { useOrderUpdateDeliveryCosts } from "src/features/order/service";
import { RefreshPriceButton } from "src/features/order/refresh-price-button";
import { CartPageEmpty } from "./cart-page-empty";
import { OrderBlock } from "../order-block";
import {
  OrderEnvironmentQuery,
  OrderEnvironmentData,
  OrderEnvironmentVars,
} from "../queries";

export function CartPage() {
  const myEnvironment = useMyEnvironment();

  const [fetch, { data, refetch }] = useLazyQuery<
    OrderEnvironmentData,
    OrderEnvironmentVars
  >(OrderEnvironmentQuery);

  useOrderUpdateDeliveryCosts(data, refetch);

  useEffect(
    function queryWhenEnvReady() {
      if (!myEnvironment?.cartOrderId) {
        return;
      }

      fetch({
        variables: {
          orderId: myEnvironment.cartOrderId,
        },
      });
    },
    [myEnvironment, fetch]
  );

  useEffect(
    function updateWhenDeliveryCostReset() {
      if (!data?.OrderEnvironment.order.deliveryCostResetAt) {
        return;
      }
      refetch({
        orderId: data?.OrderEnvironment.order.id,
        shouldUpdateDeliveryCosts: true,
      });
    },
    [data, refetch]
  );

  if (myEnvironment && !myEnvironment.cartOrderId) {
    return (
      <CartPageEmpty
        hasAwaitingOrders={myEnvironment.awaitingOrdersCount > 0}
      />
    );
  }

  if (!data || !myEnvironment) {
    return (
      <Layout>
        <Breadcrumbs
          items={[{ title: "Кабинет", url: "/account" }, { title: "Корзина" }]}
        />
        <Spinner />
      </Layout>
    );
  }

  const { order, orderLineItems, priceActualTill } = data.OrderEnvironment;
  const now = new Date();

  if (orderLineItems.length === 0) {
    return (
      <CartPageEmpty
        hasAwaitingOrders={myEnvironment.awaitingOrdersCount > 0}
      />
    );
  }

  return (
    <Layout>
      <Breadcrumbs
        items={[{ title: "Кабинет", url: "/account" }, { title: "Корзина" }]}
      />
      <h1>Корзина</h1>
      <AuthControl />
      {priceActualTill && priceActualTill > now ? (
        <p>
          Цены действительны до <FormatDateTime date={priceActualTill} />
        </p>
      ) : (
        <p>
          Срок актуальности указанных цен истек, нужно{" "}
          <RefreshPriceButton type="link" orderId={order.id}>
            пересчитать цены
          </RefreshPriceButton>
          .
        </p>
      )}
      <OrderBlock orderEnvironment={data.OrderEnvironment} isEditable />
      <br />

      <Link to="/account/cart/order">
        <Button size="L">Оформить заказ</Button>
      </Link>
    </Layout>
  );
}
