import React, { useCallback } from "react";
import { TemplateConfigArrayGroup } from "shared";
import { useField } from "formik";
import styled from "@emotion/styled";
import { gutters, Button } from "client-lib/styles";
import { ConfigArrayItem } from "./config-array-item";

const Container = styled.div`
  margin-bottom: ${gutters.md}px;
`;

interface Props<Config> {
  group: TemplateConfigArrayGroup;
  config: Config;
  onAttributeChange: (name: string, value: any) => void;
}

export function ConfigArrayGroup<Config>({
  group,
  config,
  onAttributeChange,
}: Props<Config>) {
  const [{ value }, , { setValue }] = useField<any[]>(group.arrayConfig.name);
  const items = config[group.arrayConfig.name as keyof Config];

  const handleDelete = useCallback(
    (index: number) => {
      setValue([...value.slice(0, index), ...value.slice(index + 1)]);
    },
    [value, setValue]
  );

  const handleAdd = useCallback(() => {
    const newValue = [...value, { ...value[value.length - 1] } || {}];
    setValue(newValue);
    onAttributeChange(group.arrayConfig.name, newValue);
  }, [value, setValue, onAttributeChange]);

  if (!Array.isArray(items)) {
    return <div>Ошибка конфигурации параметров</div>;
  }

  return (
    <Container>
      <h4>{group.title}</h4>
      {items.map((_, index: number) => (
        <ConfigArrayItem
          key={index}
          index={index}
          amount={items.length}
          onAttributeChange={onAttributeChange}
          disableDelete={value.length <= group.arrayConfig.min}
          onDelete={handleDelete}
          group={group}
        />
      ))}
      <Button
        size="XS"
        disabled={value.length >= group.arrayConfig.max}
        onClick={handleAdd}
      >
        {group.arrayConfig.addTitle}
      </Button>
    </Container>
  );
}
