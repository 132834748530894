import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { OrderLineItemEnvironment } from "shared";
import {
  TR,
  TD,
  Price,
  CloseIcon,
  Limits,
  gutters,
  typographyBody2,
  black,
  grey70,
} from "client-lib/ui";
import { useEnvironment } from "src/features/environment";
import { AmountForm } from "../amount-form";
import {
  UpdateOrderLineItemAmount,
  UpdateOrderLineItemAmountData,
  UpdateOrderLineItemAmountVars,
  DeleteOrderLineItem,
  DeleteOrderLineItemData,
  DeleteOrderLineItemVars,
} from "./queries";

const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  opacity: 0.9;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const TitleTD = styled(TD)`
  line-height: 1.2;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const TitleElement = styled.div([
  // typographyBody2,
  css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
    width: min-content;
    a {
      color: ${black};
    }
  `,
]);

const TitleVersion = styled(TitleElement)([
  typographyBody2,
  css`
    color: ${grey70};
  `,
]);

const TitleSeparator = styled(TitleElement)`
  padding: 0 ${gutters.xs}px;
  font-size: 8px;
`;

const limits: Limits = {
  min: 1,
  max: 100,
};

interface Props {
  orderLineItemEnvironment: OrderLineItemEnvironment;
  number: number;
  isEditable: boolean;
}

export function OrderLineItemRow({
  orderLineItemEnvironment,
  number,
  isEditable,
}: Props) {
  const { refreshEnvironment } = useEnvironment();
  const { orderLineItem, templatePreset, templateShot, templateShotVersion } =
    orderLineItemEnvironment;

  const [updateAmount] = useMutation<
    UpdateOrderLineItemAmountData,
    UpdateOrderLineItemAmountVars
  >(UpdateOrderLineItemAmount);

  const [deleteItem] = useMutation<
    DeleteOrderLineItemData,
    DeleteOrderLineItemVars
  >(DeleteOrderLineItem);

  const handleAmountChange = useCallback(
    async (amount: number) => {
      await updateAmount({
        variables: {
          orderLineItemId: orderLineItem.id,
          amount,
        },
      });
    },
    [orderLineItem.id]
  );

  const handleDeleteClick = useCallback(async () => {
    await deleteItem({
      variables: {
        orderLineItemId: orderLineItem.id,
      },
    });
    refreshEnvironment();
  }, [orderLineItem.id, deleteItem, refreshEnvironment]);

  const total = orderLineItem.price * orderLineItem.amount;

  return (
    <TR>
      <TD>{number}</TD>
      <TitleTD>
        <Title>
          <TitleElement>
            Шаблон{" "}
            <Link to={`/templates/${templatePreset.id}`}>
              {templatePreset.title}
            </Link>
          </TitleElement>
          <TitleSeparator>/</TitleSeparator>
          <TitleElement>
            Модель{" "}
            <Link
              to={`/account/models/${templateShot.id}/${templateShotVersion.number}`}
            >
              {templateShot.title}
            </Link>
          </TitleElement>
          <TitleElement>&nbsp;</TitleElement>
          <TitleVersion>(версия {templateShotVersion.number})</TitleVersion>
        </Title>
      </TitleTD>
      <TD align="center">
        {isEditable ? (
          <AmountForm
            amount={orderLineItem.amount}
            limits={limits}
            onSubmit={handleAmountChange}
          />
        ) : (
          orderLineItem.amount
        )}
      </TD>
      <TD align="right">
        <Price kopecks={orderLineItem.price} />
      </TD>
      <TD align="right">
        <Price kopecks={total} />
      </TD>
      {isEditable && (
        <TD>
          <CloseButton onClick={handleDeleteClick}>
            <CloseIcon />
          </CloseButton>
        </TD>
      )}
    </TR>
  );
}
