import React, { useContext } from "react";

type ConfigValue = {
  readOnly: boolean;
};

const ConfigContext = React.createContext<ConfigValue>({
  readOnly: false,
});

export function useConfig(): ConfigValue {
  return useContext(ConfigContext);
}

interface Props {
  readOnly: boolean;
  children: React.ReactNode;
}

export function ConfigProvider({ readOnly, children }: Props) {
  return (
    <ConfigContext.Provider
      value={{
        readOnly,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}
