import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";

export const templateShotVersionSchema = z.object({
  ...standardAttributes,
  number: z.number().int(),
  templateShotId: z.string().uuid(),
  templateConfig: z.string(),
});
checkSchema(templateShotVersionSchema);
export type TemplateShotVersion = z.infer<typeof templateShotVersionSchema>;
