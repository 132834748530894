import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import { AutoSubmitter, AmountField, Limits } from "client-lib/ui";
import { formikAmountSchema, Amount } from "./types";

const Container = styled(Form)`
  display: flex;
  align-items: center;
  input {
    width: 50px;
    text-align: center;
  }
`;

interface Props {
  amount: number;
  limits: Limits;
  onSubmit: (v: number) => void;
  disabled?: boolean;
}

export function AmountForm({ amount, onSubmit, limits, disabled }: Props) {
  const handleSubmit = useCallback(
    (values: Amount) => {
      onSubmit(values.amount);
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={{
        amount,
      }}
      validationSchema={formikAmountSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <Container>
        <AmountField name="amount" limits={limits} disabled={disabled} />
        <AutoSubmitter />
      </Container>
    </Formik>
  );
}
