import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { APP_BAR_HEIGHT, StdContainer, white } from "client-lib/styles";
import { AppBar } from "./app-bar";
import { Footer } from "./footer";

const Container = styled.div<{ bg: string }>(
  ({ bg }) => css`
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: ${bg};
  `
);

const Payload = styled.div`
  margin-top: ${APP_BAR_HEIGHT}px;
  flex: 1 0 auto;
`;

interface Props {
  children: ReactNode;
  seamless?: boolean;
  bg?: string;
}

export function Layout({ children, seamless, bg = white }: Props) {
  return (
    <Container bg={bg}>
      <AppBar />
      {seamless ? (
        <Payload>{children}</Payload>
      ) : (
        <Payload>
          <StdContainer responsivePadding>{children}</StdContainer>
        </Payload>
      )}
      <Footer />
    </Container>
  );
}
