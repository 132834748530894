import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { TemplateShotEnvironment } from "shared";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Lightbox from "react-image-lightbox";
import {
  gutters,
  borderRadius,
  whiteBlockShadow,
  white,
  grey30,
  grey70,
  getImageUrl,
  useBoolean,
  zLayers,
  typographyBody2,
  typographyBody3,
  AutoInput,
  Button,
  IconButton,
  MultiPageIcon,
  mqMax,
} from "client-lib";
import { useMutation } from "@apollo/client";
import {
  UpdateTemplateShotTitle,
  UpdateTemplateShotTitleData,
  UpdateTemplateShotTitleVars,
} from "./queries";

const IMAGE_SIZE = 150;

const Container = styled.div`
  display: flex;
  background-color: ${white};
  border-radius: ${borderRadius.lg}px;
  ${whiteBlockShadow};
  overflow: hidden;
  margin-bottom: ${gutters.sm}px;
  ${mqMax.phone} {
    flex-direction: column;
  }
`;

const ImageContainer = styled(Link)`
  position: relative;
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${grey30};
  overflow: hidden;
  ${mqMax.phone} {
    width: 100%;
    background: #999;
  }
`;

const Image = styled.div<{ imageUrl: string }>(
  ({ imageUrl }) => css`
    width: ${IMAGE_SIZE}px;
    height: ${IMAGE_SIZE}px;
    background-image: url(${imageUrl});
    background-size: cover;
  `
);
const EmptyImage = styled.div([
  typographyBody2,
  css`
    color: ${grey70};
  `,
]);

const GalleryButton = styled(IconButton)`
  position: absolute;
  right: ${gutters.sm}px;
  bottom: ${gutters.sm}px;

  transition: transform 0.25s;
  :hover {
    background-color: transparent;
    transform: scale(1.5);
  }
`;

const InfoContainer = styled(Link)`
  flex: 0 1 100%;
  padding: ${gutters.md}px ${gutters.xl}px ${gutters.md}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  ${mqMax.phone} {
    justify-content: flex-start;
  }
`;

const Info = styled.div`
  flex: 0 1 auto;
  h2 {
    margin-top: 0;
    line-height: 1;
    margin-bottom: ${gutters.xs}px;
  }
  ${mqMax.phone} {
    margin-bottom: ${gutters.lg}px;
    h2 {
      margin-bottom: 0px;
    }
  }
`;

const Version = styled.div([
  typographyBody2,
  css`
    color: ${grey70};
    margin-bottom: ${gutters.md}px;
  `,
]);

const TemplateBreadcrumbs = styled.div([
  typographyBody2,
  css`
    display: flex;
    align-items: center;
    div,
    a {
      color: ${grey70};
    }
  `,
]);

const Separator = styled.div([
  typographyBody3,
  css`
    padding: 0 ${gutters.xs}px;
  `,
]);

interface Props {
  templateShotEnvironment: TemplateShotEnvironment;
}

export function ShotCard({ templateShotEnvironment }: Props) {
  const { id, templateShotActual, templatePresetEnvironment } =
    templateShotEnvironment;
  const [editingStoppedAt, setEditingStoppedAt] = useState<Date | null>(null);
  const { templatePreset, images } = templatePresetEnvironment;
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [isLightboxOpen, { set: openLightbox, reset: closeLightbox }] =
    useBoolean(false);

  const [updateTitle] = useMutation<
    UpdateTemplateShotTitleData,
    UpdateTemplateShotTitleVars
  >(UpdateTemplateShotTitle);

  const handleTitleChange = useCallback(
    (title: string) => {
      updateTitle({
        variables: {
          templateShotId: id,
          title,
        },
      });
    },
    [updateTitle, id]
  );

  const handleIsTitleEditingChange = useCallback((isTitleEditing: boolean) => {
    if (!isTitleEditing) {
      setEditingStoppedAt(new Date());
    }
  }, []);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      const now = new Date();
      if (
        editingStoppedAt &&
        now.getTime() - editingStoppedAt.getTime() < 500
      ) {
        e.preventDefault();
      }
    },
    [editingStoppedAt]
  );

  const handleGalleryClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (images?.length) {
        openLightbox();
      }
    },
    [openLightbox, images?.length]
  );

  return (
    <Container>
      <ImageContainer to={`/account/models/${id}`}>
        {images.length > 0 ? (
          <Image
            imageUrl={getImageUrl(images[0].image.id)}
            onClick={openLightbox}
          />
        ) : (
          <EmptyImage>Нет фото</EmptyImage>
        )}
        {images?.length && (
          <GalleryButton onClick={handleGalleryClick}>
            <MultiPageIcon />
          </GalleryButton>
        )}
      </ImageContainer>
      <InfoContainer to={`/account/models/${id}`} onClick={handleClick}>
        <TemplateBreadcrumbs>
          <Link to="/templates">Шаблоны</Link>
          <Separator> / </Separator>
          <Link to={`/templates/${templatePreset.id}`}>
            {templatePreset.title}
          </Link>
          <Separator> / </Separator>
          <div>{templateShotActual.title}</div>
        </TemplateBreadcrumbs>
        <Info>
          <h2>
            <AutoInput
              value={templateShotActual.title}
              onChange={handleTitleChange}
              onIsEditingChange={handleIsTitleEditingChange}
            />
          </h2>
          <Version>Текущая версия: {templateShotActual.versionNumber}</Version>
          <Button size="S">Открыть</Button>
        </Info>
      </InfoContainer>
      {isLightboxOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: zLayers.MODAL,
            },
          }}
          mainSrc={getImageUrl(images[imageIndex].image.id)}
          nextSrc={
            imageIndex < images.length - 1
              ? getImageUrl(images[imageIndex + 1].image.id)
              : undefined
          }
          prevSrc={
            imageIndex > 0
              ? getImageUrl(images[imageIndex - 1].image.id)
              : undefined
          }
          onMovePrevRequest={() => setImageIndex(imageIndex - 1)}
          onMoveNextRequest={() => setImageIndex(imageIndex + 1)}
          onCloseRequest={closeLightbox}
          imageTitle={images[imageIndex].title}
        />
      )}
    </Container>
  );
}
