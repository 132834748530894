import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import DatePicker from "react-datepicker";
import { useField } from "formik";
import { format } from "date-fns";
import { IconButton, Button } from "../../ui";
import { CloseIcon } from "../../icons";
import { errorRed, grey40 } from "../../styles";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const PickerButton = styled(Button)<{ error?: boolean }>(
  ({ error }) => css`
    min-width: 130px;
    border-color: ${error ? errorRed : grey40};
  `
);

export function DateTimeField({
  name,
  hasTime,
  disabled,
  required,
  onChange,
}: {
  name: string;
  hasTime?: boolean;
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: Date | null) => void;
}) {
  const [{ value }, meta, { setValue }] = useField<Date | null>(name);

  const handleChange = useCallback(
    (value: Date | null) => {
      setValue(value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange, setValue]
  );

  return (
    <Container>
      <DatePicker
        selected={value || undefined}
        onChange={handleChange}
        dateFormat={"dd.MM.yyyy"}
        timeFormat={"HH:mm"}
        showTimeSelect={hasTime}
        disabled={disabled}
        required={required}
        locale={"ru-RU"}
        name={name}
        customInput={
          <PickerButton color={"transparent"} error={Boolean(meta.error)}>
            {value
              ? format(value, `dd MMM yyyy${hasTime ? " HH:mm" : ""}`)
              : "Не выбрано"}
          </PickerButton>
        }
      />
      {value && !required && (
        <IconButton onClick={() => setValue(null)}>
          <CloseIcon />
        </IconButton>
      )}
    </Container>
  );
}
