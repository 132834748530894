import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { white, grey20, gutters, Button, mqMax } from "client-lib";
import { Slide, SlideContainer, Header, Typo140 } from "src/features/layout";

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  button {
    margin-top: ${gutters.md}px;
  }
  ${mqMax.phone} {
    display: block;
    a {
      display: none;
    }
  }
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  min-height: 570px;
  ${mqMax.phone} {
    display: block;
  }
`;

const Side = styled.div`
  width: 50%;
  position: relative;
  a {
    display: inline-block;
    margin-top: ${gutters.xxl}px;
  }
  img {
    max-width: 86%;
  }
  ${mqMax.phone} {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${gutters.md}px;
    img {
      max-height: 300px;
    }
  }
`;

const Item = styled(Typo140)`
  background-color: ${grey20};
  padding: ${gutters.xl}px;
  margin-bottom: ${gutters.md}px;
`;

export const FrameSlide = memo(function FrameSlide() {
  return (
    <Slide style={{ backgroundColor: white }}>
      <SlideContainer>
        <Controls>
          <Header>Каркас</Header>
          <Link to="/templates">
            <Button size="L">Перейти в&nbsp;конструктор</Button>
          </Link>
        </Controls>
        <Body>
          <Side>
            <img src="/img/frame.jpg" />
          </Side>
          <Side>
            <Item>
              Каркасы всей мебели Метафабрики изготавливаются из стальной
              профилированной трубы. Это прочное, надежное и долговечное
              решение. Как&nbsp;и&nbsp;деревянные элементы, прослужат не один
              десяток лет.
            </Item>
            <Item>
              Защищаются каркасы порошковым покрытием. В&nbsp;отличие от жидких
              лакокрасочных покрытий, порошковый вариант дает более надежную
              защиту от&nbsp;коррозии и механических повреждений. Кроме того, он
              более равномерно распределяется по&nbsp;поверхности и лучше
              выглядит.
            </Item>
            <Item>
              Перед покрытием все сварочные швы тщательно зачищаются и
              зашлифовываются – поэтому каркасы идеально гладкие, и о&nbsp;них
              невозможно поцарапаться.
            </Item>
          </Side>
        </Body>
      </SlideContainer>
    </Slide>
  );
});
