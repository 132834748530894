import gql from "graphql-tag";
import { Environment } from "shared";
import { ENVIRONMENT_FULL_FRAGMENT } from "client-lib/fragments";

export const EnvironmentQuery = gql`
  query Environment {
    Environment {
      ...EnvironmentFullFragment
    }
  }
  ${ENVIRONMENT_FULL_FRAGMENT}
`;

export interface EnvironmentVars {}

export type EnvironmentData = {
  Environment: Environment;
};
