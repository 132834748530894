import { useState, useEffect, useMemo, useCallback } from "react";
import { DecompositionPackage } from "shared";
import { useLazyQuery } from "@apollo/client";
import { useProduction } from "src/features/order";
import {
  DeliveryEstimate,
  DeliveryEstimateData,
  DeliveryEstimateVars,
} from "./queries";

interface DeliveryEstimateResult {
  isLoading: boolean;
  isTerminalCostActual: boolean;
  isLocationCostActual: boolean;
  refresh: VoidFunction;
  tkTerminalKopecks: number | null;
  tkLocationKopecks: number | null;
}

export function useDeliveryEstimate(
  productPackage: DecompositionPackage | null,
  deliveryLocationId: string | null
): DeliveryEstimateResult {
  const [data, setData] = useState<
    DeliveryEstimateData["DeliveryEstimate"] | null
  >(null);
  const [variablesUpdatedAt, setVariablesUpdatedAt] = useState<Date>(
    new Date()
  );
  const [terminalVariablesUpdatedAt, setTerminalVariablesUpdatedAt] =
    useState<Date>(new Date());
  const [resultUpdatedAt, setResultUpdatedAt] = useState<Date | null>(null);
  const { amount } = useProduction();

  const terminalVariables = useMemo<Omit<
    DeliveryEstimateVars,
    "deliveryLocationId"
  > | null>(
    () =>
      productPackage
        ? {
            package: productPackage,
            amount,
          }
        : null,
    [productPackage, amount]
  );

  const variables = useMemo<DeliveryEstimateVars | null>(
    () =>
      terminalVariables
        ? {
            ...terminalVariables,
            deliveryLocationId,
          }
        : null,
    [terminalVariables, deliveryLocationId]
  );

  useEffect(
    function resetOnTerminalVariablesUpdate() {
      setTerminalVariablesUpdatedAt(new Date());
    },
    [terminalVariables]
  );

  useEffect(
    function resetOnVariablesUpdate() {
      setVariablesUpdatedAt(new Date());
    },
    [variables]
  );

  const [fetch, { loading }] = useLazyQuery<
    DeliveryEstimateData,
    DeliveryEstimateVars
  >(DeliveryEstimate, {
    fetchPolicy: "network-only",
    onCompleted: (freshData) => {
      setResultUpdatedAt(new Date());
      setData(freshData.DeliveryEstimate);
    },
  });

  const handleRefresh = useCallback(() => {
    if (!variables) {
      return;
    }
    fetch({ variables });
  }, [variables, fetch]);

  return {
    isLoading: loading,
    isTerminalCostActual: Boolean(
      resultUpdatedAt && resultUpdatedAt >= terminalVariablesUpdatedAt
    ),
    isLocationCostActual: Boolean(
      resultUpdatedAt && resultUpdatedAt >= variablesUpdatedAt
    ),
    refresh: handleRefresh,
    tkTerminalKopecks: data?.tkTerminalKopecks ?? null,
    tkLocationKopecks: data?.tkLocationKopecks ?? null,
  };
}
