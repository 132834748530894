import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs, Button } from "client-lib/ui";
import { Layout } from "src/features/layout";

interface Props {
  hasAwaitingOrders: boolean;
}

export function CartPageEmpty({ hasAwaitingOrders }: Props) {
  return (
    <Layout>
      <Breadcrumbs
        items={[{ title: "Кабинет", url: "/account" }, { title: "Корзина" }]}
      />
      <h1>Корзина</h1>
      {hasAwaitingOrders ? (
        <React.Fragment>
          <p>
            В корзине пока пусто. Проверьте свои заказы – некоторые ждут вашей
            оплаты.
          </p>
          <Link to="/account/orders">
            <Button>Заказы</Button>
          </Link>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            В корзине пока пусто. Перейдите в каталог щаблонов, сконструируйте и
            закажите свой первый товар.
          </p>
          <Link to="/templates">
            <Button>Каталог</Button>
          </Link>
        </React.Fragment>
      )}
    </Layout>
  );
}
