import React, { useMemo } from "react";
import { AnyLegalTerm, CompiledMarker, isLegalTerm, LegalTerm } from "./types";
import { LegalTerms } from "./legal-terms";
import { LegalTermsContext } from "./legal-terms-context";

function compileMarkers(
  currentIndex: string,
  markers: CompiledMarker[],
  terms: AnyLegalTerm[]
) {
  for (let i = 0; i < terms.length; i++) {
    const term = terms[i];
    if (!isLegalTerm(term)) {
      continue;
    }
    const termIndex = `${currentIndex ? `${currentIndex}.` : ""}${i + 1}`;
    if (term.marker) {
      markers.push({
        originalMarker: term.marker,
        actualIndex: termIndex,
      });
    }
    if (term.items) {
      compileMarkers(termIndex, markers, term.items);
    }
  }
}

interface Props {
  terms: LegalTerm[];
}

export function Legal({ terms }: Props) {
  const markers = useMemo<CompiledMarker[]>(() => {
    const markers: CompiledMarker[] = [];
    compileMarkers("", markers, terms);
    return markers;
  }, [terms]);

  const value = useMemo(
    () => ({
      markers,
    }),
    [markers]
  );

  return (
    <LegalTermsContext.Provider value={value}>
      <LegalTerms currentIndex="" terms={terms} />
    </LegalTermsContext.Provider>
  );
}
