import { pick, without } from "lodash";

export interface FormulaComponent<Config> {
  fieldName: keyof Config;
  coefficient: number;
  min: number;
  max: number;
  immutable?: boolean;
}

export interface Formula<Config> {
  min: number | null;
  max: number | null;
  components: FormulaComponent<Config>[];
}

export function getFormulaPatch<Config, Field extends keyof Config>(
  formula: Formula<Config>,
  values: Config,
  fieldName: Field,
  fieldValue: Config[Field],
  priorities: (keyof Config)[]
): Partial<Config> {
  const keys: (keyof Config)[] = formula.components.map((c) => c.fieldName);
  const result: Partial<Config> = pick(values, keys);
  result[fieldName] = fieldValue;

  const sequence: (keyof Config)[] = [];
  for (const key of priorities) {
    if (key === fieldName) {
      continue;
    }
    if (!keys.includes(key)) {
      continue;
    }
    sequence.push(key);
  }

  const remain: (keyof Config)[] = without<keyof Config>(keys, ...sequence);
  for (const key of remain) {
    if (key === fieldName) {
      continue;
    }
    sequence.push(key);
  }

  const sum = formula.components.reduce((acc: number, component) => {
    return acc + component.coefficient * Number(result[component.fieldName]);
  }, 0);

  let defect = sum;
  if (formula.min !== null || formula.max !== null) {
    defect = 0;
    if (formula.max !== null && sum > formula.max) {
      defect = sum - formula.max;
    } else if (formula.min !== null && sum < formula.min) {
      defect = sum - formula.min;
    }
  }

  if (defect === 0) {
    return result;
  }

  for (const key of sequence) {
    if (defect === 0) {
      break;
    }
    const component: FormulaComponent<Config> = formula.components.find(
      (c) => c.fieldName === key
    )!;
    if (component.immutable) {
      continue;
    }
    const desiredDelta = -defect / component.coefficient;
    const currentValue = Number(result[component.fieldName]);
    const desiredValue = Math.round(currentValue + desiredDelta);
    const updatedValue = Math.min(
      Math.max(desiredValue, component.min),
      component.max
    );
    const updatedDelta = updatedValue - currentValue;
    defect += updatedDelta * component.coefficient;
    result[component.fieldName] =
      updatedValue as unknown as Config[keyof Config];
  }

  return result;
}
