import React, { memo } from "react";
import styled from "@emotion/styled";
import { StickyNavigationContainer } from "./layout";

const Container = styled(StickyNavigationContainer)`
  background-color: lightgreen;
`;

export const StageNavigation = memo(function StageNavigation() {
  return (
    <Container>
      <h1>NAVIGATION</h1>
    </Container>
  );
});
