import { withField } from "./formik-field-wrapper";
import { StyledInput } from "./styles";

export const IntField = withField(StyledInput, {
  translateInputValue: (rawInputValue: string) => {
    if (rawInputValue === "") {
      return null;
    }
    const numericValue = Number(rawInputValue);
    if (
      Number.isNaN(numericValue) ||
      parseInt(rawInputValue) !== numericValue
    ) {
      return rawInputValue;
    }
    return numericValue;
  },
});
