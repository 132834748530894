import React, { memo, useState, useEffect, useRef, ChangeEvent } from "react";
import { useLazyQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { regionsRF } from "shared";
import { StyledSelect, gutters, white, grey70 } from "client-lib/ui";
import { Spinner } from "@vkontakte/vkui";
import {
  DeliveryLocations,
  DeliveryLocationsData,
  DeliveryLocationsVars,
} from "./queries";

const Container = styled.div`
  display: flex;
  align-items: center;
  select {
    background-color: ${white};
    margin-right: ${gutters.sm}px;
  }
`;
const Section = styled.section`
  width: 178px;
  min-height: 36px;
  display: flex;
  align-items: center;
  margin-bottom: ${gutters.md}px;
`;
const ChildrenSection = styled(Section)`
  width: auto;
  min-height: 0;
`;

const LabelWrapper = styled.label`
  width: 100%;
`;

const Label = styled.div`
  color: ${grey70};
  margin-bottom: ${gutters.xxs}px;
`;

const REGION_OPTIONS = regionsRF.map((region) => (
  <option key={region.code} value={region.code}>
    {region.name}
  </option>
));

interface Props {
  region: string | null;
  deliveryLocationId: string | null;
  onDeliveryLocationChange: (v: string | null) => void;
  onRegionChange?: (v: string | null) => void;
  className?: string;
  withLabels?: boolean;
  regionError?: boolean;
  locationError?: boolean;
  required?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const DeliveryLocationSelector = memo(function DeliveryLocationSelector({
  region,
  deliveryLocationId,
  onDeliveryLocationChange,
  onRegionChange,
  className,
  withLabels,
  regionError,
  locationError,
  required,
  disabled,
  children,
}: Props) {
  const initializationRef = useRef<boolean>(false);
  const regionRef = useRef<string | null>(region);
  const [deliveryLocations, setDeliveryLocations] =
    useState<JSX.Element | null>(null);
  const deliveryLocationRef = useRef<string | null>(deliveryLocationId);

  useEffect(
    function updateDeliveryLocationRef() {
      deliveryLocationRef.current = deliveryLocationId;
    },
    [deliveryLocationId]
  );

  const [queryDeliveryLocations, { loading }] = useLazyQuery<
    DeliveryLocationsData,
    DeliveryLocationsVars
  >(DeliveryLocations);

  useEffect(
    function handleRegionChange() {
      const isInitialRun = !initializationRef.current;
      initializationRef.current = true;

      const formerLocationId = deliveryLocationRef.current;
      if (regionRef.current === region && !isInitialRun) {
        return;
      }
      if (!isInitialRun) {
        onDeliveryLocationChange(null);
      }
      if (!region) {
        return;
      }
      regionRef.current = region;
      queryDeliveryLocations({
        variables: {
          regionCode: region,
        },
        onCompleted(data) {
          setDeliveryLocations(
            <React.Fragment>
              {data.DeliveryLocations.map((deliveryLocation) => (
                <option key={deliveryLocation.id} value={deliveryLocation.id}>
                  {deliveryLocation.title}
                </option>
              ))}
            </React.Fragment>
          );
          if (!isInitialRun || !deliveryLocationRef.current) {
            const locationIdToSet =
              formerLocationId &&
              data.DeliveryLocations.find(
                (regionLocation) => formerLocationId === regionLocation.id
              )
                ? formerLocationId
                : data.DeliveryLocations[0]?.id ?? null;
            onDeliveryLocationChange(locationIdToSet);
          }
        },
      });
    },
    [region, queryDeliveryLocations, onDeliveryLocationChange]
  );

  const Wrapper = withLabels ? LabelWrapper : React.Fragment;

  return (
    <Container className={className}>
      <Section>
        <Wrapper>
          {withLabels && <Label>Регион:</Label>}
          <StyledSelect
            error={regionError || (required && !region)}
            value={region || ""}
            disabled={disabled}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              onRegionChange?.(e.target.value)
            }
          >
            <option disabled value="">
              Выберите регион
            </option>
            {REGION_OPTIONS}
          </StyledSelect>
        </Wrapper>
      </Section>
      {loading ? (
        <Section>
          <Spinner size="small" />
        </Section>
      ) : deliveryLocations ? (
        <Section>
          <Wrapper>
            {withLabels && <Label>Город:</Label>}
            <StyledSelect
              error={locationError || (required && !deliveryLocationId)}
              value={deliveryLocationId || ""}
              disabled={disabled}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                onDeliveryLocationChange(e.target.value)
              }
            >
              <option disabled value="">
                Выберите город
              </option>
              {deliveryLocations}
            </StyledSelect>
          </Wrapper>
        </Section>
      ) : null}
      {children && <ChildrenSection>{children}</ChildrenSection>}
    </Container>
  );
});
