import React, { useCallback } from "react";
import { Button } from "client-lib/ui";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { TemplateShotActual } from "shared";
import { useTemplate } from "src/features/templates/template-context";
import {
  UpdateTemplateShotConfig,
  UpdateTemplateShotConfigData,
  UpdateTemplateShotConfigVars,
} from "../queries";
import { CreateTemplateShotButton } from "./create-template-shot-button";

export function TemplateShotBlock() {
  const [updateShotConfig, { loading }] = useMutation<
    UpdateTemplateShotConfigData,
    UpdateTemplateShotConfigVars
  >(UpdateTemplateShotConfig);

  const {
    templateShotActual,
    isModified,
    templateConfig,
    templatePreset,
    onSave,
  } = useTemplate();
  const history = useHistory();

  const handleUpdateShot = useCallback(async () => {
    if (!templateShotActual) {
      return;
    }
    await updateShotConfig({
      variables: {
        templateShotId: templateShotActual.id,
        templateConfig: JSON.stringify(templateConfig),
      },
    });
    onSave();
  }, [updateShotConfig, templateShotActual, templateConfig, onSave]);

  const handleCreateShot = useCallback(
    async (shot: TemplateShotActual) => {
      onSave();
      history.push(`/account/models/${shot.id}`);
    },
    [history, onSave]
  );

  if (!isModified && templateShotActual) {
    return null;
  }

  if (!templateShotActual) {
    return (
      <CreateTemplateShotButton
        onCreate={handleCreateShot}
        titleSuggestion={`${templatePreset.title} #1`}
        templateConfig={JSON.stringify(templateConfig)}
      />
    );
  }

  return (
    <React.Fragment>
      <Button
        size="XS"
        color="primary"
        onClick={handleUpdateShot}
        disabled={loading || !isModified}
      >
        Сохранить изменения
      </Button>
      <CreateTemplateShotButton
        seamless
        onCreate={handleCreateShot}
        titleSuggestion={`${templatePreset.title} #1`}
        templateConfig={JSON.stringify(templateConfig)}
      >
        Сохранить как новую модель
      </CreateTemplateShotButton>
    </React.Fragment>
  );
}
