import { css } from "@emotion/react";
import { borderGrey, trueBlack, withAlpha } from "./colors";

export const insetBorder = (
  width = 0.5,
  color = withAlpha(trueBlack, 0.1)
): string => `
  box-shadow: inset 0 0 0 ${width}px ${color};
`;

type BoxSide = "top" | "right" | "bottom" | "left";
export interface BorderOptions {
  color: string;
  radius: number;
  sides: BoxSide[];
  width: number;
  otherBorders?: string[];
}
const defaultBorderOptions: BorderOptions = {
  color: borderGrey,
  radius: 0,
  sides: ["top", "right", "bottom", "left"],
  otherBorders: [],
  width: 0.5,
};
export const hairline = (
  options: Partial<BorderOptions> = defaultBorderOptions
): string => {
  const withDefaults: BorderOptions = {
    ...defaultBorderOptions,
    ...options,
  };
  const { width } = withDefaults;
  const offset = width * 2;
  const shadows = withDefaults.sides
    .map((side: BoxSide): string => {
      switch (side) {
        case "top":
          return `inset 0 ${offset}px 0 -${width}px`;
        case "right":
          return `inset -${offset}px 0 0 -${width}px`;
        case "bottom":
          return `inset 0 -${offset}px 0 -${width}px`;
        case "left":
          return `inset ${offset}px 0 0 -${width}px`;
        default:
          throw new Error(`Unknown BoxSide "${side}"`);
      }
    })
    .map(
      (shadowString: string): string => `${shadowString} ${withDefaults.color}`
    );
  const loDpiShadows = withDefaults.sides
    .map((side: BoxSide): string => {
      const loDpiWidth = Math.ceil(width);
      const loDpiOffset = Math.ceil(loDpiWidth * 2);
      switch (side) {
        case "top":
          return `inset 0 ${loDpiOffset}px 0 -${loDpiWidth}px`;
        case "right":
          return `inset -${loDpiOffset}px 0 0 -${loDpiWidth}px`;
        case "bottom":
          return `inset 0 -${loDpiOffset}px 0 -${loDpiWidth}px`;
        case "left":
          return `inset ${loDpiOffset}px 0 0 -${loDpiWidth}px`;
        default:
          throw new Error(`Unknown BoxSide "${side}"`);
      }
    })
    .map(
      (shadowString: string): string => `${shadowString} ${withDefaults.color}`
    );

  const radius = withDefaults.radius
    ? `border-radius: ${withDefaults.radius}px;`
    : "";
  return `
    box-shadow: ${[...(withDefaults.otherBorders || []), ...shadows].join(
      ", "
    )};
    ${radius}

    @media (max-resolution: 1dppx), (-webkit-max-device-pixel-ratio: 1) {
      box-shadow: ${[
        ...(withDefaults.otherBorders || []),
        ...loDpiShadows,
      ].join(", ")};
    }`;
};

export const hairlineRight = css`
  ${hairline({ sides: ["right"] })}
`;

export const hairlineBottom = css`
  ${hairline({ sides: ["bottom"] })}
`;

export const hairlineTopAndBottom = css`
  ${hairline({ sides: ["top", "bottom"] })}
`;
