import { withField } from "./formik-field-wrapper";
import { StyledInput } from "./styles";

export const FloatField = withField(StyledInput, {
  translateInputValue: (rawInputValue: string) => {
    if (rawInputValue === "") {
      return null;
    }

    // the inputs ending up with a period (like "1.") are treated by correct numbers by JS
    // but we should not transform them to numbers, because it will throw out the period
    // which makes typing period at the end impossible for users
    if (
      typeof rawInputValue === "string" &&
      rawInputValue[rawInputValue.length - 1] === "."
    ) {
      return rawInputValue;
    }

    const numericValue = Number(rawInputValue);
    if (Number.isNaN(numericValue)) {
      return rawInputValue;
    }
    return numericValue;
  },
});
