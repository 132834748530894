import { Config, Assets, Variables } from "./types";
import { TemplateConfigControlType, TemplateController } from "../types";

import {
  MAX_LENGTH,
  MIN_LENGTH,
  MAX_WIDTH,
  MIN_WIDTH,
  MAX_HEIGHT,
  MIN_HEIGHT,
  MAX_THICKNESS,
  MIN_THICKNESS,
  MIN_FRAME_GAP,
  MIN_BUTT_OFFSET,
  MAX_BUTT_OFFSET,
  MIN_EDGE_OFFSET,
  MIN_FRAME_TOP_WIDTH,
  MAX_FRAME_TOP_WIDTH,
  MAX_EDGE_OFFSET,
  MIN_FRAME_HEIGHT,
  MAX_FRAME_HEIGHT,
  MIN_SUPPORT_LENGTH,
  MAX_SUPPORT_LENGTH,
  MIN_SUPPORT_GAP,
  MAX_SUPPORT_GAP,
} from "./constants";
import { MaterialExpanded } from "../../schemas";
import { Formula, getFormulaPatch } from "../formula";

const heightFormula: Formula<Config> = {
  min: null,
  max: null,
  components: [
    {
      fieldName: "frameHeight",
      coefficient: 1,
      min: MIN_FRAME_HEIGHT,
      max: MAX_FRAME_HEIGHT,
    },
    {
      fieldName: "countertopThickness",
      coefficient: 1,
      min: MIN_THICKNESS,
      max: MAX_THICKNESS,
    },
    {
      fieldName: "height",
      coefficient: -1,
      min: MIN_HEIGHT,
      max: MAX_HEIGHT,
    },
  ],
};

const lengthFormula: Formula<Config> = {
  min: MIN_FRAME_GAP,
  max: null,
  components: [
    {
      fieldName: "countertopLength",
      coefficient: 1,
      min: MIN_LENGTH,
      max: MAX_LENGTH,
    },
    {
      fieldName: "frameButtOffset",
      coefficient: -2,
      min: MIN_BUTT_OFFSET,
      max: MAX_BUTT_OFFSET,
    },
    {
      fieldName: "frameMaterialWidth",
      coefficient: -2,
      min: 0,
      max: 0,
      immutable: true,
    },
  ],
};

const widthFormula: Formula<Config> = {
  min: null,
  max: null,
  components: [
    {
      fieldName: "countertopWidth",
      coefficient: -1,
      min: MIN_WIDTH,
      max: MAX_WIDTH,
    },
    {
      fieldName: "frameEdgeOffset",
      coefficient: 2,
      min: MIN_EDGE_OFFSET,
      max: MAX_EDGE_OFFSET,
    },
    {
      fieldName: "frameTopWidth",
      coefficient: 1,
      min: MIN_FRAME_TOP_WIDTH,
      max: MAX_FRAME_TOP_WIDTH,
    },
  ],
};

const supportFormula: Formula<Config> = {
  min: 0,
  max: null,
  components: [
    {
      fieldName: "frameTopWidth",
      coefficient: 1,
      min: MIN_FRAME_TOP_WIDTH,
      max: MAX_FRAME_TOP_WIDTH,
    },
    {
      fieldName: "supportGap",
      coefficient: -1,
      min: MIN_SUPPORT_GAP,
      max: MAX_SUPPORT_GAP,
    },
    {
      fieldName: "frameMaterialWidth",
      coefficient: -2,
      min: 0,
      max: 0,
      immutable: true,
    },
  ],
};

export const controller: TemplateController<Config, Assets, Variables> = {
  getControlGroups: (
    configValues: Config,
    assets: Assets,
    variables: Variables
  ) => [
    {
      title: "Габариты",
      controls: [
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "length",
          label: "Длина, мм",
          min: MIN_LENGTH,
          max: MAX_LENGTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "width",
          label: "Ширина, мм",
          min: MIN_WIDTH,
          max: MAX_WIDTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "height",
          label: "Высота, мм",
          min: MIN_HEIGHT,
          max: MAX_HEIGHT,
        },
      ],
    },
    {
      title: variables.countertopTitle,
      controls: [
        {
          type: TemplateConfigControlType.IMG_PICKER,
          name: "countertopCoverageId",
          label: "Покрытие",
          nullable: true,
          items: assets.countertopCoverage.map((m: MaterialExpanded) => ({
            title: m.pickerTitle || m.title,
            value: m.id,
            img: m.texture1?.path,
          })),
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "countertopLength",
          label: "Длина, мм",
          min: MIN_LENGTH,
          max: MAX_LENGTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "countertopWidth",
          label: "Ширина, мм",
          min: MIN_WIDTH,
          max: MAX_WIDTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "countertopThickness",
          label: "Толщина, мм",
          min: MIN_THICKNESS,
          max: MAX_THICKNESS,
        },
      ],
    },
    {
      title: variables.frameTitle,
      controls: [
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "frameMaterialId",
          label: "Материал",
          items: assets.frameMaterials.map((m: MaterialExpanded) => ({
            title: m.title,
            value: m.id,
          })),
        },
        {
          type: TemplateConfigControlType.CHECKBOX,
          name: "frameHasBottomBar",
          label: "Есть нижняя перекладина",
        },
        {
          type: TemplateConfigControlType.IMG_PICKER,
          name: "frameCoverageId",
          label: "Покрытие",
          nullable: true,
          items: assets.frameCoverage.map((m: MaterialExpanded) => ({
            title: m.pickerTitle || m.title,
            value: m.id,
            img: m.texture1?.path,
          })),
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "frameHeight",
          label: "Высота, мм",
          min: MIN_FRAME_HEIGHT,
          max: MAX_FRAME_HEIGHT,
        },
        {
          type: TemplateConfigControlType.CHECKBOX,
          name: "frameWidthsAreEqual",
          label: "Верхняя и нижняя ширина одинаковы",
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "frameTopWidth",
          label: configValues.frameWidthsAreEqual
            ? "Ширина, мм"
            : "Верхняя ширина, мм",
          min: MIN_FRAME_TOP_WIDTH,
          max: MAX_FRAME_TOP_WIDTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "frameBottomWidth",
          label: "Нижняя ширина, мм",
          min: MIN_FRAME_TOP_WIDTH,
          max: MAX_WIDTH,
          hidden: configValues.frameWidthsAreEqual,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "frameEdgeOffset",
          label: "Отступ по краям, мм",
          min: MIN_EDGE_OFFSET,
          max: MAX_EDGE_OFFSET,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "frameButtOffset",
          label: "Отступ от торцов, мм",
          min: MIN_BUTT_OFFSET,
          max: MAX_BUTT_OFFSET,
        },
        {
          type: TemplateConfigControlType.SELECTOR_NUMERIC,
          name: "supportAmount",
          label: "Количество подпорок",
          items: [
            { title: "Без подпорок", value: "0" },
            { title: "Одна подпорка (на сторону)", value: "1" },
            { title: "Две подпорки (на сторону)", value: "2" },
          ],
        },
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "supportMaterialId",
          label: `Материал подпорок`,
          hidden: configValues.supportAmount === 0,
          items: assets.frameMaterials.map((m: MaterialExpanded) => ({
            title: m.title,
            value: m.id,
          })),
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "supportLength",
          label: "Длина подпорок, мм",
          hidden: configValues.supportAmount === 0,
          min: MIN_SUPPORT_LENGTH,
          max: MAX_SUPPORT_LENGTH,
        },
        {
          type: TemplateConfigControlType.INT_SLIDER,
          name: "supportGap",
          label: "Между подпорками, мм",
          hidden: configValues.supportAmount < 2,
          min: MIN_SUPPORT_GAP,
          max: MAX_SUPPORT_GAP,
        },
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "frameFootingId",
          label: "Подкладка/опора",
          nullable: true,
          items: assets.frameFooting.map((m: MaterialExpanded) => ({
            title: m.title,
            value: m.id,
          })),
        },
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "fastenerId",
          label: "Крепление",
          items: assets.fasteners.map((m: MaterialExpanded) => ({
            title: m.title,
            value: m.id,
          })),
        },
      ],
    },
    {
      title: "Упаковка",
      controls: [
        {
          type: TemplateConfigControlType.SELECTOR,
          name: "packageFramePosition",
          label: "Размещение ножек",
          items: [
            { title: "Ближе к краям", value: "corners" },
            { title: "Ближе к центру", value: "center" },
          ],
        },
      ],
    },
  ],
  onAttributeChange: (
    name: string,
    v: any,
    values: Config,
    assets: Assets,
    _variables: Variables,
    setValues: (v: Config) => void
  ) => {
    const value = v as number;
    switch (name) {
      case "frameMaterialId": {
        const frameMaterialId = value as unknown as string;
        const patch: Partial<Config> = { frameMaterialId };
        const frameMaterial =
          assets.frameMaterials.find((m) => m.id === frameMaterialId) || null;
        if (frameMaterial?.mmDimension1 && frameMaterial?.mmDimension2) {
          patch.frameMaterialWidth = frameMaterial?.mmDimension1;
          patch.frameMaterialHeight = frameMaterial?.mmDimension2;
        }
        setValues({
          ...values,
          ...patch,
        });
        break;
      }
      case "length":
      case "countertopLength":
      case "frameButtOffset": {
        const patch = getFormulaPatch(
          lengthFormula,
          values,
          name === "length" ? "countertopLength" : name,
          value,
          name === "frameButtOffset"
            ? ["countertopLength"]
            : ["frameButtOffset"]
        );
        patch.length = patch.countertopLength;
        setValues({
          ...values,
          ...patch,
        });
        break;
      }
      case "frameWidthsAreEqual": {
        const patch: Partial<Config> = {
          frameWidthsAreEqual: Boolean(value),
        };
        if (value) {
          patch.frameBottomWidth = values.frameTopWidth;
          patch.width = values.countertopWidth;
        }
        setValues({
          ...values,
          ...patch,
        });
        break;
      }
      case "width": {
        const delta = value - values.width;
        const newValues = {
          ...values,
          width: value,
        };

        const isWidthDefinedByCountertop =
          values.countertopWidth >= values.frameBottomWidth;
        if (isWidthDefinedByCountertop) {
          newValues.countertopWidth = Math.min(
            MAX_WIDTH,
            Math.max(MIN_WIDTH, newValues.countertopWidth + delta)
          );

          const patch = getFormulaPatch(
            widthFormula,
            values,
            "countertopWidth",
            newValues.countertopWidth,
            ["frameTopWidth"]
          );
          Object.assign(newValues, patch);
          if (values.frameWidthsAreEqual) {
            newValues.frameBottomWidth = newValues.frameTopWidth;
          } else if (values.frameBottomWidth > value) {
            newValues.frameBottomWidth = value;
          }
        } else {
          newValues.frameBottomWidth = value;
          if (values.countertopWidth > value) {
            const patch = getFormulaPatch(
              widthFormula,
              values,
              "countertopWidth",
              value,
              ["frameTopWidth"]
            );
            Object.assign(newValues, patch);
          }
        }

        setValues(newValues);
        break;
      }
      case "countertopWidth":
      case "frameEdgeOffset":
      case "frameTopWidth": {
        const patch = getFormulaPatch(
          widthFormula,
          values,
          name,
          value,
          name === "frameTopWidth" ? ["frameEdgeOffset"] : ["frameTopWidth"]
        );
        if (values.frameWidthsAreEqual) {
          patch.frameBottomWidth = patch.frameTopWidth || values.frameTopWidth;
        }
        patch.width = Math.max(
          patch.countertopWidth || values.countertopWidth,
          values.frameBottomWidth
        );
        let patch2: Partial<Config> = {};

        if (patch.frameTopWidth !== values.frameTopWidth) {
          patch2 = getFormulaPatch(
            supportFormula,
            { ...values, ...patch },
            "frameTopWidth",
            patch.frameTopWidth!,
            ["supportGap"]
          );
        }

        setValues({
          ...values,
          ...patch,
          ...patch2,
        });
        break;
      }
      case "frameBottomWidth": {
        const patch: Partial<Config> = {
          frameBottomWidth: value,
        };

        const newValues = {
          ...values,
          ...patch,
        };

        newValues.width = Math.max(
          newValues.countertopWidth,
          newValues.frameBottomWidth
        );
        setValues(newValues);
        break;
      }
      case "supportGap": {
        const patch = getFormulaPatch(supportFormula, values, name, value, [
          "frameTopWidth",
        ]);
        let patch2: Partial<Config> = {};
        if (patch.frameTopWidth !== values.frameTopWidth) {
          patch2 = getFormulaPatch(
            widthFormula,
            { ...values, ...patch },
            "frameTopWidth",
            patch.frameTopWidth!,
            ["frameEdgeOffset"]
          );
        }
        setValues({
          ...values,
          ...patch,
          ...patch2,
        });
        break;
      }
      case "countertopThickness":
      case "frameHeight":
      case "height": {
        const patch = getFormulaPatch(
          heightFormula,
          values,
          name,
          value,
          name === "frameHeight" ? ["height"] : ["frameHeight"]
        );
        setValues({
          ...values,
          ...patch,
        });
        break;
      }
    }
  },
};
