import { z } from "zod";
import { checkSchema, standardAttributes, EMAIL_REGEXP } from "../services";

export enum AuthChannelType {
  GUEST = "GUEST",
  EMAIL = "EMAIL",
  ADMIN_ISSUED = "ADMIN_ISSUED",
}

export const authChannelTypeSchema = z.nativeEnum(AuthChannelType);

// the schema of user being stored in auth token
// should not contain any refinements!
export const tokenAuthChannelSchema = z.object({
  type: authChannelTypeSchema,
  channelKey: z.string(),
});
export type TokenAuthChannel = z.infer<typeof tokenAuthChannelSchema>;

const uncheckedAuthChannelSchema = z.object({
  ...standardAttributes,
  userId: z.string().uuid().nullable(),
  type: authChannelTypeSchema,

  channelKey: z.string(),
  passwordHash: z.string().nullable(),
  verifiedAt: z.date().nullable(),
  isSuspended: z.boolean(),
});
type UncheckedChannelAuth = z.infer<typeof uncheckedAuthChannelSchema>;

export const authChannelSchema = uncheckedAuthChannelSchema.refine(
  (authChannel: UncheckedChannelAuth) => {
    switch (authChannel.type) {
      case "EMAIL":
        return (
          EMAIL_REGEXP.test(authChannel.channelKey) &&
          authChannel.passwordHash !== null
        );
      default:
        return true;
    }
  },
  {
    message: `channelKey format is invalid`,
  }
);
checkSchema(authChannelSchema);

export type AuthChannel = z.infer<typeof authChannelSchema>;
