import gql from "graphql-tag";
import { Order, OrderLineItem, OrderLineItemInput, OrderStatus } from "shared";
import {
  ORDER_FULL_FRAGMENT,
  ORDER_ENVIRONMENT_FULL_FRAGMENT,
  ORDER_LINE_ITEM_FULL_FRAGMENT,
  OrderEnvironment,
} from "client-lib/fragments";

export const Orders = gql`
  query Orders($limit: Int, $offset: Int) {
    Orders(limit: $limit, offset: $offset) {
      ...OrderFullFragment
    }
  }
  ${ORDER_FULL_FRAGMENT}
`;

export interface OrdersVars {
  limit?: number;
  offset?: number;
}

export type OrdersData = {
  Orders: Order[];
};

export const OrderEnvironmentQuery = gql`
  query OrderEnvironment(
    $orderId: String!
    $shouldUpdateDeliveryCosts: Boolean
  ) {
    OrderEnvironment(
      orderId: $orderId
      shouldUpdateDeliveryCosts: $shouldUpdateDeliveryCosts
    ) {
      ...OrderEnvironmentFullFragment
    }
  }
  ${ORDER_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface OrderEnvironmentVars {
  orderId: string;
  shouldUpdateDeliveryCosts?: boolean;
}

export type OrderEnvironmentData = {
  OrderEnvironment: OrderEnvironment;
};

export const CreateOrderLineItem = gql`
  mutation CreateOrderLineItem($input: OrderLineItemInput!) {
    CreateOrderLineItem(input: $input) {
      ...OrderEnvironmentFullFragment
    }
  }
  ${ORDER_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface CreateOrderLineItemVars {
  input: OrderLineItemInput;
}

export type CreateOrderLineItemData = {
  CreateOrderLineItem: {
    order: Order;
    orderLineItems: OrderLineItem[];
  };
};

export const UpdateOrderLineItemAmount = gql`
  mutation UpdateOrderLineItemAmount($orderLineItemId: String!, $amount: Int!) {
    UpdateOrderLineItemAmount(
      orderLineItemId: $orderLineItemId
      amount: $amount
    ) {
      ...OrderEnvironmentFullFragment
    }
  }
  ${ORDER_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface UpdateOrderLineItemAmountVars {
  orderLineItemId: string;
  amount: number;
}

export type UpdateOrderLineItemAmountData = {
  UpdateOrderLineItemAmount: {
    order: Order;
    orderLineItems: OrderLineItem[];
  };
};
export const UpdateOrderLineItemDestination = gql`
  mutation UpdateOrderLineItemDestination(
    $orderLineItemId: String!
    $tkDeliveryLocationId: String
  ) {
    UpdateOrderLineItemDestination(
      orderLineItemId: $orderLineItemId
      tkDeliveryLocationId: $tkDeliveryLocationId
    ) {
      ...OrderLineItemFullFragment
    }
  }
  ${ORDER_LINE_ITEM_FULL_FRAGMENT}
`;

export interface UpdateOrderLineItemDestinationVars {
  orderLineItemId: string;
  tkDeliveryLocationId: string | null;
}

export type UpdateOrderLineItemDestinationData = {
  UpdateOrderLineItemDestination: OrderLineItem;
};

export const DeleteOrderLineItem = gql`
  mutation DeleteOrderLineItem($orderLineItemId: String!) {
    DeleteOrderLineItem(orderLineItemId: $orderLineItemId) {
      ...OrderEnvironmentFullFragment
    }
  }
  ${ORDER_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface DeleteOrderLineItemVars {
  orderLineItemId: string;
}

export type DeleteOrderLineItemData = {
  DeleteOrderLineItem: {
    order: Order;
    orderLineItems: OrderLineItem[];
  };
};

export const RecalculateOrderPricing = gql`
  mutation RecalculateOrderPricing($orderId: String!) {
    RecalculateOrderPricing(orderId: $orderId) {
      ...OrderEnvironmentFullFragment
    }
  }
  ${ORDER_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface RecalculateOrderPricingVars {
  orderId: string;
}

export type RecalculateOrderPricingData = {
  RecalculateOrderPricing: OrderEnvironment;
};

export const UpdateOrderStatus = gql`
  mutation UpdateOrderStatus($orderId: String!, $status: OrderStatus!) {
    UpdateOrderStatus(orderId: $orderId, status: $status) {
      ...OrderFullFragment
    }
  }
  ${ORDER_FULL_FRAGMENT}
`;

export interface UpdateOrderStatusVars {
  orderId: string;
  status: OrderStatus;
}

export type UpdateOrderStatusData = {
  UpdateOrderStatus: Order;
};
