import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";
import {
  templatePresetImageExpandedSchema,
  templatePresetImageInputSchema,
} from "./template-preset-image";
import { templateSchema, templateEnvironmentSchema } from "./template";

export const templatePresetSchema = z.object({
  ...standardAttributes,
  ordering: z.number().int(),
  isPublic: z.boolean(),
  templateId: z.string().uuid(),
  templateConfig: z.string().nullable(),
  title: z.string(),
  description: z.string(),
  templateVariables: z.string().nullable(),
});
checkSchema(templatePresetSchema);
export type TemplatePreset = z.infer<typeof templatePresetSchema>;

export const templatePresetInputSchema = templatePresetSchema
  .pick({
    id: true,
    ordering: true,
    isPublic: true,
    templateId: true,
    title: true,
    description: true,
    templateVariables: true,
  })
  .extend({
    images: z.array(templatePresetImageInputSchema),
  });
export type TemplatePresetInput = z.infer<typeof templatePresetInputSchema>;

export const templatePresetPatchSchema = templatePresetSchema
  .pick({
    ordering: true,
    isPublic: true,
    title: true,
    description: true,
    templateVariables: true,
  })
  .extend({
    images: z.array(templatePresetImageInputSchema),
  });
export type TemplatePresetPatch = z.infer<typeof templatePresetPatchSchema>;

export const templatePresetEnvironmentParentSchema = z.object({
  id: z.string().uuid(),
  templatePreset: templatePresetSchema,
});
export type TemplatePresetEnvironmentParent = z.infer<
  typeof templatePresetEnvironmentParentSchema
>;

export const templatePresetEnvironmentSchema =
  templatePresetEnvironmentParentSchema.extend({
    images: z.array(templatePresetImageExpandedSchema),
    template: templateSchema,
    templateEnvironment: templateEnvironmentSchema,
  });

export type TemplatePresetEnvironment = z.infer<
  typeof templatePresetEnvironmentSchema
>;
