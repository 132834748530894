import React, { useRef } from "react";
import { useFrame } from "react-three-fiber";
import { OrbitControls } from "@react-three/drei";
import { useSceneOptions } from "./scene-context";
import { OrbitControls as OrbitControlsImpl } from "three-stdlib";

export function OrbitControlsWrapper() {
  const options = useSceneOptions();
  const orbitInitializedRef = useRef<boolean>(false);
  const orbitRef = useRef<OrbitControlsImpl | null>(null);

  useFrame(() => {
    const needSetAngle =
      options.camera?.azimuthalAngle !== undefined ||
      options.camera?.polarAngle !== undefined;
    if (!orbitRef.current || orbitInitializedRef.current || !needSetAngle) {
      return;
    }
    if (options.camera?.azimuthalAngle !== undefined) {
      orbitRef.current.setAzimuthalAngle(options.camera?.azimuthalAngle);
    }
    if (options.camera?.polarAngle !== undefined) {
      orbitRef.current.setPolarAngle(options.camera?.polarAngle);
    }
    orbitInitializedRef.current = true;
  });

  return (
    <OrbitControls
      ref={orbitRef}
      // target={[0, 2000, 0]}
      maxDistance={3000}
      minDistance={500}
      enableZoom={!options.disableZoom}
      enableRotate={!options.disableRotate}
      autoRotate={options.autoRotate}
    />
  );
}
