import React, { useMemo } from "react";
import { TemplateConfigArrayGroup, TemplateConfigControl } from "shared";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  gutters,
  primary,
  grey15,
  borderRadius,
  typographyBody2,
  InnerLink,
  disabledGrey,
} from "client-lib/styles";
import { Control } from "../controls";

const Container = styled.div`
  margin-bottom: ${gutters.md}px;
  padding: ${gutters.sm}px ${gutters.lg}px;
  background-color: ${grey15};
  border-radius: ${borderRadius.lg}px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${gutters.sm}px;
  h4 {
    margin: 0;
  }
`;

const Delete = styled(InnerLink)([
  typographyBody2,
  css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 100%;
    color: ${primary};
    border-color: ${primary};
  `,
]);

const DisabledDelete = styled(Delete)`
  color: ${disabledGrey};
  border-color: ${disabledGrey};
`;

interface Props {
  group: TemplateConfigArrayGroup;
  index: number;
  amount: number;
  onAttributeChange: (name: string, value: any) => void;
  disableDelete?: boolean;
  onDelete: (index: number) => void;
}

export function ConfigArrayItem({
  group,
  index,
  amount,
  onAttributeChange,
  disableDelete,
  onDelete,
}: Props) {
  const controls = useMemo<TemplateConfigControl[]>(
    () => group.controls(index, amount),
    [group, index, amount]
  );

  return (
    <Container>
      <Header>
        <h4>{group.arrayConfig.itemTitle(index)}</h4>
        {disableDelete ? (
          <DisabledDelete>удалить</DisabledDelete>
        ) : (
          <Delete onClick={() => onDelete(index)}>удалить</Delete>
        )}
      </Header>
      {controls.map((def, controlIndex) => (
        <Control
          def={{
            ...def,
            name: `${group.arrayConfig.name}[${index}].${def.name}`,
          }}
          key={controlIndex}
          onAttributeChange={onAttributeChange}
        />
      ))}
    </Container>
  );
}
