import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";
import {
  templatePresetSchema,
  templatePresetEnvironmentSchema,
} from "./template-preset";
import { templatePresetImageExpandedSchema } from "./template-preset-image";

export const templateShotSchema = z.object({
  ...standardAttributes,
  ordering: z.number().int(),
  teamId: z.string().uuid(),
  templatePresetId: z.string().uuid(),
  templateId: z.string().uuid(),
  title: z.string(),
});
checkSchema(templateShotSchema);
export type TemplateShot = z.infer<typeof templateShotSchema>;

export const templateShotActualSchema = templateShotSchema.extend({
  templateConfig: z.string(),
  versionId: z.string().uuid(),
  versionNumber: z.number().int(),
});
export type TemplateShotActual = z.infer<typeof templateShotActualSchema>;

export const templateShotInputSchema = templateShotActualSchema.pick({
  id: true,
  templatePresetId: true,
  title: true,
  templateConfig: true,
});
export type TemplateShotInput = z.infer<typeof templateShotInputSchema>;

export const templateShotPatchSchema = templateShotSchema
  .pick({
    title: true,
  })
  .extend({
    templateConfig: z.string().nullable(),
  });
export type TemplateShotPatch = z.infer<typeof templateShotPatchSchema>;

export const templateShotSnippetSchema = z.object({
  id: z.string().uuid(),
  templateShot: templateShotSchema,
  templatePreset: templatePresetSchema,
  image: templatePresetImageExpandedSchema.nullable(),
});

export type TemplateShotSnippet = z.infer<typeof templateShotSnippetSchema>;

export const templateShotEnvironmentSchema = z.object({
  id: z.string().uuid(),
  latestVersionNumber: z.number().int().positive(),
  templateShotActual: templateShotActualSchema,
  templatePresetEnvironment: templatePresetEnvironmentSchema,
});

export type TemplateShotEnvironment = z.infer<
  typeof templateShotEnvironmentSchema
>;
