export const PROFILE_W = 40;
export const PROFILE_H = 20;

export const MAX_LENGTH = 3000;
export const MIN_LENGTH = 250;
export const MAX_WIDTH = 800;
export const MIN_WIDTH = 200;
export const MAX_HEIGHT = 1200;
export const MIN_HEIGHT = 200;

export const MAX_THICKNESS = 40;
export const MIN_THICKNESS = 20;

export const MIN_FRAME_GAP = 200;
export const MIN_BUTT_OFFSET = 5;
export const MAX_BUTT_OFFSET = (MAX_LENGTH - MIN_FRAME_GAP) / 2 - PROFILE_W;

export const MIN_EDGE_OFFSET = 5;

export const MIN_FRAME_TOP_WIDTH = 2 * PROFILE_H + 50;
export const MAX_FRAME_TOP_WIDTH = MAX_WIDTH - 2 * MIN_EDGE_OFFSET;

export const MAX_EDGE_OFFSET = (MAX_WIDTH - MIN_FRAME_TOP_WIDTH) / 2;

export const MIN_FRAME_HEIGHT = MIN_HEIGHT - MAX_THICKNESS;
export const MAX_FRAME_HEIGHT = MAX_HEIGHT - MIN_THICKNESS;

export const MIN_SUPPORT_SIDES_GAP = 50;
export const MIN_SUPPORT_GAP = 80;
export const MAX_SUPPORT_GAP = MAX_FRAME_TOP_WIDTH - 2 * 40;
export const MIN_SUPPORT_LENGTH = 50;
export const MAX_SUPPORT_LENGTH = (MIN_FRAME_GAP - MIN_SUPPORT_SIDES_GAP) / 2;
