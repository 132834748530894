import { css } from "@emotion/react";
import { mutedGrey } from "./colors";

import {
  fontSizeBody1,
  fontSizeBody2,
  fontSizeBody3,
  fontSizeH1,
  fontSizeH2,
  fontSizeH3,
  HEADER_BAR_HEIGHT,
} from "./variables";

export const primaryFamily = "'Source Sans Pro', sans-serif";
export const secondaryFamily = "'Roboto', sans-serif";

export const primaryFont = `font-family: ${primaryFamily};`;
export const secondaryFont = `font-family: ${secondaryFamily};`;

export const typographyH1 = css`
  font-size: ${fontSizeH1}px;
  font-weight: 500;
  line-height: ${HEADER_BAR_HEIGHT}px;
  ${secondaryFont};
`;

export const typographyH2 = css`
  font-size: ${fontSizeH2}px;
  font-weight: 500;
  line-height: 32px;
  ${secondaryFont};
`;

export const typographyH3 = css`
  font-size: ${fontSizeH3}px;
  font-weight: 500;
  line-height: 28px;
  margin: 1em 0 0.4em;
  ${secondaryFont};
`;

export const typographyBody1 = css`
  ${primaryFont}
  font-size: ${fontSizeBody1}px;
  line-height: 18px;
`;

export const typographyBody2 = css`
  ${primaryFont}
  font-size: ${fontSizeBody2}px;
  line-height: 14px;
`;

export const typographyBody3 = css`
  ${primaryFont}
  font-size: ${fontSizeBody3}px;
  line-height: 12px;
`;

export const labelText = css`
  ${primaryFont}
  font-size: ${fontSizeBody3}px;
  line-height: 16px;
  font-style: normal;
  font-weight: 300;
  color: ${mutedGrey};
`;

export const typographyCondensed = css`
  line-height: 120%;
`;
