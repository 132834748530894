import {
  Decomposition,
  DecompositionSection,
  TechProcessCode,
  UnitType,
} from "../../schemas";
import { Assets, Config, Variables } from "./types";
import { hypotenuseLength } from "../service";
import { tableLoft1Utils } from "./utils";
import {
  getUnusedWoodenPanelLength,
  getUnusedWoodenPanelThickness,
  getUnusedWoodenPanelWidth,
  parseCustomFlags,
  getBoxArea,
  createMaterialItem,
  createTechProcessItem,
} from "../utils";

export function getDecomposition(
  config: Config,
  assets: Assets,
  variables: Variables
): Decomposition {
  const countertopSection: DecompositionSection = {
    title: variables.countertopTitle,
    items: [],
  };

  const unusedWoodenPanelThickness = getUnusedWoodenPanelThickness(
    config.countertopThickness
  );
  const blankThickness =
    config.countertopThickness + (unusedWoodenPanelThickness || 0);
  const counterTopVolume =
    blankThickness * config.countertopWidth * config.countertopLength;

  countertopSection.items.push(
    createMaterialItem(
      assets.countertopMaterial,
      counterTopVolume,
      UnitType.MILLIMETER_3,
      {
        title: `${assets.countertopMaterial.title} ${config.countertopLength}x${config.countertopWidth}x${blankThickness}мм`,
      }
    )
  );

  if (unusedWoodenPanelThickness) {
    const unusedVolume =
      unusedWoodenPanelThickness *
      config.countertopWidth *
      config.countertopLength;
    countertopSection.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_PLANING,
        unusedVolume,
        UnitType.MILLIMETER_3,
        {
          title: `Строжка мебельного щита ${unusedWoodenPanelThickness}мм`,
        }
      )
    );
  }

  // thickness of initial panel
  const unusedWoodenPanelWidth = getUnusedWoodenPanelWidth(
    config.countertopWidth
  );
  if (unusedWoodenPanelWidth) {
    const unusedVolume =
      blankThickness * unusedWoodenPanelWidth * config.countertopLength;
    countertopSection.items.push(
      createMaterialItem(
        assets.countertopMaterial,
        unusedVolume,
        UnitType.MILLIMETER_3,
        {
          title: `Отходы материала по ширине: ${assets.countertopMaterial.title} ${config.countertopLength}x${unusedWoodenPanelWidth}x${blankThickness}мм`,
        }
      )
    );
  }
  const unusedWoodenPanelLength = getUnusedWoodenPanelLength(
    config.countertopLength
  );
  if (unusedWoodenPanelLength) {
    const unusedVolume =
      blankThickness * config.countertopWidth * unusedWoodenPanelLength;
    countertopSection.items.push(
      createMaterialItem(
        assets.countertopMaterial,
        unusedVolume,
        UnitType.MILLIMETER_3,
        {
          title: `Отходы материала по длине: ${assets.countertopMaterial.title} ${unusedWoodenPanelLength}x${config.countertopWidth}x${blankThickness}мм`,
        }
      )
    );
  }

  if (config.countertopWidth !== 600 && config.countertopWidth !== 400) {
    countertopSection.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_SAWING_SIDE,
        config.countertopLength * config.countertopThickness,
        UnitType.MILLIMETER_2,
        {
          title: `Опиливание края столешницы, сечение ${config.countertopLength}x${config.countertopThickness}мм`,
        }
      )
    );
  }

  if (config.countertopWidth !== 3000) {
    countertopSection.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_SAWING_END,
        config.countertopWidth * config.countertopThickness,
        UnitType.MILLIMETER_2,
        {
          title: `Опиливание торца столешницы, сечение ${config.countertopWidth}x${config.countertopThickness}мм`,
        }
      )
    );
  }

  const countertopArea =
    +(2 * config.countertopThickness * config.countertopWidth) +
    2 * config.countertopThickness * config.countertopLength +
    2 * config.countertopWidth * config.countertopLength;

  countertopSection.items.push(
    createTechProcessItem(
      TechProcessCode.WOOD_GRINDING,
      countertopArea,
      UnitType.MILLIMETER_2,
      {
        title: `Шлифовка поверхности`,
      }
    )
  );

  const coverage =
    config.countertopCoverageId &&
    assets.countertopCoverage.find((c) => c.id === config.countertopCoverageId);
  if (coverage) {
    countertopSection.items.push(
      createTechProcessItem(
        TechProcessCode.WOOD_OILING,
        countertopArea,
        UnitType.MILLIMETER_2,
        {
          title: `Покрытие маслом`,
        }
      )
    );
    countertopSection.items.push(
      createMaterialItem(coverage, countertopArea, UnitType.MILLIMETER_2)
    );
  }

  const frameMaterial = assets.frameMaterials.find(
    (m) => m.id === config.frameMaterialId
  );
  if (!frameMaterial) {
    throw new Error("Can't build decomposition: empty frameMaterial");
  }
  if (
    !frameMaterial.mmDimension1 ||
    !frameMaterial.mmDimension2 ||
    !frameMaterial.mmDimension3
  ) {
    throw new Error(
      "Can't build decomposition: empty frameMaterial dimensions"
    );
  }

  const supportMaterial = assets.frameMaterials.find(
    (m) => m.id === config.supportMaterialId
  );
  if (config.supportAmount > 0) {
    if (!supportMaterial) {
      throw new Error("Can't build decomposition: empty supportMaterial");
    }
    if (
      !supportMaterial.mmDimension1 ||
      !supportMaterial.mmDimension2 ||
      !supportMaterial.mmDimension3
    ) {
      throw new Error(
        "Can't build decomposition: empty supportMaterial dimensions"
      );
    }
  }

  const frameSection: DecompositionSection = {
    title: variables.frameTitle,
    items: [],
  };

  const legDiagonal = hypotenuseLength(
    config.frameHeight,
    (config.frameTopWidth - config.frameBottomWidth) / 2
  );
  const legPerimeter =
    config.frameTopWidth + config.frameBottomWidth + legDiagonal * 2;

  const legArea = getBoxArea([
    legPerimeter,
    frameMaterial.mmDimension1,
    frameMaterial.mmDimension2,
  ]);

  frameSection.items.push(
    createMaterialItem(frameMaterial, legPerimeter, UnitType.MILLIMETER, {
      amount: 2,
    })
  );

  const legDimension2Diagonal = hypotenuseLength(
    frameMaterial.mmDimension2,
    frameMaterial.mmDimension2
  );
  const pipeSawingPerimeter =
    (frameMaterial.mmDimension1 + legDimension2Diagonal) * 2;
  const pipeSawingArea = pipeSawingPerimeter * frameMaterial.mmDimension3;

  frameSection.items.push(
    createTechProcessItem(
      TechProcessCode.STEEL_SAWING,
      pipeSawingArea,
      UnitType.MILLIMETER_2,
      {
        title: `Распил стального профиля диагональный`,
        amount: 16,
      }
    )
  );

  frameSection.items.push(
    createTechProcessItem(
      TechProcessCode.STEEL_WELDING,
      pipeSawingPerimeter,
      UnitType.MILLIMETER,
      {
        title: `Сварка стального профиля`,
        amount: 8,
      }
    )
  );

  // Grinding width is 10mm
  const pipeGrindingArea = pipeSawingPerimeter * 10;
  frameSection.items.push(
    createTechProcessItem(
      TechProcessCode.STEEL_GRINDING,
      pipeGrindingArea,
      UnitType.MILLIMETER_2,
      {
        title: `Шлифовка сварочного шва`,
        amount: 8,
      }
    )
  );

  const DRILLING_RADIUS = 5 / 2;
  const DRILLING_AREA = Math.PI * DRILLING_RADIUS ** 2;
  const profileDrillingVolume = DRILLING_AREA * frameMaterial.mmDimension3;

  const FASTENERS_AMOUNT = 6;
  frameSection.items.push(
    createTechProcessItem(
      TechProcessCode.STEEL_DRILLING,
      profileDrillingVolume,
      UnitType.MILLIMETER_3,
      {
        title: `Сверление стального профиля (для крепления сиденья)`,
        amount: FASTENERS_AMOUNT,
      }
    )
  );

  const frameFooting = assets.frameFooting.find(
    (m) => m.id === config.frameFootingId
  );

  if (frameFooting) {
    const flags = parseCustomFlags(frameFooting.customFlags);
    if (flags.has("drilling")) {
      frameSection.items.push(
        createTechProcessItem(
          TechProcessCode.STEEL_DRILLING,
          profileDrillingVolume,
          UnitType.MILLIMETER_3,
          {
            title: `Сверление стального профиля (для крепления опор)`,
            amount: 4,
          }
        )
      );
    }
  }

  if (config.supportAmount > 0) {
    frameSection.items.push(
      createMaterialItem(
        supportMaterial!,
        config.supportLength,
        UnitType.MILLIMETER,
        {
          title: `Материал подпорок (${frameMaterial.title}`,
          amount: config.supportAmount * 2,
        }
      )
    );
    const supportSawingPerimeter =
      (supportMaterial!.mmDimension1! + supportMaterial!.mmDimension2!) * 2;
    const supportSawingArea =
      supportSawingPerimeter * supportMaterial!.mmDimension3!;

    frameSection.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_SAWING,
        supportSawingArea,
        UnitType.MILLIMETER_2,
        {
          title: `Распил стального профиля поперечный, для подпорок`,
          amount: config.supportAmount * 2,
        }
      )
    );
    frameSection.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_WELDING,
        pipeSawingPerimeter,
        UnitType.MILLIMETER,
        {
          title: `Сварка подпорок`,
          amount: config.supportAmount * 2,
        }
      )
    );

    // Grinding width is 1cm
    const supportGrindingArea = supportSawingPerimeter * 10;
    frameSection.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_GRINDING,
        supportGrindingArea,
        UnitType.MILLIMETER_2,
        {
          title: `Шлифовка сварочного шва подпорок`,
          amount: config.supportAmount * 2,
        }
      )
    );
  }

  if (config.frameCoverageId) {
    frameSection.items.push(
      createTechProcessItem(
        TechProcessCode.STEEL_POWDER_COATING,
        legArea,
        UnitType.MILLIMETER_2,
        {
          amount: 2,
        }
      )
    );
  }

  const miscSection: DecompositionSection = {
    title: "Упаковка, крепеж и фурнитура",
    items: [],
  };
  if (frameFooting) {
    miscSection.items.push(
      createMaterialItem(frameFooting, 1, UnitType.PIECE, {
        amount: 4,
      })
    );
  }

  const fastener = assets.fasteners.find((m) => m.id === config.fastenerId);
  if (fastener) {
    miscSection.items.push(
      createMaterialItem(fastener, 1, UnitType.PIECE, {
        amount: FASTENERS_AMOUNT,
      })
    );
  }

  const dimensions = tableLoft1Utils.detectPackageDimensions(config, assets);
  if (!dimensions) {
    throw new Error("Can't determine package dimensions");
  }

  let totalPackingArea = 0;
  const cardboard = assets.packageCardboard.find(
    (m) => m.id === config.packageCardboardId
  );
  if (cardboard) {
    const cardboardArea = getBoxArea(dimensions) * 2;
    miscSection.items.push(
      createMaterialItem(cardboard, cardboardArea, UnitType.MILLIMETER_2, {
        title: `Внешщняя упаковка (${cardboard.title})`,
      })
    );
    totalPackingArea += cardboardArea;
  }
  const bubbleWrap = assets.packageBubbleWrap.find(
    (m) => m.id === config.packageBubbleWrapId
  );
  if (bubbleWrap) {
    const layers = 3;
    const countertopArea = getBoxArea([
      config.countertopLength,
      config.countertopWidth,
      config.countertopThickness,
    ]);
    miscSection.items.push(
      createMaterialItem(
        bubbleWrap,
        countertopArea * layers,
        UnitType.MILLIMETER_2,
        {
          title: `${variables.countertopTitle} (${bubbleWrap.title})`,
        }
      )
    );
    totalPackingArea += countertopArea * layers;
    miscSection.items.push(
      createMaterialItem(bubbleWrap, legArea * layers, UnitType.MILLIMETER_2, {
        title: `${variables.frameTitle} (${bubbleWrap.title})`,
        amount: 2,
      })
    );
    totalPackingArea += legArea * layers * 2;
  }

  miscSection.items.push(
    createTechProcessItem(
      TechProcessCode.PACKING,
      totalPackingArea,
      UnitType.MILLIMETER_2,
      {
        title: `Работы по упаковке`,
      }
    )
  );

  miscSection.items.push(
    createTechProcessItem(TechProcessCode.TESTING_ASSEMBLY, 0.25, UnitType.HOUR)
  );

  const sections = [countertopSection, frameSection, miscSection];
  let massKg = 0;
  for (const section of sections) {
    for (const item of section.items) {
      if (item.massKg) {
        massKg += item.massKg;
      }
    }
  }

  return {
    sections,
    package: {
      massKg,
      lengthMm: dimensions[0],
      heightMm: dimensions[1],
      widthMm: dimensions[2],
    },
  };
}
