import gql from "graphql-tag";
import {
  orderSchema,
  orderLineItemSchema,
  invoiceSchema,
  Order,
  Invoice,
  orderLineItemEnvironmentSchema,
  OrderLineItemEnvironment,
  DeliveryLocation,
} from "shared";
import { getFieldsFromSchema } from "../services";
import { DELIVERY_LOCATION_FULL_FRAGMENT } from "./delivery";

export const ORDER_FULL_FRAGMENT = gql`
  fragment OrderFullFragment on Order {
    ${getFieldsFromSchema(orderSchema)}
  }
`;

export const ORDER_LINE_ITEM_FULL_FRAGMENT = gql`
  fragment OrderLineItemFullFragment on OrderLineItem {
    ${getFieldsFromSchema(orderLineItemSchema)}
  }
`;

export const INVOICE_FULL_FRAGMENT = gql`
  fragment InvoiceFullFragment on Invoice {
    ${getFieldsFromSchema(invoiceSchema)}
  }
`;

export const ORDER_LINE_ITEM_ENVIRONMENT_FULL_FRAGMENT = gql`
  fragment OrderLineItemEnvironmentFullFragment on OrderLineItemEnvironment {
    ${getFieldsFromSchema(orderLineItemEnvironmentSchema)}
  }
`;

export interface OrderEnvironment {
  order: Order;
  orderLineItems: OrderLineItemEnvironment[];
  invoice: Invoice | null;
  deliveryLocation: DeliveryLocation | null;
  priceActualTill: Date;
}

export const ORDER_ENVIRONMENT_FULL_FRAGMENT = gql`
  fragment OrderEnvironmentFullFragment on OrderEnvironment {
    id
    order {
      ...OrderFullFragment
    }
    orderLineItems {
      ...OrderLineItemEnvironmentFullFragment
    }
    invoice {
      ...InvoiceFullFragment
    }
    deliveryLocation {
      ...DeliveryLocationFullFragment
    }
    priceActualTill
  }
  ${ORDER_FULL_FRAGMENT}
  ${ORDER_LINE_ITEM_ENVIRONMENT_FULL_FRAGMENT}
  ${INVOICE_FULL_FRAGMENT}
  ${DELIVERY_LOCATION_FULL_FRAGMENT}
`;
