import React, { useContext, useMemo } from "react";
import { Environment } from "shared";
import { useQuery } from "@apollo/client";
import { EnvironmentQuery, EnvironmentData, EnvironmentVars } from "./queries";

export type EnvironmentValue = {
  environment: Environment | null;
  refreshEnvironment: VoidFunction;
};

export const environmentValueDefault = {
  environment: null,
  refreshEnvironment: () => {},
};

const EnvironmentContext = React.createContext<EnvironmentValue>(
  environmentValueDefault
);

export function useEnvironment() {
  return useContext(EnvironmentContext);
}

export function useMyEnvironment() {
  const { environment } = useContext(EnvironmentContext);
  return environment?.myEnvironment || null;
}

export function useEnvironmentStrict() {
  const { environment } = useEnvironment();
  if (!environment) {
    throw new Error("Expected environment to be set");
  }
  return environment;
}

interface Props {
  children?: JSX.Element | JSX.Element[];
}

export function EnvironmentProvider({ children }: Props) {
  const { data, refetch } = useQuery<EnvironmentData, EnvironmentVars>(
    EnvironmentQuery
  );

  const value = useMemo<EnvironmentValue>(
    () => ({
      environment: data?.Environment || null,
      refreshEnvironment: refetch,
    }),
    [data, refetch]
  );

  return (
    <EnvironmentContext.Provider value={value}>
      {children}
    </EnvironmentContext.Provider>
  );
}
