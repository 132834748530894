import { useEffect, useRef } from "react";
import { useFormikContext } from "formik";

interface Props {
  timeout?: number;
}

export function AutoSubmitter({ timeout = 500 }: Props) {
  const initialValuesHandledRef = useRef<boolean>(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { submitForm, values, initialValues, isValid } = useFormikContext();

  useEffect(
    function resetOnInitialValuesChange() {
      initialValuesHandledRef.current = false;
    },
    [initialValues]
  );

  useEffect(
    function launchTimerOnValuesChange() {
      if (!initialValuesHandledRef.current) {
        initialValuesHandledRef.current = true;
        return;
      }

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(submitForm, timeout);
    },
    [values, isValid, timeout]
  );

  return null;
}
