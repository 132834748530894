import { TemplateModel } from "../types";
import {
  shelfConfigSchema,
  shelfAssetsSchema,
  shelfVariablesSchema,
  Config,
  Assets,
  Variables,
} from "./types";
import { SideRibType } from "../util-types";
import { MIN_ITEM_MARGIN } from "./constants";

export const model: TemplateModel<Config, Assets, Variables> = {
  configSchema: shelfConfigSchema,
  configInitialValues: (assets: Assets) => ({
    length: 600,
    width: 240,
    height: 400,
    items: [
      {
        coverageId: assets.shelfCoverage[0]?.id ?? null,
        supportMaterialId: assets.frameMaterials[0]?.id ?? "",
        length: 600,
        width: 200,
        thickness: 20,
        marginTop: MIN_ITEM_MARGIN,
        sideRibMaterialId: assets.frameMaterials[0]?.id ?? "",
        sideRibType: SideRibType.BOTH,
      },
      {
        coverageId: assets.shelfCoverage[0]?.id ?? null,
        supportMaterialId: assets.frameMaterials[0]?.id ?? "",
        length: 550,
        width: 200,
        thickness: 20,
        marginTop: MIN_ITEM_MARGIN,
        sideRibMaterialId: null,
        sideRibType: SideRibType.NONE,
      },
      {
        coverageId: assets.shelfCoverage[0]?.id ?? null,
        supportMaterialId: assets.frameMaterials[0]?.id ?? "",
        length: 500,
        width: 200,
        thickness: 20,
        marginTop: MIN_ITEM_MARGIN,
        sideRibMaterialId: null,
        sideRibType: SideRibType.NONE,
      },
    ],
    frameMaterialId: assets.frameMaterials[0]?.id ?? "",
    frameCoverageId: assets.frameCoverage[0]?.id ?? null,
    frameWidth: 240,
    frameHeight: 400,
    frameGap: 300,
    frameItemsCount: 2,
    frameBottomMargin: MIN_ITEM_MARGIN,
    frameMaterialWidth: assets.frameMaterials[0]?.mmDimension1 || 40,
    frameMaterialHeight: assets.frameMaterials[0]?.mmDimension2 || 20,
    frameBottomSideRibType: SideRibType.NONE,
    frameBottomSideRibMaterialId: assets.frameMaterials[0]?.id ?? "",
    frameFootingId: null,

    areShelvesSplit: false,

    fastenerId: assets.fasteners[0]?.id ?? null,
    packageCardboardId: assets.packageCardboard[0]?.id ?? "",
    packageBubbleWrapId: assets.packageBubbleWrap[0]?.id ?? "",
  }),
  assetsSchema: shelfAssetsSchema,
  variablesSchema: shelfVariablesSchema,
  variablesDefaults: {
    isRackLike: false,
  },
};
