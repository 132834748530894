import React, { useMemo } from "react";
import { useLocation, Link } from "react-router-dom";
import { Layout } from "src/features/layout";
import { SignInBlock } from "./signin-block";

export function SignInPage() {
  const { search } = useLocation();
  const redirectTo = useMemo<string | null>(() => {
    const redirectValue = new URLSearchParams(search).get("redirect");
    return redirectValue ? decodeURIComponent(redirectValue) : null;
  }, [search]);

  return (
    <Layout>
      <h1>Войти в Метафабрику</h1>
      <p>
        Нет аккаунта на Метафабрике? <Link to="/signup">Зарегистрируйтесь</Link>
      </p>
      <SignInBlock redirectTo={redirectTo || "/"} />
    </Layout>
  );
}
