import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { white, grey20, gutters, Button, mqMax } from "client-lib";
import { Slide, SlideContainer, Header } from "src/features/layout";

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  button {
    margin-top: ${gutters.md}px;
  }
  ${mqMax.phone} {
    display: block;
    a {
      display: none;
    }
    h2 {
      font-size: 40px;
    }
  }
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  min-height: 570px;

  ${mqMax.phone} {
    min-height: auto;
    display: block;
  }
`;

const Side = styled.div`
  width: 50%;
  position: relative;
  a {
    display: inline-block;
    margin-top: ${gutters.xxl}px;
  }
  img {
    max-width: 600px;
  }
  ${mqMax.phone} {
    width: auto;
    img {
      max-width: 100%;
    }
  }
`;

const LeftSide = styled(Side)``;

const Item = styled.div`
  background-color: ${white};
  padding: ${gutters.xl}px;
  margin-bottom: ${gutters.md}px;
`;

const SlideFooter = styled.div`
  display: none;
  ${mqMax.phone} {
    margin: ${gutters.xxl}px 0 ${gutters.xxl * 2}px 0;
    display: block;
    text-align: center;
  }
`;

export const TransparencySlide = memo(function TransparencySlide() {
  return (
    <Slide style={{ backgroundColor: grey20 }}>
      <SlideContainer>
        <Controls>
          <Header>Прозрачность на каждом этапе</Header>
          <Link to="/templates">
            <Button size="XL">Убедиться</Button>
          </Link>
        </Controls>
        <Body>
          <LeftSide>
            <img src="/img/transparency.jpg" />
          </LeftSide>
          <Side>
            <Item>
              Вы знаете цену за единицу изделия и можете корректировать её
              самостоятельно, управляя параметрами в меню конструктора
            </Item>
            <Item>
              Вы с самого начала знаете стоимость доставки и её сроки.
            </Item>
            <Item>
              Если вы оптовый покупатель, можете заказать тестовый образец перед
              заказом основной партии. Так вы сможете убедиться, что всё в
              порядке – или внесли в дизайн коррективы при необходимости.
            </Item>
            <Item>
              Вы можете в любой момент отследить статус заказа — от фабрики до
              точки доставки.
            </Item>
          </Side>
          <SlideFooter>
            <Link to="/templates">
              <Button size="XL">Убедиться</Button>
            </Link>
          </SlideFooter>
        </Body>
      </SlideContainer>
    </Slide>
  );
});
