import { useState, useRef } from "react";

export interface BooleanControls {
  setTrue: () => void;
  setFalse: () => void;
  set: () => void;
  reset: () => void;
  toggle: () => void;
}

export function useBoolean(initialValue = false): [boolean, BooleanControls] {
  const [value, setValue] = useState<boolean>(initialValue);

  const setTrue = useRef(() => setValue(true)).current;
  const setFalse = useRef(() => setValue(false)).current;
  const toggle = useRef(() => setValue((was) => !was)).current;

  return [value, { setTrue, setFalse, set: setTrue, reset: setFalse, toggle }];
}
