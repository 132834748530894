import React, { useMemo } from "react";
import { getDimensionsMm } from "shared";
import { Measurement, MEASUREMENT_DEFAULT_OFFSET } from "../../objects";
import { Frame } from "./frame";
import { ShelfItem } from "./shelf-item";
import { Package } from "./package";
import { ShelfProvider } from "./shelf-context";
import { Config, Assets, Variables } from "./types";
import { RenderMode } from "../../types";
import { useSceneOptions } from "../../scene-context";

interface Props {
  config: Config;
  assets: Assets;
  variables: Variables;
  mode: RenderMode;
}

export function Shelf({ config, assets, variables, mode }: Props) {
  const options = useSceneOptions();

  const frameMaterial =
    assets.frameMaterials.find((m) => m.id === config.frameMaterialId) || null;
  const framePipe = getDimensionsMm(frameMaterial);

  const items = useMemo<JSX.Element[]>(() => {
    const result: JSX.Element[] = [];
    let bottomY = framePipe.d2 || 0;
    if (variables.isRackLike) {
      bottomY += config.frameBottomMargin;
    }
    for (let index = 0; index < config.items.length; index++) {
      const item = config.items[index];
      if (options.showMeasurements) {
        if (index === 0) {
          result.push(
            <Measurement
              key="bottom-margin"
              p1={[-config.frameGap / 2, bottomY, 0]}
              p2={[-config.frameGap / 2, bottomY - config.frameBottomMargin, 0]}
              normal={[-1, 0, 0]}
              offset={
                MEASUREMENT_DEFAULT_OFFSET +
                (config.length - config.frameGap) / 2
              }
              textRotation={[0, 0, Math.PI / 2]}
            />
          );
        }
        if (index < config.items.length - 1) {
          result.push(
            <Measurement
              key={`${index}-margin`}
              p1={[-config.frameGap / 2, bottomY + item.thickness, 0]}
              p2={[
                -config.frameGap / 2,
                bottomY + item.thickness + item.marginTop,
                0,
              ]}
              normal={[-1, 0, 0]}
              offset={
                MEASUREMENT_DEFAULT_OFFSET +
                (config.length - config.frameGap) / 2
              }
              textRotation={[0, 0, Math.PI / 2]}
            />
          );
        }
      }
      result.push(<ShelfItem key={index} item={item} bottomY={bottomY} />);
      bottomY += item.marginTop + item.thickness;
    }
    return result;
  }, [config, assets, options.showMeasurements, framePipe.d2, variables.isRackLike]);

  if (!frameMaterial?.mmDimension1 || !frameMaterial?.mmDimension2) {
    return null;
  }
  const frameCoverageMaterial =
    assets.frameCoverage.find((m) => m.id === config.frameCoverageId) || null;

  if (mode === "package") {
    return (
      <ShelfProvider
        config={config}
        assets={assets}
        variables={variables}
        mode={mode}
      >
        <Package
          frameMaterial={frameMaterial}
          frameCoverageMaterial={frameCoverageMaterial}
        />
      </ShelfProvider>
    );
  }

  return (
    <ShelfProvider
      config={config}
      assets={assets}
      variables={variables}
      mode={mode}
    >
      <group position={[0, -config.height / 2, 0]}>
        {items}
        <Frame material={frameMaterial} coverage={frameCoverageMaterial} />
      </group>
    </ShelfProvider>
  );
}
