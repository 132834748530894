import React from "react";
import { MaterialExpanded } from "shared";
import { Config } from "./types";
import { Leg } from "./leg";

export function Frame({
  config,
  material,
  supportMaterial,
  coverage,
}: {
  config: Config;
  material: MaterialExpanded;
  supportMaterial: MaterialExpanded;
  coverage: MaterialExpanded | null;
}) {
  if (!material.mmDimension1 || !material.mmDimension2) {
    return null;
  }
  const pipeWidth = material.mmDimension1;

  return (
    <group position={[0, 0, 0]}>
      <Leg
        config={config}
        material={material}
        supportMaterial={supportMaterial}
        coverage={coverage}
        position={[
          -(config.countertopLength - pipeWidth) / 2 + config.frameButtOffset,
          -(config.frameHeight + config.countertopThickness) / 2,
          0,
        ]}
      />
      <Leg
        config={config}
        material={material}
        supportMaterial={supportMaterial}
        coverage={coverage}
        scale={[-1, 1, 1]}
        position={[
          (config.countertopLength - pipeWidth) / 2 - config.frameButtOffset,
          -(config.frameHeight + config.countertopThickness) / 2,
          0,
        ]}
      />
    </group>
  );
}
