import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  primary,
  white,
  grey20,
  typographyBody2,
  borderRadius,
} from "../styles";

type Size = "m";

const HEIGHTS: Record<Size, number> = {
  m: 25,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

const Item = styled.label<{
  isActive: boolean;
  width: number;
  height: number;
}>(({ isActive, width, height }) => [
  css`
    height: ${height}px;
    width: ${width}px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;
    &:first-child {
      margin-left: 0;
      border-radius: ${borderRadius.lg}px 0 0 ${borderRadius.lg}px;
    }
    &:last-child {
      margin-left: 0;
      border-radius: 0 ${borderRadius.lg}px ${borderRadius.lg}px 0;
    }
    cursor: pointer;
  `,
  typographyBody2,
  isActive
    ? css`
        border: 1px solid ${primary} !important;
        background-color: ${primary} !important;
        color: ${white};
      `
    : css`
        border: 1px solid ${grey20};
      `,
]);

export interface SwitcherItem<ID> {
  id: ID;
  text: string;
}

interface Props<ID> {
  items: SwitcherItem<ID>[];
  activeId: ID | null;
  onChange: (id: ID) => void;
  width?: number;
  size?: Size;
}

export function Switcher<ID>({
  items,
  activeId,
  onChange,
  width = 100,
  size = "m",
}: Props<ID>) {
  return (
    <Container>
      {items.map((item) => {
        return (
          <Item
            key={String(item.id)}
            isActive={activeId === item.id}
            onClick={() => onChange(item.id)}
            height={HEIGHTS[size]}
            width={width}
          >
            {item.text}
          </Item>
        );
      })}
    </Container>
  );
}
