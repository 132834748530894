export const regionsRF = [
  { name: "Москва", code: "77" },
  { name: "Московская область", code: "50" },
  { name: "Санкт-Петербург", code: "78" },
  { name: "Ленинградская область", code: "47" },

  { name: "Адыгея", code: "01" },
  { name: "Алтай", code: "04" },
  { name: "Алтайский край", code: "22" },
  { name: "Амурская область", code: "28" },
  { name: "Архангельская область", code: "29" },
  { name: "Астраханская область", code: "30" },

  { name: "Башкортостан", code: "02" },
  { name: "Бурятия", code: "03" },
  { name: "Белгородская область", code: "31" },
  { name: "Брянская область", code: "32" },
  { name: "Владимирская область", code: "33" },
  { name: "Волгоградская область", code: "34" },
  { name: "Вологодская область", code: "35" },
  { name: "Воронежская область", code: "36" },
  { name: "Дагестан", code: "05" },
  { name: "Донецкая Народная Республика", code: "80" },

  { name: "Запорожская область", code: "85" },
  { name: "Ивановская область", code: "37" },
  { name: "Ингушетия", code: "06" },
  { name: "Иркутская область", code: "38" },

  { name: "Кабардино-Балкарская Республика", code: "07" },
  { name: "Калининградская область", code: "39" },
  { name: "Калмыкия", code: "08" },
  { name: "Калужская область", code: "40" },
  { name: "Камчатский край", code: "41" },
  { name: "Карачаево-Черкесская Республика", code: "09" },
  { name: "Карелия", code: "10" },

  { name: "Кемеровская область", code: "42" },
  { name: "Кировская область", code: "43" },
  { name: "Коми", code: "11" },
  { name: "Костромская область", code: "44" },
  { name: "Краснодарский край", code: "23" },
  { name: "Красноярский край", code: "24" },
  { name: "Крым", code: "91" },
  { name: "Курганская область", code: "45" },
  { name: "Курская область", code: "46" },
  { name: "Липецкая область", code: "48" },
  { name: "Луганская Народная Республика", code: "81" },
  { name: "Магаданская область", code: "49" },
  { name: "Марий Эл", code: "12" },
  { name: "Мордовия", code: "13" },
  { name: "Мурманская область", code: "51" },
  { name: "Нижегородская область", code: "52" },
  { name: "Новгородская область", code: "53" },
  { name: "Новосибирская область", code: "54" },
  { name: "Омская область", code: "55" },
  { name: "Оренбургская область", code: "56" },
  { name: "Орловская область", code: "57" },
  { name: "Пензенская область", code: "58" },
  { name: "Пермский край", code: "59" },
  { name: "Приморский край", code: "25" },
  { name: "Псковская область", code: "60" },
  { name: "Ростовская область", code: "61" },
  { name: "Рязанская область", code: "62" },
  { name: "Самарская область", code: "63" },
  { name: "Саратовская область", code: "64" },
  { name: "Сахалинская область", code: "65" },
  { name: "Свердловская область", code: "66" },
  { name: "Севастополь", code: "92" },
  { name: "Северная Осетия (Алания)", code: "15" },
  { name: "Смоленская область", code: "67" },
  { name: "Ставропольский край", code: "26" },

  { name: "Тамбовская область", code: "68" },
  { name: "Татарстан", code: "16" },
  { name: "Тверская область", code: "69" },
  { name: "Томская область", code: "70" },
  { name: "Тульская область", code: "71" },
  { name: "Тюменская область", code: "72" },
  { name: "Тыва", code: "17" },
  { name: "Удмуртия", code: "18" },
  { name: "Ульяновская область", code: "73" },
  { name: "Челябинская область", code: "74" },
  { name: "Забайкальский край", code: "75" },
  { name: "Ярославская область", code: "76" },
  { name: "Еврейская Автономная область", code: "79" },
  { name: "Ненецкий АО", code: "83" },
  { name: "Хабаровский край", code: "27" },
  { name: "Хакасия", code: "19" },
  { name: "Ханты-Мансийский Автономный округ", code: "86" },
  { name: "Херсонская область", code: "84" },
  { name: "Чечня", code: "20" },
  { name: "Чувашия", code: "21" },
  { name: "Чукотский АО", code: "87" },
  { name: "Республика Саха (Якутия)", code: "14" },
  { name: "Ямало-Ненецкий АО", code: "89" },
];

export const regionsRFByCode: Record<string, string> = regionsRF.reduce(
  (acc, item) => {
    return {
      ...acc,
      [item.code]: item.name,
    };
  },
  {}
);
