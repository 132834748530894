import React from "react";
import { black } from "../styles";
import { IconBase, IconProps, Rotate90ClockWise, Rotate90CounterClockWise } from "./base";

export function CaretIcon({ color = black, ...props }: IconProps) {
  return (
    <IconBase {...props}>
      <path
        stroke="none"
        fill={color}
        d="M7.646 10.354l.708-.708L12 13.293l3.646-3.647.707.707L12 14.707l-4.354-4.354z"
      />
    </IconBase>
  );
}

export function CaretRight({ color = black, ...props }: IconProps) {
  return (
    <IconBase {...props}>
      <Rotate90CounterClockWise>
        <path
          stroke="none"
          fill={color}
          d="M7.646 10.354l.708-.708L12 13.293l3.646-3.647.707.707L12 14.707l-4.354-4.354z"
        />
      </Rotate90CounterClockWise>
    </IconBase>
  );
}

export function CaretLeft({ color = black, ...props }: IconProps) {
  return (
    <IconBase {...props}>
      <Rotate90ClockWise>
        <path
          stroke="none"
          fill={color}
          d="M7.646 10.354l.708-.708L12 13.293l3.646-3.647.707.707L12 14.707l-4.354-4.354z"
        />
      </Rotate90ClockWise>
    </IconBase>
  );
}
