import React, { useMemo } from "react";
import { Vector3, Vector3Tuple } from "three";
import { Text } from "@react-three/drei";

interface Props {
  p1: Vector3Tuple;
  p2: Vector3Tuple;
  normal: Vector3Tuple;
  textRotation?: Vector3Tuple;
  offset?: number;
  outer?: boolean;
  outerSerifLength?: number;
  outerEmptySerifLength?: number;
  textInside?: boolean;
}

const HEAD_LENGTH = 30;
const HEAD_WIDTH = 10;
const OUTER_SERIF_EMPTY = 15;
const OUTER_SERIF_TEXT = 100;
const TEXT_OFFSET = 25;

export const SERIF = 20;
export const MEASUREMENT_DEFAULT_OFFSET = 100;

export function Measurement({
  p1: p1Tuple,
  p2: p2Tuple,
  normal: normalTuple,
  textRotation = [0, 0, 0],
  offset = MEASUREMENT_DEFAULT_OFFSET,
  outer = false,
  outerSerifLength = OUTER_SERIF_TEXT,
  outerEmptySerifLength = OUTER_SERIF_EMPTY,
  textInside = false,
}: Props) {
  const { p1, p2, d1, d2, d1d2Unit, d2d1Unit, distance, textPosition } =
    useMemo(() => {
      const normal = new Vector3(...normalTuple).normalize();
      const offsetVector = normal.clone().multiplyScalar(offset);

      const p1 = new Vector3(...p1Tuple);
      const p2 = new Vector3(...p2Tuple);

      const d1 = p1.clone().add(offsetVector);
      const d2 = p2.clone().add(offsetVector);
      const d1d2Unit = d2.clone().sub(d1).normalize();
      const d2d1Unit = d1d2Unit.clone().multiplyScalar(-1);

      const distance = p2.distanceTo(p1);

      const textPosition = outer
        ? d1
            .clone()
            .sub(
              d1d2Unit
                .clone()
                .multiplyScalar(outerSerifLength / 2 + HEAD_LENGTH)
            )
        : d1.clone().add(d2).multiplyScalar(0.5);

      textPosition.add(
        normal.clone().multiplyScalar(textInside ? -TEXT_OFFSET : TEXT_OFFSET)
      );

      return {
        p1,
        p2,
        d1,
        d2,
        d1d2Unit,
        d2d1Unit,
        textPosition,
        distance,
      };
    }, [p1Tuple, p2Tuple, normalTuple, offset]);

  return (
    <React.Fragment>
      <Text
        rotation={textRotation}
        position={textPosition}
        fontSize={40}
        color="#000"
        anchorX="center"
        anchorY="middle"
      >
        {String(distance)}
      </Text>
      <arrowHelper
        args={[d1.clone().sub(p1).normalize(), p1, offset + SERIF, 0x000, 0, 0]}
      />
      <arrowHelper
        args={[d2.clone().sub(p2).normalize(), p2, offset + SERIF, 0x000, 0, 0]}
      />
      {outer ? (
        <React.Fragment>
          <arrowHelper
            args={[
              d1d2Unit,
              d1.clone().sub(d1d2Unit.clone().multiplyScalar(HEAD_LENGTH)),
              HEAD_LENGTH,
              0x000,
              HEAD_LENGTH,
              HEAD_WIDTH,
            ]}
          />
          <arrowHelper
            args={[
              d2d1Unit,
              d2.clone().sub(d2d1Unit.clone().multiplyScalar(HEAD_LENGTH)),
              HEAD_LENGTH,
              0x000,
              HEAD_LENGTH,
              HEAD_WIDTH,
            ]}
          />
          <arrowHelper
            args={[
              d1d2Unit,
              d1
                .clone()
                .sub(
                  d1d2Unit
                    .clone()
                    .multiplyScalar(outerSerifLength + HEAD_LENGTH)
                ),
              distance +
                2 * HEAD_LENGTH +
                outerSerifLength +
                outerEmptySerifLength,
              0x000,
              0,
              0,
            ]}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <arrowHelper
            args={[d1d2Unit, d1, distance, 0x000, HEAD_LENGTH, HEAD_WIDTH]}
          />
          <arrowHelper
            args={[d2d1Unit, d2, distance, 0x000, HEAD_LENGTH, HEAD_WIDTH]}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
