import React from "react";
import styled from "@emotion/styled";
import { TemplateConfigSimpleGroup } from "shared";
import { gutters } from "client-lib/styles";
import { Control } from "../controls";

const Container = styled.div`
  margin-bottom: ${gutters.md}px;
`;

interface Props {
  group: TemplateConfigSimpleGroup;
  onAttributeChange: (name: string, value: any) => void;
}

export function ConfigSimpleGroup({ group, onAttributeChange }: Props) {
  return (
    <Container>
      <h3>{group.title}</h3>
      {group.controls.map((def, index) => (
        <Control def={def} key={index} onAttributeChange={onAttributeChange} />
      ))}
    </Container>
  );
}
