import gql from "graphql-tag";
import { TemplateShotEnvironment, TemplatePresetEnvironment } from "shared";
import {
  TEMPLATE_PRESET_ENVIRONMENT_FOR_LISTS_FRAGMENT,
  TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT,
  TEMPLATE_SHOT_ENVIRONMENT_FULL_FRAGMENT,
  TemplatePresetEnvironmentForLists,
} from "client-lib";

export const TemplatePresetEnvironments = gql`
  query TemplatePresetEnvironments {
    TemplatePresetEnvironments {
      ...TemplatePresetEnvironmentForListsFragment
    }
  }
  ${TEMPLATE_PRESET_ENVIRONMENT_FOR_LISTS_FRAGMENT}
`;

export type TemplatePresetEnvironmentsData = {
  TemplatePresetEnvironments: TemplatePresetEnvironmentForLists[];
};

export const TemplateShotEnvironments = gql`
  query TemplateShotEnvironments {
    TemplateShotEnvironments {
      ...TemplateShotEnvironmentFullFragment
    }
  }
  ${TEMPLATE_SHOT_ENVIRONMENT_FULL_FRAGMENT}
`;

export type TemplateShotEnvironmentsData = {
  TemplateShotEnvironments: TemplateShotEnvironment[];
};

export const TemplatePresetPageQuery = gql`
  query TemplatePresetPage($templatePresetId: String!) {
    TemplatePresetEnvironment(templatePresetId: $templatePresetId) {
      ...TemplatePresetEnvironmentFullFragment
    }
  }
  ${TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface TemplatePresetPageVars {
  templatePresetId: string;
}

export type TemplatePresetPageData = {
  TemplatePresetEnvironment: TemplatePresetEnvironment;
};

export const TemplateShotPageQuery = gql`
  query TemplateShotPage($templateShotId: String!, $versionNumber: Int) {
    TemplateShotEnvironment(templateShotId: $templateShotId, versionNumber: $versionNumber) {
      ...TemplateShotEnvironmentFullFragment
    }
  }
  ${TEMPLATE_SHOT_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface TemplateShotPageVars {
  templateShotId: string;
  versionNumber: number | null;
}

export type TemplateShotPageData = {
  TemplateShotEnvironment: TemplateShotEnvironment;
};
