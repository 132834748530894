import React, { useContext, useState, useMemo, useEffect } from "react";
import { useMyEnvironment } from "src/features/environment";

type ProductionValue = {
  amount: number;
  deliveryRegion: string | null;
  deliveryLocationId: string | null;
  setAmount: (v: number) => void;
  setDeliveryRegion: (v: string | null) => void;
  setDeliveryLocationId: (v: string | null) => void;
};

const ProductionContext = React.createContext<ProductionValue>({
  amount: 0,
  deliveryRegion: null,
  deliveryLocationId: null,
  setAmount: () => {},
  setDeliveryRegion: () => {},
  setDeliveryLocationId: () => {},
});

export function useProduction(): ProductionValue {
  return useContext(ProductionContext);
}

interface Props {
  initialAmount?: number;
  children: React.ReactNode;
}

export function ProductionProvider({ initialAmount, children }: Props) {
  const [amount, setAmount] = useState<number>(initialAmount || 1);
  const [deliveryRegion, setDeliveryRegion] = useState<string | null>(null);
  const myEnvironment = useMyEnvironment();
  const [deliveryLocationId, setDeliveryLocationId] = useState<string | null>(
    myEnvironment?.deliveryLocationId ?? null
  );

  useEffect(
    function updateDeliveryLocationIdByEnvironment() {
      if (myEnvironment?.deliveryLocationId) {
        setDeliveryLocationId(myEnvironment?.deliveryLocationId);
      }
      if (myEnvironment?.deliveryRegion) {
        setDeliveryRegion(myEnvironment?.deliveryRegion);
      }
    },
    [myEnvironment?.deliveryLocationId, myEnvironment?.deliveryRegion]
  );

  const value = useMemo(
    () => ({
      amount,
      deliveryRegion,
      deliveryLocationId,
      setAmount,
      setDeliveryRegion,
      setDeliveryLocationId,
    }),
    [
      amount,
      deliveryRegion,
      deliveryLocationId,
      setAmount,
      setDeliveryRegion,
      setDeliveryLocationId,
    ]
  );

  return (
    <ProductionContext.Provider value={value}>
      {children}
    </ProductionContext.Provider>
  );
}
