import { z } from "zod";
import { materialExpandedSchema } from "../../schemas";
import {
  MAX_LENGTH,
  MIN_LENGTH,
  MAX_WIDTH,
  MIN_WIDTH,
  MAX_HEIGHT,
  MIN_HEIGHT,
  MAX_THICKNESS,
  MIN_THICKNESS,
  MIN_BUTT_OFFSET,
  MAX_BUTT_OFFSET,
  MIN_EDGE_OFFSET,
  MIN_FRAME_TOP_WIDTH,
  MAX_FRAME_TOP_WIDTH,
  MAX_EDGE_OFFSET,
  MIN_FRAME_HEIGHT,
  MAX_FRAME_HEIGHT,
  MAX_SUPPORT_GAP,
  MAX_SUPPORT_LENGTH,
  MIN_SUPPORT_GAP,
  MIN_SUPPORT_LENGTH,
} from "./constants";

export const configSchema = z.object({
  length: z.number().int().min(MIN_LENGTH).max(MAX_LENGTH),
  width: z.number().int().min(MIN_WIDTH).max(MAX_WIDTH),
  height: z.number().int().min(MIN_HEIGHT).max(MAX_HEIGHT),

  countertopCoverageId: z.string().nullable(),
  countertopLength: z.number().int().min(MIN_LENGTH).max(MAX_LENGTH),
  countertopWidth: z.number().int().min(MIN_WIDTH).max(MAX_WIDTH),
  countertopThickness: z.number().int().min(MIN_THICKNESS).max(MAX_THICKNESS),

  frameMaterialId: z.string(),
  frameMaterialWidth: z.number().int(),
  frameMaterialHeight: z.number().int(),
  frameCoverageId: z.string().nullable(),
  frameHeight: z.number().int().min(MIN_FRAME_HEIGHT).max(MAX_FRAME_HEIGHT),
  frameTopWidth: z
    .number()
    .int()
    .min(MIN_FRAME_TOP_WIDTH)
    .max(MAX_FRAME_TOP_WIDTH),
  frameBottomWidth: z.number().int().min(MIN_FRAME_TOP_WIDTH).max(MAX_WIDTH),
  frameWidthsAreEqual: z.boolean().optional(),

  supportAmount: z.number().int().min(0).max(2),
  supportMaterialId: z.string(),
  supportLength: z
    .number()
    .int()
    .min(MIN_SUPPORT_LENGTH)
    .max(MAX_SUPPORT_LENGTH),
  supportGap: z.number().int().min(MIN_SUPPORT_GAP).max(MAX_SUPPORT_GAP),

  frameHasBottomBar: z.boolean(),

  frameEdgeOffset: z.number().int().min(MIN_EDGE_OFFSET).max(MAX_EDGE_OFFSET),
  frameButtOffset: z.number().int().min(MIN_BUTT_OFFSET).max(MAX_BUTT_OFFSET),

  frameFootingId: z.string().nullable().optional(),
  fastenerId: z.string(),

  packageFramePosition: z.literal("center").or(z.literal("corners")),
  packageCardboardId: z.string(),
  packageBubbleWrapId: z.string(),
});
export type Config = z.infer<typeof configSchema>;

export const assetsSchema = z.object({
  countertopMaterial: materialExpandedSchema,
  countertopCoverage: z.array(materialExpandedSchema),
  frameMaterials: z.array(materialExpandedSchema),
  frameCoverage: z.array(materialExpandedSchema),
  frameFooting: z.array(materialExpandedSchema),
  fasteners: z.array(materialExpandedSchema),
  packageCardboard: z.array(materialExpandedSchema),
  packageBubbleWrap: z.array(materialExpandedSchema),
});
export type Assets = z.infer<typeof assetsSchema>;

export const variablesSchema = z.object({
  countertopTitle: z.string(),
  frameTitle: z.string(),
});
export type Variables = z.infer<typeof variablesSchema>;
