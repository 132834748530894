import { UnitGroupType, UnitType } from "../schemas";
import { getGroupAndUnitType } from "./conversion";

export interface Unit {
  type: UnitType;
  short: string;
  full: string;
  basic: number;
}

export const UNITS: Record<UnitGroupType, Unit[]> = {
  [UnitGroupType.PIECES]: [
    { type: UnitType.PIECE, short: "шт", full: "штук", basic: 1 },
  ],
  [UnitGroupType.LINEAR]: [
    {
      type: UnitType.MILLIMETER,
      short: "мм",
      full: "миллиметры",
      basic: 0.001,
    },
    { type: UnitType.CENTIMETER, short: "см", full: "сантиметры", basic: 0.01 },
    { type: UnitType.METER, short: "м", full: "метры", basic: 1 },
  ],
  [UnitGroupType.AREA]: [
    {
      type: UnitType.MILLIMETER_2,
      short: "мм\u00B2",
      full: "квадратные миллиметры",
      basic: 1 / (1000 * 1000),
    },
    {
      type: UnitType.CENTIMETER_2,
      short: "см\u00B2",
      full: "квадратные сантиметры",
      basic: 1 / (100 * 100),
    },
    {
      type: UnitType.METER_2,
      short: "м\u00B2",
      full: "квадратные метры",
      basic: 1,
    },
  ],
  [UnitGroupType.VOLUME]: [
    {
      type: UnitType.MILLIMETER_3,
      short: "мм\u00B3",
      full: "кубические миллиметры",
      basic: 1 / (1000 * 1000 * 1000),
    },
    {
      type: UnitType.CENTIMETER_3,
      short: "см\u00B3",
      full: "кубические сантиметры",
      basic: 1 / (100 * 100 * 100),
    },
    {
      type: UnitType.METER_3,
      short: "м\u00B3",
      full: "кубометры",
      basic: 1,
    },
  ],
  [UnitGroupType.MASS]: [
    {
      type: UnitType.MILLIGRAM,
      short: "мг",
      full: "миллиграммы",
      basic: 1 / (1000 * 1000),
    },
    {
      type: UnitType.GRAM,
      short: "г",
      full: "граммы",
      basic: 1 / 1000,
    },
    {
      type: UnitType.KILOGRAM,
      short: "кг",
      full: "килограммы",
      basic: 1,
    },
    {
      type: UnitType.TON,
      short: "т",
      full: "тонны",
      basic: 1000,
    },
  ],
  [UnitGroupType.MONEY]: [
    {
      type: UnitType.KOPECKS,
      short: "коп",
      full: "копейки",
      basic: 1,
    },
    {
      type: UnitType.RUBLE,
      short: "\u20BD",
      full: "рубли",
      basic: 100,
    },
  ],
  [UnitGroupType.TIME]: [
    {
      type: UnitType.HOUR,
      short: "ч",
      full: "часы",
      basic: 1,
    },
  ],
};

(function validateUnits() {
  for (const unitGroupType of Object.values(UnitGroupType)) {
    const basicUnits = UNITS[unitGroupType].filter((u) => u.basic === 1);
    if (basicUnits.length !== 1) {
      throw new Error(`Invalid unit group ${unitGroupType}`);
    }
  }
})();

export function getUnit(unitType: UnitType) {
  const { unit } = getGroupAndUnitType(unitType);
  return unit;
}
