import React, { useMemo, useState } from "react";
import {
  Pricing,
  PricingLimit,
  pickBestPricingValue,
  PricingLimitValues,
} from "shared";
import styled from "@emotion/styled";
import {
  Price,
  Table,
  THead,
  TFoot,
  TR,
  TH,
  typographyCondensed,
} from "client-lib/ui";
import { CartButton } from "src/features/order";
import { LimitSelector } from "./limit-selector";
import { PricingSection } from "./pricing-section";
import { SectionPrices } from "./types";

const Container = styled.div``;
const PricingComment = styled.p(typographyCondensed);

interface Props {
  pricing: Pricing;
}

export function PricingFull({ pricing }: Props) {
  const [activeLimit, setActiveLimit] = useState<PricingLimit>(
    PricingLimit.BASIC
  );

  const { sectionPrices, total } = useMemo<{
    sectionPrices: SectionPrices;
    total: number;
  }>(() => {
    let acc = 0;
    const result: SectionPrices = {};
    for (let i = 0; i < pricing.sections.length; i++) {
      const section = pricing.sections[i];
      result[i] = 0;
      for (const item of section.items) {
        const pricingValue = pickBestPricingValue(
          item.unitPricingValues,
          PricingLimitValues[activeLimit]
        );
        if (!pricingValue) {
          throw new Error(
            `Couldn't find appropriate pricing for item ${item.title} of section ${section.title}`
          );
        }
        const itemPrice = Math.round(
          item.unitAmount * pricingValue.costKopecks * item.amount
        );
        result[i] += itemPrice;
        acc += itemPrice;
      }
    }
    return { sectionPrices: result, total: acc };
  }, [pricing, activeLimit]);

  return (
    <Container>
      <PricingComment>
        Цены зависят от&nbsp;количества заказываемых изделий:
      </PricingComment>
      <LimitSelector activeLimit={activeLimit} onChange={setActiveLimit} />
      <Table>
        <THead>
          <TR>
            <TH openTop>Наименование</TH>
            <TH openTop>Единица</TH>
            <TH openTop>Цена ед.</TH>
            <TH openTop>Объем</TH>
            <TH openTop>Кол-во</TH>
            <TH openTop>Стоимость</TH>
          </TR>
        </THead>
        {pricing.sections.map((_section, index) => (
          <PricingSection
            key={index}
            pricing={pricing}
            pricingLimit={activeLimit}
            sectionPrices={sectionPrices}
            index={index}
            preline
          />
        ))}
        <TFoot>
          <TR>
            <TH openBottom colSpan={5}>
              Итого
            </TH>
            <TH openBottom>
              <Price kopecks={total} />
            </TH>
          </TR>
        </TFoot>
      </Table>
      <CartButton />
    </Container>
  );
}
