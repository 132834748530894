import styled from "@emotion/styled";
import { APP_BAR_HEIGHT, black, gutters, white } from "client-lib/ui";
import { SIDEBAR_WIDTH } from "src/features/template-editor/template-editor-core";
import { Link } from "react-router-dom";

export const Layout = styled.div`
  overflow-x: none;
`;

export const Container = styled.div``;

export const Bar = styled.div`
  height: ${APP_BAR_HEIGHT}px;
  background-color: ${black};
  color: ${white};
  display: flex;
  align-items: center;
`;
export const Left = styled.div`
  width: ${SIDEBAR_WIDTH}px;
  flex: 0 0 ${SIDEBAR_WIDTH}px;
  display: flex;
  align-items: center;
  padding-left: ${gutters.sm}px;
  box-sizing: border-box;
  a {
    margin-right: ${gutters.sm}px;
  }
`;
export const Center = styled.div`
  flex: 1 1 100%;
`;
export const Right = styled.div`
  display: flex;
  flex: 0 0 ${SIDEBAR_WIDTH}px;
  align-items: center;
  justify-content: space-between;
  button {
    margin-right: ${gutters.md}px;
  }
`;

const BACK_SIZE = 32;
export const Back = styled(Link)`
  color: ${white};
  height: ${BACK_SIZE}px;
  display: flex;
  align-items: center;
  text-decoration: none;
`;
