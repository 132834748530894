import { z } from "zod";

export enum UnitType {
  PIECE = "PIECE",
  MILLIMETER = "MILLIMETER",
  CENTIMETER = "CENTIMETER",
  METER = "METER",
  MILLIMETER_2 = "MILLIMETER_2",
  CENTIMETER_2 = "CENTIMETER_2",
  METER_2 = "METER_2",
  MILLIMETER_3 = "MILLIMETER_3",
  CENTIMETER_3 = "CENTIMETER_3",
  METER_3 = "METER_3",
  MILLIGRAM = "MILLIGRAM",
  GRAM = "GRAM",
  KILOGRAM = "KILOGRAM",
  TON = "TON",
  KOPECKS = "KOPECKS",
  RUBLE = "RUBLE",
  HOUR = "HOUR",
}
export const unitTypeSchema = z.nativeEnum(UnitType);

export enum UnitGroupType {
  PIECES = "PIECES",
  LINEAR = "LINEAR",
  AREA = "AREA",
  VOLUME = "VOLUME",
  MASS = "MASS",
  MONEY = "MONEY",
  TIME = "TIME",
}
export const unitGroupTypeSchema = z.nativeEnum(UnitGroupType);
