import React, { memo } from "react";
import {
  Box2,
  Measurement,
  MEASUREMENT_DEFAULT_OFFSET,
} from "../../../objects";
import { useShelf } from "../shelf-context";
import { ShelfItemProps } from "./types";

export const ShelfItem4 = memo(function ShelfItem4({
  item,
  edgeX,
  edgeY,
  surface,
  showMeasurements,
}: ShelfItemProps) {
  const { config } = useShelf();

  return (
    <React.Fragment>
      <Box2
        xSize={item.width}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          ltf: [item.thickness, 0, 0],
          ltb: [item.thickness, 0, 0],
          rtf: [-item.thickness, 0, 0],
          rtb: [-item.thickness, 0, 0],
        }}
        {...surface}
        position={[edgeX + item.width / 2, edgeY + item.thickness / 2, 0]}
      />
      <Box2
        xSize={item.width}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          ltf: [item.thickness, 0, 0],
          ltb: [item.thickness, 0, 0],
          rtf: [-item.thickness, 0, 0],
          rtb: [-item.thickness, 0, 0],
        }}
        {...surface}
        position={[
          edgeX + item.width / 2,
          edgeY + item.thickness / 2 + (item.height - item.thickness),
          0,
        ]}
        rotation={[0, 0, Math.PI]}
      />
      <Box2
        xSize={item.height}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          ltf: [item.thickness, 0, 0],
          ltb: [item.thickness, 0, 0],
          rtf: [-item.thickness, 0, 0],
          rtb: [-item.thickness, 0, 0],
        }}
        {...surface}
        position={[
          edgeX + item.width / 2 - (item.width - item.thickness) / 2,
          edgeY + item.height / 2,
          0,
        ]}
        rotation={[0, 0, -Math.PI / 2]}
      />
      <Box2
        xSize={item.height}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          ltf: [item.thickness, 0, 0],
          ltb: [item.thickness, 0, 0],
          rtf: [-item.thickness, 0, 0],
          rtb: [-item.thickness, 0, 0],
        }}
        {...surface}
        position={[
          edgeX + item.width / 2 + (item.width - item.thickness) / 2,
          edgeY + item.height / 2,
          0,
        ]}
        rotation={[0, 0, Math.PI / 2]}
      />

      {showMeasurements && (
        <React.Fragment>
          <Measurement
            key="thickness"
            p2={[edgeX, edgeY, config.depth / 2]}
            p1={[edgeX + item.thickness, edgeY, config.depth / 2]}
            normal={[0, 0, 1]}
            outer
            outerEmptySerifLength={0}
            textInside
            textRotation={[-Math.PI / 2, 0, 0]}
          />
          <Measurement
            key="weight"
            p1={[edgeX, edgeY, config.depth / 2]}
            p2={[edgeX + item.width, edgeY, config.depth / 2]}
            normal={[0, 0, 1]}
            offset={MEASUREMENT_DEFAULT_OFFSET * 2}
            textInside
            textRotation={[-Math.PI / 2, 0, 0]}
          />
          <Measurement
            key="height"
            p1={[edgeX, edgeY, 0]}
            p2={[edgeX, edgeY + item.height, 0]}
            normal={[-1, 0, 0]}
            textRotation={[0, 0, Math.PI / 2]}
          />
          <Measurement
            key="depth"
            p1={[edgeX, edgeY + item.height, -config.depth / 2]}
            p2={[edgeX, edgeY + item.height, config.depth / 2]}
            normal={[0, 1, 0]}
            textRotation={[0, Math.PI / 2, 0]}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
});
