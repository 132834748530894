import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";

export const imageSchema = z.object({
  ...standardAttributes,
  originalWidth: z.number().int(),
  originalHeight: z.number().int(),
  mimeType: z.string(),
});
checkSchema(imageSchema);
export type Image = typeof imageSchema["_type"];

export const imageInputSchema = imageSchema.pick({
  id: true,
  originalWidth: true,
  originalHeight: true,
  mimeType: true,
});
export type ImageInput = z.infer<typeof imageInputSchema>;
