import React from "react";
import { MaterialExpanded } from "shared";
import { Box } from "../../objects";
import { Config, Assets } from "./types";

export function Countertop({
  config,
  assets,
  coverage,
}: {
  config: Config;
  assets: Assets;
  coverage: MaterialExpanded | null;
}) {
  return coverage ? (
    <Box
      xSize={config.countertopLength}
      zSize={config.countertopWidth}
      ySize={config.countertopThickness}
      xSurface={coverage.texture3}
      ySurface={coverage.texture1}
      zSurface={coverage.texture2}
    />
  ) : (
    <Box
      xSize={config.countertopLength}
      zSize={config.countertopWidth}
      ySize={config.countertopThickness}
      xSurface={assets.countertopMaterial.texture3}
      ySurface={assets.countertopMaterial.texture1}
      zSurface={assets.countertopMaterial.texture2}
    />
  );
}
