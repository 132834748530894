import React, { useMemo, useState, useCallback } from "react";
import { Pricing } from "shared";
import styled from "@emotion/styled";
import {
  InnerLink,
  Price,
  Table,
  TBody,
  THead,
  TFoot,
  TR,
  TD,
  TH,
  gutters,
} from "client-lib/ui";
import { useProduction } from "src/features/order";
import { PricingModal } from "../pricing-modal";
import { SectionPricesAndTotal } from "../types";
import { calculateSectionPricesAndTotal } from "./utils";

const Container = styled.div``;

const CountLink = styled(InnerLink)``;
const ActionBar = styled.div`
  margin: ${gutters.sm}px 0 0;
`;

interface Props {
  pricing: Pricing;
  isLoading: boolean;
  isStale: boolean;
}

export function PricingBrief({ pricing }: Props) {
  const { amount } = useProduction();
  const [isPricingModalOpen, setIsPricingModalOpen] = useState<boolean>(false);

  const { sectionPrices, total } = useMemo<SectionPricesAndTotal>(() => {
    return calculateSectionPricesAndTotal(pricing, amount);
  }, [pricing, amount]);

  const handlePricingModalCloseRequest = useCallback(() => {
    setIsPricingModalOpen(false);
  }, []);

  return (
    <Container>
      <Table>
        <THead>
          <TR>
            <TH openTop>Компонент</TH>
            <TH openTop>Цена</TH>
          </TR>
        </THead>
        <TBody>
          {pricing.sections.map((section, index) => (
            <TR key={index}>
              <TD>{section.title}</TD>
              <TD>
                <Price kopecks={sectionPrices[index]} />
              </TD>
            </TR>
          ))}
        </TBody>
        <TFoot>
          <TR>
            <TH openBottom>Итого</TH>
            <TH openBottom>
              <Price kopecks={total} />
            </TH>
          </TR>
        </TFoot>
      </Table>
      <ActionBar>
        <CountLink onClick={() => setIsPricingModalOpen(true)}>
          Открыть подробную смету
        </CountLink>
      </ActionBar>
      {isPricingModalOpen && (
        <PricingModal
          pricing={pricing}
          onCloseRequest={handlePricingModalCloseRequest}
        />
      )}
    </Container>
  );
}
