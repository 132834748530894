import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";
import { imageSchema } from "./image";

export const templatePresetImageSchema = z.object({
  ...standardAttributes,
  ordering: z.number().int(),
  templatePresetId: z.string().uuid(),
  imageId: z.string().uuid(),
  title: z.string().nullable(),
});
checkSchema(templatePresetImageSchema);
export type TemplatePresetImage = z.infer<typeof templatePresetImageSchema>;

export const templatePresetImageExpandedSchema =
  templatePresetImageSchema.extend({
    ...standardAttributes,
    image: imageSchema,
  });
export type TemplatePresetImageExpanded = z.infer<
  typeof templatePresetImageExpandedSchema
>;

export const templatePresetImageInputSchema = templatePresetImageSchema.pick({
  id: true,
  ordering: true,
  imageId: true,
  title: true,
});
export type TemplatePresetImageInput = z.infer<
  typeof templatePresetImageInputSchema
>;
