import React, { Dispatch, memo, SetStateAction } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { white, black, gutters, logo } from "client-lib";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Pill = styled.div<{ isActive: boolean }>(({ isActive }) => [
  css`
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 ${gutters.md}px;
    margin-right: ${gutters.sm}px;
    border-radius: 36px;
    border: 1px solid ${white};
    color: ${white};
    cursor: pointer;
  `,
  isActive &&
    css`
      border: 1px solid ${logo};
      color: ${black};
      background-color: ${logo};
    `,
]);

interface Props {
  titles: string[];
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  className?: string;
}

export const PillSelector = memo(function PillSelector({
  titles,
  activeIndex,
  setActiveIndex,
  className,
}: Props) {
  return (
    <Container className={className}>
      {titles.map((title, index) => (
        <Pill
          key={index}
          isActive={activeIndex === index}
          onClick={() => setActiveIndex(index)}
        >
          {title}
        </Pill>
      ))}
    </Container>
  );
});
