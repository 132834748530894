import React from "react";
import { black } from "../styles";
import { IconBase, IconProps } from "./base";

export function BackIcon({ color = black, ...props }: IconProps) {
  return (
    <IconBase {...props}>
      <path stroke={color} d="M14.5 6.5L9 12L14.5 17.5" />
    </IconBase>
  );
}
