import React from "react";
import { MaterialExpanded, getDimensionsMm } from "shared";
import {
  Measurement,
  MEASUREMENT_DEFAULT_OFFSET,
} from "src/features/template-editor/objects";
import { FrameItem } from "./frame-item";
import { useShelf } from "./shelf-context";
import { useSceneOptions } from "../../scene-context";

export function Frame({
  material,
  coverage,
}: {
  material: MaterialExpanded;
  coverage: MaterialExpanded | null;
}) {
  const options = useSceneOptions();
  const { config } = useShelf();
  const pipe = getDimensionsMm(material);
  if (!pipe.d1 || !pipe.d2) {
    return null;
  }

  return (
    <group position={[0, 0, 0]}>
      <FrameItem
        material={material}
        coverage={coverage}
        position={[-(config.frameGap + pipe.d1) / 2, 0, 0]}
      />
      <FrameItem
        material={material}
        coverage={coverage}
        position={[(config.frameGap + pipe.d1) / 2, 0, 0]}
      />
      {new Array(config.frameItemsCount - 2)
        .fill(null)
        .map((_, index: number) => (
          <FrameItem
            key={index}
            material={material}
            coverage={coverage}
            position={[
              -(config.frameGap + pipe.d1!) / 2 +
                ((config.frameGap + pipe.d1!) / (config.frameItemsCount - 1)) *
                  (index + 1),
              0,
              0,
            ]}
          />
        ))}

      {options.showMeasurements && (
        <React.Fragment>
          <Measurement
            key="x-size"
            p1={[-(config.frameGap / 2 + pipe.d1), 0, config.frameWidth / 2]}
            p2={[config.frameGap / 2 + pipe.d1, 0, config.frameWidth / 2]}
            normal={[0, -1, 0]}
            textInside
            offset={MEASUREMENT_DEFAULT_OFFSET}
            textRotation={[0, 0, 0]}
          />
          <Measurement
            key="width"
            p1={[-(config.frameGap / 2 + pipe.d1), 0, -config.frameWidth / 2]}
            p2={[-(config.frameGap / 2 + pipe.d1), 0, config.frameWidth / 2]}
            normal={[0, -1, 0]}
            textInside
            offset={MEASUREMENT_DEFAULT_OFFSET}
            textRotation={[0, -Math.PI / 2, 0]}
          />
          <Measurement
            key="height"
            p1={[-(config.frameGap / 2 + pipe.d1), 0, config.frameWidth / 2]}
            p2={[
              -(config.frameGap / 2 + pipe.d1),
              config.frameHeight,
              config.frameWidth / 2,
            ]}
            normal={[-1, 0, 0]}
            offset={
              (config.length - config.frameGap) / 2 +
              MEASUREMENT_DEFAULT_OFFSET * 3
            }
            textRotation={[0, 0, Math.PI / 2]}
          />
          <Measurement
            key="pipe-width"
            p1={[
              -(config.frameGap / 2 + pipe.d1),
              config.frameHeight,
              config.frameWidth / 2,
            ]}
            p2={[
              -(config.frameGap / 2),
              config.frameHeight,
              config.frameWidth / 2,
            ]}
            normal={[0, 1, 0]}
            outer
            outerSerifLength={50}
            textRotation={[0, 0, 0]}
          />
          <Measurement
            key="pipe-height"
            p1={[
              -(config.frameGap / 2 + pipe.d1),
              config.frameHeight,
              config.frameWidth / 2,
            ]}
            p2={[
              -(config.frameGap / 2 + pipe.d1),
              config.frameHeight - pipe.d2,
              config.frameWidth / 2,
            ]}
            normal={[-1, 0, 0]}
            outer
            outerSerifLength={50}
            textRotation={[0, 0, Math.PI / 2]}
          />
        </React.Fragment>
      )}
    </group>
  );
}
