import React from "react";
import { Measurement, MEASUREMENT_DEFAULT_OFFSET } from "../../objects";
import { Frame } from "./frame";
import { Countertop } from "./countertop";
import { Package } from "./package";

import { Config, Assets, Variables } from "./types";
import { RenderMode } from "../../types";
import { useSceneOptions } from "../../scene-context";

interface Props {
  config: Config;
  assets: Assets;
  variables: Variables;
  mode: RenderMode;
}

export function Table({ config, assets, mode }: Props) {
  const options = useSceneOptions();
  const countertopCoverageMaterial =
    assets.countertopCoverage.find(
      (m) => m.id === config.countertopCoverageId
    ) || null;
  const frameMaterial =
    assets.frameMaterials.find((m) => m.id === config.frameMaterialId) || null;
  const supportMaterial =
    assets.frameMaterials.find((m) => m.id === config.supportMaterialId) ||
    null;
  if (!frameMaterial || !supportMaterial) {
    return <React.Fragment />;
  }
  if (!frameMaterial.mmDimension1 || !frameMaterial.mmDimension2) {
    return <React.Fragment />;
  }

  const pipeWidth = frameMaterial.mmDimension1;
  const pipeHeight = frameMaterial.mmDimension2;
  const frameCoverageMaterial =
    assets.frameCoverage.find((m) => m.id === config.frameCoverageId) || null;

  const showCountertop = !options.parts || options.parts.has("countertop");
  const showFrame = !options.parts || options.parts.has("frame");

  if (mode === "package") {
    return (
      <Package
        config={config}
        assets={assets}
        frameMaterial={frameMaterial}
        supportMaterial={supportMaterial}
        frameCoverageMaterial={frameCoverageMaterial}
        countertopCoverageMaterial={countertopCoverageMaterial}
      />
    );
  }
  return (
    <group position={[0, config.height / 2, 0]}>
      {showCountertop && (
        <Countertop
          config={config}
          assets={assets}
          coverage={countertopCoverageMaterial}
        />
      )}
      {showFrame && (
        <Frame
          config={config}
          material={frameMaterial}
          supportMaterial={supportMaterial}
          coverage={frameCoverageMaterial}
        />
      )}

      {options.showMeasurements && (
        <React.Fragment>
          {showCountertop && (
            <React.Fragment>
              /* render horiz or vertical coords depending on is it just
              countertop or a whole table */
              {showFrame ? (
                <React.Fragment>
                  <Measurement
                    key="Countertop Length"
                    p1={[
                      -config.countertopLength / 2,
                      config.countertopThickness / 2,
                      config.countertopWidth / 2,
                    ]}
                    p2={[
                      config.countertopLength / 2,
                      config.countertopThickness / 2,
                      config.countertopWidth / 2,
                    ]}
                    normal={[0, 1, 0]}
                  />
                  <Measurement
                    key="Countertop Width"
                    p1={[
                      config.countertopLength / 2,
                      config.countertopThickness / 2,
                      config.countertopWidth / 2,
                    ]}
                    p2={[
                      config.countertopLength / 2,
                      config.countertopThickness / 2,
                      -config.countertopWidth / 2,
                    ]}
                    normal={[0, 1, 0]}
                    textRotation={[0, Math.PI / 2, 0]}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Measurement
                    key="Countertop Length"
                    p1={[
                      -config.countertopLength / 2,
                      config.countertopThickness / 2,
                      config.countertopWidth / 2,
                    ]}
                    p2={[
                      config.countertopLength / 2,
                      config.countertopThickness / 2,
                      config.countertopWidth / 2,
                    ]}
                    normal={[0, 0, 1]}
                    textInside
                    textRotation={[-Math.PI / 2, 0, 0]}
                  />
                  <Measurement
                    key="Countertop Width"
                    p1={[
                      config.countertopLength / 2,
                      config.countertopThickness / 2,
                      config.countertopWidth / 2,
                    ]}
                    p2={[
                      config.countertopLength / 2,
                      config.countertopThickness / 2,
                      -config.countertopWidth / 2,
                    ]}
                    normal={[1, 0, 0]}
                    textInside
                    textRotation={[-Math.PI / 2, 0, Math.PI / 2]}
                  />
                </React.Fragment>
              )}
              <Measurement
                key="Countertop Thickness"
                p1={[
                  -config.countertopLength / 2,
                  config.countertopThickness / 2,
                  0,
                ]}
                p2={[
                  -config.countertopLength / 2,
                  -config.countertopThickness / 2,
                  0,
                ]}
                normal={[-1, 0, 0]}
                textRotation={[0, 0, Math.PI / 2]}
                offset={2 * MEASUREMENT_DEFAULT_OFFSET}
                outer
              />
            </React.Fragment>
          )}
          {showFrame && (
            <React.Fragment>
              <Measurement
                key="Frame Height"
                p1={[
                  -config.countertopLength / 2 + config.frameButtOffset,
                  -config.countertopThickness / 2,
                  0,
                ]}
                p2={[
                  -config.countertopLength / 2 + config.frameButtOffset,
                  -config.countertopThickness / 2 - config.frameHeight,
                  0,
                ]}
                normal={[-1, 0, 0]}
                textRotation={[0, 0, Math.PI / 2]}
                offset={2 * MEASUREMENT_DEFAULT_OFFSET + config.frameButtOffset}
              />
              <Measurement
                key="Frame Inner Height"
                p1={[
                  -config.countertopLength / 2 + config.frameButtOffset,
                  -config.countertopThickness / 2 - pipeHeight,
                  0,
                ]}
                p2={[
                  -config.countertopLength / 2 + config.frameButtOffset,
                  -config.countertopThickness / 2 -
                    config.frameHeight +
                    pipeHeight,
                  0,
                ]}
                normal={[-1, 0, 0]}
                textRotation={[0, 0, Math.PI / 2]}
                offset={MEASUREMENT_DEFAULT_OFFSET + config.frameButtOffset}
              />
              <Measurement
                key="Profile Height"
                p1={[
                  -config.countertopLength / 2 + config.frameButtOffset,
                  -config.countertopThickness / 2 - config.frameHeight,
                  0,
                ]}
                p2={[
                  -config.countertopLength / 2 + config.frameButtOffset,
                  -config.countertopThickness / 2 -
                    config.frameHeight +
                    pipeHeight,
                  0,
                ]}
                normal={[-1, 0, 0]}
                textRotation={[0, 0, Math.PI / 2]}
                offset={MEASUREMENT_DEFAULT_OFFSET + config.frameButtOffset}
                outer
              />
              <Measurement
                key="Total Height"
                p1={[
                  -config.countertopLength / 2 + config.frameButtOffset,
                  config.countertopThickness / 2,
                  0,
                ]}
                p2={[
                  -config.countertopLength / 2 + config.frameButtOffset,
                  -config.countertopThickness / 2 - config.frameHeight,
                  0,
                ]}
                normal={[-1, 0, 0]}
                textRotation={[0, 0, Math.PI / 2]}
                offset={3 * MEASUREMENT_DEFAULT_OFFSET + config.frameButtOffset}
              />
              <Measurement
                key="Profile Width"
                p1={[
                  -config.countertopLength / 2 +
                    config.frameButtOffset +
                    pipeWidth,
                  -config.countertopThickness / 2 - config.frameHeight,
                  0,
                ]}
                p2={[
                  -config.countertopLength / 2 + config.frameButtOffset,
                  -config.countertopThickness / 2 - config.frameHeight,
                  0,
                ]}
                normal={[0, -1, 0]}
                outer
                textInside
              />
              <Measurement
                key="Frame Top Width"
                p1={[
                  config.countertopLength / 2 - config.frameButtOffset,
                  -config.countertopThickness / 2,
                  config.countertopWidth / 2 - config.frameEdgeOffset,
                ]}
                p2={[
                  config.countertopLength / 2 - config.frameButtOffset,
                  -config.countertopThickness / 2,
                  -(config.countertopWidth / 2 - config.frameEdgeOffset),
                ]}
                normal={[1, 0, 0]}
                offset={2 * MEASUREMENT_DEFAULT_OFFSET + config.frameButtOffset}
                textRotation={[-Math.PI / 2, 0, Math.PI / 2]}
                textInside
              />
            </React.Fragment>
          )}
          {showCountertop && showFrame && (
            <React.Fragment>
              <Measurement
                key="frameEdgeOffset"
                p1={[
                  config.countertopLength / 2 - config.frameButtOffset,
                  -config.countertopThickness / 2,
                  config.countertopWidth / 2,
                ]}
                p2={[
                  config.countertopLength / 2 - config.frameButtOffset,
                  -config.countertopThickness / 2,
                  config.countertopWidth / 2 - config.frameEdgeOffset,
                ]}
                normal={[1, 0, 0]}
                offset={2 * MEASUREMENT_DEFAULT_OFFSET + config.frameButtOffset}
                textRotation={[-Math.PI / 2, 0, Math.PI / 2]}
                outer={config.frameEdgeOffset < 100}
                textInside
              />
              <Measurement
                key="frameButtOffset"
                p1={[
                  config.countertopLength / 2 - config.frameButtOffset,
                  -config.countertopThickness / 2,
                  config.countertopWidth / 2 - config.frameEdgeOffset,
                ]}
                p2={[
                  config.countertopLength / 2,
                  -config.countertopThickness / 2,
                  config.countertopWidth / 2 - config.frameEdgeOffset,
                ]}
                normal={[0, 0, 1]}
                offset={2 * MEASUREMENT_DEFAULT_OFFSET + config.frameEdgeOffset}
                textRotation={[-Math.PI / 2, 0, 0]}
                outer={config.frameButtOffset < 100}
                textInside
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </group>
  );
}
