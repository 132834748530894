import React, { memo } from "react";
import {
  Box2,
  Measurement,
  MEASUREMENT_DEFAULT_OFFSET,
} from "../../../objects";
import { useShelf } from "../shelf-context";
import { useSceneOptions } from "../../../scene-context";
import { ShelfItemProps } from "./types";
import { ShelfConfigItem } from "../types";

function getVars(item: ShelfConfigItem) {
  const side = ((item.width / 2) ** 2 + item.height ** 2) ** 0.5;
  const dh = (item.thickness * side) / (item.width / 2);

  const iw = (item.width * (item.height - item.thickness - dh)) / item.height;
  const dw = (item.width - iw) / 2;

  return {
    side,
    dh,
    iw,
    dw,
  };
}

export function getPackageDeltaXForItem3(item: ShelfConfigItem) {
  return getVars(item).dw;
}

export const ShelfItem3 = memo(function ShelfItem3({
  item,
  edgeX,
  edgeY,
  surface,
}: ShelfItemProps) {
  const options = useSceneOptions();
  const { config } = useShelf();

  const { dh, iw, dw } = getVars(item);

  return (
    <React.Fragment>
      <Box2
        xSize={item.width}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          ltf: [dw, 0, 0],
          ltb: [dw, 0, 0],
          rtf: [-dw, 0, 0],
          rtb: [-dw, 0, 0],
        }}
        {...surface}
        position={[edgeX + item.width / 2, edgeY + item.thickness / 2, 0]}
      />
      <Box2
        xSize={item.width / 2}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          rtf: [0, item.height - item.thickness, 0],
          rtb: [0, item.height - item.thickness, 0],
          rbf: [0, item.height - dh, 0],
          rbb: [0, item.height - dh, 0],
          ltf: [0, -item.thickness, 0],
          ltb: [0, -item.thickness, 0],
          lbf: [dw, item.thickness, 0],
          lbb: [dw, item.thickness, 0],
        }}
        {...surface}
        position={[edgeX + item.width / 4, edgeY + item.thickness / 2, 0]}
      />
      <Box2
        xSize={item.width / 2}
        ySize={item.thickness}
        zSize={config.depth}
        offset={{
          ltf: [0, item.height - item.thickness, 0],
          ltb: [0, item.height - item.thickness, 0],
          lbf: [0, item.height - dh, 0],
          lbb: [0, item.height - dh, 0],
          rtf: [0, -item.thickness, 0],
          rtb: [0, -item.thickness, 0],
          rbf: [-dw, item.thickness, 0],
          rbb: [-dw, item.thickness, 0],
        }}
        {...surface}
        position={[edgeX + (3 * item.width) / 4, edgeY + item.thickness / 2, 0]}
      />

      {options.showMeasurements && (
        <React.Fragment>
          <Measurement
            key="thickness"
            p1={[edgeX + item.width / 2 - iw / 2, edgeY, config.depth / 2]}
            p2={[
              edgeX + item.width / 2 - iw / 2,
              edgeY + item.thickness,
              config.depth / 2,
            ]}
            normal={[0, 0, 1]}
            outer
            outerSerifLength={60}
            outerEmptySerifLength={0}
            textRotation={[0, Math.PI / 2, Math.PI / 2]}
          />
          <Measurement
            key="height"
            p1={[edgeX + item.width / 2, edgeY, 0]}
            p2={[edgeX + item.width / 2, edgeY + item.height, 0]}
            normal={[-1, 0, 0]}
            offset={item.width / 2 + MEASUREMENT_DEFAULT_OFFSET}
            outerSerifLength={40}
            outerEmptySerifLength={0}
            textRotation={[0, 0, Math.PI / 2]}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
});
