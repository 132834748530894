import React, { useMemo } from "react";
import { useSceneOptions } from "src/features/template-editor/scene-context";
import { ShelfItem, getPackageDeltaX } from "./shelf-item";
import { ShelfProvider } from "./shelf-context";
import { Config, Assets, Variables } from "./types";
import { RenderMode } from "../../types";

const GAP = 200;

interface Props {
  config: Config;
  assets: Assets;
  variables: Variables;
  mode: RenderMode;
}

export function ShelfMatreshka({ config, assets, variables, mode }: Props) {
  const { showMeasurements } = useSceneOptions();

  const items = useMemo<JSX.Element[]>(() => {
    const result: JSX.Element[] = [];

    if (mode === "package") {
      let edgeX = -config.items[0].width / 2;
      let edgeY = 0;
      for (let index = 0; index < config.items.length; index++) {
        const item = config.items[index];

        result.push(
          <ShelfItem
            key={index}
            item={item}
            edgeX={edgeX}
            edgeY={edgeY}
            showMeasurements={showMeasurements && index === 0}
          />
        );

        edgeX += getPackageDeltaX(config, item);
        edgeY += item.thickness;
      }
    } else {
      const edgeY = 0;
      let totalWidth = -GAP;
      for (const item of config.items) {
        totalWidth += item.width + GAP;
      }

      let edgeX = -totalWidth / 2;
      for (let index = 0; index < config.items.length; index++) {
        const item = config.items[index];

        result.push(
          <ShelfItem
            key={index}
            item={item}
            edgeX={edgeX}
            edgeY={edgeY}
            showMeasurements={showMeasurements}
          />
        );

        edgeX += item.width + GAP;
      }
    }
    return result;
  }, [config, mode, showMeasurements]);

  return (
    <ShelfProvider
      config={config}
      assets={assets}
      variables={variables}
      mode={mode}
    >
      <group position={[0, -config.height / 2, 0]}>{items}</group>
    </ShelfProvider>
  );
}
