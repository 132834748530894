import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { gutters, typographyBody2, errorRed as errorColor } from "../../styles";

export const FieldError = styled.label([
  typographyBody2,
  css`
    display: block;
    color: ${errorColor};
    margin-top: ${gutters.sm}px;
  `,
]);
