import { UnitGroupType, UnitType } from "../schemas";
import { UNITS, Unit } from "./units";

export function getGroupAndUnitType(unitType: UnitType): {
  unitGroupType: UnitGroupType;
  unit: Unit;
} {
  for (const unitGroupType of Object.values(UnitGroupType)) {
    const unit = UNITS[unitGroupType].find((u) => u.type === unitType);
    if (unit) {
      return {
        unitGroupType,
        unit,
      };
    }
  }
  throw new Error(`Can't find unit with type ${unitType}`);
}

export function convertUnit(from: UnitType, to: UnitType, value: number) {
  if (from === to) {
    return value;
  }

  const fromDetails = getGroupAndUnitType(from);
  const toDetails = getGroupAndUnitType(to);

  if (fromDetails.unitGroupType !== toDetails.unitGroupType) {
    throw new Error(`Can't convert from ${from} to ${to}`);
  }

  return (value * fromDetails.unit.basic) / toDetails.unit.basic;
}
