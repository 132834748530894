import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";

export const deliveryLocationSchema = z.object({
  ...standardAttributes,
  title: z.string(),
  kitCode: z.string().nullable(),
  region: z.string(),
});
checkSchema(deliveryLocationSchema);
export type DeliveryLocation = z.infer<typeof deliveryLocationSchema>;
