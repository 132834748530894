import styled from "@emotion/styled";
import { primary, gutters, withAlpha } from "../styles";

export const Alert = styled.div`
  border: 1px solid ${withAlpha(primary, 0.3)};
  padding: ${gutters.md}px ${gutters.lg}px;
  background-color: ${withAlpha(primary, 0.1)};
  line-height: 1.4;
  > p:first-child {
    margin-top: 0;
  }
`;

export const AlertMargined = styled(Alert)`
  margin: ${gutters.md}px 0;
`;

export const AlertControls = styled.div`
  margin-top: ${gutters.sm}px;
  display: flex;
  align-items: center;
  button,
  a {
    margin-right: ${gutters.sm}px;
  }
`;
