import {
  templateBackPool,
  assembleAssetValues,
  TemplateEnvironment,
  TemplatePreset,
} from "shared";
import { useMemo } from "react";
import { templateRenderPool } from "./templates";
import { TemplateFull } from "./types";

export function assembleTemplate(
  templateEnvironment: TemplateEnvironment
): TemplateFull<any, any, any> {
  const { template, assets } = templateEnvironment;

  const back = templateBackPool[template.code];
  const component = templateRenderPool[template.code];

  return {
    ...back,
    assets: assembleAssetValues(back, assets),
    component,
  };
}

export function useTemplateVariables<Variables>(
  templateFull: TemplateFull<any, any, Variables>,
  templatePreset: TemplatePreset
) {
  return useMemo<Variables>(() => {
    const variables = templateFull.model.variablesDefaults;
    try {
      if (templatePreset.templateVariables) {
        const presetVariables = templateFull.model.variablesSchema.parse(
          JSON.parse(templatePreset.templateVariables)
        );
        return {
          ...variables,
          ...presetVariables,
        };
      }
    } catch (e) {}
    return variables;
  }, [
    templateFull.model.variablesDefaults,
    templateFull.model.variablesSchema,
    templatePreset.templateVariables,
  ]);
}
