import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  ChangeEvent,
} from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useField, Form } from "formik";
import { black, grey40 } from "../../styles";
import { StyledInput } from "./styles";
import { PlusIcon, MinusIcon } from "../../icons";
import { IntField } from "./int-field";

const containerStyles = css`
  display: flex;
  align-items: center;
  input {
    width: 50px;
    text-align: center;
  }
`;
const Container = styled.div(containerStyles);
const FormContainer = styled(Form)(containerStyles);
const IconContainer = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export interface Limits {
  min: number;
  max: number;
}

interface AmountInputProps {
  limits: Limits;
  value: number;
  onChange: (v: number) => void;
  className?: string;
}

export function AmountInput({
  limits,
  value,
  onChange,
  className,
}: AmountInputProps) {
  const [inputValue, setInputValue] = useState<string>(String(value));
  const inputValueRef = useRef<string>(String(value));
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(
    function updateInputValueWhenPropChanges() {
      if (String(value) !== inputValueRef.current) {
        inputValueRef.current = String(value);
        setInputValue(inputValueRef.current);
      }
    },
    [value]
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue: string = event.target.value;
      inputValueRef.current = inputValue;
      setInputValue(inputValue);
      const parsedInt = parseInt(inputValue, 10);
      if (String(parsedInt) === inputValue && !Number.isNaN(parsedInt)) {
        setIsError(false);
        onChange(parsedInt);
      } else {
        setIsError(true);
      }
    },
    []
  );

  return (
    <Container className={className}>
      <IconContainer onClick={() => value > limits.min && onChange(value - 1)}>
        <MinusIcon color={value <= limits.min ? grey40 : "inherit"} />
      </IconContainer>
      <StyledInput
        error={isError ? "Number required" : undefined}
        value={inputValue}
        onChange={handleInputChange}
      />
      <IconContainer onClick={() => value < limits.max && onChange(value + 1)}>
        <PlusIcon color={value >= limits.max ? grey40 : "inherit"} />
      </IconContainer>
    </Container>
  );
}

interface Props {
  name: string;
  limits: Limits;
  disabled?: boolean;
}
export function AmountField({ name, limits, disabled }: Props) {
  const [{ value }, , { setValue }] = useField<number>(name);

  return (
    <FormContainer>
      <IconContainer
        onClick={() => !disabled && value > limits.min && setValue(value - 1)}
      >
        <MinusIcon color={disabled || value <= limits.min ? grey40 : black} />
      </IconContainer>
      <IntField name={"amount"} disabled={disabled} />
      <IconContainer
        onClick={() => !disabled && value < limits.max && setValue(value + 1)}
      >
        <PlusIcon color={disabled || value >= limits.max ? grey40 : black} />
      </IconContainer>
    </FormContainer>
  );
}
