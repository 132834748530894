import React, { memo } from "react";
import styled from "@emotion/styled";
import { grey20, gutters, mqMax } from "client-lib/ui";

const Container = styled.div`
  margin: ${gutters.xxl * 2}px 0;
  ${mqMax.phone} {
    margin-top: 0;
  }
`;

const SCROLL_GAP = 30;
const Body = styled.div`
  position: relative;
  white-space: nowrap;
  margin-bottom: -${SCROLL_GAP}px;
  &:after {
    content: "";
    position: absolute;
    height: ${SCROLL_GAP}px;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${grey20};
  }
`;

const Scroller = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: ${SCROLL_GAP}px;
`;

const Img = styled.img`
  display: inline-block;
  vertical-align: top;
  height: 600px;
  width: 600px;
  margin-right: ${gutters.md}px;
  ${mqMax.phone} {
    max-width: 80vw;
    height: auto;
  }
`;

export const Gallery = memo(function Gallery() {
  return (
    <Container>
      <Body>
        <Scroller>
          <Img src="/img/gallery/gallery-2.jpg" />
          <Img src="/img/gallery/gallery-3.jpg" />
          <Img src="/img/gallery/gallery-4.jpg" />
          <Img src="/img/gallery/gallery-1.jpg" />
        </Scroller>
      </Body>
    </Container>
  );
});
