import React, { memo, useMemo } from "react";
import styled from "@emotion/styled";
import { TemplateEnvironment, TemplatePreset } from "shared";
import {
  assembleTemplate,
  useTemplateVariables,
} from "src/features/template-editor/service";
import { Scene } from "src/features/template-editor/scene";
import { SceneOptions } from "src/features/template-editor/types";
import {
  black,
  white,
  LogoIcon,
  gutters,
  APP_BAR_HEIGHT,
  mqMax,
  useEnv,
} from "client-lib";
import { ScreenSlide, ScreenSlideContainer } from "src/features/layout";

const Container = styled(ScreenSlide)`
  min-height: calc(100vh - ${APP_BAR_HEIGHT}px);
`;

const MobileScreenSlideFullContainer = styled(ScreenSlideContainer)`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
`;

const SceneContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const SceneOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const LogoContainer = styled.div``;

const Subheader = styled.h1`
  color: ${white};
  font-weight: 300;
  margin-top: ${gutters.sm}px;
  margin-top: 0.125em;
  line-height: 100%;
`;

const Info = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${mqMax.tablet} {
    display: block;
  }
`;

interface Props {
  templateEnvironment: TemplateEnvironment;
  templatePreset: TemplatePreset;
}

export const IntroSlide = memo(function SlideIntro({
  templateEnvironment,
  templatePreset,
}: Props) {
  const { isMobileAgent } = useEnv();
  const templateFull = useMemo(
    () => assembleTemplate(templateEnvironment),
    [templateEnvironment]
  );

  const configValues = useMemo(() => {
    if (templatePreset.templateConfig) {
      return JSON.parse(templatePreset.templateConfig);
    }
    return templateFull.model.configInitialValues(templateFull.assets);
  }, [templatePreset, templateFull]);

  const templateVariables = useTemplateVariables(templateFull, templatePreset);

  const sceneOptions = useMemo<SceneOptions>(
    () => ({
      showMeasurements: false,
      backgroundColor: black,
      disableZoom: !isMobileAgent,
      autoRotate: true,
      camera: {
        position: isMobileAgent ? [0, 900, 2000] : [0, 800, 700],
      },
    }),
    [isMobileAgent]
  );

  const SlideContainer = isMobileAgent
    ? MobileScreenSlideFullContainer
    : ScreenSlideContainer;

  return (
    <Container>
      <SlideContainer>
        <SceneContainer>
          <Scene options={sceneOptions}>
            <templateFull.component
              config={configValues}
              assets={templateFull.assets}
              variables={templateVariables}
              mode="model"
            />
          </Scene>
          {isMobileAgent && <SceneOverlay />}
        </SceneContainer>
        <Info>
          <LogoContainer>
            <LogoIcon width={isMobileAgent ? 300 : 465} />
            <Subheader
              style={{
                fontSize: isMobileAgent ? 22 : 34,
                marginBottom: isMobileAgent ? 80 : 0,
              }}
            >
              Сервис онлайн-производства
            </Subheader>
          </LogoContainer>
        </Info>
      </SlideContainer>
    </Container>
  );
});
