import { errorRed as errorColor, gutters, inputStyles } from "../../styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const styles = ({
  error,
  seamless,
}: {
  error?: string;
  seamless?: boolean;
  rows?: number;
}) => [
  ...inputStyles,
  seamless
    ? css`
        outline-color: transparent;
        padding-left: 0;
        padding-right: 0;
      `
    : null,
  error
    ? css`
        outline-color: ${errorColor};
      `
    : null,
];

export const StyledInput = styled.input(styles);
export const StyledTextarea = styled.textarea([
  styles,
  css`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    line-height: 1.2;
    padding: ${gutters.sm}px ${gutters.md}px;
  `,
]);
