import React, { Dispatch, memo, SetStateAction, useMemo } from "react";
import styled from "@emotion/styled";
import { TemplateConfigControlType, TemplateConfigControl } from "shared";
import { Scene } from "src/features/template-editor/scene";
import { useEnv, toFormikSchema, gutters, grey10, mqMax } from "client-lib";
import { TemplateFull, SceneOptions } from "src/features/template-editor/types";
import { Control } from "src/features/template-editor/controls";
import { Formik } from "formik";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const SceneContainer = styled.div`
  position: relative;
  flex: 1 1 100%;
`;

const SceneOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1 1px;
  padding-right: ${gutters.lg}px;
  ${mqMax.phone} {
    display: block;
  }
`;

const ControlWrapper = styled.div`
  width: 33%;
  ${mqMax.phone} {
    width: auto;
  }
`;

const CONTROLS: TemplateConfigControl[] = [
  {
    type: TemplateConfigControlType.INT_SLIDER,
    name: "length",
    label: "Длина, мм",
    min: 250,
    max: 3000,
    options: {
      hideInput: true,
      showValue: true,
    },
  },
  {
    type: TemplateConfigControlType.INT_SLIDER,
    name: "width",
    label: "Ширина, мм",
    min: 200,
    max: 1200,
    options: {
      hideInput: true,
      showValue: true,
    },
  },
  {
    type: TemplateConfigControlType.INT_SLIDER,
    name: "height",
    label: "Высота, мм",
    min: 200,
    max: 1200,
    options: {
      hideInput: true,
      showValue: true,
    },
  },
];

interface Props {
  configValues: any;
  variables: any;
  templateFull: TemplateFull<any, any, any>;
  setConfigValues: Dispatch<SetStateAction<any>>;
  handleAttributeChange: (name: string, value: any) => void;
}

export const SizeExample = memo(function SizeExample({
  configValues,
  variables,
  templateFull,
  setConfigValues,
  handleAttributeChange,
}: Props) {
  const { isMobileAgent } = useEnv();

  const validationSchema = useMemo(
    () => toFormikSchema(templateFull.model.configSchema),
    [templateFull.model.configSchema]
  );

  const sceneOptions = useMemo<SceneOptions>(
    () => ({
      showMeasurements: false,
      disableZoom: !isMobileAgent,
      autoRotate: true,
      camera: {
        position: [0, 800, 900],
      },
      backgroundColor: grey10,
    }),
    [isMobileAgent]
  );

  return (
    <Container>
      <Formik
        initialValues={configValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={setConfigValues}
      >
        <Controls>
          <ControlWrapper>
            <Control
              def={CONTROLS[0]}
              onAttributeChange={handleAttributeChange}
            />
          </ControlWrapper>
          <ControlWrapper>
            <Control
              def={CONTROLS[1]}
              onAttributeChange={handleAttributeChange}
            />
          </ControlWrapper>
          <ControlWrapper>
            <Control
              def={CONTROLS[2]}
              onAttributeChange={handleAttributeChange}
            />
          </ControlWrapper>
        </Controls>
      </Formik>
      <SceneContainer>
        <Scene options={sceneOptions}>
          <templateFull.component
            config={configValues}
            assets={templateFull.assets}
            variables={variables}
            mode="model"
          />
        </Scene>
        {isMobileAgent && <SceneOverlay />}
      </SceneContainer>
    </Container>
  );
});
