import React, { useState, useCallback, ChangeEvent, ReactNode } from "react";
import styled from "@emotion/styled";
import { InputProps } from "./formik-field-wrapper";
import { Label } from "../label";
import { FieldError } from "./field-error";
import {
  withAlpha,
  tint,
  trueBlack,
  black,
  mutedGrey,
  primary,
} from "../../styles";
import { useField } from "formik";

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const LabelAndStatus = styled.div`
  flex: 1 1 100%;
  padding-left: 0.5em;
`;

const StyledLabel = styled(Label)`
  cursor: pointer;
`;

interface StyleProps {
  checkedColor?: string;
  size?: number;
}

export const Checkbox = styled.input`
  -webkit-appearance: none;
  border: 1px solid ${withAlpha(trueBlack, 0.1)};
  border-radius: 4px;
  background-color: ${tint(black, 0.015)};
  outline: none;
  transition: opacity 80ms ease-in-out;
  transition: box-shadow 150ms ease-in-out;
  cursor: pointer;
  margin: 2px 0 0 0;

  &:active {
    opacity: 0.5;
  }

  &:checked {
    background-image: url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNMS41IDMuNUw1IDdMMTEgMSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4NCjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
  }

  &:focus {
    box-shadow: 0 0 3px ${tint(mutedGrey, 0.4)};
  }

  ${({ checkedColor = primary, size = 16 }: StyleProps): string => {
    return `
    height: ${size}px;
    width: ${size}px;
    flex: 0 0 ${size}px;

    &:checked:focus {
      box-shadow: 0px 0px 3px ${tint(checkedColor, 0.8)};
    }
    &:checked {
      border: 1px solid ${checkedColor};
      background-color: ${checkedColor};
    }
  `;
  }}
`;

export function CheckboxWithLabel({
  onChange,
  value,
  error,
  label,
  disabled,
  className,
}: Omit<InputProps, "onChange"> & {
  onChange: (value: boolean) => void;
  value: boolean;
  label?: string | ReactNode;
  disabled?: boolean;
  className?: string;
}) {
  const [id] = useState<string>(`checkbox-${Math.random()}`);
  return (
    <Root className={className}>
      <Checkbox
        id={id}
        type={"checkbox"}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.checked);
        }}
        checked={value}
        disabled={disabled}
      />
      <LabelAndStatus>
        {label && (
          <StyledLabel isError={false} htmlFor={id}>
            {label}
          </StyledLabel>
        )}
        {error && <FieldError>{error}</FieldError>}
      </LabelAndStatus>
    </Root>
  );
}

export function CheckboxField({
  name,
  label,
  disabled,
  onChange,
  className,
}: {
  name: string;
  label?: string;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
}) {
  const [{ ...field }, { error }, { setValue }] = useField<boolean>(name);

  const handleChange = useCallback(
    (v: boolean) => {
      setValue(v);
      onChange?.(v);
    },
    [onChange, setValue]
  );

  return (
    <CheckboxWithLabel
      onChange={handleChange}
      value={field.value}
      label={label}
      error={error}
      disabled={disabled}
      className={className}
    />
  );
}
