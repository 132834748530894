import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import { useMutation } from "@apollo/client";
import { OrderLineItemInput, TemplateShotInput } from "shared";
import { Button, ButtonProps, useBoolean } from "client-lib/ui";
import { useTemplate } from "src/features/templates/template-context";
import {
  CreateTemplateShot,
  CreateTemplateShotData,
  CreateTemplateShotVars,
  UpdateTemplateShotConfig,
  UpdateTemplateShotConfigData,
  UpdateTemplateShotConfigVars,
} from "src/features/templates/template-layout/queries";
import { TemplateShotModal } from "src/features/templates/template-layout/template-shot-form";
import { useEnvironment } from "src/features/environment";
import { useProduction } from "src/features/order";
import { AddToCart, AddToCartData, AddToCartVars } from "./queries";

export function CartButton(props: ButtonProps) {
  const [
    isTemplateShotOpen,
    { set: openTemplateShot, reset: closeTemplateShot },
  ] = useBoolean(false);
  const {
    templatePreset,
    templateShotActual,
    templateConfig,
    onSave,
    isModified,
  } = useTemplate();
  const { refreshEnvironment } = useEnvironment();
  const { deliveryLocationId } = useProduction();
  const [orderLineItemId, setOrderLineItemId] = useState<string>(v4());
  const history = useHistory();

  const [addToCart, { loading: isAdding }] = useMutation<
    AddToCartData,
    AddToCartVars
  >(AddToCart, {
    onCompleted: refreshEnvironment,
  });

  const [updateShotConfig, { loading: isUpdating }] = useMutation<
    UpdateTemplateShotConfigData,
    UpdateTemplateShotConfigVars
  >(UpdateTemplateShotConfig);

  const [createShot, { loading: isCreatingShot }] = useMutation<
    CreateTemplateShotData,
    CreateTemplateShotVars
  >(CreateTemplateShot);

  const handleAddToCart = useCallback(
    async (versionId?: string) => {
      const templateShotVersionId = versionId || templateShotActual?.versionId;
      if (!templateShotVersionId) {
        return;
      }
      const orderLineInput: OrderLineItemInput = {
        id: orderLineItemId,
        templateShotVersionId,
        amount: 1,
      };

      await addToCart({
        variables: {
          items: [orderLineInput],
          deliveryLocationId,
        },
      });
      setOrderLineItemId(v4());
      history.push(`/account/cart`);
    },
    [
      templateShotActual,
      orderLineItemId,
      templateShotActual,
      deliveryLocationId,
    ]
  );

  const handleCreateShot = useCallback(
    async (input: TemplateShotInput) => {
      const { data } = await createShot({
        variables: {
          input,
        },
      });
      closeTemplateShot();

      handleAddToCart(data!.CreateTemplateShot.versionId);
      history.push(`/account/models/${data!.CreateTemplateShot.id}`);
    },
    [createShot, handleAddToCart, closeTemplateShot]
  );

  const handleClick = useCallback(async () => {
    if (!templateShotActual) {
      openTemplateShot();
      return;
    }
    let versionId: undefined | string;
    if (isModified) {
      if (
        !window.confirm(
          `Модель была изменена, хотим сохранить ее перед добавлением в корзину.
Продолжить?`
        )
      ) {
        return;
      }
      const { data } = await updateShotConfig({
        variables: {
          templateShotId: templateShotActual.id,
          templateConfig: JSON.stringify(templateConfig),
        },
      });
      versionId = data!.UpdateTemplateShotConfig.versionId;
      onSave();
    }

    handleAddToCart(versionId);
  }, [
    templateShotActual,
    openTemplateShot,
    isModified,
    updateShotConfig,
    templateConfig,
    onSave,
    handleAddToCart,
  ]);

  return (
    <React.Fragment>
      <Button
        {...props}
        onClick={handleClick}
        disabled={isUpdating || isCreatingShot || isAdding}
      >
        {props.children || "Заказать"}
      </Button>
      {isTemplateShotOpen && (
        <TemplateShotModal
          onCloseRequest={closeTemplateShot}
          initialValues={{
            id: v4(),
            title: "",
            templatePresetId: templatePreset.id,
            templateConfig: JSON.stringify(templateConfig),
          }}
          titleSuggestion="ABC"
          onSubmit={handleCreateShot}
        />
      )}
    </React.Fragment>
  );
}
