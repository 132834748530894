import gql from "graphql-tag";
import { Order, OrderLineItem, OrderDeliveryPatch } from "shared";
import {
  ORDER_ENVIRONMENT_FULL_FRAGMENT,
  ORDER_FULL_FRAGMENT,
} from "client-lib/fragments";

export const UpdateOrderLineItemAmount = gql`
  mutation UpdateOrderLineItemAmount($orderLineItemId: String!, $amount: Int!) {
    UpdateOrderLineItemAmount(
      orderLineItemId: $orderLineItemId
      amount: $amount
    ) {
      ...OrderEnvironmentFullFragment
    }
  }
  ${ORDER_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface UpdateOrderLineItemAmountVars {
  orderLineItemId: string;
  amount: number;
}

export type UpdateOrderLineItemAmountData = {
  UpdateOrderLineItemAmount: {
    order: Order;
    orderLineItems: OrderLineItem[];
  };
};
export const UpdateOrderDelivery = gql`
  mutation UpdateOrderDelivery(
    $orderId: String!
    $deliveryPatch: OrderDeliveryPatch!
  ) {
    UpdateOrderDelivery(orderId: $orderId, deliveryPatch: $deliveryPatch) {
      ...OrderFullFragment
    }
  }
  ${ORDER_FULL_FRAGMENT}
`;

export interface UpdateOrderDeliveryVars {
  orderId: string;
  deliveryPatch: OrderDeliveryPatch;
}

export type UpdateOrderDeliveryData = {
  UpdateOrderDelivery: Order;
};

export const DeleteOrderLineItem = gql`
  mutation DeleteOrderLineItem($orderLineItemId: String!) {
    DeleteOrderLineItem(orderLineItemId: $orderLineItemId) {
      ...OrderEnvironmentFullFragment
    }
  }
  ${ORDER_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface DeleteOrderLineItemVars {
  orderLineItemId: string;
}

export type DeleteOrderLineItemData = {
  DeleteOrderLineItem: {
    order: Order;
    orderLineItems: OrderLineItem[];
  };
};
