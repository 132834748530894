import React from "react";
import { getDimensionsMm, MaterialExpanded } from "shared";
import { Box } from "../../objects";
import { useShelf } from "./shelf-context";

interface Props {
  coverage: MaterialExpanded | null;
  supportMaterial: MaterialExpanded;
  frameMaterial: MaterialExpanded;
  topY: number;
}

export function SupportItem({
  topY,
  coverage,
  supportMaterial,
  frameMaterial,
}: Props) {
  const { config } = useShelf();
  const framePipe = getDimensionsMm(frameMaterial);
  const supportPipe = getDimensionsMm(supportMaterial);
  if (!supportPipe.d1 || !supportPipe.d2 || !framePipe.d1 || !framePipe.d2) {
    return null;
  }
  const surface = coverage?.texture1 || supportMaterial.texture1;
  if (!surface) {
    return null;
  }

  return (
    <Box
      xSize={supportPipe.d1}
      zSize={config.frameWidth - framePipe.d2 * 2}
      ySize={supportPipe.d2}
      defaultSurface={surface}
      offset={[0, topY - supportPipe.d2 / 2, 0]}
    />
  );
}
