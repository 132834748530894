export const PROFILE_W = 40;
export const PROFILE_H = 20;

export const MAX_LENGTH = 3000;
export const MIN_LENGTH = 300;
export const MAX_WIDTH = 600;
export const MIN_WIDTH = 200;
export const MAX_HEIGHT = 1200;
export const MAX_RACK_HEIGHT = 3000;
export const MIN_HEIGHT = 200;

export const MAX_THICKNESS = 40;
export const MIN_THICKNESS = 20;

export const MIN_ITEMS_AMOUNT = 1;
export const MAX_ITEMS_AMOUNT = 10;
export const MIN_ITEM_WIDTH = 120;
export const MAX_ITEM_WIDTH = 500;
export const MIN_ITEM_MARGIN = 50;
export const MAX_ITEM_MARGIN = 500;

export const MIN_FRAME_GAP = 200;
export const MAX_FRAME_GAP = MAX_LENGTH - 2 * PROFILE_W;

export const MIN_FRAME_WIDTH = MIN_WIDTH;
export const MAX_FRAME_WIDTH = MAX_WIDTH;

export const MIN_FRAME_HEIGHT = MIN_HEIGHT;
export const MAX_FRAME_HEIGHT = MAX_HEIGHT;
