import gql from "graphql-tag";
import { TemplatePresetEnvironment } from "shared";
import { TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT } from "client-lib/fragments";

export const LoftTableQuery = gql`
  query LoftTableQuery {
    TemplatePresetEnvironment(templatePresetId: "loft-table") {
      ...TemplatePresetEnvironmentFullFragment
    }
  }
  ${TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface LoftTableVars {}

export type LoftTableData = {
  TemplatePresetEnvironment: TemplatePresetEnvironment;
};
