import React, { memo, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  white,
  grey10,
  primary,
  lighten,
  gutters,
  useBoolean,
  borderRadius,
  transitionBase,
  Button,
  TelegramIcon,
  mqMax,
} from "client-lib";
import {
  Slide,
  SlideContainer,
  Header,
  ButtonArrow,
  Typo140,
  // typo450,
} from "src/features/layout";
import { ContactsModal } from "src/features/contacts";
import { useEnvironment } from "src/features/environment";

const Body = styled.div`
  p {
    width: 50%;
  }
  ${mqMax.phone} {
    p {
      width: auto;
      text-align: center;
    }
  }
`;

const SuperHeader = styled(Header)([
  // typo450,
  css`
    ${mqMax.phone} {
      font-size: 32px;
    }
  `,
]);

const Items = styled.div`
  display: flex;
  margin: ${gutters.xxl}px 0;
  ${mqMax.phone} {
    display: block;
  }
`;

const Item = styled(Link)<{ base: string }>`
  flex: ${({ base }) => base} 1 1;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${gutters.xl}px;
  text-decoration: none;
  background: ${white};
  border-radius: ${borderRadius.lg}px;
  margin-left: ${gutters.md}px;
  transition: background-color ${transitionBase}, color ${transitionBase};
  &:first-of-type {
    margin-left: 0;
  }
  &:hover {
    text-decoration: none;
    background: ${primary};
    color: ${white};
    span {
      color: ${white};
      border-color: ${white};

      transition: color ${transitionBase}, border-color ${transitionBase};
    }
  }
  ${mqMax.phone} {
    justify-content: flex-start;
    margin-left: 0;
    margin-bottom: ${gutters.sm}px;
  }
`;

const Number = styled.span`
  flex: 44px 0 0;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${primary};
  border-radius: 22px;
  color: ${primary};
  margin-right: ${gutters.md}px;

  ${mqMax.phone} {
    width: 32px;
    height: 32px;
    flex: 32px 0 0;
  }
`;

const ActionButton = styled(Button)`
  ${mqMax.phone} {
    text-align: left;
    padding: ${gutters.lg}px ${gutters.xl}px;
    height: auto;
    line-height: 1;
  }
`;

const ContactsPart = styled(Typo140)`
  display: flex;
  align-items: flex-end;
  margin-top: ${gutters.xxl * 2}px;
  h2 {
    margin-bottom: ${gutters.xxl}px;
    font-size: 45px;
  }
  ${mqMax.phone} {
    display: block;
    text-align: center;
  }
`;

const ContactsLeft = styled.div`
  flex: 50% 1 1;
  ${mqMax.phone} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const ContactsRight = styled(ContactsLeft)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${mqMax.phone} {
    align-items: center;
  }
`;

const ContactLine = styled.div`
  margin-bottom: ${gutters.md}px;
`;

const TelegramLink = styled.a`
  width: 140px;
  height: 76px;
  margin-top: ${gutters.sm}px;
  text-decoration: none;
  border: 2px solid ${primary};
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${primary};
  transition: background-color ${transitionBase};
  &:hover {
    background-color: ${lighten(primary, 0.9)};
  }
  ${mqMax.phone} {
    width: 100px;
    height: 56px;
  }
`;

export const MissionSlide = memo(function MissionSlide() {
  const [isContactsOpen, { set: openContacts, reset: closeContacts }] =
    useBoolean();
  const { environment } = useEnvironment();

  const handleOpenContacts = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      openContacts();
    },
    [openContacts]
  );

  return (
    <Slide style={{ backgroundColor: grey10 }}>
      <SlideContainer>
        <SuperHeader>
          Метафабрика &mdash;
          <br />
          это производство, которое вы
          <br />
          контролируете!
        </SuperHeader>
        <Body>
          <p>
            С Метафабрикой вы можете быть спокойны и уверены на каждом этапе. Мы
            произведем точно то, что вы заказали. Вы заплатите ровно столько,
            сколько рассчитывали заплатить вначале.
          </p>
          <p>
            Мы будем держать вас в курсе относительно статуса заказа в любой
            момент. А если у вас остались вопросы,{" "}
            <a href="https://t.me/alevkon" target="_blank" rel="noreferrer">
              свяжитесь&nbsp;с&nbsp;нами&nbsp;в&nbsp;Телеграме
            </a>{" "}
            или{" "}
            <a href="/" onClick={handleOpenContacts}>
              напишите&nbsp;в&nbsp;форму обратной&nbsp;связи
            </a>
            .
          </p>
        </Body>
        {isContactsOpen && <ContactsModal onCloseRequest={closeContacts} />}
        <Items>
          <Item base="23%" to="/templates">
            <Number>1</Number> Выбираете шаблон
          </Item>
          <Item base="44%" to="/templates">
            <Number>2</Number> Настраиваете в онлайн конструкторе под себя
          </Item>
          <Item base="33%" to="/templates">
            <Number>3</Number> Отправляете на производство
          </Item>
        </Items>
        <Link to="/templates">
          <ActionButton size="XL">
            Перейти в&nbsp;конструктор&nbsp;
            <ButtonArrow />
          </ActionButton>
        </Link>
        <ContactsPart>
          <ContactsLeft>
            <Header>Контакты</Header>
            {environment && (
              <ContactLine>{environment.companyRequisites.phone}</ContactLine>
            )}
            <ContactLine>
              <a href="/" onClick={handleOpenContacts}>
                Форма обратной связи
              </a>
            </ContactLine>
          </ContactsLeft>
          <ContactsRight>
            Или напишите нам в Телеграм
            <div>
              <TelegramLink href="https://t.me/alevkon">
                <TelegramIcon color={white} size={36} />
              </TelegramLink>
            </div>
          </ContactsRight>
        </ContactsPart>
      </SlideContainer>
    </Slide>
  );
});
