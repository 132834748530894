import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";

export const textureSchema = z.object({
  ...standardAttributes,
  title: z.string(),
  path: z.string(),
  mmWidth: z.number().int().positive(),
  mmHeight: z.number().int().positive(),
  metalness: z.number().min(0).max(1).nullable(),
  roughness: z.number().min(0).max(1).nullable(),
});
checkSchema(textureSchema);
export type Texture = z.infer<typeof textureSchema>;

export const textureInputSchema = textureSchema.pick({
  id: true,
  title: true,
  path: true,
  mmWidth: true,
  mmHeight: true,
  metalness: true,
  roughness: true,
});
export type TextureInput = z.infer<typeof textureInputSchema>;

export const texturePatchSchema = textureInputSchema.omit({
  id: true,
});
export type TexturePatch = z.infer<typeof texturePatchSchema>;

export const surfaceSchema = textureSchema.pick({
  path: true,
  mmWidth: true,
  mmHeight: true,
  metalness: true,
  roughness: true,
});
export type Surface = z.infer<typeof surfaceSchema>;
