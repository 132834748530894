import React from "react";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";
import { black, Spinner } from "client-lib";
import { Layout } from "src/features/layout";
import { MainPageQuery, MainPageData, MainPageVars } from "./queries";
import { IntroSlide } from "./intro-slide";
import { HowSlide } from "./how-slide";
import { FeaturesSlide } from "./features-slide";
import { HonestlySlide } from "./honestly-slide";
import { TransparencySlide } from "./transparency-slide";
import { DeliverySlide } from "./delivery-slide";
import { WhoSlide } from "./who-slide";
import { ReviewsSlide } from "./reviews-slide";
import { TechMaterialsSlide } from "./tech-materials-slide";
import { WoodSlide } from "./wood-slide";
import { WoodOilingSlide } from "./wood-oiling-slide";
import { FrameSlide } from "./frame-slide";
import { MissionSlide } from "./mission-slide";

const PendingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: flex;
  align-items: center;
  justify-content: center;
  background: black;
`;

export function MainPage() {
  const { data } = useQuery<MainPageData, MainPageVars>(MainPageQuery);

  if (!data) {
    return (
      <Layout seamless bg={black}>
        <PendingContainer>
          <Spinner size="large" />
        </PendingContainer>
      </Layout>
    );
  }

  return (
    <Layout seamless bg={black}>
      <IntroSlide
        templateEnvironment={
          data.TemplatePresetEnvironments[0].templateEnvironment
        }
        templatePreset={data.TemplatePresetEnvironments[0].templatePreset}
      />
      <HowSlide />
      <FeaturesSlide
        templateEnvironment={
          data.TemplatePresetEnvironments[0].templateEnvironment
        }
        templatePreset={data.TemplatePresetEnvironments[0].templatePreset}
      />
      <HonestlySlide />
      <TransparencySlide />
      <DeliverySlide
        templatePresetEnvironments={data.TemplatePresetEnvironments}
      />
      <WhoSlide />
      <ReviewsSlide />
      <TechMaterialsSlide />
      <WoodSlide />
      <WoodOilingSlide />
      <FrameSlide />
      <MissionSlide />
    </Layout>
  );
}
