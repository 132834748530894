import React from "react";
import { Link } from "react-router-dom";
import { useMyEnvironment } from "src/features/environment";
import styled from "@emotion/styled";
import { CartIcon, secondary, white, gutters } from "client-lib";

const BUTTON_HEIGHT = 36;
const Container = styled(Link)`
  height: ${BUTTON_HEIGHT}px;
  width: ${BUTTON_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -1px;
`;

const BADGE_HEIGHT = 18;

const Badge = styled.div`
  position: absolute;
  line-height: ${BADGE_HEIGHT}px;
  min-width: ${BADGE_HEIGHT}px;
  right: -${gutters.xxs}px;
  top: -${gutters.xxs}px;
  text-align: center;
  color: ${white};
  background-color: ${secondary};
  border-radius: ${BADGE_HEIGHT}px;
  font-size: 12px;
`;

export function Cart() {
  const myEnvironment = useMyEnvironment();

  return (
    <Container to="/account/cart">
      <CartIcon color={white} />
      {(myEnvironment?.cartItemsAmount || 0) > 0 && (
        <Badge>{myEnvironment!.cartItemsAmount}</Badge>
      )}
    </Container>
  );
}
