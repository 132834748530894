import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";
import { materialExpandedSchema } from "./material";

export enum TemplateAssetType {
  MATERIAL = "MATERIAL",
  PROCESS = "PROCESS",
}
export const templateAssetTypeSchema = z.nativeEnum(TemplateAssetType);

export const templateAssetSchema = z.object({
  ...standardAttributes,
  ordering: z.number().int(),
  templateId: z.string().uuid().nullable(),
  type: templateAssetTypeSchema,
  name: z.string(),
  isArray: z.boolean(),
  materialId: z.string().uuid().nullable(),
});
checkSchema(templateAssetSchema);
export type TemplateAsset = z.infer<typeof templateAssetSchema>;

export const templateAssetInputSchema = templateAssetSchema.pick({
  id: true,
  ordering: true,
  templateId: true,
  type: true,
  name: true,
  isArray: true,
  materialId: true,
});
export type TemplateAssetInput = z.infer<typeof templateAssetInputSchema>;

export const templateAssetPatchSchema = templateAssetInputSchema.omit({
  id: true,
  templateId: true,
});
export type TemplateAssetPatch = z.infer<typeof templateAssetPatchSchema>;

export const templateAssetExpandedSchema = templateAssetSchema.extend({
  ...standardAttributes,
  materialExpanded: materialExpandedSchema.nullable(),
});
export type TemplateAssetExpanded = z.infer<typeof templateAssetExpandedSchema>;
