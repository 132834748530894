import { z } from "zod";
import { checkSchema, standardAttributes } from "../services";
import { templateAssetExpandedSchema } from "./template-asset";

export enum TemplateCode {
  TABLE_LOFT_1 = "TABLE_LOFT_1",
  SHELF_LOFT_1 = "SHELF_LOFT_1",
  SHELF_MATRESHKA = "SHELF_MATRESHKA",
}
export const templateCodeSchema = z.nativeEnum(TemplateCode);

export const templateSchema = z.object({
  ...standardAttributes,
  ordering: z.number().int(),
  code: templateCodeSchema,
  title: z.string(),
  image: z.string().nullable(),
});
checkSchema(templateSchema);
export type Template = z.infer<typeof templateSchema>;

export const templateInputSchema = templateSchema.pick({
  id: true,
  ordering: true,
  code: true,
  title: true,
  image: true,
});
export type TemplateInput = z.infer<typeof templateInputSchema>;

export const templatePatchSchema = templateSchema.pick({
  ordering: true,
  title: true,
  image: true,
});
export type TemplatePatch = z.infer<typeof templatePatchSchema>;

export const templateEnvironmentParentSchema = z.object({
  id: z.string().uuid(),
  template: templateSchema,
});
export type TemplateEnvironmentParent = z.infer<
  typeof templateEnvironmentParentSchema
>;

export const templateEnvironmentSchema = templateEnvironmentParentSchema.extend(
  {
    assets: z.array(templateAssetExpandedSchema),
  }
);
export type TemplateEnvironment = z.infer<typeof templateEnvironmentSchema>;
