import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { borderRadius, grey20, grey90, gutters } from "../styles";

const iconButtonStyle = css`
  display: flex;
  border-radius: ${borderRadius.md}px;
  cursor: pointer;
  &:hover {
    background-color: ${grey20};
  }
`;

const withMargin = css`
  margin-left: ${gutters.xxs}px;
`;

const withDarkTheme = css`
  &:hover {
    background-color: ${grey90};
  }
`;

export const IconLink = styled(Link)(iconButtonStyle);
export const IconButton = styled.div<{ margin?: boolean; dark?: boolean }>(
  ({ margin, dark }) => [
    iconButtonStyle,
    margin ? withMargin : null,
    dark ? withDarkTheme : null,
  ]
);
