import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Invoice, Order, OrderStatus } from "shared";
import { Button, gutters } from "client-lib";
import { RefreshPriceButton } from "./refresh-price-button";
import { CancelOrderButton } from "./cancel-order-button";

const Controls = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${gutters.md}px;
  }
`;

interface Props {
  order: Order;
  invoice: Invoice | null;
  priceActualTill: Date | null;
}

export function ActionBlock({ invoice, priceActualTill, order }: Props) {
  switch (order.status) {
    case OrderStatus.AWAITING_PAYMENT: {
      const now = new Date();
      if (!priceActualTill || priceActualTill < now) {
        return (
          <React.Fragment>
            <h2>Оплата</h2>
            <p>
              Срок актуальности указанных цен истек, нужно пересчитать цены.
            </p>
            <Controls>
              <RefreshPriceButton orderId={order.id} />
              <CancelOrderButton orderId={order.id} />
            </Controls>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          <h2>Оплата</h2>
          <p>
            Оплатить заказ можно по банковским реквизитам, указанным в счете.
          </p>
          <Controls>
            {invoice && (
              <a
                target="_blank"
                href={`${process.env.REACT_APP_API_HOST || ""}/rest/invoice/${
                  invoice.id
                }`}
                rel="noreferrer"
              >
                <Button>Открыть счет</Button>
              </a>
            )}
            <CancelOrderButton orderId={order.id} />
          </Controls>
        </React.Fragment>
      );
    }
    case OrderStatus.CART: {
      return (
        <React.Fragment>
          <h2>Корзина</h2>
          <p>
            Заказ еще не оформлен и находится в корзине. Пожалуйста, перейдите в
            корзину для оформления.
          </p>
          <Controls>
            <Link to="/account/cart">
              <Button>Перейти в корзину</Button>
            </Link>
          </Controls>
        </React.Fragment>
      );
    }
    case OrderStatus.FACTORY_CANCELED:
    case OrderStatus.CUSTOMER_CANCELED: {
      return (
        <React.Fragment>
          <h2>Статус заказа</h2>
          <p>Заказ был отменен</p>
        </React.Fragment>
      );
    }
    case OrderStatus.PRODUCTION: {
      return (
        <React.Fragment>
          <h2>Статус заказа</h2>
          <p>Заказ был успешно оплачен и находится на производстве.</p>
        </React.Fragment>
      );
    }
    case OrderStatus.DELIVERY: {
      return (
        <React.Fragment>
          <h2>Статус заказа</h2>
          <p>Заказ был успешно произведен и находится на пути к вам!</p>
        </React.Fragment>
      );
    }
    case OrderStatus.COMPLETED: {
      return (
        <React.Fragment>
          <h2>Статус заказа</h2>
          <p>Заказ был успешно выполнен и доставлен.</p>
        </React.Fragment>
      );
    }
  }
}
