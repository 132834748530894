import React from "react";
import { Modal } from "client-lib/ui";
import { Contacts } from "./contacts";

interface Props {
  onCloseRequest: VoidFunction;
}

export function ContactsModal({ onCloseRequest }: Props) {
  return (
    <Modal
      onCloseRequest={onCloseRequest}
      title="Напишите нам!"
      width="75vw"
      maxWidth={600}
    >
      <Contacts />
    </Modal>
  );
}
