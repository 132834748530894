import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import {
  Breadcrumbs,
  Spinner,
  Table,
  THead,
  TBody,
  TR,
  TD,
  TH,
  FormatDate,
  gutters,
  disabledGrey,
} from "client-lib/ui";
import { Layout } from "src/features/layout";
import { ORDER_STATUS_TITLES } from "shared";
import { AuthControl } from "src/features/auth";
import { Orders, OrdersData, OrdersVars } from "./queries";

const EmptyCell = styled(TD)`
  padding: ${gutters.md}px;
  color: ${disabledGrey};
  text-align: center;
`;

export function OrdersPage() {
  const { data } = useQuery<OrdersData, OrdersVars>(Orders);

  return (
    <Layout>
      <Breadcrumbs
        items={[{ title: "Кабинет", url: "/account" }, { title: "Заказы" }]}
      />
      <h1>Ваши заказы</h1>
      <AuthControl />
      <Table>
        <THead>
          <TR>
            <TH>#</TH>
            <TH width="50%">Заказ</TH>
            <TH>Статус</TH>
          </TR>
        </THead>
        <TBody>
          {data ? (
            data.Orders.length > 0 ? (
              data.Orders.map((order) => {
                const url = `/account/orders/${order.id}`;
                return (
                  <TR key={order.id}>
                    <TD isLink>
                      <Link to={url}>#{order.teamNumber}</Link>
                    </TD>
                    <TD isLink>
                      <Link to={url}>
                        Заказ #{order.teamNumber} от{" "}
                        <FormatDate date={order.fixedAt} />
                      </Link>
                    </TD>
                    <TD isLink>
                      <Link to={url}>{ORDER_STATUS_TITLES[order.status]}</Link>
                    </TD>
                  </TR>
                );
              })
            ) : (
              <TR>
                <EmptyCell colSpan={3}>
                  Пока не создано ни одного заказа
                </EmptyCell>
              </TR>
            )
          ) : (
            <TR>
              <EmptyCell colSpan={3}>
                <Spinner />
              </EmptyCell>
            </TR>
          )}
        </TBody>
      </Table>
    </Layout>
  );
}
