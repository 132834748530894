import gql from "graphql-tag";
import { DecompositionPackage, DeliveryLocation } from "shared";
import { DELIVERY_LOCATION_FULL_FRAGMENT } from "client-lib/fragments";

export const DeliveryLocations = gql`
  query DeliveryLocations($regionCode: String!) {
    DeliveryLocations(regionCode: $regionCode) {
      ...DeliveryLocationFullFragment
    }
  }
  ${DELIVERY_LOCATION_FULL_FRAGMENT}
`;

export interface DeliveryLocationsVars {
  regionCode: string;
}

export type DeliveryLocationsData = {
  DeliveryLocations: DeliveryLocation[];
};

export const DeliveryEstimate = gql`
  query DeliveryEstimate(
    $package: DecompositionPackageInput!
    $amount: Int!
    $deliveryLocationId: String
  ) {
    DeliveryEstimate(
      package: $package
      amount: $amount
      deliveryLocationId: $deliveryLocationId
    ) {
      tkTerminalKopecks
      tkLocationKopecks
    }
  }
`;

export interface DeliveryEstimateVars {
  package: DecompositionPackage;
  amount: number;
  deliveryLocationId: string | null;
}

export type DeliveryEstimateData = {
  DeliveryEstimate: {
    tkTerminalKopecks: number;
    tkLocationKopecks: number | null;
  };
};
