import { z } from "zod";
import { EMAIL_REGEXP } from "./email";

export const PHONE_REGEXP = /^9[0-9]{9}$/;

export const phoneSchema = z
  .string()
  .transform((val: string) =>
    val
      .trim()
      .replace(/[-\s\t()]/g, "")
      .replace(/^((\+?7)|8)/, "")
  )
  .refine((val: string) => {
    return PHONE_REGEXP.test(val);
  });

export const emailOrPhoneSchema = z
  .string()
  .transform((val: string) => {
    const trimmed = val.trim();
    if (EMAIL_REGEXP.test(val)) {
      return trimmed;
    }
    return trimmed.replace(/[-\s\t()]/g, "").replace(/^((\+?7)|8)/, "");
  })
  .refine((val: string) => {
    return EMAIL_REGEXP.test(val) || PHONE_REGEXP.test(val);
  });
