import React, { MutableRefObject, useRef } from "react";
import { ApolloClient, ApolloProvider as Provider } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import { AuthRef } from "src/features/auth";
import { useToaster } from "src/features/toaster";
import { createClient } from "./create-client";

interface Props {
  children: JSX.Element | JSX.Element[];
  authRef: MutableRefObject<AuthRef | null>;
}

export function ApolloProvider({ children, authRef }: Props) {
  const { pushErrorToast } = useToaster();
  const location = useLocation();
  const history = useHistory();
  const client = useRef<ApolloClient<Record<string, unknown>>>(
    createClient(history, location, authRef, pushErrorToast)
  );

  return <Provider client={client.current}>{children}</Provider>;
}
