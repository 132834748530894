import React from "react";
import { PricingLimit, PricingLimitValues } from "shared";
import { Switcher } from "client-lib/ui";

const LIMIT_SWITCHER_ITEMS = Object.values(PricingLimit).map((limit) => ({
  id: limit,
  text: String(PricingLimitValues[limit]),
}));

interface Props {
  activeLimit: PricingLimit;
  onChange: (v: PricingLimit) => void;
}

export function LimitSelector({ activeLimit, onChange }: Props) {
  return (
    <Switcher
      width={30}
      items={LIMIT_SWITCHER_ITEMS}
      activeId={activeLimit}
      onChange={onChange}
    />
  );
}
