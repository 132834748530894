import { z } from "zod";
import { decompositionSectionSchema } from "./decomposition-section";

export const decompositionPackageSchema = z.object({
  massKg: z.number(),
  lengthMm: z.number().int().positive(),
  widthMm: z.number().int().positive(),
  heightMm: z.number().int().positive(),
});
export type DecompositionPackage = z.infer<typeof decompositionPackageSchema>;

export const decompositionSchema = z.object({
  sections: z.array(decompositionSectionSchema),
  package: decompositionPackageSchema,
});
export type Decomposition = z.infer<typeof decompositionSchema>;
