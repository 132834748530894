import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { black, grey70, fontSizeBody2 } from "../styles";

export const SeamlessLink = styled(Link)`
  color: ${black};
  text-decoration: none;
`;

export const InnerLink = styled.div<{ align?: string }>`
  display: inline-block;
  vertical-align: ${({ align = "baseline" }) => align};
  color: ${grey70};
  line-height: 100%;
  border-bottom: 1px dashed ${grey70};
  cursor: pointer;
`;

export const InnerLinkSmall = styled(InnerLink)`
  font-size: ${fontSizeBody2}px;
`;
