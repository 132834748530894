import React, { memo, useCallback, useMemo } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Select } from "@radix-ui/themes";
import {
  toFormikSchema,
  gutters,
  grey20,
  grey80,
  borderRadius,
  Price,
  Spinner,
  InnerLink,
  disabledGrey,
  useBoolean,
  mqMin,
} from "client-lib";
import {
  WOODEN_PANEL_THICKNESS,
  TemplateConfigControl,
  isTemplateConfigArrayGroup,
  MaterialExpanded,
} from "shared";
import { useTemplateEditor } from "src/features/template-editor/template-editor-context";
import { Control } from "src/features/template-editor/controls";
import { Config, Assets, Variables } from "../types";
import { Formik, Form } from "formik";
import {
  calculateSectionPricesAndTotal,
  usePricing,
  PricingSectionModal,
} from "src/features/template-editor/pricing";

const Controls = styled(Form)`
  ${mqMin.tablet} {
    display: flex;
    align-items: stretch;
    margin-bottom: ${gutters.md}px;
  }

  .rt-SelectTrigger {
    .rt-SelectIcon {
      margin-left: 10px;
    }
  }
`;

const Section = styled.div`
  ${mqMin.tablet} {
    width: 50%;
    margin-right: ${gutters.xl}px;
  }
  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 0;
  }
`;

const Group = styled.div`
  font-size: 18px;
  ${mqMin.tablet} {
    display: flex;
    > div {
      width: 50%;
      margin-left: ${gutters.lg}px;
      :first-of-type {
        margin-left: 0;
      }
    }
  }
`;

const SizeOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${gutters.md}px;
`;

const SizeOption = styled.div`
  margin-right: ${gutters.xs}px;
  margin-bottom: ${gutters.sm}px;
  font-size: 14px;
  cursor: pointer;
  border-radius: ${borderRadius.md}px;
  color: ${grey80};
  background-color: ${grey20};
  padding: ${gutters.xxs}px ${gutters.sm}px;
`;

const Item = styled.div`
  display: flex;
`;

const Thumb = styled.div<{ img?: string | null }>(
  ({ img }) => css`
    width: 20px;
    height: 20px;
    background-color: ${grey20};
    ${img ? `background-image: url(${img})` : ""};
    margin-right: ${gutters.sm}px;
    border-radius: ${borderRadius.sm}px;
  `
);

const TakeFullHeight = styled.div`
  flex: 1 1 100%;
`;

const PriceContainer = styled.div`
  margin-bottom: ${gutters.md}px;
`;

const PRICE_HEIGHT = 45;
const COUNT_LINK_LINE_HEIGHT = 16;
const COUNT_LINK_MARGIN = gutters.xs;

const PRICE_VALUE_HEIGHT =
  PRICE_HEIGHT + (COUNT_LINK_LINE_HEIGHT + 1) + COUNT_LINK_MARGIN;

const PriceValue = styled.div`
  height: ${PRICE_VALUE_HEIGHT}px;
`;

const HeroPrice = styled(Price)<{ isStale: boolean }>(
  ({ isStale }) => css`
    color: ${isStale ? disabledGrey : "inherit"};
    display: block;
    font-weight: 400;
    font-size: 40px;
    line-height: ${PRICE_HEIGHT}px;
    cursor: pointer;
  `
);

const CountLink = styled(InnerLink)`
  font-size: 16px;
  line-height: ${COUNT_LINK_LINE_HEIGHT}px;
  margin-top: ${COUNT_LINK_MARGIN}px;
`;

const STD_WIDTH_OPTIONS = [300, 400, 600, 800];
const STD_LENGTH_OPTIONS = [
  600, 800, 900, 1000, 1200, 1500, 1800, 2000, 2500, 3000,
];

type ControlType = "countertopWidth" | "countertopLength";
const controlNames = new Set<ControlType>([
  "countertopWidth",
  "countertopLength",
]);

export const CounterTopForm = memo(function CounterTopForm() {
  const [isPricingOpen, { set: openPricing, reset: closePricing }] =
    useBoolean();
  const {
    configValues,
    setConfigValues,
    templateFull,
    templatePreset,
    templateVariables,
    decomposition,
  } = useTemplateEditor<Config, Assets, Variables>();
  const { isStale, pricing } = usePricing(templatePreset.id, configValues);

  const controlsByName = useMemo<
    Record<ControlType, TemplateConfigControl>
  >(() => {
    const result = {} as Record<ControlType, TemplateConfigControl>;
    const groups = templateFull.controller.getControlGroups(
      configValues,
      templateFull.assets,
      templateVariables
    );
    for (const group of groups) {
      const controls = isTemplateConfigArrayGroup(group)
        ? group.controls(0, 0)
        : group.controls;

      for (const control of controls) {
        if (controlNames.has(control.name as ControlType)) {
          result[control.name as ControlType] = control;
        }
      }
    }
    return result;
  }, [configValues, templateFull, templateVariables]);

  const coverageOptions = useMemo<JSX.Element[]>(() => {
    return [
      <Select.Item value={null as unknown as string} key="empty">
        <Item>
          <Thumb />
          Без покрытия
        </Item>
      </Select.Item>,
      ...templateFull.assets.countertopCoverage.map((m: MaterialExpanded) => (
        <Select.Item value={m.id} key={m.id}>
          <Item>
            <Thumb img={m.texture1?.path || null} />
            {m.title}
          </Item>
        </Select.Item>
      )),
    ];
  }, [templateFull.assets.countertopCoverage]);

  const handleAttributeChange = useCallback(
    (name: string, value: any) => {
      setConfigValues({ ...configValues, [name]: value });
      templateFull.controller.onAttributeChange?.(
        name,
        value,
        configValues,
        templateFull.assets,
        templateVariables,
        setConfigValues
      );
    },
    [configValues, setConfigValues]
  );

  const validationSchema = useMemo(
    () => toFormikSchema(templateFull.model.configSchema),
    [templateFull.model.configSchema]
  );

  const countertopSectionIndex = useMemo<number | null>(() => {
    const index = decomposition.sections.findIndex(
      (section) => section.title === templateVariables.countertopTitle
    );
    return index > -1 ? index : null;
  }, []);

  const sectionPricesAndTotal = useMemo(
    () => (pricing ? calculateSectionPricesAndTotal(pricing) : null),
    [pricing]
  );

  const countertopPrice = useMemo<number | null>(() => {
    if (!sectionPricesAndTotal || countertopSectionIndex === null) {
      return null;
    }
    return sectionPricesAndTotal.sectionPrices[countertopSectionIndex] || null;
  }, [sectionPricesAndTotal, countertopSectionIndex]);

  return (
    <Formik
      initialValues={configValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={setConfigValues}
    >
      <Controls>
        <Section>
          <h1>Материал</h1>
          <Select.Root
            value={String(configValues.countertopThickness)}
            defaultValue={String(WOODEN_PANEL_THICKNESS[0])}
            onValueChange={(v) =>
              handleAttributeChange("countertopThickness", Number(v))
            }
          >
            <Select.Trigger />
            <Select.Content>
              {WOODEN_PANEL_THICKNESS.map((v) => (
                <Select.Item value={String(v)} key={v}>
                  Мебельный щит толщиной {v}мм
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
          <h1>Размеры</h1>
          <Group>
            <Control
              def={controlsByName.countertopLength}
              onAttributeChange={handleAttributeChange}
            />
            <Control
              def={controlsByName.countertopWidth}
              onAttributeChange={handleAttributeChange}
            />
          </Group>
          <p>
            Старайтесь выбирать размеры так, чтобы они укладывались в
            стандартные габариты заготовок – это позволит сэкономить на обрезках
            и сделает стол немного дешевле.
          </p>
          <p>Вот хорошие варианты размеров:</p>
          <SizeOptions>
            {STD_LENGTH_OPTIONS.map((v) => (
              <SizeOption
                key={`l/${v}`}
                onClick={() => handleAttributeChange("countertopLength", v)}
              >
                Длина {v}
              </SizeOption>
            ))}
          </SizeOptions>
          <SizeOptions>
            {STD_WIDTH_OPTIONS.map((v) => (
              <SizeOption
                key={`w/${v}`}
                onClick={() => handleAttributeChange("countertopWidth", v)}
              >
                Ширина {v}
              </SizeOption>
            ))}
          </SizeOptions>
        </Section>
        <Section>
          <TakeFullHeight>
            <h1>Покрытие</h1>
            <Select.Root
              value={configValues.countertopCoverageId as string}
              onValueChange={(v) =>
                handleAttributeChange("countertopCoverageId", v)
              }
            >
              <Select.Trigger />
              <Select.Content>{coverageOptions}</Select.Content>
            </Select.Root>
            <p>
              Для покрытия столешницы мы используем премиум-масла{" "}
              <b>Rubio Monocoat</b> или <b>Biofa</b>.
            </p>
            <p>
              Оба эти масла прекрасно выделяют естественную текстуту древесины,
              дают гладкую на ощупь поверхность, не липнут и не пахнут, надежно
              защищают древесину от внешних воздействий.
            </p>
          </TakeFullHeight>
          <PriceContainer>
            <h1>Стоимость столешницы:</h1>
            <PriceValue>
              {countertopPrice !== null ? (
                <>
                  <HeroPrice
                    onClick={openPricing}
                    kopecks={countertopPrice}
                    hideKopecks
                    showRubles
                    isStale={isStale}
                  />
                  <CountLink onClick={openPricing}>
                    открыть подробную смету
                  </CountLink>
                </>
              ) : (
                <Spinner size={"medium"} />
              )}
            </PriceValue>
          </PriceContainer>
        </Section>
        {isPricingOpen &&
          pricing &&
          sectionPricesAndTotal &&
          countertopSectionIndex !== null && (
            <PricingSectionModal
              title={"Подробная смета на столешницу"}
              onCloseRequest={closePricing}
              pricing={pricing}
              sectionPrices={sectionPricesAndTotal.sectionPrices}
              index={countertopSectionIndex}
            />
          )}
      </Controls>
    </Formik>
  );
});
