import React from "react";
import { MaterialExpanded, tableLoft1Utils } from "shared";
import { Measurement } from "../../objects";
import { Leg } from "./leg";
import { Config, Assets } from "./types";
import { Countertop } from "./countertop";

function Measurements({ config, assets }: { config: Config; assets: Assets }) {
  const dimensions = tableLoft1Utils.detectPackageDimensions(config, assets);
  if (!dimensions) {
    return null;
  }
  const [x, y, z] = dimensions;
  return (
    <React.Fragment>
      <Measurement
        key="Length"
        p1={[
          -config.countertopLength / 2,
          -config.countertopThickness / 2,
          config.countertopWidth / 2,
        ]}
        p2={[
          -config.countertopLength / 2 + x,
          -config.countertopThickness / 2,
          config.countertopWidth / 2,
        ]}
        normal={[0, -1, 0]}
        textInside
      />
      <Measurement
        key="width"
        p1={[
          -config.countertopLength / 2,
          -config.countertopThickness / 2,
          config.countertopWidth / 2,
        ]}
        p2={[
          -config.countertopLength / 2,
          -config.countertopThickness / 2,
          config.countertopWidth / 2 - z,
        ]}
        normal={[0, -1, 0]}
        textInside
        textRotation={[0, -Math.PI / 2, 0]}
      />
      <Measurement
        key="thickness"
        p1={[
          -config.countertopLength / 2,
          -config.countertopThickness / 2,
          config.countertopWidth / 2,
        ]}
        p2={[
          -config.countertopLength / 2,
          -config.countertopThickness / 2 + y,
          config.countertopWidth / 2,
        ]}
        normal={[-1, 0, 0]}
        textRotation={[0, 0, Math.PI / 2]}
        outer
      />
    </React.Fragment>
  );
}

export function Package({
  config,
  assets,
  frameMaterial,
  supportMaterial,
  countertopCoverageMaterial,
  frameCoverageMaterial,
}: {
  config: Config;
  assets: Assets;
  frameMaterial: MaterialExpanded;
  supportMaterial: MaterialExpanded;
  countertopCoverageMaterial: MaterialExpanded | null;
  frameCoverageMaterial: MaterialExpanded | null;
}) {
  if (!frameMaterial.mmDimension1 || !frameMaterial.mmDimension2) {
    return null;
  }
  const pipeWidth = frameMaterial.mmDimension1;
  // const pipeHeight = 0 && frameMaterial.mmDimension2;

  const countertop = (
    <Countertop
      config={config}
      assets={assets}
      coverage={countertopCoverageMaterial}
    />
  );

  const legsMode = tableLoft1Utils.detectLegsPackageMode(config);

  const frameMaxWidth = Math.max(config.frameTopWidth, config.frameBottomWidth);
  const frameAvgWidth = (config.frameTopWidth + config.frameBottomWidth) / 2;
  switch (legsMode) {
    case "parallel":
      return (
        <React.Fragment>
          {countertop}
          <Measurements config={config} assets={assets} />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, 0, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? -(config.countertopLength - config.frameHeight) / 2
                : 0,
              config.countertopThickness / 2 + pipeWidth / 2,
              config.packageFramePosition === "corners"
                ? (config.countertopWidth - frameMaxWidth) / 2
                : frameAvgWidth / 2,
            ]}
          />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, 0, -Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? -(config.countertopLength - config.frameHeight) / 2
                : 0,
              config.countertopThickness / 2 + pipeWidth / 2,
              config.packageFramePosition === "corners"
                ? -(config.countertopWidth - frameMaxWidth) / 2
                : -frameAvgWidth / 2,
            ]}
          />
        </React.Fragment>
      );
    case "parallel-rotated":
      return (
        <React.Fragment>
          {countertop}
          <Measurements config={config} assets={assets} />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, Math.PI / 2, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? -(config.countertopLength - frameMaxWidth) / 2
                : 0,
              config.countertopThickness / 2 + pipeWidth / 2,
              config.packageFramePosition === "corners"
                ? (config.countertopWidth - config.frameHeight) / 2
                : config.frameHeight / 2,
            ]}
          />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, Math.PI / 2, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? -(config.countertopLength - frameMaxWidth) / 2
                : 0,
              config.countertopThickness / 2 + pipeWidth / 2,
              config.packageFramePosition === "corners"
                ? -(config.countertopWidth - config.frameHeight) / 2
                : -config.frameHeight / 2,
            ]}
          />
        </React.Fragment>
      );
    case "sequence":
      return (
        <React.Fragment>
          {countertop}
          <Measurements config={config} assets={assets} />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, 0, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? -(config.countertopLength - config.frameHeight) / 2
                : -config.frameHeight / 2,
              config.countertopThickness / 2 + pipeWidth / 2,
              config.packageFramePosition === "corners"
                ? (config.countertopWidth - frameMaxWidth) / 2
                : 0,
            ]}
          />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, 0, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? (config.countertopLength - config.frameHeight) / 2
                : config.frameHeight / 2,
              config.countertopThickness / 2 + pipeWidth / 2,
              config.packageFramePosition === "corners"
                ? (config.countertopWidth - frameMaxWidth) / 2
                : 0,
            ]}
          />
        </React.Fragment>
      );
    case "sequence-rotated":
      return (
        <React.Fragment>
          {countertop}
          <Measurements config={config} assets={assets} />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, Math.PI / 2, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? -(config.countertopLength - frameMaxWidth) / 2
                : -frameAvgWidth / 2,
              config.countertopThickness / 2 + pipeWidth / 2,
              config.packageFramePosition === "corners"
                ? (config.countertopWidth - config.frameHeight) / 2
                : 0,
            ]}
          />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, -Math.PI / 2, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? (config.countertopLength - frameMaxWidth) / 2
                : frameAvgWidth / 2,
              config.countertopThickness / 2 + pipeWidth / 2,
              config.packageFramePosition === "corners"
                ? (config.countertopWidth - config.frameHeight) / 2
                : 0,
            ]}
          />
        </React.Fragment>
      );
    case "layer":
      return (
        <React.Fragment>
          {countertop}
          <Measurements config={config} assets={assets} />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, 0, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? -(config.countertopLength - config.frameHeight) / 2
                : 0,
              config.countertopThickness / 2 + pipeWidth / 2,
              config.packageFramePosition === "corners"
                ? (config.countertopWidth - frameMaxWidth) / 2
                : 0,
            ]}
          />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, 0, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? -(config.countertopLength - config.frameHeight) / 2
                : 0,
              config.countertopThickness / 2 + pipeWidth / 2 + pipeWidth,
              config.packageFramePosition === "corners"
                ? (config.countertopWidth - frameMaxWidth) / 2
                : 0,
            ]}
          />
        </React.Fragment>
      );
    case "layer-rotated":
      return (
        <React.Fragment>
          {countertop}
          <Measurements config={config} assets={assets} />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, Math.PI / 2, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? -(config.countertopLength - frameMaxWidth) / 2
                : 0,
              config.countertopThickness / 2 + pipeWidth / 2,
              config.packageFramePosition === "corners"
                ? (config.countertopWidth - config.frameHeight) / 2
                : 0,
            ]}
          />
          <Leg
            config={config}
            material={frameMaterial}
            supportMaterial={supportMaterial}
            coverage={frameCoverageMaterial}
            rotation={[0, Math.PI / 2, Math.PI / 2]}
            position={[
              config.packageFramePosition === "corners"
                ? -(config.countertopLength - frameMaxWidth) / 2
                : 0,
              config.countertopThickness / 2 + pipeWidth / 2 + pipeWidth,
              config.packageFramePosition === "corners"
                ? (config.countertopWidth - config.frameHeight) / 2
                : 0,
            ]}
          />
        </React.Fragment>
      );
  }

  return (
    <group position={[0, 0, 0]}>
      <Countertop
        config={config}
        assets={assets}
        coverage={countertopCoverageMaterial}
      />
      <group
        rotation={[0, 0, Math.PI / 2]}
        position={[
          // -(config.counterTopLength - config.frameHeight)/2,
          0, // -(config.countertopLength),
          0,
          0,
        ]}
      >
        <Leg
          config={config}
          material={frameMaterial}
          supportMaterial={supportMaterial}
          coverage={frameCoverageMaterial}
        />
      </group>
      <Measurement
        key="Countertop Length"
        p1={[
          -config.countertopLength / 2,
          config.countertopThickness / 2,
          config.countertopWidth / 2,
        ]}
        p2={[
          config.countertopLength / 2,
          config.countertopThickness / 2,
          config.countertopWidth / 2,
        ]}
        normal={[0, 1, 0]}
      />
    </group>
  );
}
